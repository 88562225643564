import { Strategy } from '../utils/entities/Strategy';
import * as icons from '../assets/defi';
import { ChainId } from '../constants';

const convex_lusd = new Strategy('Convex LUSD', {
  protocol: 'convex',
  poolName: 'lusd',
  protocolIcon: icons['convex'],
  outputAssets: {
    gov: 'CVX',
    crv: 'CRV',
  },
  allocationName: 'convex-lusd',
  tokenPricesNeeded: ['curve-dao-token', 'liquity-usd', 'convex-finance'],
  options: {
    curveLiquidityGaugeAddress: {
      [ChainId.MAINNET]: '0xd8b712d29381748db89c36bca0138d7c75866ddf',
    },
  },
});

export default convex_lusd;
