import { useMemo } from 'react';
import { useHistoricalPoolValues } from './index';

export function useHistoricalTvls() {
  const { chartData: daiPoolValues } = useHistoricalPoolValues('DAI');
  const { chartData: usdcPoolValues } = useHistoricalPoolValues('USDC');
  const { chartData: usdtPoolValues } = useHistoricalPoolValues('USDT');

  const tvlChartData = useMemo(() => {
    let data;

    if (daiPoolValues.data && usdcPoolValues.data && usdtPoolValues.data) {
      data = {
        '7d': {
          DAI: daiPoolValues.data['7d'],
          USDC: usdcPoolValues.data['7d'],
          USDT: usdtPoolValues.data['7d'],
        },
        '1m': {
          DAI: daiPoolValues.data['1m'],
          USDC: usdcPoolValues.data['1m'],
          USDT: usdtPoolValues.data['1m'],
        },
        '3m': {
          DAI: daiPoolValues.data['3m'],
          USDC: usdcPoolValues.data['3m'],
          USDT: usdtPoolValues.data['3m'],
        },
        all: {
          DAI: daiPoolValues.data['all'],
          USDC: usdcPoolValues.data['all'],
          USDT: usdtPoolValues.data['all'],
        },
      };
    }

    return {
      data: data,
      isLoading:
        !data &&
        !daiPoolValues.error &&
        !usdcPoolValues.error &&
        !usdtPoolValues.error,
      error:
        daiPoolValues.error ?? usdcPoolValues.error ?? usdtPoolValues.error,
    };
  }, [daiPoolValues, usdcPoolValues, usdtPoolValues]);

  return { tvlChartData };
}
