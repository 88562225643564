import { Contract } from '../utils/entities/Contract';
import { Strategy } from '../utils/entities/Strategy';
import * as icons from '../assets/defi';
import { ChainId } from '../constants';

import { CURVE_POOL_ABI, CURVE_LIQUIDITY_GAUGE_ABI } from './abis';
import {
  CURVE_GAUGE_CONTROLLER,
  getCurveBaseApy,
  getCrvTokenApy,
  getCurveRewardTokenApy,
} from './utils/curve';

const CURVE_SUSD_POOL = {
  [ChainId.MAINNET]: new Contract(
    '0xA5407eAE9Ba41422680e2e00537571bcC53efBfD',
    CURVE_POOL_ABI,
    'Curve sUSD Pool'
  ),
};

const SUSD_LIQUIDITY_GAUGE_ADDRESS =
  '0xa90996896660decc6e997655e065b23788857849';

const CURVE_SUSD_LIQUIDITY_GAUGE = {
  [ChainId.MAINNET]: new Contract(
    SUSD_LIQUIDITY_GAUGE_ADDRESS,
    CURVE_LIQUIDITY_GAUGE_ABI
  ),
};

const curve_susd = new Strategy('Curve sUSD', {
  protocol: 'curve',
  poolName: 'susdv2',
  protocolIcon: icons['curve'],
  outputAssets: ['CRV', 'SNX'],
  contracts: {
    pool: CURVE_SUSD_POOL,
    liquidityGauge: CURVE_SUSD_LIQUIDITY_GAUGE,
    gaugeController: CURVE_GAUGE_CONTROLLER,
  },
  allocationName: 'curve-susdv2',
  tokenPricesNeeded: ['curve-dao-token', 'nusd'],
  getApys: (chainId, library) => ({
    baseApy: getCurveBaseApy('susd'),
    CRV: getCrvTokenApy('curve_susd', chainId, library),
    SNX: getCurveRewardTokenApy(SUSD_LIQUIDITY_GAUGE_ADDRESS),
  }),
  description: 'Add strategy description here...',
});

export default curve_susd;
