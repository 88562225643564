import React from 'react';

const Icon = ({ adjustIcon }) => {
  return (
    <svg
      style={{ marginRight: adjustIcon }}
      height="90%"
      width="90%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 6.01953V6"
        stroke="#d0d0d0"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 10V18"
        stroke="#d0d0d0"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Icon;
