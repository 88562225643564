import React from 'react';

import { useSessionStore } from '../../../../state/stores';

import Nav from '../../controls/Nav';
import BrandLink from './BrandLink';
import ConnectButton from '../../../pages/Landing/ConnectButton';
import InstallMetamaskButton from '../../../pages/Landing/InstallMetamaskButton';
import { headerWrap, headerContainer } from './Header.module.scss';

const InjectedMetaMask = window?.ethereum?.isMetaMask;

function Header() {
  const isSessionActive = useSessionStore(state => state.isSessionActive);

  return (
    <header className={`${headerWrap}`}>
      <div className={headerContainer}>
        <BrandLink />
        {isSessionActive ? (
          <Nav />
        ) : InjectedMetaMask ? (
          <ConnectButton />
        ) : (
          <InstallMetamaskButton />
        )}
      </div>
    </header>
  );
}

export default Header;
