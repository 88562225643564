import { useMemo } from 'react';
import { useWeb3React } from '@web3-react/core';
import useSWR from 'swr';

import { POOL_CONTRACT_KEYS } from '../constants';
import { useAccountBalance } from './useAccountBalance';
import { fromWei } from '../utils/helpers';

export function useFormBalances(formType) {
  const { chainId } = useWeb3React();

  const tokens = {
    DEPOSIT_WITHDRAW: [
      'DAI',
      'USDC',
      'USDT',
      'aptDAI',
      'aptUSDC',
      'aptUSDT',
      'DAI_DEMO_POOL',
      'USDC_DEMO_POOL',
      'USDT_DEMO_POOL',
    ],
    STAKE_UNSTAKE: ['UNIV2', 'BPT', 'UNIV2_STAKING', 'BPT_STAKING'],
  };

  /**
   * Hooks can't be called conditionally.
   * If useAccountBalance hook doesn't receive token,
   * it won't fetch the balance.
   */
  const checkTokenList = (formType, tokenKey) => {
    if (tokens[formType].includes(tokenKey)) {
      return tokenKey;
    }
  };

  // Stablecoins
  const { balance: daiBalance } = useAccountBalance(
    checkTokenList(formType, 'DAI')
  );
  const { balance: usdcBalance } = useAccountBalance(
    checkTokenList(formType, 'USDC')
  );
  const { balance: usdtBalance } = useAccountBalance(
    checkTokenList(formType, 'USDT')
  );

  // Pool Tokens (APT)
  const { balance: aptDAIBalance } = useAccountBalance(
    checkTokenList(formType, POOL_CONTRACT_KEYS['DAI'])
  );
  const { balance: aptUSDCBalance } = useAccountBalance(
    checkTokenList(formType, POOL_CONTRACT_KEYS['USDC'])
  );
  const { balance: aptUSDTBalance } = useAccountBalance(
    checkTokenList(formType, POOL_CONTRACT_KEYS['USDT'])
  );

  const fetchUnderlyer = (poolTokenKey, chainId, aptBalance) => {
    if (poolTokenKey && chainId && aptBalance) {
      return [poolTokenKey, chainId, 'getUnderlyerAmount', aptBalance];
    }
  };

  // Pool Underlyers
  const daiUnderlyerBalance = useSWR(
    fetchUnderlyer(POOL_CONTRACT_KEYS['DAI'], chainId, aptDAIBalance.data)
  );
  const usdcUnderlyerBalance = useSWR(
    fetchUnderlyer(POOL_CONTRACT_KEYS['USDC'], chainId, aptUSDCBalance.data)
  );
  const usdtUnderlyerBalance = useSWR(
    fetchUnderlyer(POOL_CONTRACT_KEYS['USDT'], chainId, aptUSDTBalance.data)
  );

  // Liquidity Pool Tokens
  const { balance: univ2Balance } = useAccountBalance(
    checkTokenList(formType, 'UNIV2')
  );
  const { balance: bptBalance } = useAccountBalance(
    checkTokenList(formType, 'BPT')
  );

  // Staked Tokens
  const { balance: univv2StakingBalance } = useAccountBalance(
    checkTokenList(formType, 'UNIV2_STAKING')
  );
  const { balance: bptStakingBalance } = useAccountBalance(
    checkTokenList(formType, 'BPT_STAKING')
  );

  const balances = useMemo(() => {
    let data;
    let error;

    switch (formType) {
      case 'DEPOSIT_WITHDRAW':
        if (
          ![
            daiBalance.data,
            usdcBalance.data,
            usdtBalance.data,
            daiUnderlyerBalance.data,
            usdcUnderlyerBalance.data,
            usdtUnderlyerBalance.data,
          ].includes(undefined)
        ) {
          data = {
            DEPOSIT: {
              DAI: fromWei(daiBalance.data, 'DAI'),
              USDC: fromWei(usdcBalance.data, 'USDC'),
              USDT: fromWei(usdtBalance.data, 'USDT'),
            },
            WITHDRAW: {
              aptDAI: fromWei(daiUnderlyerBalance.data, 'DAI'),
              aptUSDC: fromWei(usdcUnderlyerBalance.data, 'USDC'),
              aptUSDT: fromWei(usdtUnderlyerBalance.data, 'USDT'),
            },
          };
        }

        if (
          [
            daiBalance.error,
            usdcBalance.error,
            usdtBalance.error,
            daiUnderlyerBalance.error,
            usdcUnderlyerBalance.error,
            usdtUnderlyerBalance.error,
          ].some(val => val instanceof Error)
        ) {
          error = new Error(
            "STABLECOIN_BALANCE_ERROR: Can't fetch form balances due to error(s) with stablecoin balances"
          );
        }

        return {
          data,
          isLoading: !error && !data,
          error,
        };

      case 'STAKE_UNSTAKE':
        if (
          ![
            univ2Balance.data,
            bptBalance.data,
            univv2StakingBalance.data,
            bptStakingBalance.data,
          ].includes(undefined)
        ) {
          data = {
            UNIV2: fromWei(univ2Balance.data, 'UNIV2'),
            BPT: fromWei(bptBalance.data, 'BPT'),
            UNIV2_STAKING: fromWei(univv2StakingBalance.data, 'UNIV2_STAKING'),
            BPT_STAKING: fromWei(bptStakingBalance.data, 'BPT_STAKING'),
          };
        }

        if (
          [
            univ2Balance.error,
            bptBalance.error,
            univv2StakingBalance.error,
            bptStakingBalance.error,
          ].some(val => val instanceof Error)
        ) {
          error = new Error(
            "LPTOKEN_BALANCE_ERROR: Can't fetch staking form balances due to error(s) with LP token balances"
          );
        }

        return {
          data,
          isLoading: !error && !data,
          error,
        };

      default:
        return;
    }
  }, [
    formType,
    daiBalance,
    usdcBalance,
    usdtBalance,
    daiUnderlyerBalance,
    usdcUnderlyerBalance,
    usdtUnderlyerBalance,
    univ2Balance,
    bptBalance,
    univv2StakingBalance,
    bptStakingBalance,
  ]);

  return { balances };
}
