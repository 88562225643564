export class Strategy {
  constructor(
    strategyName,
    {
      protocol,
      poolName,
      protocolIcon,
      inputAssets,
      outputAssets,
      contracts,
      allocationName,
      tokenPricesNeeded,
      getApys,
      description,
      options,
    } = {}
  ) {
    this.strategyName = strategyName;
    this.poolName = poolName;
    this.protocol = protocol;
    this.protocolIcon = protocolIcon;
    this.inputAssets = inputAssets;
    this.outputAssets = outputAssets;
    this.contracts = contracts;
    this.allocationName = allocationName;
    this.tokenPricesNeeded = tokenPricesNeeded;
    this.getApys = getApys;
    this.description = description;
    this.options = options;
  }
}
