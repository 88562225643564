import { useMemo } from 'react';
import { useWeb3React } from '@web3-react/core';
import useEtherSWR from 'ether-swr';

import * as Contracts from '../constants/contracts';
import { ChainId } from '../constants';
import { fromWei } from '../utils/helpers';
import { useTotalPoolValues } from '.';
import { FetcherError } from '../utils/errors';

const ABIs = {
  [ChainId.MAINNET]: [
    [Contracts['BL_APY'][1].address, Contracts['BL_APY'][1].abi],
  ],
};

export function useBlApyValue(blApyAmount) {
  const { chainId } = useWeb3React();

  const BL_APY_ADDRESS = Contracts['BL_APY'][chainId]?.address;

  const { data: totalSupply, error: totalSupplyError } = useEtherSWR(
    [BL_APY_ADDRESS, 'totalSupply()'],
    {
      ABIs: new Map(ABIs[chainId]),
    }
  );

  const { totalValueLocked } = useTotalPoolValues();

  const totalBlApySupply = useMemo(() => {
    let data;
    let error;

    if (totalSupplyError) {
      error = new FetcherError(
        totalSupplyError.data?.message ?? totalSupplyError.message,
        '"totalSupply"',
        'useBlApyValue'
      );
      console.error(error.message);
    }

    if (blApyAmount.data && totalSupply) {
      data = fromWei(totalSupply).plus(blApyAmount.data);
    }

    return {
      data: data,
      isLoading: !data && !totalSupplyError,
      error: error,
    };
  }, [blApyAmount, totalSupply, totalSupplyError]);

  const blApyValue = useMemo(() => {
    let data;

    // blApyValue = blAPY Balance * TVL / Total blAPY Supply
    if (blApyAmount.data && totalValueLocked.data && totalBlApySupply.data) {
      data = blApyAmount.data
        .times(totalValueLocked.data)
        .div(totalBlApySupply.data);
    }

    return {
      data: data,
      error: totalValueLocked.error ?? totalBlApySupply.error,
    };
  }, [blApyAmount, totalValueLocked, totalBlApySupply]);

  return { blApyValue };
}
