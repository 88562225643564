import { Strategy } from '../utils/entities/Strategy';
import * as icons from '../assets/defi';
import { ChainId } from '../constants';

const convex_compound = new Strategy('Convex Compound', {
  protocol: 'convex',
  poolName: 'compound',
  protocolIcon: icons['convex'],
  outputAssets: {
    gov: 'CVX',
    crv: 'CRV',
  },
  allocationName: 'convex-compound',
  tokenPricesNeeded: ['curve-dao-token', 'convex-finance'],
  options: {
    curveLiquidityGaugeAddress: {
      [ChainId.MAINNET]: '0x7ca5b0a2910B33e9759DC7dDB0413949071D7575',
    },
  },
});

export default convex_compound;
