import React, { useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { deployedChains } from '../../utils/deployedChains';

// import Footer from './Footer';
import NetworkNotify from './NetworkNotify';
import SliderMenu from '../apy/layout/SliderMenu';
import { layoutWrap } from './scss/Layout.module.scss';

function Layout({ children }) {
  const { active, chainId } = useWeb3React();
  const [isNotifyOpen, setNotifyOpen] = useState(
    deployedChains.includes(chainId)
  );

  return (
    <div className={layoutWrap}>
      {active && !deployedChains.includes(chainId) && (
        <NetworkNotify
          chainId={chainId}
          isOpen={isNotifyOpen}
          onClose={() => setNotifyOpen(false)}
        />
      )}

      <main>
        {children}
        <SliderMenu />
      </main>

      {/* Temp remove footer */}
      {/* {active && !vwOver1024 ? (
        <MobilePanel
          expanded={expanded}
          setExpanded={() => setExpanded(!expanded)}
        />
      ) : (
        <Footer />
      )} */}
    </div>
  );
}

export default Layout;
