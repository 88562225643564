import React, { forwardRef } from 'react';
import { useMedia } from 'use-media';

import Panel from '../Panel';
import FormToggle from '../../controls/FormToggle';

import { togglePanelWrap } from './TogglePanel.module.scss';

const TogglePanel = forwardRef(
  (
    {
      classes = {},
      tabLabels,
      selectedTab,
      toggleTab,
      children,
      isTourElementActive,
    },
    ref
  ) => {
    // Media queries
    const isVwOver500 = useMedia({ minWidth: '501px' });

    return (
      <div
        ref={ref}
        className={`${togglePanelWrap} ${classes.togglePanelWrap}`}
        style={
          isTourElementActive
            ? {
                overflow: 'initial',
                scrollMarginTop: isVwOver500 && '8rem',
              }
            : null
        }
      >
        <FormToggle
          classes={classes.formToggleClasses}
          /**
           * Example:
           *   { formToggleWrap, tabClasses: { tab, activeOverlay }}
           */
          tabLabels={tabLabels}
          toggleTab={toggleTab}
          selectedTab={selectedTab}
          isTourElementActive={isTourElementActive}
        />
        <Panel
          classes={classes.panelClasses}
          isTourElementActive={isTourElementActive}
        >
          {children}
        </Panel>
      </div>
    );
  }
);

export default TogglePanel;
