import { useMemo } from 'react';
import { useWeb3React } from '@web3-react/core';
import { BigNumber } from 'bignumber.js';
import useEtherSWR from 'ether-swr';

import { useAbisStore } from '../state/stores';
import {
  useAccountBalance,
  useTotalPoolValues,
  useCoinGeckoPrices,
  useOracleStatus,
  useApi,
} from './index';
import { POOL_CONTRACT_KEYS } from '../constants';
import { REWARDS_SERVICE } from '../constants/claims';
import { isListEmpty, fromWei } from '../utils/helpers';
import { FetcherError } from '../utils/errors';

export function usePortfolioValue(account) {
  const { chainId } = useWeb3React();

  const { coinPrices } = useCoinGeckoPrices();

  // Zustand
  const { abis: ABIs, addresses } = useAbisStore(state => state.abiInfo);

  // Check Oracle Status
  const { isOracleLocked, blockBeforeLock } = useOracleStatus();

  // Set older blockTag is oracle is locked
  let blockTag = 'latest';
  if (isOracleLocked && blockBeforeLock) {
    blockTag = blockBeforeLock;
  }

  const { totalValueLocked } = useTotalPoolValues();

  // Get APT Balances
  const { balance: daiAptBalance } = useAccountBalance(
    POOL_CONTRACT_KEYS['DAI']
  );
  const { balance: usdcAptBalance } = useAccountBalance(
    POOL_CONTRACT_KEYS['USDC']
  );
  const { balance: usdtAptBalance } = useAccountBalance(
    POOL_CONTRACT_KEYS['USDT']
  );

  const aptBalances = useMemo(() => {
    return {
      aptDAI: {
        data: daiAptBalance.data,
        isLoading: daiAptBalance.isLoading,
        error: daiAptBalance.error,
      },
      aptUSDC: {
        data: usdcAptBalance.data,
        isLoading: usdcAptBalance.isLoading,
        error: usdcAptBalance.error,
      },
      aptUSDT: {
        data: usdtAptBalance.data,
        isLoading: usdtAptBalance.isLoading,
        error: usdtAptBalance.error,
      },
    };
  }, [daiAptBalance, usdcAptBalance, usdtAptBalance]);

  // Need to first get pool total supplys
  // (bc will only call getAPTValue on pool's that have a supply)
  const { data: totalSupplys, error: totalSupplysError } = useEtherSWR(
    !isListEmpty(ABIs)
      ? [
          [addresses[POOL_CONTRACT_KEYS['DAI']][0], 'totalSupply'],
          [addresses[POOL_CONTRACT_KEYS['USDC']][0], 'totalSupply'],
          [addresses[POOL_CONTRACT_KEYS['USDT']][0], 'totalSupply'],
        ]
      : [],
    { ABIs: new Map(ABIs) }
  );

  const supplysFetcherError = useMemo(() => {
    let fetcherError;

    if (totalSupplysError) {
      fetcherError = new FetcherError(
        totalSupplysError.data?.message ?? totalSupplysError.message,
        '"totalSupply"',
        'usePortfolioValue'
      );
      console.error(fetcherError.message);
    }

    return fetcherError;
  }, [totalSupplysError]);

  // Construct getAPTValue calls
  let calls = [];
  const aptBalanceData = Object.values(aptBalances).map(value => value.data);
  if (totalSupplys && !aptBalanceData.includes(undefined)) {
    totalSupplys.forEach((totalSupply, idx) => {
      const poolKeys = [
        POOL_CONTRACT_KEYS['DAI'],
        POOL_CONTRACT_KEYS['USDC'],
        POOL_CONTRACT_KEYS['USDT'],
      ];
      if (!totalSupply.isZero()) {
        calls.push([
          addresses[poolKeys[idx]][0],
          'getAPTValue',
          aptBalanceData[idx],
          { blockTag: blockTag },
        ]);
      }
    });
  }

  // Get APT values
  const { data: aptValues, error: aptValuesError } = useEtherSWR(
    !isListEmpty(calls) ? calls : [],
    {
      ABIs: new Map(ABIs),
    }
  );

  const aptValueFetcherError = useMemo(() => {
    let fetcherError;

    if (aptValuesError) {
      fetcherError = new FetcherError(
        aptValuesError.data?.message ?? aptValuesError.message,
        '"getAPTValue"',
        'usePortfolioValue'
      );
      console.error(fetcherError.message);
    }

    return fetcherError;
  }, [aptValuesError]);

  // Total APT Value = USD value of all of a user's APT
  const totalAptValue = useMemo(() => {
    let data;

    if (
      totalSupplys &&
      totalSupplys[0].add(totalSupplys[1]).add(totalSupplys[2]).isZero()
    ) {
      data = fromWei(0, 'USD');
    } else if (aptValues) {
      const values = aptValues.map(value => BigNumber(value.toString()));
      const totalValue = values.reduce((total, value) => {
        return total.plus(value);
      }, BigNumber(0));

      data = fromWei(totalValue, 'USD');
    }

    return {
      data,
      isLoading: !data && !aptValueFetcherError && !supplysFetcherError,
      error: supplysFetcherError ?? aptValueFetcherError,
    };
  }, [aptValues, aptValueFetcherError, totalSupplys, supplysFetcherError]);

  // Account Value = Total APT Value plus USD value of a user's unclaimed APY tokens
  const { res: unclaimed } = useApi(
    'apy.finance',
    `${REWARDS_SERVICE}/unclaimed/${chainId}/${account}`,
    { conditions: [chainId, account] }
  );

  const accountValue = useMemo(() => {
    let data;
    let priceError;

    if (coinPrices.data && !coinPrices.data['apy-finance']) {
      priceError = new Error(
        "Missing Price: Can't calculate Account Value without APY Price"
      );
      console.error(priceError.message);
    }

    if (
      totalAptValue.data &&
      unclaimed.data &&
      coinPrices.data?.['apy-finance']
    ) {
      const unclaimedAmount = fromWei(unclaimed.data.unclaimedRewards, 'APY');
      const unclaimedValue = unclaimedAmount.times(
        coinPrices.data['apy-finance']
      );
      data = totalAptValue.data.plus(unclaimedValue);
    }

    return {
      data: data,
      isLoading:
        !data && !totalAptValue.error && !unclaimed.error && !priceError,
      error: totalAptValue.error ?? unclaimed.error ?? priceError,
    };
  }, [coinPrices, totalAptValue, unclaimed]);

  const tvlShare = useMemo(() => {
    let data;

    if (totalValueLocked.data?.isZero()) {
      data = BigNumber(0);
    } else if (totalValueLocked.data && totalAptValue.data) {
      data = totalAptValue.data.div(totalValueLocked.data);
    }

    return {
      data: data,
      isLoading: !data && !totalValueLocked.error && !totalAptValue.error,
      error: totalValueLocked.error ?? totalAptValue.error,
    };
  }, [totalValueLocked, totalAptValue]);

  return { aptBalances, totalAptValue, tvlShare, accountValue };
}
