import React from 'react';
import styled from 'styled-components';
import { WarningCircle } from '@phosphor-icons/react';

import { useModal } from '../../../hooks';
import Modal from '../reusableComponents/Modal';

function CortexModal() {
  const [isCortexModalOpen, setIsCortexModalOpen] = useModal('cortex');

  return (
    <Modal
      isModalOpen={isCortexModalOpen}
      closeModal={() => setIsCortexModalOpen(false)}
    >
      <StyledPanel>
        <Stack>
          <ModalIcon>
            <WarningCircle size={64} color="#18a974" />
          </ModalIcon>
          <div>
            <span
              style={{
                fontSize: '24px',
                fontFamily: 'archiabold',
                lineHeight: '1.2',
              }}
            >
              APY.Finance has upgraded!
            </span>
            <br />
            <br />
            <span
              style={{
                lineHeight: '1.3',
              }}
            >
              Visit our new website at{' '}
              <a href="https://cortexdao.com/" style={{ color: '#18a974' }}>
                CortexDAO.com
              </a>{' '}
              to access your existing deposits and brand new features.
            </span>
          </div>
          <LinkButton href="https://cortexdao.com/">Visit CortexDAO</LinkButton>
        </Stack>
      </StyledPanel>
    </Modal>
  );
}

const PadBox = styled.div`
  padding: 1rem;
`;

const Stack = styled.div`
  display: grid;
  gap: 1.25rem;
`;

const StyledPanel = styled(PadBox)`
  position: relative;
  border: 1px solid #18a974;
  border-radius: 10px;
  background-color: #191919;
  width: 300px;
  padding: 1.25rem;
  font-family: archiaregular;
  font-size: 1rem;
  color: #d0d0d0;
  text-align: center;
  box-sizing: border-box;

  @media (min-width: 501px) {
    width: 350px;
  }
`;

const ModalIcon = styled.div`
  display: grid;
  place-items: center;
`;

const LinkButton = styled.a`
  display: block;
  background-color: #159868;
  border: 1px solid transparent;
  border-radius: 7px;
  padding: 0.65rem 0.75rem;
  color: #d0d0d0;
  text-align: center;
  text-decoration: none;
  font-weight: 700;
  line-height: initial;
  letter-spacing: 1px;
  cursor: pointer;
  user-select: none;
`;

export default CortexModal;
