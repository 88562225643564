import React from 'react';
import { BeatLoader } from 'react-spinners';
import { useMedia } from 'use-media';

import {
  formBalanceWrap,
  balance,
  notEnabled,
  errorMessage,
} from './FormBalance.module.scss';

const FormBalance = ({
  poolsLocked,
  oracleLocked,
  protocol,
  currentForm,
  token,
  displayBalance,
  label = 'Balance',
  children,
  error,
  isLoading,
  allowance,
  isApproved,
  showDisabledNotice = false,
}) => {
  const showBalance = () => {
    if (
      currentForm === 'STAKE' &&
      displayBalance?.isZero() &&
      allowance.data.isZero()
    ) {
      return (
        <span
          className={notEnabled}
          style={{ fontSize: isVwOver500 ? '1.3rem' : '1.2rem' }}
        >{`Add liquidity on ${protocol} first`}</span>
      );
    } else if (
      (currentForm === 'DEPOSIT' || currentForm === 'STAKE') &&
      isApproved.data === false
    ) {
      return (
        <span className={notEnabled}>{`Enable ${token} for ${
          currentForm === 'DEPOSIT' ? 'depositing' : 'staking'
        }`}</span>
      );
    } else {
      return children;
    }
  };

  // Media queries
  const isVwOver500 = useMedia({ minWidth: '501px' });

  return (
    <div className={formBalanceWrap}>
      {showDisabledNotice ? (
        <div className={errorMessage}>
          {oracleLocked
            ? 'Paused while validating Total Value Locked'
            : 'Paused while under maintenance'}
        </div>
      ) : isLoading ? (
        <BeatLoader size={18} margin={6} color={'gray'} />
      ) : (
        <>
          <h1>
            {currentForm === 'DEPOSIT' || currentForm === 'STAKE'
              ? token &&
                isApproved.data === true &&
                !error &&
                `${token} ${label}`
              : token && !error && `${token} ${label}`}
          </h1>
          {!error ? (
            <div className={balance}>{showBalance()}</div>
          ) : (
            <div className={errorMessage}>{error}</div>
          )}
        </>
      )}
    </div>
  );
};

export default FormBalance;
