import { useMemo } from 'react';
import { useWeb3React } from '@web3-react/core';
import useEtherSWR from 'ether-swr';

import * as Contracts from '../constants/contracts';
import { ChainId } from '../constants';
import { fromWei } from '../utils/helpers';
import { useTotalPoolValues } from '.';
import { FetcherError } from '../utils/errors';

const ABIs = {
  [ChainId.MAINNET]: [
    [Contracts['BL_APY'][1].address, Contracts['BL_APY'][1].abi],
  ],
};

export function useBlApyData(account) {
  const { chainId } = useWeb3React();

  const BL_APY_ADDRESS = Contracts['BL_APY'][chainId]?.address;

  const { data: balance, error: balanceError, mutate } = useEtherSWR(
    [BL_APY_ADDRESS, 'balanceOf(address)', account],
    {
      ABIs: new Map(ABIs[chainId]),
    }
  );

  const { data: totalSupply, error: totalSupplyError } = useEtherSWR(
    [BL_APY_ADDRESS, 'totalSupply()'],
    {
      ABIs: new Map(ABIs[chainId]),
    }
  );

  const { totalValueLocked } = useTotalPoolValues();

  const blApyBalance = useMemo(() => {
    let data;
    let error;

    if (balanceError) {
      error = new FetcherError(
        balanceError.data?.message ?? balanceError.message,
        '"balanceOf"',
        'useBlApyData'
      );
      console.error(error.message);
    }

    if (balance) {
      data = fromWei(balance);
    }

    return {
      data: data,
      isLoading: !data && !balanceError,
      error: error,
    };
  }, [balance, balanceError]);

  const totalBlApySupply = useMemo(() => {
    let data;
    let error;

    if (totalSupplyError) {
      error = new FetcherError(
        totalSupplyError.data?.message ?? totalSupplyError.message,
        '"totalSupply"',
        'useBlApyData'
      );
      console.error(error.message);
    }

    if (totalSupply) {
      data = fromWei(totalSupply);
    }

    return {
      data: data,
      isLoading: !data && !totalSupplyError,
      error: error,
    };
  }, [totalSupply, totalSupplyError]);

  const blApyValue = useMemo(() => {
    let data;

    // blApyValue = blAPY Balance * TVL / Total blAPY Supply
    if (blApyBalance.data && totalValueLocked.data && totalBlApySupply.data) {
      data = blApyBalance.data
        .times(totalValueLocked.data)
        .div(totalBlApySupply.data);
    }

    return {
      data: data,
      isLoading:
        !data &&
        !blApyBalance.error &&
        !totalValueLocked.error &&
        !totalBlApySupply.error,
      error:
        blApyBalance.error ?? totalValueLocked.error ?? totalBlApySupply.error,
    };
  }, [blApyBalance, totalValueLocked, totalBlApySupply]);

  return { blApyBalance, blApyValue, updateBlApyData: mutate };
}
