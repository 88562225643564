import React from 'react';
import styled from 'styled-components';
import { useMedia } from 'react-use';

import { breakpoints } from '../../styles/breakpoints';
import BoostMetric from './sections/BoostMetric';
import LockData from './sections/LockData';
import LockApy from './sections/LockApy';
import Faq from './sections/Faq';
import { Stack, Grid } from '../../styles/primitives';

function BoostLock() {
  const isVwUnder900 = useMedia('(max-width: 900px)');

  return (
    <Stack gap={7} style={{ margin: '3rem 0' }}>
      <Stack gap={6}>
        <PageHeader>
          <h1>Lock APY Tokens for Boost</h1>
          <span>
            Lock your APY tokens for a selected duration and enhance your APY
            reward yield.
          </span>
        </PageHeader>
        <Banner style={{ textAlign: 'center' }}>
          <strong>Important:</strong> Boost is ONLY applied to your APY Reward
          yield on stablecoin deposits
        </Banner>
        <LockData />
        <Grid
          gap={6}
          minItemWidth={'400px'}
          style={{
            height: !isVwUnder900 ? '425px' : undefined,
          }}
        >
          <BoostMetric />
          <LockApy />
        </Grid>
      </Stack>
      <Faq />
    </Stack>
  );
}

const PageHeader = styled.div`
  text-align: center;

  & > h1 {
    font-size: 1.75rem;
    font-family: 'archiamedium';
    letter-spacing: 1.25px;
    line-height: 1.6;
    margin-bottom: 1rem;
    line-height: 1.3;
  }

  & > span {
    font-size: 1.1375rem;
    font-family: 'archiathin';
  }

  @media ${breakpoints.tablet} {
    text-align: left;
    font-size: 1.2rem;

    & > h1 {
      margin-bottom: 0.25rem;
    }
  }
`;

const Banner = styled.div`
  padding: 1.5rem 1rem;
  background-color: #281e01;
  border: 1px solid #d3a21d;
  border-left: 10px solid #d3a21d;
  border-radius: 7px;
  font-family: 'archiaregular';
  font-size: 1rem;
  text-align: center;

  @media ${breakpoints.tablet} {
    font-size: 1.2rem;
  }
`;

export default BoostLock;
