import { Strategy } from '../utils/entities/Strategy';
import { Contract } from '../utils/entities/Contract';
import * as icons from '../assets/defi';
import { ChainId } from '../constants';

import { CURVE_POOL_ABI, CURVE_LIQUIDITY_GAUGE_ABI } from './abis';
import { CURVE_GAUGE_CONTROLLER } from './utils/curve';

const CURVE_MUSD_POOL = {
  [ChainId.MAINNET]: new Contract(
    '0x8474DdbE98F5aA3179B3B3F5942D724aFcdec9f6',
    CURVE_POOL_ABI
  ),
};

const MUSD_LIQUIDITY_GAUGE_ADDRESS =
  '0x5f626c30ec1215f4edcc9982265e8b1f411d1352';

const CURVE_MUSD_LIQUIDITY_GAUGE = {
  [ChainId.MAINNET]: new Contract(
    MUSD_LIQUIDITY_GAUGE_ADDRESS,
    CURVE_LIQUIDITY_GAUGE_ABI
  ),
};

const curve_musd = new Strategy('Curve mUSD', {
  protocol: 'curve',
  poolName: 'musd',
  protocolIcon: icons['curve'],
  outputAssets: ['CRV', 'MTA'],
  contracts: {
    pool: CURVE_MUSD_POOL,
    liquidityGauge: CURVE_MUSD_LIQUIDITY_GAUGE,
    gaugeController: CURVE_GAUGE_CONTROLLER,
  },
  allocationName: 'curve-musd',
  tokenPricesNeeded: ['curve-dao-token', 'musd'],
  description: 'Add strategy description here...',
});

export default curve_musd;
