export const initialStablecoinFormState = {
  currentForm: 'DEPOSIT',
  selectedToken: null,
  inputValue: null,
  inputErrorMsg: null,
  isTrxPending: false,
};

export function tokenFormReducer(
  state,
  { type, token, inputValue, errorMsg, switchTo, formType }
) {
  switch (type) {
    case 'SWITCH_FORM':
      return {
        ...state,
        currentForm: switchTo,
        selectedToken: token,
        inputValue: switchTo === 'WITHDRAW' ? '0' : null,
        inputErrorMsg: null,
      };

    case 'CHANGE_TOKEN':
      return {
        ...state,
        selectedToken: token,
        inputValue: formType === 'WITHDRAW' ? '0' : null,
        inputErrorMsg: null,
      };

    case 'CHANGE_INPUT':
      return {
        ...state,
        inputValue: inputValue,
        inputErrorMsg: null,
      };

    case 'SETUP_TRANSACTION':
      return {
        ...state,
        isTrxPending: true,
      };

    case 'SUCCESSFUL_TRANSACTION':
      return {
        ...state,
        isTrxPending: false,
        inputValue: formType === 'WITHDRAW' ? '0' : null,
        inputErrorMsg: null,
      };

    case 'SUCCESSFUL_TRANSACTION_KEEP_INPUT':
      return {
        ...state,
        isTrxPending: false,
        inputErrorMsg: null,
      };

    case 'FAILED_TRANSACTION':
      return {
        ...state,
        isTrxPending: false,
      };

    case 'INPUT_ERROR':
      return {
        ...state,
        inputErrorMsg: errorMsg,
      };

    default:
      return state;
  }
}
