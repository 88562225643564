import { useEffect, useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';

import { useClaimStore } from '../state/stores';
import { useApi } from './index';
import { REWARDS_SERVICE } from '../constants/claims';
import { getContracts, isListEmpty, fromWei } from '../utils/helpers';
export function usePendingClaim() {
  // Zustand
  const pendingClaim = useClaimStore(
    useCallback(state => state.pendingClaim, [])
  );
  const setPendingClaim = useClaimStore(
    useCallback(state => state.setPendingClaim, [])
  );

  const { library, chainId, account } = useWeb3React();

  const { res: claimData } = useApi(
    'apy.finance',
    `${REWARDS_SERVICE}/claimable/${chainId}/${account}`,
    {
      conditions: [chainId, account],
      swrOptions: {
        revalidateOnFocus: pendingClaim.amount ? true : false,
        refreshInterval: pendingClaim.amount ? 30000 : 0,
      },
    }
  );

  const safeBlock = claimData.data?.blockNumber;

  useEffect(() => {
    if (!safeBlock || !chainId || !library || !account) return;

    const getPendingClaim = async (library, chainId, safeBlock, account) => {
      const RewardsContract = await getContracts(
        ['REWARDS_DISTRIBUTOR'],
        chainId,
        library
      );

      const filter = await RewardsContract.filters.Claimed();

      /**
       * - Search as far back as safeBlock + 1
       * - Because if there's a claim with blockNumber equal to safeBlock, it's already considered fully confirmed
       * - If confirmed, we don't need to show any state on the UI that it's pending
       * - This prevents showing "0 block confirmations remaining", lowest it'll show is 1
       */
      const logs = await RewardsContract.queryFilter(
        filter,
        safeBlock + 1,
        'latest'
      );

      const recentUserClaims = logs.filter(
        log => log.args.recipient === account
      );

      let claimAmount;
      let blocksLeft;

      // Mock claim ----------------------------------------
      // const mockClaim = {
      //   blockNumber: 13610428,
      //   args: { amount: getWei('1000', 'APY') },
      // };

      // if (safeBlock < mockClaim.blockNumber) {
      //   recentUserClaims.push(mockClaim);
      // }
      // ---------------------------------------------------

      // console.log(`Safe Block: ${safeBlock}`);

      if (!isListEmpty(recentUserClaims)) {
        claimAmount = recentUserClaims.reduce((total, claim) => {
          const { amount } = claim.args;
          const claimAmount = fromWei(amount, 'APY');
          return total.plus(claimAmount);
        }, BigNumber(0));

        // console.log(`Pending Claim: ${claimAmount} APY`);

        const blockNumberOfRecentClaim =
          recentUserClaims[recentUserClaims.length - 1].blockNumber;

        // Claim is "safe" once claim block number === safeBlock
        blocksLeft = blockNumberOfRecentClaim - safeBlock;

        // console.log(`Block confirmations needed: ${blocksLeft}`);
      } else {
        // Clears/resets pendingClaim state
        setPendingClaim({
          amount: undefined,
          blocksLeft: undefined,
          safeBlock: undefined,
        });
      }

      return {
        amount: claimAmount,
        blocksLeft,
        safeBlock,
      };
    };

    getPendingClaim(library, chainId, safeBlock, account)
      .then(claimData => {
        if (claimData.amount) {
          setPendingClaim(claimData);
        }
      })
      .catch(err =>
        console.error('Error: Unable to check for pending claim:', err.message)
      );
  }, [safeBlock, library, account, chainId]);

  return [pendingClaim, setPendingClaim];
}
