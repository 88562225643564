import { Strategy } from '../utils/entities/Strategy';
import { Contract } from '../utils/entities/Contract';
import * as icons from '../assets/defi';
import { ChainId } from '../constants';

import { CURVE_POOL_ABI, CURVE_LIQUIDITY_GAUGE_ABI } from './abis';
import {
  CURVE_GAUGE_CONTROLLER,
  getCurveBaseApy,
  getCrvTokenApy,
} from './utils/curve';

const CURVE_COMPOUND_POOL = {
  [ChainId.MAINNET]: new Contract(
    '0xA2B47E3D5c44877cca798226B7B8118F9BFb7A56',
    CURVE_POOL_ABI
  ),
};

const CURVE_COMPOUND_LIQUIDITY_GAUGE = {
  [ChainId.MAINNET]: new Contract(
    '0x7ca5b0a2910B33e9759DC7dDB0413949071D7575',
    CURVE_LIQUIDITY_GAUGE_ABI
  ),
};

const curve_compound = new Strategy('Curve Compound', {
  protocol: 'curve',
  protocolIcon: icons['curve'],
  poolName: 'compound',
  outputAssets: ['CRV'],
  contracts: {
    pool: CURVE_COMPOUND_POOL,
    liquidityGauge: CURVE_COMPOUND_LIQUIDITY_GAUGE,
    gaugeController: CURVE_GAUGE_CONTROLLER,
  },
  allocationName: 'curve-compound',
  tokenPricesNeeded: ['curve-dao-token'],
  getApys: (chainId, library) => ({
    baseApy: getCurveBaseApy('compound'),
    CRV: getCrvTokenApy('curve_compound', chainId, library),
  }),
  description: 'Add strategy description here...',
});

export default curve_compound;
