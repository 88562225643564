import { useMemo } from 'react';
import useEtherSWR from 'ether-swr';

import { useAbisStore } from '../state/stores';
import { isListEmpty } from '../utils/helpers';
import { FetcherError } from '../utils/errors';
import { POOL_CONTRACT_KEYS } from '../constants';

export function usePoolStatus() {
  // Zustand
  const { abis: ABIs, addresses } = useAbisStore(state => state.abiInfo);

  const { data: poolStatuses, error } = useEtherSWR(
    !isListEmpty(ABIs)
      ? [
          [addresses[POOL_CONTRACT_KEYS['DAI']][0], 'paused'],
          [addresses[POOL_CONTRACT_KEYS['DAI']][0], 'addLiquidityLock'],
          [addresses[POOL_CONTRACT_KEYS['DAI']][0], 'redeemLock'],
          [addresses[POOL_CONTRACT_KEYS['USDC']][0], 'paused'],
          [addresses[POOL_CONTRACT_KEYS['USDC']][0], 'addLiquidityLock'],
          [addresses[POOL_CONTRACT_KEYS['USDC']][0], 'redeemLock'],
          [addresses[POOL_CONTRACT_KEYS['USDT']][0], 'paused'],
          [addresses[POOL_CONTRACT_KEYS['USDT']][0], 'addLiquidityLock'],
          [addresses[POOL_CONTRACT_KEYS['USDT']][0], 'redeemLock'],
        ]
      : [],
    { ABIs: new Map(ABIs) }
  );

  const fetcherError = useMemo(() => {
    let fetcherError;

    if (error) {
      fetcherError = new FetcherError(
        error.data?.message ?? error.message,
        'paused / addLiquidityLock / redeemLock',
        'usePoolStatus'
      );

      console.error(fetcherError.message);
    }

    return fetcherError;
  }, [error]);

  const isPoolsLocked = useMemo(() => {
    let data;

    if (poolStatuses) {
      if (poolStatuses?.includes(true)) {
        data = true;
      } else {
        data = false;
      }
    }

    return {
      data,
      isLoading: typeof data !== 'boolean' && !fetcherError,
      error: fetcherError,
    };
  }, [poolStatuses, fetcherError]);

  return { isPoolsLocked };
}
