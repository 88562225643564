import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { BigNumber } from 'bignumber.js';

/**
 * Stores are used for global (need to be used
 * in more than one component) app state that can't be stored in SWR cache
 * (either compound or computed data), or global UI state that we don't want to create context/provider for
 */

let coinGeckoStore = set => ({
  coinGeckoList: null,
  setCoinGeckoList: list => set({ coinGeckoList: list }),
  coinPrices: { data: null, isLoading: true, error: null },
  setCoinPrices: prices => set({ coinPrices: prices }),
});

let sessionStore = persist(
  set => ({
    isSessionActive: false,
    setSession: isActive => set({ isSessionActive: isActive }),
  }),
  {
    name: 'apy-session',
  }
);

let sliderMenuStore = set => ({
  isSliderMenuOpen: false,
  setIsSliderMenuOpen: menuStatus => set({ isSliderMenuOpen: menuStatus }),
});

let completedToursStore = persist(
  set => ({
    completedTours: { home: false, portfolio: false, staking: false },
    setCompletedTour: page =>
      set(state => ({
        completedTours: { ...state.completedTours, [page]: true },
      })),
  }),
  {
    name: 'apy-tours',
  }
);

let abisStore = set => ({
  abiInfo: { abis: null, addresses: null, error: null },
  setAbiInfo: abiInfo => set({ abiInfo: abiInfo }),
});

let claimStore = set => ({
  pendingClaim: {
    amount: undefined,
    blocksLeft: undefined,
    safeBlock: undefined,
  },
  setPendingClaim: claim => set(() => ({ pendingClaim: claim })),
});

let platformStore = set => ({
  tvl: { data: null, isLoading: true, error: null },
  setTvl: amount => set(state => ({ tvl: { ...state.tvl, data: amount } })),
  platformApy: { data: null, isLoading: true, error: null },
  setPlatformApy: platformApy => set(() => ({ platformApy })),
});

let strategiesStore = set => ({
  deployedValues: { data: null, isLoading: true, error: null },
  setDeployedValues: deployedValues => set(() => ({ deployedValues })),
  strategyApys: {},
  setStrategyApys: apys => set(() => ({ strategyApys: apys })),
  aggregateApys: {},
  setAggregateApys: apys => set(() => ({ aggregateApys: apys })),
});

export const useModalStore = create(set => ({
  isModalOpen: false,
  setIsModalOpen: status => set({ isModalOpen: status }),
}));
export const useCortexModalStore = create(set => ({
  isCortexModalOpen: true,
  setIsCortexModalOpen: status => set({ isCortexModalOpen: status }),
}));
export const compareBN = (oldBN, newBN) => BigNumber(oldBN).isEqualTo(newBN);
export const useCompletedToursStore = create(completedToursStore);
export const useSessionStore = create(sessionStore);
export const useSliderMenuStore = create(sliderMenuStore);
export const useAbisStore = create(abisStore);
export const useClaimStore = create(claimStore);
export const usePlatformStore = create(platformStore);
export const useStrategiesStore = create(strategiesStore);
export const useCoinGeckoStore = create(coinGeckoStore);
