import { Strategy } from '../utils/entities/Strategy';
import * as icons from '../assets/defi';
import { ChainId } from '../constants';

const convex_usdt = new Strategy('Convex USDT', {
  protocol: 'convex',
  poolName: 'usdt',
  protocolIcon: icons['convex'],
  outputAssets: {
    gov: 'CVX',
    crv: 'CRV',
  },
  allocationName: 'convex-usdt',
  tokenPricesNeeded: ['curve-dao-token', 'convex-finance'],
  options: {
    curveLiquidityGaugeAddress: {
      [ChainId.MAINNET]: '0xBC89cd85491d81C6AD2954E6d0362Ee29fCa8F53',
    },
  },
});

export default convex_usdt;
