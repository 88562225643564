import React, { createContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaLayout } from 'use-media';

import { steps, content } from '../../constants/TourSteps';
export const TourGuideContext = createContext();

export const TourGuideProvider = ({ children }) => {
  let history = useHistory();

  const [isTourOpen, startTour] = useState(false);
  const [page, setPage] = useState(0);
  const [tourguideSteps, setTourGuideSteps] = useState(content);
  const [currentTarget, setTarget] = useState('');

  const goForth = (modalTrigger = null) => {
    const currentPath = tourguideSteps[page].path;
    if (history.location.pathname !== currentPath) {
      history.push(currentPath);
    } else if (
      currentPath === '/rewards' &&
      tourguideSteps[page + 1].path === '/dashboard'
    ) {
      history.push('/dashboard');
    } else if (
      currentPath === '/dashboard' &&
      tourguideSteps[page + 1].path === '/rewards'
    ) {
      history.push('/rewards');
    }
    setPage(page => page + 1);
    modalTrigger && modalTrigger();
  };

  const goBack = (modalTrigger = null) => {
    const prevPath = tourguideSteps[page - 1].path;
    if (history.location.pathname !== prevPath) {
      history.push(prevPath);
    }
    setPage(page => page - 1);
    modalTrigger && modalTrigger();
  };

  const tourRestart = () => {
    const currentPath = tourguideSteps[page].path;
    if (history.location.pathname !== currentPath) {
      history.push(currentPath);
    } else if (currentPath !== tourguideSteps[page + 1].path) {
      history.push('/dashboard');
    }
    setPage(2);
    startTour(true);
  };

  const endTour = () => {
    setPage(0);
    startTour(false);
  };

  useEffect(() => {
    setTarget(currentTarget => steps[page]);
  }, [page, steps]);

  return (
    <TourGuideContext.Provider
      value={{
        tourguideSteps,
        currentTarget,
        page,
        steps,
        isTourOpen,
        actions: {
          setPage,
          setTarget,
          startTour,
          goBack,
          goForth,
          endTour,
          tourRestart,
        },
      }}
    >
      {children}
    </TourGuideContext.Provider>
  );
};
