import { Strategy } from '../utils/entities/Strategy';
import { Contract } from '../utils/entities/Contract';
import * as icons from '../assets/defi';
import { ChainId } from '../constants';

import { CURVE_POOL_ABI, CURVE_LIQUIDITY_GAUGE_ABI } from './abis';
import { CURVE_GAUGE_CONTROLLER } from './utils/curve';

const CURVE_MIM_POOL = {
  [ChainId.MAINNET]: new Contract(
    '0x5a6A4D54456819380173272A5E8E9B9904BdF41B',
    CURVE_POOL_ABI
  ),
};

const MIM_LIQUIDITY_GAUGE_ADDRESS =
  '0xd8b712d29381748db89c36bca0138d7c75866ddf';

const CURVE_MIM_LIQUIIDTY_GAUGE = {
  [ChainId.MAINNET]: new Contract(
    MIM_LIQUIDITY_GAUGE_ADDRESS,
    CURVE_LIQUIDITY_GAUGE_ABI
  ),
};

const curve_mim = new Strategy('Curve MIM', {
  protocol: 'curve',
  protocolIcon: icons['curve'],
  poolName: 'mim',
  outputAssets: ['CRV', 'SPELL'],
  allocationName: 'curve-mim',
  tokenPricesNeeded: ['curve-dao-token', 'magic-internet-money'],
  contracts: {
    pool: CURVE_MIM_POOL,
    liquidityGauge: CURVE_MIM_LIQUIIDTY_GAUGE,
    gaugeController: CURVE_GAUGE_CONTROLLER,
  },
  options: {
    calcCrvApy: true,
  },
});

export default curve_mim;
