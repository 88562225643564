import React from 'react';

import { useModal } from '../../../../../hooks';

import Modal from '../../../../apy/reusableComponents/Modal';
import { Panel } from '../../../../styles/Panel.styled';
import { Stack } from '../../../../styles/primitives';

function BoostBreakdownModal() {
  const [isModalOpen, setIsModalOpen] = useModal();

  return (
    <Modal isModalOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}>
      <Panel as={Stack}>
        <div>Current Boost: 1.0x</div>

        <div style={{ display: 'grid' }}>
          <span>Your APT Value: $10,000.00</span>
          <span>Total Value Locked: $25,000,000.00</span>
          <span>Your blAPY: 0.00</span>
          <span> Total blAPY Supply: $5,714,141.50</span>
        </div>

        <div>Lock Score: 4,000.00</div>
      </Panel>
    </Modal>
  );
}

export default BoostBreakdownModal;
