import React from 'react';
import { NavLink } from 'react-router-dom';

import {
  toggleWrap,
  navLinkList,
  navLink,
  activeNavLink,
} from './PageToggle.module.scss';

function PageToggle() {
  return (
    <div className={toggleWrap}>
      <ul className={navLinkList}>
        <NavLink
          className={navLink}
          exact
          to="/dashboard/portfolio"
          activeClassName={activeNavLink}
        >
          Portfolio
        </NavLink>
        <NavLink
          className={navLink}
          to="/dashboard/platform"
          activeClassName={activeNavLink}
        >
          Platform
        </NavLink>
      </ul>
    </div>
  );
}

export default PageToggle;
