import { BigNumber } from 'bignumber.js';

import { STABLECOINS } from '../../constants';
import { fromWei } from './index';

export const getDefaultToken = (balances, formType) => {
  if (!balances) {
    return;
  }

  // Check if balances are set
  let largestBalance;
  if (!Object.values(balances[formType]).includes(undefined)) {
    largestBalance = BigNumber.maximum(...Object.values(balances[formType]));

    // If max is under .01, set largestBalance to 0
    if (largestBalance.isLessThan(0.01)) {
      largestBalance = BigNumber(0);
    }
  }

  // Set defaultToken to token w/ largest balance
  let defaultToken;
  if (largestBalance) {
    defaultToken = Object.keys(balances[formType]).find(key =>
      balances[formType][key].isEqualTo(largestBalance)
    );

    if (formType === 'WITHDRAW') {
      defaultToken = STABLECOINS[defaultToken];
    }
  }

  return defaultToken;
};

export const calcPortfolioValue = (balances, prices) => {
  const poolValues = {
    DAI: balances['DAI'].times(prices['DAI']),
    USDC: balances['USDC'].times(prices['USDC']),
    USDT: balances['USDT'].times(prices['USDT']),
  };

  const portfolioValue = BigNumber.sum(...Object.values(poolValues));
  return portfolioValue;
};

export const calcEarnings = (portfolioValue, totalCashflow) => {
  const earnings = portfolioValue.plus(totalCashflow);

  return earnings;
};

// Percent Change
export const calcPercentGrowth = (
  endPv,
  endEarnings,
  startPv,
  startEarnings
) => {
  const endTotal = endPv.plus(endEarnings);
  const startTotal = startPv.plus(startEarnings);

  const percentChange = endTotal.minus(startTotal).div(startTotal);
  return percentChange;
};
