import React from 'react';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';

import * as Contracts from '../../../../../constants/contracts';
import useTransaction from '../../../../../hooks/useTransaction';
import { displayDate } from '../../../../../utils/helpers';

import SelectLockEnd from './SelectLockEnd';
import Button from '../../../../apy/reusableComponents/Button';
import { PadBox, Stack } from '../../../../styles/primitives';

/**
 * If a lock already exists, can't reduce time -- can only increase/extend it
 * - ExtendLockForm shouldn't show options lower times
 * - Should show current lock duration
 */

function ExtendLockForm({
  isDataLoading,
  blApyBalance,
  updateBlApyData,
  updateLockData,
  formState: { unlockEpoch, isTrxPending },
  formDispatch,
}) {
  const { library, chainId } = useWeb3React();

  const [sendTransaction] = useTransaction();

  const onSubmit = async evt => {
    evt.preventDefault();

    const signer = library.getSigner();

    const blApyAddress = Contracts['BL_APY'][chainId]?.address;
    const blApyAbi = Contracts['BL_APY'][chainId]?.abi;
    const Contract = new ethers.Contract(blApyAddress, blApyAbi, signer);

    formDispatch({ type: 'SETUP_TRX' });

    sendTransaction('EXTEND_LOCK_TIME', {
      contract: Contract,
      functionName: 'increase_unlock_time',
      args: [unlockEpoch],
      options: { unlockDate: displayDate(unlockEpoch) },
    })
      .then(() => {
        updateLockData(undefined, true);
        updateBlApyData(undefined, true);

        formDispatch({ type: 'SUCCESSFUL_TRX' });
      })
      .catch(err => {
        console.error(err.message);
        formDispatch({ type: 'FAILED_TRX' });
      });
  };

  return (
    <form onSubmit={onSubmit}>
      <Stack as={PadBox} gap={6} padding={[6]}>
        <SelectLockEnd
          isDisabled={
            isDataLoading ||
            isTrxPending ||
            blApyBalance.data?.lte(0) ||
            !unlockEpoch
          }
          selectedEpoch={unlockEpoch}
          onChange={newUnlockEpoch =>
            formDispatch({
              type: 'UNLOCK_EPOCH_CHANGE',
              newUnlockEpoch,
            })
          }
        />

        <Button
          isDisabled={
            isDataLoading ||
            isTrxPending ||
            blApyBalance.data?.lte(0) ||
            !unlockEpoch
          }
          isLoading={isTrxPending}
          label={'Extend Lock Time'}
          type={'submit'}
          color={'green'}
        />
      </Stack>
    </form>
  );
}

export default ExtendLockForm;
