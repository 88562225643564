import { Strategy } from '../utils/entities/Strategy';
import { Contract } from '../utils/entities/Contract';
import * as icons from '../assets/defi';
import { ChainId } from '../constants';

import { CURVE_POOL_ABI, CURVE_LIQUIDITY_GAUGE_ABI } from './abis';
import {
  CURVE_GAUGE_CONTROLLER,
  getCurveBaseApy,
  getCrvTokenApy,
} from './utils/curve';

const CURVE_USDP_POOL = {
  [ChainId.MAINNET]: new Contract(
    '0x42d7025938bEc20B69cBae5A77421082407f053A',
    CURVE_POOL_ABI
  ),
};

const CURVE_USDP_LIQUIDITY_GAUGE = {
  [ChainId.MAINNET]: new Contract(
    '0x055be5DDB7A925BfEF3417FC157f53CA77cA7222',
    CURVE_LIQUIDITY_GAUGE_ABI
  ),
};

const curve_usdp = new Strategy('Curve USDP', {
  protocol: 'curve',
  poolName: 'USDP',
  protocolIcon: icons['curve'],
  outputAssets: ['CRV'],
  contracts: {
    pool: CURVE_USDP_POOL,
    liquidityGauge: CURVE_USDP_LIQUIDITY_GAUGE,
    gaugeController: CURVE_GAUGE_CONTROLLER,
  },
  allocationName: '',
  getApys: (chainId, library) => ({
    baseApy: getCurveBaseApy('usdp'),
    CRV: getCrvTokenApy('curve_usdp', chainId, library),
  }),
  description: 'Add strategy description here...',
});

export default curve_usdp;
