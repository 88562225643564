import React, { useEffect, useCallback, useContext, useRef } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Route, Redirect } from 'react-router-dom';

import { useSessionStore } from '../state/stores';
import {
  useCoinGeckoPrices,
  useActiveWalletCheck,
  usePendingClaim,
  useAbis,
  useModal,
} from '../hooks';
import { ToastContext, TrxProvider, PageTourProvider } from '../state/contexts';
import { injectedConnector } from '../utils/connectors';
import { isChainSupported } from '../utils/helpers';

import ToastList from './apy/info/ToastList';
import TrxModal from './Layout/TrxModal';
import Header from './apy/layout/Header';
import Pool2 from './pages/Pool2';
import Portfolio from './pages/Yield/Portfolio';
import Platform from './pages/Yield/Platform';
import Uni from './pages/Uni';
import Bpt from './pages/Bpt';
import BoostLock from './pages/BoostLock';
import PrivacyPolicy from './Layout/Information/PrivacyPolicy';
import TermsOfService from './Layout/Information/TermsOfService';
import { pageContentWrap, reconnectButton } from './ConnectedApp.module.scss';

function ConnectedApp() {
  // Web3 React
  const { activate, active, chainId } = useWeb3React();

  // Toast notifications
  const {
    showToast,
    clearList,
    removeToastByTitle,
    updateToastByTitle,
  } = useContext(ToastContext);

  // Zustand
  const setSession = useSessionStore(
    useCallback(state => state.setSession, [])
  );

  // Custom hooks
  useAbis(); // Sets the ABIs globally (Zustand) so they can be passed to useEtherSWR
  const { coinPrices } = useCoinGeckoPrices();
  const { isWalletActive } = useActiveWalletCheck(active);
  const [pendingClaim] = usePendingClaim();

  // Show notification if no connected account (i.e. inactive wallet)
  const reconnectMessage = useCallback(() => {
    const onConnect = async () => {
      await activate(injectedConnector);

      let isAuthorized = await injectedConnector.isAuthorized();
      if (isAuthorized) {
        setSession(true);
      }
    };

    return (
      <button className={reconnectButton} onClick={onConnect}>
        Click here to reconnect your wallet
      </button>
    );
  }, [activate, setSession]);

  const [isCortexModalOpen, setIsCortexModalOpen] = useModal('cortex');
  useEffect(() => {
    if (!isCortexModalOpen) {
      setIsCortexModalOpen(true);
    }
  }, []);

  useEffect(() => {
    if (!isWalletActive) {
      showToast({
        type: 'warning',
        title: 'No Connected Account',
        message: reconnectMessage(),
      });
    }

    return () => {
      removeToastByTitle('No Connected Account');
    };
  }, [isWalletActive, reconnectMessage, removeToastByTitle]);

  // Show notification if APY claim is pending confirmations
  const pendingClaimToast = useRef(); // Prevents duplicate toasts
  useEffect(() => {
    const notificationMessage = `${pendingClaim.blocksLeft} block ${
      pendingClaim.blocksLeft === 1 ? 'confirmation' : 'confirmations'
    } remaining`;

    if (pendingClaim.amount && !pendingClaimToast.current) {
      const newNotification = {
        type: 'pending',
        title: 'Pending APY Claim',
        message: notificationMessage,
      };

      pendingClaimToast.current = newNotification;

      showToast(newNotification);
    } else if (pendingClaim.amount) {
      // Edit exisiting notification message with updated blocksLeft
      updateToastByTitle({
        type: 'pending',
        title: 'Pending APY Claim',
        message: notificationMessage,
      });
    } else {
      removeToastByTitle('Pending APY Claim');
    }
  }, [pendingClaim, showToast, updateToastByTitle]);

  // Show notification if CoinGecko API error
  useEffect(() => {
    if (coinPrices.error) {
      showToast({
        type: 'warning',
        title: 'CoinGecko API Failure',
        // message: 'Could not fetch prices from their API',
        message: 'Could not get prices for calculations',
      });
    }

    return () => {
      removeToastByTitle('CoinGecko API Failure');
    };
  }, [coinPrices, removeToastByTitle]);

  // Show notification if on unsupported chain
  useEffect(() => {
    if (chainId && !isChainSupported(chainId)) {
      showToast({
        type: 'warning',
        title: 'Unsupported Network',
        message: 'Switch to Mainnet or Kovan testnet',
      });
    }
  }, [chainId, showToast]);

  // Clears all toast notifications on page refresh or when user logs out
  useEffect(() => {
    return () => clearList();
  }, [clearList]);

  return (
    <>
      <Header />
      <ToastList position={'bottomRight'} />
      <TrxProvider>
        <TrxModal />
        <div className={pageContentWrap}>
          {/* Dashboard */}
          <PageTourProvider>
            <Route path="/" exact>
              <Redirect to="/dashboard/portfolio" />
            </Route>
          </PageTourProvider>
          <Route path="/dashboard" exact>
            <Redirect to="/dashboard/portfolio" />
          </Route>
          <PageTourProvider>
            <Route path="/dashboard/portfolio" exact component={Portfolio} />
          </PageTourProvider>
          <Route path="/dashboard/platform" exact component={Platform} />
          <Route path="/boost-lock" component={BoostLock} />

          {/* Pool 2s */}
          <PageTourProvider>
            <Route path="/pool2" exact component={Pool2} />
          </PageTourProvider>
          <PageTourProvider>
            <Route path="/pool2/univ2" exact component={Uni} />
          </PageTourProvider>
          <PageTourProvider>
            <Route path="/pool2/bpt" exact component={Bpt} />
          </PageTourProvider>

          {/* Other */}
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/terms-of-service" component={TermsOfService} />
        </div>
      </TrxProvider>
    </>
  );
}

export default ConnectedApp;
