import React, { createContext, useState, useCallback } from 'react';

import { tourSteps } from '../../constants/pageTours';
import { useCompletedToursStore } from '../stores';

export const PageTourContext = createContext();

export const PageTourProvider = ({ children }) => {
  // Zustand
  const setCompletedTour = useCompletedToursStore(
    useCallback(state => state.setCompletedTour, [])
  );

  // Tour state
  const [isTourActive, setIsTourActive] = useState(false);
  const [currentTourPage, setCurrentTourPage] = useState();
  const [currentTourStep, setCurrentTourStep] = useState(0);

  const isLastTourStep = (steps, currentStep) => {
    let lastStep;
    for (let i = 0; i < steps.length; i++) {
      if (Array.isArray(steps[i])) {
        const list = steps[i];
        lastStep = list.find(subStep => subStep.isLastStep);
      } else if (steps[i].isLastStep) {
        lastStep = steps[i];
      }
    }

    return currentStep === lastStep?.stepNum;
  };

  const goForwardStep = () => {
    if (isLastTourStep(tourSteps[currentTourPage], currentTourStep)) {
      endTour();
    } else {
      setCurrentTourStep(currentTourStep => currentTourStep + 1);
    }
  };

  const goBackStep = () => {
    if (currentTourStep === 0) {
      endTour();
    } else {
      setCurrentTourStep(currentTourStep => currentTourStep - 1);
    }
  };

  const endTour = useCallback(() => {
    setIsTourActive(false);
    setCurrentTourStep(0);
  }, []);

  const saveTourComplete = page => {
    endTour();
    setCompletedTour(page);
  };

  const isTourStepActive = step => {
    // Check if multiple stepNums
    if (Array.isArray(step)) {
      const stepNums = step.map(tourStep => tourStep.stepNum);
      return isTourActive && stepNums.includes(currentTourStep);
    }

    return isTourActive && currentTourStep === step.stepNum;
  };

  return (
    <PageTourContext.Provider
      value={{
        tourSteps,
        isTourActive,
        setIsTourActive,
        currentTourPage,
        setCurrentTourPage,
        currentTourStep,
        goForwardStep,
        goBackStep,
        endTour,
        saveTourComplete,
        isTourStepActive,
      }}
    >
      {children}
    </PageTourContext.Provider>
  );
};
