import React from 'react';

import { panelHeader } from './PanelHeader.module.scss';

const PanelHeader = ({ classes = {}, children }) => {
  return (
    <div
      className={`${panelHeader} ${classes.panelHeader}`}
      data-augmented-ui="br-clip-x bl-clip-x border tl-round tr-round"
    >
      {children}
    </div>
  );
};

export default PanelHeader;
