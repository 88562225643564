import React from 'react';
import { NavLink } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import styled from 'styled-components';
import { BeatLoader } from 'react-spinners';

import {
  useModal,
  useCurrentBoost,
  useRewardsPerDay,
} from '../../../../../hooks';
import { displayAmount } from '../../../../../utils/helpers';

import BoostBreakdownModal from '../BoostBreakdownModal';
import { TransparentButton } from '../../../../styles/TransparentButton.styled';
import { Panel } from '../../../../styles/Panel.styled';
import { Inline, Stack, Center } from '../../../../styles/primitives';

function BoostMetric() {
  const [, setIsModalOpen] = useModal();
  const { account } = useWeb3React();
  const { currentBoost } = useCurrentBoost(account);
  const { rewardsPerDay } = useRewardsPerDay(account);

  return (
    <>
      <BoostBreakdownModal />

      <Panel as={Stack} gap={2}>
        <div>
          <Inline justify={'spaceBetween'} align={'center'}>
            <h3
              style={{
                fontSize: '24px',
                fontFamily: 'archiathin',
                color: '#959595',
              }}
            >
              Your Boost
            </h3>
            {/* <ModalButton onClick={() => setIsModalOpen(true)}>
            View Breakdown
          </ModalButton> */}
          </Inline>
          <i style={{ fontFamily: 'archiathin', fontSize: '13px' }}>
            Boost isn't applied until you have{' '}
            <NavLink to={'/dashboard/portfolio'} style={{ color: '#8e40dd' }}>
              deposited stablecoins
            </NavLink>
          </i>
        </div>

        <Center centerChildren={true}>
          <span style={{ fontSize: '50px' }}>
            {currentBoost.isLoading ? (
              <BeatLoader size={20} margin={6} color={'gray'} />
            ) : (
              <>
                {displayAmount(currentBoost.data)}
                {currentBoost.data && 'x'}
              </>
            )}
          </span>

          <span
            style={{
              fontSize: '24px',
              fontFamily: 'archiathin',
              color: '#959595',
              visibility:
                currentBoost.isLoading || rewardsPerDay.isLoading
                  ? 'hidden'
                  : undefined,
            }}
          >
            <>
              {displayAmount(rewardsPerDay.data)}{' '}
              <span style={{ fontSize: '1.2rem' }}>
                {rewardsPerDay.data && 'APY Per Day'}
              </span>
            </>
          </span>
        </Center>

        {/* Boost Gauge */}
        <BoostGauge>
          <Meter
            id="boost-gauge"
            value={currentBoost.data?.toNumber()}
            min={1}
            max={2.5}
          ></Meter>
          <div
            style={{
              padding: '0 .25rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ fontSize: '1.2rem' }}>1x</span>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'right',
              }}
            >
              <span style={{ fontSize: '1.2rem' }}>2.5x</span>
            </div>
          </div>
        </BoostGauge>
      </Panel>
    </>
  );
}

const ModalButton = styled(TransparentButton)`
  border-bottom: 1px solid #844ed4;
  color: #844ed4;

  &:hover {
    border-bottom-color: transparent;
  }
`;

const BoostGauge = styled.div`
  /* border: 1px solid green; */
`;

const Meter = styled.meter`
  width: 100%;
  height: 50px;
  border: 1px solid #ccc;
  border-radius: 3px;
`;

export default BoostMetric;
