import { capitalizeLabel } from '../../utils/helpers';

const COLORS = {
  NEEDS_APPROVAL: 'blue',
  DEPOSIT: 'green',
  WITHDRAW: 'red',
  STAKE: 'green',
  UNSTAKE: 'gold',
};

const TOKENS = {
  DAI: 'DAI',
  USDC: 'USDC',
  USDT: 'USDT',
  UNIV2: 'UNI-V2',
  BPT: 'BPT',
};

export function formButtonReducer(
  state,
  { type, formType, setBtnLabel, selectedToken }
) {
  switch (type) {
    case 'NO_TOKEN_SELECTED':
      return {
        ...state,
        btnColor: COLORS[formType],
        btnLabel: capitalizeLabel(formType),
      };

    case 'POOLS_LOCKED':
      return {
        ...state,
        btnColor: COLORS[formType],
        btnLabel: capitalizeLabel(formType),
      };

    case 'NEEDS_APPROVAL': {
      return {
        ...state,
        btnColor: COLORS['NEEDS_APPROVAL'],
        btnLabel: `Enable ${TOKENS[selectedToken]}`,
      };
    }

    case 'DEFAULT':
      return {
        btnColor: COLORS[formType],
        btnLabel: `${
          setBtnLabel ? capitalizeLabel(setBtnLabel) : capitalizeLabel(formType)
        } ${TOKENS[selectedToken]}`,
      };
  }
}
