import React, { forwardRef, useState, useContext, useEffect } from 'react';
import BigNumber from 'bignumber.js';
import { useMedia } from 'use-media';

import { PageTourContext } from '../../../../../state/contexts';
import {
  getRequiredDecimals,
  displayAmount,
} from '../../../../../utils/helpers';
import { APY_MINING_REWARDS } from '../../../../../constants';

import PageTourBox from '../../../info/PageTourBox';
import MetricColumn from '../../../info/MetricColumn';
import Panel from '../../../containers/Panel';
import Metric from '../../../info/Metric';
import Slideshow from '../../../controls/Slideshow';
import {
  panelWrap,
  panelContainer,
  metricWrap,
  metricTitle,
  rewardsDisplay,
  formatToggleWrap,
  formatBtn,
  activeFormatBtn,
  positiveGreen,
  negativeRed,
} from './TourStablecoinFormMetrics.module.scss';

const getInitialGrowthSlides = currentForm => ({
  isLoading: false,
  type: 'projections',
  slides:
    currentForm === 'DEPOSIT'
      ? [
          {
            label: '7d',
            data: { dollar: BigNumber(0), percent: BigNumber(0) },
          },
          {
            label: '3m',
            data: { dollar: BigNumber(0), percent: BigNumber(0) },
          },
          {
            label: '6m',
            data: { dollar: BigNumber(0), percent: BigNumber(0) },
          },
          {
            label: '12m',
            data: { dollar: BigNumber(0), percent: BigNumber(0) },
          },
        ]
      : [
          {
            label: '7d',
            data: { dollar: BigNumber(0.95), percent: BigNumber(0.0095) },
          },
          {
            label: '3m',
            data: { dollar: BigNumber(12.5), percent: BigNumber(0.125) },
          },
          {
            label: '6m',
            data: { dollar: BigNumber(25), percent: BigNumber(0.25) },
          },
          {
            label: '12m',
            data: { dollar: BigNumber(50), percent: BigNumber(0.5) },
          },
        ],
});

const TourStablecoinFormMetrics = forwardRef(
  ({ classes = {}, inputValue, pageTourBox, isTourElementActive }, ref) => {
    // Page Tour
    const {
      tourSteps: { portfolio: portfolioSteps },
      isTourActive,
      isTourStepActive,
      currentTourStep,
    } = useContext(PageTourContext);
    const [growthElement, setGrowthElement] = useState();
    const [rewardsElement, setRewardsElement] = useState();

    const currentForm = currentTourStep === 7 ? 'WITHDRAW' : 'DEPOSIT';

    // Media queries
    const isVwOver768 = useMedia({ minWidth: '769px' });

    // Projected Growth
    const [growthFormat, setGrowthFormat] = useState('dollar');
    const [growthSlides, setGrowthSlides] = useState(() =>
      getInitialGrowthSlides(currentForm)
    );
    useEffect(() => {
      const MOCK_VALUES = {
        accountValue: currentForm === 'DEPOSIT' ? BigNumber(0) : BigNumber(100),
        apy: BigNumber(0.5),
      };

      const TIME_APYS = {
        '7d': MOCK_VALUES['apy'].div(365).times(7),
        '3m': MOCK_VALUES['apy'].div(4),
        '6m': MOCK_VALUES['apy'].div(2),
        '12m': MOCK_VALUES['apy'],
      };

      const timeoutId = setTimeout(() => {
        if (inputValue > 0) {
          // Set isLoading
          setGrowthSlides(currentSlides => ({
            ...currentSlides,
            isLoading: true,
          }));

          let valueAfterInput;
          let slides;
          if (currentForm === 'DEPOSIT') {
            valueAfterInput = MOCK_VALUES['accountValue'].plus(inputValue);

            slides = Object.keys(TIME_APYS).map(timeLabel => {
              return {
                label: timeLabel,
                data: {
                  dollar: valueAfterInput.times(TIME_APYS[timeLabel]),
                  percent: TIME_APYS[timeLabel],
                },
              };
            });
          } else if (currentForm === 'WITHDRAW') {
            const amtWithdrawing = MOCK_VALUES['accountValue'].times(
              inputValue / 100
            );
            valueAfterInput = MOCK_VALUES['accountValue'].minus(amtWithdrawing);

            slides = Object.keys(TIME_APYS).map(timeLabel => {
              return {
                label: timeLabel,
                data: {
                  // This represents the projected $/% amount increase user will forego if they withdraw that amount
                  dollar: valueAfterInput
                    .times(TIME_APYS[timeLabel])
                    .minus(
                      MOCK_VALUES['accountValue'].times(TIME_APYS[timeLabel])
                    ),
                  percent: valueAfterInput
                    .times(TIME_APYS[timeLabel])
                    .minus(
                      MOCK_VALUES['accountValue'].times(TIME_APYS[timeLabel])
                    )
                    .div(100),
                },
              };
            });
          }

          setGrowthSlides(currentSlides => ({
            ...currentSlides,
            slides,
            isLoading: false,
          }));
        } else {
          setGrowthSlides(getInitialGrowthSlides(currentForm));
        }
      }, 550);

      return () => clearTimeout(timeoutId);
    }, [inputValue, currentForm]);

    // Rewards
    const mockCurrentRewards = currentForm === 'DEPOSIT' ? 0 : 0.12;
    const [projectedRewards, setProjectedRewards] = useState(null);
    useEffect(() => {
      const MOCK_VALUES = {
        accountValue: currentForm === 'DEPOSIT' ? BigNumber(0) : BigNumber(100),
        tvl:
          currentForm === 'DEPOSIT'
            ? BigNumber(25000000)
            : BigNumber(250001000),
      };

      const timeoutId = setTimeout(() => {
        if (inputValue > 0) {
          let tvlShare;
          if (currentForm === 'DEPOSIT') {
            const valueAfterInput = MOCK_VALUES['accountValue'].plus(
              inputValue
            );
            tvlShare = valueAfterInput.div(MOCK_VALUES['tvl']);
          } else if (currentForm === 'WITHDRAW') {
            const amtWithdrawing = MOCK_VALUES['accountValue'].times(
              inputValue / 100
            );
            const valueAfterInput = MOCK_VALUES['accountValue'].minus(
              amtWithdrawing
            );
            tvlShare = valueAfterInput.div(MOCK_VALUES['tvl']);
          }

          const rewardsPerDay = tvlShare.times(APY_MINING_REWARDS);
          setProjectedRewards(rewardsPerDay);
        } else {
          setProjectedRewards(null);
        }
      }, 550);

      return () => clearTimeout(timeoutId);
    }, [inputValue, currentForm]);

    const showRewards = (projection, current) => {
      let value = projection ?? current;
      const decimals = getRequiredDecimals(value);

      const setColor = () => {
        if (projection) {
          if (projection.gt(current)) {
            return positiveGreen;
          } else if (projection.lt(current)) {
            return negativeRed;
          }
        }
      };

      return (
        <div
          className={`${rewardsDisplay} ${setColor()}`}
          style={{ fontStyle: projection && 'italic' }}
        >
          {displayAmount(value, { decimals })}
          {'*'}
          <span style={{ fontSize: '1rem' }}> APY</span>
        </div>
      );
    };

    return (
      <>
        <MetricColumn
          classes={classes.metricColumn}
          ref={ref}
          isTourElementActive={isTourElementActive}
        >
          {/* Projected Growth */}
          <Panel
            classes={{ panelWrap, panelContainer }}
            cornerComponent={
              <div className={formatToggleWrap}>
                <button
                  className={`${formatBtn} ${
                    growthFormat === 'dollar' && activeFormatBtn
                  }`}
                  onClick={() => setGrowthFormat('dollar')}
                >
                  $
                </button>
                <button
                  className={`${formatBtn} ${
                    growthFormat === 'percent' && activeFormatBtn
                  }`}
                  onClick={() => setGrowthFormat('percent')}
                >
                  %
                </button>
              </div>
            }
            ref={setGrowthElement}
            isTourElementActive={
              !isVwOver768 && isTourStepActive(portfolioSteps[3][2])
            }
            pageTourBox={
              <PageTourBox
                steps={portfolioSteps[3][2]}
                isActive={
                  !isVwOver768 && isTourStepActive(portfolioSteps[3][2])
                }
                refElement={growthElement}
              />
            }
          >
            <Metric classes={{ metricTitle }} title={'Projected Growth'}>
              <Slideshow
                format={growthFormat}
                currentForm={currentForm}
                slidesObj={growthSlides}
                isUsingMockValues={isTourActive}
              />
            </Metric>
          </Panel>

          {/* Rewards Per Day */}
          <Panel
            classes={{ panelWrap, panelContainer }}
            ref={setRewardsElement}
            isTourElementActive={
              !isVwOver768 && isTourStepActive(portfolioSteps[3][3])
            }
            pageTourBox={
              <PageTourBox
                steps={portfolioSteps[3][3]}
                isActive={
                  !isVwOver768 && isTourStepActive(portfolioSteps[3][3])
                }
                refElement={rewardsElement}
              />
            }
          >
            <Metric
              classes={{ metricWrap, metricTitle }}
              title={'Rewards Per Day'}
            >
              {showRewards(projectedRewards, mockCurrentRewards)}
            </Metric>
          </Panel>
        </MetricColumn>

        {pageTourBox}
      </>
    );
  }
);

export default TourStablecoinFormMetrics;
