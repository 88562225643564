import React, { useContext, useReducer, useState, useEffect } from 'react';

import { useFormBalances } from '../../../../../../hooks';
import { PageTourContext } from '../../../../../../state/contexts';
import { tokenFormReducer } from '../../../../../../state/reducers';

import SectionHeader from '../../../../info/SectionHeader';
import PageTourBox from '../../../../info/PageTourBox';
import NewTooltip from '../../../../controls/NewTooltip';
import StakingForm from './StakingForm';
import TourStakingForm from './TourStakingForm';
import TourStakingFormMetrics from './TourStakingFormMetrics';
import FormMetrics from './FormMetrics';
import {
  stakeTokensWrap,
  staking,
  metricList,
} from './StakeTokens.module.scss';

function StakeTokens({ protocol, poolToken, underlyer }) {
  // Page Tour
  const {
    isTourActive,
    tourSteps: { stakingSteps },
    isTourStepActive,
    currentTourStep,
  } = useContext(PageTourContext);
  const [formElement, setFormElement] = useState();
  const [metricColumnElement, setMetricColumnElement] = useState();
  const formMetricTourSteps = stakingSteps['form'].filter(step =>
    [4, 5, 6, 7, 8].includes(step.stepNum)
  );

  // Form State
  const [
    { currentForm, selectedToken, inputValue, inputErrorMsg, isTrxPending },
    dispatch,
  ] = useReducer(tokenFormReducer, {
    currentForm: 'STAKE',
    selectedToken: poolToken,
    inputValue: null,
    inputErrorMsg: null,
    isTrxPending: false,
  });

  useEffect(() => {
    // Clear out form input when we close page tour
    if (!isTourActive) {
      dispatch({ type: 'CHANGE_INPUT', inputValue: '' });
    }
  }, [isTourActive]);

  const { balances } = useFormBalances('STAKE_UNSTAKE');

  const switchForm = (newTab, currentTab) => {
    if (newTab === currentTab) return;

    const formType = newTab === 0 ? 'STAKE' : 'UNSTAKE';

    dispatch({
      type: 'SWITCH_FORM',
      switchTo: formType,
      token: selectedToken,
    });
  };

  const changeInputValue = value => {
    dispatch({ type: 'CHANGE_INPUT', inputValue: value });
  };

  return (
    <section className={stakeTokensWrap}>
      <header>
        <SectionHeader
          title={'Stake to Earn APY Tokens'}
          // tooltip={
          //   <NewTooltip
          //     placement="right"
          //     body={'Insert tooltip info here...'}
          //   />
          // }
        />
      </header>
      <div className={staking}>
        {isTourActive ? (
          <>
            <TourStakingForm
              protocol={protocol}
              selectedToken={selectedToken}
              currentForm={currentTourStep < 8 ? 'STAKE' : 'UNSTAKE'}
              inputValue={inputValue}
              changeInputValue={changeInputValue}
              ref={setFormElement}
              isTourElementActive={isTourStepActive(stakingSteps['form'])}
              pageTourBox={
                <PageTourBox
                  steps={stakingSteps['form']}
                  isActive={[2, 3, 9].includes(currentTourStep)}
                  refElement={formElement}
                />
              }
            />
            <TourStakingFormMetrics
              classes={{ metricColumn: { metricList } }}
              currentForm={currentTourStep < 8 ? 'STAKE' : 'UNSTAKE'}
              inputValue={inputValue}
              ref={setMetricColumnElement}
              isTourElementActive={isTourStepActive(formMetricTourSteps)}
              pageTourBox={
                <PageTourBox
                  steps={formMetricTourSteps}
                  isActive={isTourStepActive(formMetricTourSteps)}
                  refElement={metricColumnElement}
                />
              }
            />
          </>
        ) : (
          <>
            <StakingForm
              protocol={protocol}
              balances={balances}
              currentForm={currentForm}
              onTabToggle={switchForm}
              selectedToken={selectedToken}
              inputValue={inputValue}
              changeInputValue={changeInputValue}
              inputError={inputErrorMsg}
              dispatch={dispatch}
              isTrxPending={isTrxPending}
            />
            <FormMetrics
              classes={{ metricColumn: { metricList } }}
              balances={balances}
              currentForm={currentForm}
              selectedToken={selectedToken}
              underlyer={underlyer}
              inputValue={inputValue}
            />
          </>
        )}
      </div>
    </section>
  );
}

export default StakeTokens;
