import React, { createContext, useReducer } from 'react';

import { trxModalReducer } from '../reducers';
import { trxConfig } from '../../constants/transactions';

export const TrxContext = createContext();

export const TrxProvider = ({ children }) => {
  const [state, dispatch] = useReducer(trxModalReducer, {
    showTrxModal: false,
    trxStatus: '',
    trxHeader: null,
    trxMessage: '',
    trxHash: '',
  });

  const showTrxModal = (trxStatus, options = {}) => {
    // Use options.message or fallback message in trxConfig
    let modalMessage =
      options.message ||
      trxConfig[options.trxType].messages(options)[trxStatus];

    dispatch({
      type: trxStatus,
      message: modalMessage,
      ...options,
    });
  };

  const closeTrxModal = () => {
    dispatch({ type: 'CLOSE' });
  };

  return (
    <TrxContext.Provider
      value={{
        state,
        showTrxModal,
        closeTrxModal,
      }}
    >
      {children}
    </TrxContext.Provider>
  );
};
