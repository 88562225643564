import { useState, useEffect, useCallback } from 'react';
import { useSessionStore } from '../state/stores';

/**
 * - Checks if 'active,' but only after 1 sec
 * - This prevents false negatives when 'active' is false for a split second on page refresh
 */

export function useActiveWalletCheck(isWeb3ReactActive) {
  // Set to true initially b/c this hook is used generally in ConnectedApp view
  const [isWalletActive, setIsWalletActive] = useState(true);

  const isSessionActive = useSessionStore(state => state.isSessionActive);
  const setSession = useSessionStore(
    useCallback(state => state.setSession, [])
  );

  useEffect(() => {
    if (isWeb3ReactActive) {
      setIsWalletActive(true);
    }

    const timeoutId = setTimeout(() => {
      if (isSessionActive && !isWeb3ReactActive) {
        setSession(false);
        setIsWalletActive(false);
      } else if (!isWeb3ReactActive) {
        setIsWalletActive(false);
      }
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isWeb3ReactActive]);

  return { isWalletActive };
}
