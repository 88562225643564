import React, { useCallback, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { AnimatePresence, motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';
import { useMedia } from 'use-media';
import { BeatLoader } from 'react-spinners';

import { PAGE_LINKS } from '../../../../constants';
import { useSessionStore, useSliderMenuStore } from '../../../../state/stores';
import { useAccountBalance } from '../../../../hooks';
import { displayAmount, fromWei } from '../../../../utils/helpers';

// import CommunityToolTip from '../../../Layout/CommunityToolTip';
import Logo from '../../visuals/Logo';
// import SubHeader from '../../headers/SubHeader';
import NewButton from '../../buttons/NewButton';
import {
  wrapper,
  container,
  balanceWrap,
  balanceLabel,
  balanceBox,
  navLinkWrap,
  activeNavLink,
  disconnectButton,
  // menuItem,
  // internalLinks,
  // footerLink,
} from './SliderMenu.module.scss';

const SliderMenu = () => {
  const { deactivate } = useWeb3React();
  const setSession = useSessionStore(state => state.setSession);
  const isSliderMenuOpen = useSliderMenuStore(
    useCallback(state => state.isSliderMenuOpen, [])
  );
  const setIsSliderMenuOpen = useSliderMenuStore(
    useCallback(state => state.setIsSliderMenuOpen, [])
  );

  const { balance: apyBalance } = useAccountBalance('APY');

  // Media queries
  const isVwOver500 = useMedia({ minWidth: '501px' });
  const isVhUnder500 = useMedia({ maxHeight: '500px' });
  const isVwOver1200 = useMedia({ minWidth: '1201px' });

  // Automatically close SliderMenu if open and viewport expands pass 1200px
  useEffect(() => {
    if (isSliderMenuOpen && isVwOver1200) {
      setIsSliderMenuOpen(false);
    }
  }, [isVwOver1200]);

  const showApyBalance = balance => {
    if (balance.isLoading) {
      return <BeatLoader size={10} margin={1} color={'gray'} />;
    } else {
      return `${displayAmount(fromWei(balance.data, 'APY'))}`;
    }
  };

  const onDisconnect = () => {
    deactivate();
    setSession(false);
    setIsSliderMenuOpen(!isSliderMenuOpen);
  };

  // const MenuItem = ({ title, children }) => {
  //   return (
  //     <div className={menuItem}>
  //       <SubHeader heading={title} />
  //       {children}
  //     </div>
  //   );
  // };

  // const Link = ({ path, label }) => {
  //   return (
  //     <NavLink
  //       to={`/${path}`}
  //       onClick={() => setIsSliderMenuOpen(!isSliderMenuOpen)}
  //     >
  //       <p className={footerLink}>{label}</p>
  //     </NavLink>
  //   );
  // };

  const variants = {
    open: {
      opacity: 1,
      x: 0,
      transition: {
        type: 'spring',
        stiffness: 50,
        restDelta: 1,
      },
    },
    closed: {
      opacity: 0,
      x: '100%',
      transition: {
        // delay: 0.5,
        type: 'spring',
        stiffness: 400,
        damping: 40,
      },
    },
  };

  return (
    <AnimatePresence>
      {isSliderMenuOpen && (
        <motion.div
          key="menu"
          initial="closed"
          animate="open"
          exit="closed"
          variants={variants}
          className={wrapper}
        >
          {(!isVwOver500 || isVhUnder500) && (
            <div className={balanceWrap}>
              <div className={balanceLabel} style={{ marginBottom: '.5rem' }}>
                APY Balance:
              </div>
              <div className={balanceBox}>
                {showApyBalance(apyBalance)}
                <Logo color="#8e40dd" size={20} addMargin={'0 0 .1rem .5rem'} />
              </div>
            </div>
          )}
          <div className={container}>
            <br />
            <ul>
              {PAGE_LINKS.map(({ label, path, exact }) => {
                return (
                  <li key={label} className={navLinkWrap}>
                    <NavLink
                      to={path}
                      exact={exact}
                      activeClassName={activeNavLink}
                      onClick={() => setIsSliderMenuOpen(!isSliderMenuOpen)}
                    >
                      {label}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
            <br />
            <NewButton
              classes={{ button: disconnectButton }}
              color="red"
              onClick={onDisconnect}
            >
              Disconnect
            </NewButton>
            {/* <MenuItem title={'About'}>
              <div className={internalLinks}>
                <Link path="privacy-policy" label="Privacy Policy" />
                <Link path="terms-of-service" label="Terms of Service" />
                <a href="mailto:support@apy.finance" className={footerLink}>
                  Support
                </a>
                <Link path="FAQ" label="FAQ" />
              </div>
            </MenuItem> */}
            {/* <MenuItem title={'Community'}>
              <CommunityToolTip />
            </MenuItem> */}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SliderMenu;
