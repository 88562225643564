import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5, // Changed from earlier value of Infinity
    retryIf: (error, _operation) => !!error,
  },
});

const subgraphUrl = {
  apyFinance: {
    1: 'https://api.thegraph.com/subgraphs/name/apy-finance/apy-finance-v2',
    42: 'https://api.thegraph.com/subgraphs/name/apy-finance/apy-finance-kovan',
    staging:
      'https://api.thegraph.com/subgraphs/name/apy-finance/apy-finance-v2-staging',
  },

  aave: {
    1: 'https://api.thegraph.com/subgraphs/name/aave/protocol-v2',
    42: 'https://api.thegraph.com/subgraphs/name/aave/protocol-v2-kovan',
  },
};

const httpLink = (chainId, subgraphKey) => {
  return new HttpLink({
    uri: subgraphUrl[subgraphKey][chainId],
  });
};

export const apolloClient = (chainId, subgraphKey) => {
  return new ApolloClient({
    link: ApolloLink.from([
      errorLink,
      retryLink,
      httpLink(chainId, subgraphKey),
    ]),
    cache: new InMemoryCache(),
  });
};
