import { Contract } from '../utils/entities/Contract';
import { Strategy } from '../utils/entities/Strategy';
import * as icons from '../assets/defi';
import { ChainId } from '../constants';

import { CURVE_POOL_ABI, CURVE_LIQUIDITY_GAUGE_ABI } from './abis';
import {
  CURVE_GAUGE_CONTROLLER,
  getCurveBaseApy,
  getCrvTokenApy,
} from './utils/curve';

const CURVE_USDT_POOL = {
  [ChainId.MAINNET]: new Contract(
    '0x52ea46506b9cc5ef470c5bf89f17dc28bb35d85c',
    CURVE_POOL_ABI
  ),
};

const CURVE_USDT_LIQUIDITY_GAUGE = {
  [ChainId.MAINNET]: new Contract(
    '0xBC89cd85491d81C6AD2954E6d0362Ee29fCa8F53',
    CURVE_LIQUIDITY_GAUGE_ABI
  ),
};

const curve_usdt = new Strategy('Curve USDT', {
  protocol: 'curve',
  poolName: 'usdt',
  protocolIcon: icons['curve'],
  outputAssets: ['CRV'],
  contracts: {
    pool: CURVE_USDT_POOL,
    liquidityGauge: CURVE_USDT_LIQUIDITY_GAUGE,
    gaugeController: CURVE_GAUGE_CONTROLLER,
  },
  allocationName: 'curve-usdt',
  tokenPricesNeeded: ['curve-dao-token'],
  getApys: (chainId, library) => ({
    baseApy: getCurveBaseApy('usdt'),
    CRV: getCrvTokenApy('curve_usdt', chainId, library),
  }),
  description: 'Add strategy description here...',
});

export default curve_usdt;
