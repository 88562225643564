import { Contract } from '../utils/entities/Contract';
import { Strategy } from '../utils/entities/Strategy';
import * as icons from '../assets/defi';
import { ChainId } from '../constants';

import { CURVE_POOL_ABI, CURVE_LIQUIDITY_GAUGE_ABI } from './abis';
import {
  CURVE_GAUGE_CONTROLLER,
  getCurveBaseApy,
  getCrvTokenApy,
} from './utils/curve';

const CURVE_Y_POOL = {
  [ChainId.MAINNET]: new Contract(
    '0x45f783cce6b7ff23b2ab2d70e416cdb7d6055f51',
    CURVE_POOL_ABI
  ),
};

const CURVE_Y_LIQUIDITY_GAUGE = {
  [ChainId.MAINNET]: new Contract(
    '0xFA712EE4788C042e2B7BB55E6cb8ec569C4530c1',
    CURVE_LIQUIDITY_GAUGE_ABI
  ),
};

const curve_y = new Strategy('Curve Y', {
  protocol: 'curve',
  poolName: 'Y',
  protocolIcon: icons['curve'],
  outputAssets: ['CRV'],
  contracts: {
    pool: CURVE_Y_POOL,
    liquidityGauge: CURVE_Y_LIQUIDITY_GAUGE,
    gaugeController: CURVE_GAUGE_CONTROLLER,
  },
  allocationName: '',
  getApys: (chainId, library) => ({
    baseApy: getCurveBaseApy('y'),
    CRV: getCrvTokenApy('curve_y', chainId, library),
  }),
  description: 'Add strategy description here...',
});

export default curve_y;
