import { Strategy } from '../utils/entities/Strategy';
import * as icons from '../assets/defi';
import { ChainId } from '../constants';

const convex_mim = new Strategy('Convex MIM', {
  protocol: 'convex',
  poolName: 'mim',
  protocolIcon: icons['convex'],
  outputAssets: {
    gov: 'CVX',
    crv: 'CRV',
    addtlReward: 'SPELL',
  },
  allocationName: 'convex-mim',
  tokenPricesNeeded: [
    'curve-dao-token',
    'magic-internet-money',
    'convex-finance',
  ],
  options: {
    curveLiquidityGaugeAddress: {
      [ChainId.MAINNET]: '0xd8b712d29381748db89c36bca0138d7c75866ddf',
    },
  },
});

export default convex_mim;
