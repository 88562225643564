import { useMemo, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import useSWR from 'swr';

import { useActiveWalletCheck } from './useActiveWalletCheck';
import { isChainSupported, getContracts } from '../utils/helpers';
import { FetcherError } from '../utils/errors';
import { errors } from 'ethers';

export function useAccountBalance(tokenKey) {
  const { active, library, chainId, account } = useWeb3React();
  const { isWalletActive } = useActiveWalletCheck(active);

  const providerError = useMemo(() => {
    if (!isWalletActive) {
      return new Error(
        `PROVIDER_ERROR: Can't fetch ${tokenKey} balance without a connected provider. Make sure your wallet is not disconnected.`
      );
    }
  }, [isWalletActive]);

  /** Notes re: updateBalance:
   * - Balance updates can be triggered MANUALLY with updateBalance (useSWR mutate)
   * - We can call updateBalance after a successful platform transaction where we expect balances to update
   * - However, balances affected from transfers outside of our platform (e.g. buying more stablecoins) won't be automatically updated on the UI
   * - This is sufficient for showing updated, post-transaction balances without requiring a page refresh
   * - However, we can include listeners for auto-updates
   */

  const { data, error, mutate: updateBalance } = useSWR(
    chainId && account && tokenKey && [tokenKey, chainId, 'balanceOf', account]
  );

  const fetcherError = useMemo(() => {
    let fetcherError;

    // Note: Ethereum error messages are under error.data.message
    if (error) {
      fetcherError = new FetcherError(
        error.data?.message ?? error.message,
        `${tokenKey}.balanceOf()`,
        'useAccountBalance'
      );
      console.error(fetcherError.message);
    }

    return fetcherError;
  }, [error]);

  useEffect(() => {
    if (!chainId || !isChainSupported(chainId)) {
      return;
    }

    const listenForTransfers = async () => {
      const Contract = await getContracts([tokenKey], chainId, library);

      if (Contract) {
        if (!['UNIV2_STAKING', 'BPT_STAKING'].includes(tokenKey)) {
          const fromMe = Contract.filters.Transfer(account, null);
          library.on(fromMe, () => {
            updateBalance(undefined, true);
          });

          const toMe = Contract.filters.Transfer(null, account);
          library.on(toMe, () => {
            updateBalance(undefined, true);
          });
        } else {
          const staked = Contract.filters.Staked(account, null);
          library.on(staked, () => {
            updateBalance(undefined, true);
          });

          const unstaked = Contract.filters.Withdrawn(account, null);
          library.on(unstaked, () => {
            updateBalance(undefined, true);
          });
        }
      }
    };

    if (library && account && tokenKey) {
      listenForTransfers();
    }

    return () => {
      if (library) {
        library.removeAllListeners();
      }
    };
  }, [chainId, tokenKey, library, account, updateBalance]);

  const balance = useMemo(() => {
    return {
      data: data,
      isLoading: !providerError && !fetcherError && !data,
      error: providerError ?? fetcherError,
    };
  }, [providerError, data, fetcherError]);

  return { balance, updateBalance };
}
