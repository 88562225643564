import { Strategy } from '../utils/entities/Strategy';
import * as icons from '../assets/defi';
import { ChainId } from '../constants';

const convex_susd = new Strategy('Convex sUSD', {
  protocol: 'convex',
  poolName: 'susdv2',
  protocolIcon: icons['convex'],
  outputAssets: {
    gov: 'CVX',
    addtlReward: 'SNX',
    crv: 'CRV',
  },
  allocationName: 'convex-susdv2',
  tokenPricesNeeded: ['curve-dao-token', 'nusd', 'convex-finance'],
  options: {
    curveLiquidityGaugeAddress: {
      [ChainId.MAINNET]: '0xa90996896660decc6e997655e065b23788857849',
    },
  },
});

export default convex_susd;
