import React, { useRef, useState, useEffect } from 'react';
import { createChart, isBusinessDay } from 'lightweight-charts';
import { usePrevious } from 'react-use';
import moment from 'moment';

import { useHistoricalTvls } from '../../../../../../hooks';
import { displayAmount } from '../../../../../../utils/helpers';

import LoadingSkeleton from '../../../../../apy/Loader/LoadingSkeleton';
import TimeRange from './TimeRange';
import { wrap, header, container, legend, outer } from './TVLChart.module.scss';

const TVLChart = () => {
  const chartRef = useRef();
  const [chartCreated, setChartCreated] = useState(null);
  const [tooltipData, setTooltipData] = useState({ time: null, prices: [] });
  const [selectedTimePeriod, setSelectedTimePeriod] = useState('all');
  const { tvlChartData } = useHistoricalTvls();
  const [data, setData] = useState({ DAI: [], USDC: [], USDT: [] });

  useEffect(() => {
    if (tvlChartData.isLoading) return;

    if (tvlChartData.error) {
      console.error(tvlChartData.error.message);
      return;
    }

    setData(tvlChartData.data[selectedTimePeriod]);
  }, [tvlChartData, selectedTimePeriod]);

  // ---------------------------------------------
  // const [width, setWidth] = useState(window.innerWidth);

  // useEffect(() => {
  //   const handleWindowResize = () => setWidth(window.innerWidth);
  //   window.addEventListener('resize', handleWindowResize);

  //   // Return a function from the effect that removes the event listener
  //   return () => window.removeEventListener('resize', handleWindowResize);
  // }, []);

  // function useMedia(queries, values, defaultValue) {
  //   const mediaQueryLists = queries.map((q) => window.matchMedia(q));
  //   const getValue = () => {
  //     const index = mediaQueryLists.findIndex((mql) => mql.matches);
  //     return typeof values[index] !== 'undefined'
  //       ? values[index]
  //       : defaultValue;
  //   };

  //   const [value, setValue] = useState(getValue);

  //   useEffect(() => {
  //     const handler = () => setValue(getValue);
  //     mediaQueryLists.forEach((mql) => mql.addListener(handler));
  //     return () =>
  //       mediaQueryLists.forEach((mql) => mql.removeListener(handler));
  //   }, []);
  //   return value;
  // }

  // const chartSize = useMedia(
  //   [
  //     '(max-width: 281px)',
  //     '(max-width: 321px)',
  //     '(max-width: 361px)',
  //     '(max-width: 376px)',
  //     '(max-width: 394px)',
  //     '(max-width: 415px)',
  //     '(max-width: 501px)',
  //     '(max-width: 541px)',
  //     '(max-width: 769px)',
  //     '(max-width: 901px)',
  //     '(max-width: 1026px)',
  //     '(max-width: 1200px)',
  //     '(min-width: 1300px)',
  //   ],
  //   [
  //     { height: 270, width: 245 },
  //     { height: 280, width: 285 },
  //     { height: 290, width: 305 },
  //     { height: 300, width: 330 },
  //     { height: 300, width: 345 },
  //     { height: 300, width: 370 },
  //     { height: 320, width: 380 },
  //     { height: 320, width: 420 },
  //     { height: 400, width: 715 },
  //     { height: 400, width: 765 },
  //     { height: 500, width: 950 },
  //     { height: 500, width: 1000 },
  //     { height: 500, width: 1090 },
  //   ],
  //   { height: 500, width: 1090 }
  // );

  // ---------------------------------------------

  const dataPrev = usePrevious(data);

  const formatPrice = price => {
    price = parseFloat(price);
    if (price < 0) {
      return '';
    } else if (price >= 1000000) {
      return price % 1000000 === 0
        ? `$${(price / 1000000).toFixed(0)}m`
        : `$${(price / 1000000).toFixed(2)}m`;
    } else if (price >= 1000) {
      return price % 1000 === 0
        ? `$${(price / 1000).toFixed(0)}k`
        : `$${(price / 1000).toFixed(2)}k`;
    } else {
      return '$' + price.toFixed(2);
    }
  };

  useEffect(() => {
    if (data !== dataPrev && chartCreated) {
      chartCreated.resize(0, 0);
      setChartCreated(null);
    }
  }, [data, dataPrev, chartCreated]);

  useEffect(() => {
    if (!chartCreated) {
      const chart = createChart(chartRef.current, {
        layout: {
          backgroundColor: 'transparent',
          textColor: '#d0d0d0',
          fontSize: 12,
          fontFamily: 'archiaregular',
        },
        width: 798,
        height: 600,
        grid: {
          vertLines: {
            visible: false,
          },
          horzLines: {
            visible: false,
          },
        },
        crosshair: {
          vertLine: {
            visible: true,
            labelVisible: false,
            style: 0,
            width: 2,
            color: 'rgba(32, 38, 46, 0.1)',
          },
          horzLine: {
            visible: false,
            labelVisible: false,
          },
        },
        localization: {
          priceFormatter: formatPrice,
        },
        priceScale: {
          drawTicks: false,
          scaleMargins: {
            top: 0.3,
          },
          borderVisible: false,
        },
        timeScale: {
          borderVisible: false,
          tickMarkFormatter: (time, tickMarkType) => {
            if (tickMarkType === 0) {
              const year = isBusinessDay(time)
                ? time.year
                : new Date(time * 1000).getUTCFullYear();
              return String(year);
            }
            return moment.unix(time).format('MMM D');
          },
        },
      });

      const daiSeries = chart.addAreaSeries({
        topColor: 'rgba(205, 151, 4, 0.5)',
        bottomColor: 'rgba(205, 151, 4, 0)',
        lineColor: 'rgba(205, 151, 4, 1)',
      });

      const usdcSeries = chart.addAreaSeries({
        topColor: 'rgba(2, 55, 136, 0.5)',
        bottomColor: 'rgba(2, 55, 136, 0)',
        lineColor: 'rgba(2, 55, 136, 1)',
      });

      const usdtSeries = chart.addAreaSeries({
        topColor: 'rgba(4, 180, 133, 0.5)',
        bottomColor: 'rgba(4, 180, 133, 0)',
        lineColor: 'rgba(4, 180, 133, 1)',
      });

      daiSeries.setData(data['DAI']);
      usdcSeries.setData(data['USDC']);
      usdtSeries.setData(data['USDT']);

      chart.subscribeCrosshairMove(param => {
        setTooltipData({
          time: param.time,
          prices: [...param.seriesPrices.values()],
        });
      });

      setChartCreated(chart);

      return () => chart.unsubscribeCrosshairMove();
    }
  }, [data, chartCreated]);

  const onTimePeriodBtnClick = time => {
    // setChartTimePeriod(time);
    setSelectedTimePeriod(time);
  };

  return (
    <div className={outer}>
      <div className={wrap}>
        <LoadingSkeleton isLoading={tvlChartData.isLoading} />
        {/* <div className={header}>
            <TimeRange
              times={['7d', '1m', '3m', 'all']}
              isSelected={selectedTimePeriod}
              onClick={onTimePeriodBtnClick}
            />
          </div> */}

        <div className={container}>
          <div className={legend}>
            <div>
              <span
                style={{
                  display: 'block',
                  marginBottom: '.45rem',
                  fontSize: '20px',
                  fontFamily: 'archiaregular',
                }}
              >
                {tooltipData.time
                  ? moment.unix(tooltipData.time).format('ddd MMM D')
                  : 'Total Value Locked'}
              </span>
              <div>
                <span style={{ fontFamily: 'archiaregular' }}>DAI </span>
                <span
                  style={{
                    color: 'hsl(43, 96%, 70%)',
                    fontFamily: 'archiathin',
                  }}
                >
                  {tooltipData.prices.length === 0 && data['DAI'].length !== 0
                    ? displayAmount(data['DAI'][data['DAI'].length - 1].value, {
                        format: 'currency',
                      })
                    : displayAmount(tooltipData.prices[0], {
                        format: 'currency',
                      })}
                </span>
              </div>
              <div>
                <span style={{ fontFamily: 'archiaregular' }}>USDC </span>
                <span
                  style={{
                    color: 'hsl(216, 97%, 57%)',
                    fontFamily: 'archiathin',
                  }}
                >
                  {tooltipData.prices.length === 0 && data['USDC'].length !== 0
                    ? displayAmount(
                        data['USDC'][data['USDC'].length - 1].value,
                        {
                          format: 'currency',
                        }
                      )
                    : displayAmount(tooltipData.prices[1], {
                        format: 'currency',
                      })}
                </span>
              </div>
              <div>
                <span style={{ fontFamily: 'archiaregular' }}>USDT </span>
                <span
                  style={{
                    color: 'hsl(163, 95%, 66%)',
                    fontFamily: 'archiathin',
                  }}
                >
                  {tooltipData.prices.length === 0 && data['USDT'].length !== 0
                    ? displayAmount(
                        data['USDT'][data['USDT'].length - 1].value,
                        {
                          format: 'currency',
                        }
                      )
                    : displayAmount(tooltipData.prices[2], {
                        format: 'currency',
                      })}
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              borderRadius: '12px',
              // border: '1px solid green',
              overflow: 'hidden',
            }}
            ref={chartRef}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(TVLChart);
