import { Strategy } from '../utils/entities/Strategy';
import { Contract } from '../utils/entities/Contract';
import * as icons from '../assets/defi';
import { ChainId } from '../constants';

import { CURVE_POOL_ABI, CURVE_LIQUIDITY_GAUGE_ABI } from './abis';
import {
  CURVE_GAUGE_CONTROLLER,
  getCurveBaseApy,
  getCrvTokenApy,
} from './utils/curve';

const CURVE_IRONBANK_POOL = {
  [ChainId.MAINNET]: new Contract(
    '0x2dded6Da1BF5DBdF597C45fcFaa3194e53EcfeAF',
    CURVE_POOL_ABI
  ),
};

const CURVE_IRONBANK_LIQUIDITY_GAUGE = {
  [ChainId.MAINNET]: new Contract(
    '0xF5194c3325202F456c95c1Cf0cA36f8475C1949F',
    CURVE_LIQUIDITY_GAUGE_ABI
  ),
};

const curve_ironbank = new Strategy('Curve Ironbank', {
  protocol: 'curve',
  poolName: 'Ironbank',
  protocolIcon: icons['curve'],
  outputAssets: ['CRV'],
  contracts: {
    pool: CURVE_IRONBANK_POOL,
    liquidityGauge: CURVE_IRONBANK_LIQUIDITY_GAUGE,
    gaugeController: CURVE_GAUGE_CONTROLLER,
  },
  allocationName: '',
  getApys: (chainId, library) => ({
    baseApy: getCurveBaseApy('ib'),
    CRV: getCrvTokenApy('curve_ironbank', chainId, library),
  }),
  description: 'Add strategy description here...',
});

export default curve_ironbank;
