import axios from 'axios';
import { BigNumber } from 'bignumber.js';
import { BigNumber as BN } from 'ethers';

import { useStrategiesStore } from '../../state/stores';
import {
  APY_MINING_REWARDS,
  ACTIVE_STRATEGIES,
  MOCK_PRODUCTION_TVL,
} from '../../constants';
import { ALLOCATION_ABI } from '../../constants/contracts/abis';
import {
  fromWei,
  getContracts,
  getCoinGeckoPrices,
  isChainSupported,
  initContract,
  getAddressFromRegistry,
} from './index';

const WEI_PER_TOKEN = '1000000000000000000';
const SECONDS_PER_DAY = 86400;
const DAYS_PER_YEAR = 365;

export const getCurveVirtualPrice = async curvePool => {
  // Price is in BN wei (1e18)
  const price = await curvePool.get_virtual_price();
  const convertedPrice = price.toString() / 1000000000000000000;
  return convertedPrice;
};

export const getCoinGeckoPrice = async (ticker, { currency = 'usd' } = {}) => {
  const res = await axios.get(
    `https://api.coingecko.com/api/v3/simple/price?ids=${ticker}&vs_currencies=${currency}`
  );

  const price = res.data[ticker][currency];
  return price;
};

export const getUserApyTokenYield = (rewardsPerDollar, apyPrice) => {
  return BigNumber(rewardsPerDollar)
    .times(apyPrice)
    .times(365)
    .div(1e18)
    .toNumber();
};

export const calcApyFinanceApy = (apyPrice, platformTvl) => {
  let apyFinanceApy;
  if (process.env.REACT_APP_USE_DEMO_POOLS === 'true') {
    apyFinanceApy = (APY_MINING_REWARDS * apyPrice * 365) / MOCK_PRODUCTION_TVL;
  } else {
    apyFinanceApy = (APY_MINING_REWARDS * apyPrice * 365) / platformTvl;
  }

  return apyFinanceApy;
};

export const getMithApy = async (mithPool, stablecoinTicker, decimals) => {
  const micPrice = await getCoinGeckoPrice('mith-cash');
  const stablePrice = await getCoinGeckoPrice(stablecoinTicker);

  const rewardRate = (await mithPool.rewardRate()).toString();

  const tokenValuePerYear =
    ((rewardRate * SECONDS_PER_DAY * DAYS_PER_YEAR) / WEI_PER_TOKEN) * micPrice;

  const totalSupply = (await mithPool.totalSupply()) / 10 ** decimals;
  const supplyValue = totalSupply * stablePrice;

  return tokenValuePerYear / supplyValue;
};

export const getDodoApy = async (
  dodoPool, // DODO
  dodoMine, // DODO_MINE
  baseToken, // DODO._BASE_TOKEN_() (USDT)
  quoteToken, // DODO._QUOTE_TOKEN() (USDC)
  // dodoPrice,
  // basePrice, // USDT price
  // quotePrice, // USDC price
  baseTicker,
  quoteTicker,
  provider
) => {
  const dodoPrice = await getCoinGeckoPrice('dodo');
  const basePrice = await getCoinGeckoPrice(baseTicker);
  const quotePrice = await getCoinGeckoPrice(quoteTicker);

  const baseDlp = await dodoPool._BASE_CAPITAL_TOKEN_();
  const miningSpeed = await dodoMine.getDlpMiningSpeed(baseDlp);

  const baseBalance = await baseToken.balanceOf(dodoPool.address);
  const quoteBalance = await quoteToken.balanceOf(dodoPool.address);
  const poolValue = baseBalance * basePrice + quoteBalance * quotePrice;

  const latestBlock = await provider.getBlock('latest');
  const previousBlock = await provider.getBlock(-1);
  const blockDuration = latestBlock.timestamp - previousBlock.timestamp;
  const secondsPerYear = SECONDS_PER_DAY * DAYS_PER_YEAR;

  return (
    (dodoPrice * miningSpeed * secondsPerYear) /
    (blockDuration * poolValue) /
    WEI_PER_TOKEN
  );
};

export const calcStakingApy = async (
  lptKey,
  apyContract,
  apyPrice,
  lptContract,
  stakingContract,
  underlyer // underlyer =  { contract, symbol, price }
) => {
  let apy;
  try {
    const SECONDS_PER_YEAR = 31536000;
    const rewardsPerSec = fromWei(await stakingContract.rewardRate(), 'APY');

    const stakingContractLptBalance = fromWei(
      await lptContract.balanceOf(stakingContract.address),
      lptKey
    );

    const lptTotalSupply = fromWei(await lptContract.totalSupply(), lptKey);

    const lptApyBalance = fromWei(
      await apyContract.balanceOf(lptContract.address),
      'APY'
    );

    const lptUnderlyerSupply = fromWei(
      await underlyer.contract.balanceOf(lptContract.address),
      underlyer.symbol
    );

    const totalValuePoolReserves = lptApyBalance
      .times(apyPrice)
      .plus(lptUnderlyerSupply.times(underlyer.price));

    apy = rewardsPerSec
      .times(SECONDS_PER_YEAR)
      .times(apyPrice)
      .div(
        stakingContractLptBalance.times(
          totalValuePoolReserves.div(lptTotalSupply)
        )
      );
  } catch (err) {
    console.error(
      `Error: Issue calculaiting ${lptKey} Staking Annual Yield -`,
      err.message
    );
  }
  return apy;
};

export const getAllocations = async (
  Allocation,
  lpAccountAddress,
  tokenCount
) => {
  try {
    let allocations = [];
    for (let i = 0; i < tokenCount; i++) {
      const balance = await Allocation.balanceOf(lpAccountAddress, i);
      const symbol = (await Allocation.symbolOf(i)).toLowerCase();
      const decimals = await Allocation.decimalsOf(i);

      allocations.push({ balance, symbol, decimals });
    }

    return allocations;
  } catch (err) {
    console.error(`Error: Issue fetching strategy allocations -`, err.message);
  }
};

export const getActiveStrategies = Strategies => {
  const activeStrategies = Object.fromEntries(
    Object.entries(Strategies).filter(([stratKey]) =>
      ACTIVE_STRATEGIES.includes(stratKey)
    )
  );
  return activeStrategies;
};

// apy-core/utils/helpers/asset_allocation
export const priceTotalValue = (allocations, quote, data) => {
  let totalValue = allocations.reduce((acc, allocation) => {
    const val = data[allocation.symbol].quote[quote].price;
    const coins = new BigNumber(allocation.balance.toString()).div(
      10 ** allocation.decimals
    );
    return acc.plus(coins.times(val));
  }, new BigNumber(0));

  totalValue = totalValue.times(1e8).toFixed(0);

  // Uses ethers BigNumber
  return BN.from(totalValue);
};

export const getAssetAllocationValue = async allocations => {
  const quote = 'USD';
  const symbols = allocations.map(allocation =>
    allocation.symbol.toLowerCase()
  );

  const data = await getCoinGeckoPrices(symbols);

  const payloadEntries = symbols.map(symbol => {
    const key = symbol.toLowerCase();
    const val = {
      quote: {
        [quote]: { price: data[key] },
      },
    };

    return [key, val];
  });

  const payload = Object.fromEntries(payloadEntries);
  const value = priceTotalValue(allocations, quote, payload);

  return value;
};

export const setDeployedValues = async (
  isWalletActive,
  chainId,
  library,
  Strategies
) => {
  const { setState } = useStrategiesStore;

  if (!isWalletActive) {
    const providerError = new Error(
      "PROVIDER_ERROR: Can't fetch deployed values without a connected provider. Make sure your wallet is not disconnected."
    );
    setState({
      deployedValues: { data: null, isLoading: false, error: providerError },
    });
    throw providerError;
  }

  if (!chainId || !library || !Strategies) return;

  if (!isChainSupported(chainId)) {
    const chainIdError = new Error(
      `CHAIN_ID_ERROR: Can't fetch deployed values from unsupported chain (Chain ID: ${chainId})`
    );
    setState({
      deployedValues: { data: null, isLoading: false, error: chainIdError },
    });
    throw chainIdError;
  }

  const TvlMgr = await getContracts(['TVL_MGR'], chainId, library);
  const activeStrategies = getActiveStrategies(Strategies);

  let deployedValues = {};
  deployedValues = Object.fromEntries(
    await Promise.all(
      Object.entries(activeStrategies).map(async ([stratKey, Strategy]) => {
        const { allocationName } = Strategy;

        const allocationAddress = await TvlMgr.getAssetAllocation(
          allocationName
        );

        const Allocation = initContract(
          allocationAddress,
          ALLOCATION_ABI,
          library
        );

        const lpAccountAddress = await getAddressFromRegistry(
          'LP_ACCOUNT',
          chainId,
          library
        );

        const tokenCount = parseInt(await Allocation.numberOfTokens());

        let allocations = [];
        allocations = await getAllocations(
          Allocation,
          lpAccountAddress,
          tokenCount
        );

        const deployedValue = fromWei(
          await getAssetAllocationValue(allocations),
          'USD'
        );

        return [stratKey, deployedValue];
      })
    )
  );

  // ***** Temporary mock deployedValues *****
  // deployedValues = {
  //   aave_dai: BigNumber(1000000),
  //   aave_usdc: BigNumber(1000000),
  //   aave_usdt: BigNumber(1000000),
  //   curve_y: BigNumber(9000000),
  //   curve_aave: BigNumber(9000000),
  //   curve_alusd: BigNumber(2000000),
  //   curve_saave: BigNumber(7000000),
  //   curve_susd: BigNumber(9000000),
  //   curve_compound: BigNumber(9000000),
  //   curve_frax: BigNumber(7000000),
  // };

  setState({
    deployedValues: { data: deployedValues, isLoading: false, error: null },
  });
};

export const calcAggregateApys = apys => {
  const totalApys = {};
  for (const strat in apys) {
    const total = Object.values(apys[strat]).reduce((total, apy) => {
      return total.plus(apy);
    }, BigNumber(0));

    totalApys[strat] = total;
  }

  return totalApys;
};

export const calcPlatformApy = platformTvl => {
  if (platformTvl) {
    const { getState } = useStrategiesStore;
    const { data: deployedValues } = getState().deployedValues;
    const aggregateApys = getState().aggregateApys;

    if (!deployedValues || Object.values(aggregateApys).length === 0) return;

    const totalDeployedValue = Object.entries(deployedValues).reduce(
      (total, entry) => {
        const [, deployedValue] = entry;
        return total.plus(deployedValue);
      },
      BigNumber(0)
    );

    const platformApy = Object.entries(deployedValues).reduce(
      (total, entry) => {
        const [stratKey, deployedValue] = entry;
        const weight = deployedValue.div(totalDeployedValue);

        return total.plus(weight.times(aggregateApys[stratKey]));
      },
      BigNumber(0)
    );

    return platformApy;
  }
};
