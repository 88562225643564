import React from 'react';
import { useWeb3React } from '@web3-react/core';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { SWRConfig } from 'swr';
import { EthSWRConfig } from 'ether-swr';
import { ThemeProvider } from 'styled-components';

import { theme } from './components/styles/Theme';
import { ToastProvider } from './state/contexts';
import { useSessionStore } from './state/stores';
import { useDisconnectListener } from './hooks/useDisconnectListener';
import { useNetworkListener } from './hooks/useNetworkListener';
import { injectedConnector } from './utils/connectors';
import { useEagerConnect } from './hooks/useEagerConnect';
import { apolloClient } from './apollo/client';
import { ethFetcher } from './utils/swrFetchers';
import { APY_SUBGRAPH_ID } from './constants';

import Layout from './components/Layout';
import Landing from './components/pages/Landing';
import ConnectedApp from './components/ConnectedApp';
import { useActiveWalletCheck } from './hooks';
import CortexModal from './components/apy/modals/CortexModal';

function App() {
  const { active, library, chainId } = useWeb3React();

  // Custom hooks
  const { isWalletActive } = useActiveWalletCheck(active);
  const isSessionActive = useSessionStore(state => state.isSessionActive);

  // Re-activates web3React on page refresh
  useEagerConnect(injectedConnector);
  // Refresh page on wallet/provider events (chainChanged, accountsChanged, etc.)
  useNetworkListener();
  // Changes local storage session when no account is connected
  useDisconnectListener();

  return (
    <ApolloProvider
      client={apolloClient(APY_SUBGRAPH_ID[chainId], 'apyFinance')}
    >
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <ToastProvider>
            <Layout>
              <CortexModal />
              {isSessionActive && isWalletActive ? (
                <SWRConfig
                  value={{
                    fetcher: ethFetcher(library),
                    shouldRetryOnError: false,
                    revalidateOnFocus: false,
                  }}
                >
                  {library && (
                    <EthSWRConfig
                      value={{
                        web3Provider: library,
                      }}
                    >
                      <ConnectedApp />
                    </EthSWRConfig>
                  )}
                </SWRConfig>
              ) : (
                <Landing />
              )}
            </Layout>
          </ToastProvider>
        </BrowserRouter>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
