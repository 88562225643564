import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useClickAway } from 'react-use';

import { breakpoints } from '../../styles/breakpoints';

function Dropdown({ toggle, children, disabled }) {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef(null);
  useClickAway(ref, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  return (
    <DropdownWrap ref={ref}>
      {toggle({
        disabled: disabled,
        type: 'button',
        onClick: () => setIsOpen(!isOpen),
      })}

      {isOpen && children}
    </DropdownWrap>
  );
}

export const DropdownWrap = styled.div`
  position: relative;
`;

export const Menu = styled.div`
  position: absolute;
  top: ${({ theme, top }) => top ?? theme.space[5]};
  right: 0;

  ${({ width }) => (width ? `width: ${width};` : `min-width: 250px;`)}

  padding: ${({ theme, padding }) =>
    padding
      ? []
          .concat(padding)
          .map(padKey => theme.space[padKey])
          .join(' ')
      : theme.space[3]};

  font-size: ${({ theme }) => theme.typeScale[1]};

  @media ${breakpoints.tablet} {
    font-size: 1rem;
  }
`;

export const MenuItem = styled.button.attrs(() => ({
  type: 'button',
}))`
  border: none;
  background-color: transparent;
  width: 100%;
  padding: 0.25rem 0.75rem;
  text-align: center;
  color: hsl(0, 0%, 50%);
  cursor: pointer;
  transition: background-color 300ms;

  &:hover,
  &:focus {
    background-color: #555;
    color: ${({ theme }) => theme.colors.white};
  }
`;

export default Dropdown;
