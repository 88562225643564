import React from 'react';

import Panel from '../../containers/Panel';
import Stablecoin from '../../visuals/icons/Stablecoin';
import {
  selectStablecoinWrap,
  panelWrap,
  panelContainer,
  optionButton,
  activeStyle,
} from './SelectStablecoin.module.scss';

function Option({ active, onClick, children }) {
  return (
    <button
      className={`${optionButton} ${active ? activeStyle : undefined}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

function SelectStablecoin({ classes = {}, selected, onSelect }) {
  return (
    <div className={`${selectStablecoinWrap} ${classes.selectStablecoinWrap}`}>
      <Panel classes={{ panelWrap, panelContainer }}>
        {['DAI', 'USDC', 'USDT'].map(tokenOption => {
          return (
            <Option
              key={tokenOption}
              active={selected === tokenOption}
              onClick={() => onSelect(tokenOption)}
            >
              <Stablecoin symbol={tokenOption} />
            </Option>
          );
        })}
      </Panel>
    </div>
  );
}

export default SelectStablecoin;
