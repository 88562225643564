import React from 'react';

import {
  textContainer,
  wrapper,
  paragraph,
  legalHeader,
  emphasize,
  link,
  legalList,
  augmented,
  legalOrdered,
} from './scss/Legal.module.scss';
import SubHeader from '../../apy/headers/SubHeader';

const TermsOfService = () => {
  return (
    <div className={wrapper}>
      <SubHeader heading="terms of service" />
      <div
        data-augmented-ui="tl-round tr-round br-round bl-round border"
        className={`${textContainer} ${augmented}`}
      >
        <p className={paragraph}>
          Welcome to{' '}
          <a className={link} href="https://apy.finance">
            https://apy.finance
          </a>
          , a website-hosted user interface (the “Interface” or “App”) provided
          by DeFi Labs Inc. (“we”, “our”, or “us”). The Interface provides
          access to a decentralized protocol on the Ethereum blockchain that
          allows suppliers and borrowers of certain digital assets to
          participate in autonomous interest rate markets (the “Protocol”).
        </p>

        <p className={paragraph}>
          This Terms of Service Agreement (the “Agreement”) explains the terms
          and conditions by which you may access and use the Interface. You must
          read this Agreement carefully. By accessing or using the Interface,
          you signify that you have read, understand, and agree to be bound by
          this Agreement in its entirety. If you do not agree, you are not
          authorized to access or use the Interface.
        </p>

        <ol className={legalOrdered}>
          <li>
            <h2 className={legalHeader}>Modification of this Agreement</h2>
            <p className={paragraph}>
              We reserve the right, in our sole discretion, to modify this
              Agreement from time to time. If we make any modifications, we will
              notify you by updating the date at the top of the Agreement and by
              maintaining a current version of the Agreement at{' '}
              <a className={link} href="https://apy.finance/terms-of-service">
                https://apy.finance/terms-of-service
              </a>
              . All modifications will be effective when they are posted, and
              your continued use of the Interface will serve as confirmation of
              your acceptance of those modifications. If you do not agree with
              any modifications to this Agreement, you must immediately stop
              accessing and using the Interface.
            </p>
          </li>
          <li>
            <h2 className={legalHeader}>Eligibility</h2>
            <p className={paragraph}>
              To access or use the Interface, you must be able to form a legally
              binding contract with us. Accordingly, you represent that you are
              at least eighteen years old and have the full right, power, and
              authority to enter into and comply with the terms and conditions
              of this Agreement on behalf of yourself and any company or legal
              entity for which you may access or use the Interface. You further
              represent that you are not a citizen, resident, or member of any
              jurisdiction or group that is subject to economic sanctions by the
              United States, or where your use of the Interface would be illegal
              or otherwise violate any applicable law. You further represent
              that your access and use of the Interface will fully comply with
              all applicable laws and regulations, and that you will not access
              or use the Interface to conduct, promote, or otherwise facilitate
              any illegal activity.
            </p>
          </li>
          <li>
            <h2 className={legalHeader}>Proprietary Rights</h2>
            <p className={paragraph}>
              We own all intellectual property and other rights in the Interface
              and its contents, including (but not limited to) software, text,
              images, trademarks, service marks, copyrights, patents, and
              designs. Unless expressly authorized by us, you may not copy,
              modify, adapt, rent, license, sell, publish, distribute, or
              otherwise permit any third party to access or use the Interface or
              any of its contents. Provided that you are eligible, you are
              hereby granted a single, personal, limited license to access and
              use the Interface. This license is non-exclusive,
              non-transferable, and freely revocable by us at any time without
              notice or cause. Use of the Interface or its contents for any
              purpose not expressly permitted by this Agreement is strictly
              prohibited. Unlike the Interface, the Protocol is comprised
              entirely of open-source software running on the public Ethereum
              blockchain and is not our proprietary property.
            </p>
          </li>
          <li>
            <h2 className={legalHeader}>Privacy</h2>
            <p className={paragraph}>
              We care about your privacy. Although we will comply with all valid
              subpoena requests, we will carefully consider each request to
              ensure that it comports with the spirit and letter of the law, and
              we will not hesitate to challenge invalid, overbroad, or
              unconstitutional requests as appropriate. We use commercially
              reasonable safeguards to preserve the integrity and security of
              your personally identifiable information (“PII”) and aggregate
              data. However, we cannot guarantee that unauthorized third parties
              will never be able to obtain or use your PII or aggregate data for
              improper purposes. You acknowledge that you provide your PII and
              aggregate data at your own risk. By accessing and using the
              Interface, you understand and consent to our collection, use, and
              disclosure of your PII and aggregate data.
            </p>
          </li>
          <li>
            <h2 className={legalHeader}>Prohibited Activity</h2>
            <p className={paragraph}>
              You agree not to engage in, or attempt to engage in, any of the
              following categories of prohibited activity in relation to your
              access and use of the Interface:
            </p>

            <ul className={legalList}>
              <li className={paragraph}>
                <span className={emphasize}>
                  Intellectual Property Infringement.
                </span>{' '}
                Activity that infringes on or violates any copyright, trademark,
                service mark, patent, right of publicity, right of privacy, or
                other proprietary or intellectual property rights under the law.
              </li>

              <li className={paragraph}>
                <span className={emphasize}>Cyberattack.</span> Activity that
                seeks to interfere with or compromise the integrity, security,
                or proper functioning of any computer, server, network, personal
                device, or other information technology system, including (but
                not limited to) the deployment of viruses and denial of service
                attacks.
              </li>

              <li className={paragraph}>
                <span className={emphasize}>Fraud and Misrepresentation.</span>{' '}
                Activity that seeks to defraud us or any other person or entity,
                including (but not limited to) providing any false, inaccurate,
                or misleading information in order to unlawfully obtain the
                property of another.
              </li>

              <li className={paragraph}>
                <span className={emphasize}>Market Manipulation.</span> Activity
                that violates any applicable law, rule, or regulation concerning
                the integrity of trading markets, including (but not limited to)
                the manipulative tactics commonly known as spoofing and wash
                trading.
              </li>

              <li className={paragraph}>
                <span className={emphasize}>Any Other Unlawful Conduct.</span>{' '}
                Activity that violates any applicable law, rule, or regulation
                of the United States or another relevant jurisdiction, including
                (but not limited to) the restrictions and regulatory
                requirements imposed by U.S. law.
              </li>
            </ul>
          </li>
          <li>
            <h2 className={legalHeader}>No Professional Advice</h2>
            <p className={paragraph}>
              All information provided by the Interface is for informational
              purposes only and should not be construed as professional advice.
              You should not take, or refrain from taking, any action based on
              any information contained in the Interface. Before you make any
              financial, legal, or other decisions involving the Interface, you
              should seek independent professional advice from an individual who
              is licensed and qualified in the area for which such advice would
              be appropriate.
            </p>
          </li>
          <li>
            <h2 className={legalHeader}>No Warranties</h2>
            <p className={paragraph}>
              The Interface is provided on an “AS IS” and “AS AVAILABLE” basis.
              To the fullest extent permitted by law, we disclaim any
              representations and warranties of any kind, whether express,
              implied, or statutory, including (but not limited to) the
              warranties of merchantability and fitness for a particular
              purpose. You acknowledge and agree that your use of the Interface
              is at your own risk. We do not represent or warrant that access to
              the Interface will be continuous, uninterrupted, timely, or
              secure; that the information contained in the Interface will be
              accurate, reliable, complete, or current; or that the Interface
              will be free from errors, defects, viruses, or other harmful
              elements. No advice, information, or statement that we make should
              be treated as creating any warranty concerning the Interface. We
              do not endorse, guarantee, or assume responsibility for any
              advertisements, offers, or statements made by third parties
              concerning the Interface.
            </p>
          </li>
          <li>
            <h2 className={legalHeader}>No Fiduciary Duties</h2>
            <p className={paragraph}>
              This Agreement is not intended to, and does not, create or impose
              any fiduciary duties on us. To the fullest extent permitted by
              law, you acknowledge and agree that we owe no fiduciary duties or
              liabilities to you or any other party, and that to the extent any
              such duties or liabilities may exist at law or in equity, those
              duties and liabilities are hereby irrevocably disclaimed, waived,
              and eliminated. You further agree that the only duties and
              obligations that we owe you are those set out expressly in this
              Agreement.
            </p>
          </li>
          <li>
            <h2 className={legalHeader}>Compliance Obligations</h2>
            <p className={paragraph}>
              The Interface is operated from facilities within the United
              States. The Interface may not be available or appropriate for use
              in other jurisdictions. By accessing or using the Interface, you
              agree that you are solely and entirely responsible for compliance
              with all laws and regulations that may apply to you. You may not
              use the Interface if you are a citizen, resident, or member of any
              jurisdiction or group that is subject to economic sanctions by the
              United States, or if your use of the Interface would be illegal or
              otherwise violate any applicable law. The Interface and all of its
              contents are solely directed to individuals, companies, and other
              entities located within the United States.
            </p>
          </li>
          <li>
            <h2 className={legalHeader}>Assumption of Risk</h2>
            <p className={paragraph}>
              By accessing and using the Interface, you represent that you
              understand the inherent risks associated with using cryptographic
              and blockchain-based systems, and that you have a working
              knowledge of the usage and intricacies of digital assets such as
              bitcoin (BTC), ether (ETH), and other digital tokens such as those
              following the Ethereum Token Standard (ERC-20). You further
              understand that the markets for these digital assets are highly
              volatile due to factors including (but not limited to) adoption,
              speculation, technology, security, and regulation. You acknowledge
              that the cost and speed of transacting with cryptographic and
              blockchain-based systems such as Ethereum are variable and may
              increase dramatically at any time. You further acknowledge the
              risk that your digital assets may lose some or all of their value
              while they are supplied to the Protocol. If you borrow digital
              assets from the Protocol, you will have to supply digital assets
              of your own as collateral. If your collateral declines in value
              such that it is no longer sufficient to secure the amount that you
              borrowed, others may interact with the Protocol to seize your
              collateral in a liquidation event. You further acknowledge that we
              are not responsible for any of these variables or risks, do not
              own or control the Protocol, and cannot be held liable for any
              resulting losses that you experience while accessing or using the
              Interface. Accordingly, you understand and agree to assume full
              responsibility for all of the risks of accessing and using the
              Interface and interacting with the Protocol.
            </p>
          </li>
          <li>
            <h2 className={legalHeader}>
              Third-Party Resources and Promotions
            </h2>
            <p className={paragraph}>
              The Interface may contain references or links to third-party
              resources, including (but not limited to) information, materials,
              products, or services, that we do not own or control. In addition,
              third parties may offer promotions related to your access and use
              of the Interface. We do not endorse or assume any responsibility
              for any such resources or promotions. If you access any such
              resources or participate in any such promotions, you do so at your
              own risk, and you understand that this Agreement does not apply to
              your dealings or relationships with any third parties. You
              expressly relieve us of any and all liability arising from your
              use of any such resources or participation in any such promotions.
            </p>
          </li>
          <li>
            <h2 className={legalHeader}>Release of Claims</h2>
            <p className={paragraph}>
              You expressly agree that you assume all risks in connection with
              your access and use of the Interface and your interaction with the
              Protocol. You further expressly waive and release us from any and
              all liability, claims, causes of action, or damages arising from
              or in any way relating to your use of the Interface and your
              interaction with the Protocol. If you are a California resident,
              you waive the benefits and protections of California Civil Code §
              1542, which provides: “[a] general release does not extend to
              claims that the creditor or releasing party does not know or
              suspect to exist in his or her favor at the time of executing the
              release and that, if known by him or her, would have materially
              affected his or her settlement with the debtor or released party.”
            </p>
          </li>
          <li>
            <h2 className={legalHeader}>Indemnity</h2>
            <p className={paragraph}>
              You agree to hold harmless, release, defend, and indemnify us and
              our officers, directors, employees, contractors, agents,
              affiliates, and subsidiaries from and against all claims, damages,
              obligations, losses, liabilities, costs, and expenses arising
              from: (a) your access and use of the Interface; (b) your violation
              of any term or condition of this Agreement, the right of any third
              party, or any other applicable law, rule, or regulation; and (c)
              any other party’s access and use of the Interface with your
              assistance or using any device or account that you own or control.
            </p>
          </li>
          <li>
            <h2 className={legalHeader}>Limitation of Liability</h2>
            <p className={paragraph}>
              Under no circumstances shall we or any of our officers, directors,
              employees, contractors, agents, affiliates, or subsidiaries be
              liable to you for any indirect, punitive, incidental, special,
              consequential, or exemplary damages, including (but not limited
              to) damages for loss of profits, goodwill, use, data, or other
              intangible property, arising out of or relating to any access or
              use of the Interface, nor will we be responsible for any damage,
              loss, or injury resulting from hacking, tampering, or other
              unauthorized access or use of the Interface or the information
              contained within it. We assume no liability or responsibility for
              any: (a) errors, mistakes, or inaccuracies of content; (b)
              personal injury or property damage, of any nature whatsoever,
              resulting from any access or use of the Interface; (c)
              unauthorized access or use of any secure server or database in our
              control, or the use of any information or data stored therein; (d)
              interruption or cessation of function related to the Interface;
              (e) bugs, viruses, trojan horses, or the like that may be
              transmitted to or through the Interface; (f) errors or omissions
              in, or loss or damage incurred as a result of the use of, any
              content made available through the Interface; and (g) the
              defamatory, offensive, or illegal conduct of any third party.
              Under no circumstances shall we or any of our officers, directors,
              employees, contractors, agents, affiliates, or subsidiaries be
              liable to you for any claims, proceedings, liabilities,
              obligations, damages, losses, or costs in an amount exceeding the
              amount you paid to us in exchange for access to and use of the
              Interface, or .00, whichever is greater. This limitation of
              liability applies regardless of whether the alleged liability is
              based on contract, tort, negligence, strict liability, or any
              other basis, and even if we have been advised of the possibility
              of such liability. Some jurisdictions do not allow the exclusion
              of certain warranties or the limitation or exclusion of certain
              liabilities and damages. Accordingly, some of the disclaimers and
              limitations set forth in this Agreement may not apply to you. This
              limitation of liability shall apply to the fullest extent
              permitted by law.
            </p>
          </li>
          <li>
            <h2 className={legalHeader}>Dispute Resolution</h2>
            <p className={paragraph}>
              We will use our best efforts to resolve any potential disputes
              through informal, good faith negotiations. If a potential dispute
              arises, you must contact us by sending an email to
              info@apy.finance so that we can attempt to resolve it without
              resorting to formal dispute resolution. If we aren’t able to reach
              an informal resolution within sixty days of your email, then you
              and we both agree to resolve the potential dispute according to
              the process set forth below.
            </p>
            <p className={paragraph}>
              Any claim or controversy arising out of or relating to the
              Interface, this Agreement, or any other acts or omissions for
              which you may contend that we are liable, including (but not
              limited to) any claim or controversy as to arbitrability
              (“Dispute”), shall be finally and exclusively settled by
              arbitration under the JAMS Optional Expedited Arbitration
              Procedures. You understand that you are required to resolve all
              Disputes by binding arbitration. The arbitration shall be held on
              a confidential basis before a single arbitrator, who shall be
              selected pursuant to JAMS rules. The arbitration will be held in
              San Francisco, California, unless you and we both agree to hold it
              elsewhere. Unless we agree otherwise, the arbitrator may not
              consolidate your claims with those of any other party. Any
              judgment on the award rendered by the arbitrator may be entered in
              any court of competent jurisdiction.
            </p>
          </li>
          <li>
            <h2 className={legalHeader}>Class Action and Jury Trial Waiver</h2>
            <p className={paragraph}>
              You must bring any and all Disputes against us in your individual
              capacity and not as a plaintiff in or member of any purported
              class action, collective action, private attorney general action,
              or other representative proceeding. This provision applies to
              class arbitration. You and we both agree to waive the right to
              demand a trial by jury.
            </p>
          </li>
          <li>
            <h2 className={legalHeader}>Governing Law</h2>
            <p className={paragraph}>
              You agree that the laws of the State of California, without regard
              to principles of conflict of laws, govern this Agreement and any
              Dispute between you and us. You further agree that the Interface
              shall be deemed to be based solely in the State of California, and
              that although the Interface may be available in other
              jurisdictions, its availability does not give rise to general or
              specific personal jurisdiction in any forum outside the State of
              California. Any arbitration conducted pursuant to this Agreement
              shall be governed by the Federal Arbitration Act. You agree that
              San Francisco, California is the proper forum for any appeals of
              an arbitration award or for court proceedings in the event that
              this Agreement’s binding arbitration clause is found to be
              unenforceable.
            </p>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default TermsOfService;
