import { Strategy } from '../utils/entities/Strategy';
import * as icons from '../assets/defi';
import { ChainId } from '../constants';

const convex_alusd = new Strategy('Convex alUSD', {
  protocol: 'convex',
  poolName: 'alusd',
  protocolIcon: icons['convex'],
  outputAssets: {
    gov: 'CVX',
    addtlReward: 'ALCX',
    crv: 'CRV',
  },
  allocationName: 'convex-alusd',
  tokenPricesNeeded: ['curve-dao-token', 'alchemix-usd', 'convex-finance'],
  options: {
    curveLiquidityGaugeAddress: {
      [ChainId.MAINNET]: '0x9582c4adacb3bce56fea3e590f05c3ca2fb9c477',
    },
  },
});

export default convex_alusd;
