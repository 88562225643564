import React from 'react';
import { motion } from 'framer-motion';

import { useAppConnection } from '../../../../hooks';

import NewButton from '../../../apy/buttons/NewButton';
import { button } from './ConnectButton.module.scss';

function ConnectButton() {
  const { protectedConnect } = useAppConnection();

  return (
    <motion.span
      whileHover={{
        scale: 1.05,
      }}
    >
      <NewButton
        classes={{ button }}
        color="gradient"
        onClick={protectedConnect}
      >
        Connect
      </NewButton>
    </motion.span>
  );
}

export default ConnectButton;
