import React from 'react';
import { motion } from 'framer-motion';

import { container, icon, cta } from './scss/MetaMaskBtn.module.scss';

const InstallMetaMaskBtn = () => {
  return (
    <motion.a
      whileHover={{
        scale: 1.02,
        boxShadow: '0 0 10px rgba(0, 0, 0, .5)',
        bottom: '2px',
      }}
      whileTap={{
        scale: 1.1,
        boxShadow: '0 0 80px rgba(0, 0, 0, .5)',
      }}
      href="https://metamask.io/download.html"
      rel="noopener noreferrer"
      target="_blank"
      className={container}
    >
      <div className={icon} />
      <p className={cta}>install MetaMask</p>
    </motion.a>
  );
};

export default InstallMetaMaskBtn;
