import { useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';

import { injectedConnector } from '../utils/connectors';
import { useSessionStore } from '../state/stores';

export function useAppConnection() {
  const { activate } = useWeb3React();
  const setSession = useSessionStore(
    useCallback(state => state.setSession, [])
  );

  const connect = useCallback(async () => {
    await activate(injectedConnector);

    let isAuthorized = await injectedConnector.isAuthorized();
    if (isAuthorized) {
      setSession(true);
    }
  }, [activate, setSession]);

  const protectedConnect = useCallback(async () => {
    if (
      [
        'apy-dashboard-staging.firebaseapp.com',
        'apy-dashboard-staging.web.app',
        'staging.service.apy.finance',
        // 'localhost:3000',
      ].includes(window.location.host)
    ) {
      if (!window?.ethereum.selectedAddress) {
        await window.ethereum.enable();
      }
      fetch(
        'https://us-central1-continual-grin-289318.cloudfunctions.net/verify-staging',
        {
          method: 'POST',
          body: JSON.stringify({
            address: window.ethereum.selectedAddress,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      ).then(
        res => {
          res.json().then(resolved => {
            if (resolved.verified) {
              connect();
            } else {
              alert('Not Authorized');
            }
          });
        },
        err => console.log(err.message)
      );
    } else {
      connect();
    }
  }, [connect]);

  return { protectedConnect };
}
