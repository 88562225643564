import { gql } from '@apollo/client';

export const SUBGRAPH_HEALTH = gql`
  query health {
    indexingStatusForCurrentVersion(subgraphName: "ianlapham/uniswapv2") {
      synced
      health
      chains {
        chainHeadBlock {
          number
        }
        latestBlock {
          number
        }
      }
    }
  }
`;

export const AAVE_POOL_BASE_APY = gql`
  query reserve($symbol: String!) {
    reserves(where: { symbol: $symbol }) {
      symbol
      decimals
      liquidityRate
    }
  }
`;

export const AAVE_TOKEN_APY = gql`
  query reserve($symbol: String!) {
    reserves(where: { symbol: $symbol }) {
      symbol
      decimals
      aEmissionPerSecond
      totalATokenSupply
    }
  }
`;

export const PTV_QUERY = gql`
  query apt($poolAddress: String!, $firstTimestampOnPage: BigInt!) {
    apts(
      orderBy: timestamp
      orderDirection: asc
      first: 1000
      where: { timestamp_gte: $firstTimestampOnPage, poolAddress: $poolAddress }
    ) {
      id
      poolAddress
      timestamp
      totalValue
      price
    }
  }
`;

export const FIRST_DEPOSIT = gql`
  query cashflowPoint($launchTimestamp: BigInt!, $userAddress: String!) {
    cashflowPoints(
      orderBy: timestamp
      orderDirection: asc
      first: 1
      where: { timestamp_gte: $launchTimestamp, userAddress: $userAddress }
    ) {
      userAddress
      poolAddress
      timestamp
      userAptBalance
      total
    }
  }
`;

export const SNAPSHOT_BALANCES = gql`
  query cashflowPoint(
    $userAddress: String!
    $daiPoolAddress: String!
    $usdcPoolAddress: String!
    $usdtPoolAddress: String!
    $launchTimestamp: BigInt!
    $endTimestamp: BigInt!
  ) {
    DAI: cashflowPoints(
      orderBy: timestamp
      orderDirection: desc
      first: 1
      where: {
        timestamp_gte: $launchTimestamp
        timestamp_lte: $endTimestamp
        userAddress: $userAddress
        poolAddress: $daiPoolAddress
      }
    ) {
      poolAddress
      timestamp
      userAptBalance
      total
    }

    USDC: cashflowPoints(
      orderBy: timestamp
      orderDirection: desc
      first: 1
      where: {
        timestamp_gte: $launchTimestamp
        timestamp_lte: $endTimestamp
        userAddress: $userAddress
        poolAddress: $usdcPoolAddress
      }
    ) {
      poolAddress
      timestamp
      userAptBalance
      total
    }

    USDT: cashflowPoints(
      orderBy: timestamp
      orderDirection: desc
      first: 1
      where: {
        timestamp_gte: $launchTimestamp
        timestamp_lte: $endTimestamp
        userAddress: $userAddress
        poolAddress: $usdtPoolAddress
      }
    ) {
      poolAddress
      timestamp
      userAptBalance
      total
    }
  }
`;

export const APT_PRICES = gql`
  query apt(
    $daiPoolAddress: String!
    $usdcPoolAddress: String!
    $usdtPoolAddress: String!
    $launchTimestamp: BigInt!
    $endTimestamp: BigInt!
  ) {
    DAI: apts(
      orderBy: timestamp
      orderDirection: desc
      first: 1
      where: {
        timestamp_gte: $launchTimestamp
        timestamp_lte: $endTimestamp
        poolAddress: $daiPoolAddress
      }
    ) {
      id
      poolAddress
      timestamp
      totalValue
      totalSupply
      price
    }

    USDC: apts(
      orderBy: timestamp
      orderDirection: desc
      first: 1
      where: {
        timestamp_gte: $launchTimestamp
        timestamp_lte: $endTimestamp
        poolAddress: $usdcPoolAddress
      }
    ) {
      id
      poolAddress
      timestamp
      totalValue
      totalSupply
      price
    }

    USDT: apts(
      orderBy: timestamp
      orderDirection: desc
      first: 1
      where: {
        timestamp_gte: $launchTimestamp
        timestamp_lte: $endTimestamp
        poolAddress: $usdtPoolAddress
      }
    ) {
      id
      poolAddress
      timestamp
      totalValue
      totalSupply
      price
    }
  }
`;

export const BLOCK_QUERY = gql`
  query block($timestamp: BigInt!) {
    blocks(
      first: 1
      orderBy: timestamp
      orderDirection: asc
      where: { timestamp_gte: $timestamp }
    ) {
      id
      number
      timestamp
    }
  }
`;

/*

// Example Subgraph Queries for The Graph Legacy Explorer

// Snapshot of prices
{
	DAI: apts(
    orderBy: timestamp
    orderDirection: desc
    first: 1
    where: {
      timestamp_gte: 1601596916
      timestamp_lt: 1617404400
      poolAddress: "0xf587EC50e2E6518F7f016d5A78561109Ab96FEa1"
    }
  ) {
    id
    poolAddress
    timestamp
    totalValue
    totalSupply
    price
  }

  USDC: apts(
    orderBy: timestamp
    orderDirection: desc
    first: 1
    where: {
      timestamp_gte: 1601596916
      timestamp_lt: 1617404400
      poolAddress: "0xA138299A1BB17e90F2edCc2d567358C4BEeCa092"
    }
  ) {
    id
    poolAddress
    timestamp
    totalValue
    totalSupply
    price
  }

  USDT: apts(
    orderBy: timestamp
    orderDirection: desc
    first: 1
    where: {
      timestamp_gte: 1601596916
      timestamp_lt: 1617404400
      poolAddress: "0x80073EeF3C57Ba05dC25E5cD5b78C5f9fb18e4D8"
    }
  ) {
    id
    poolAddress
    timestamp
    totalValue
    totalSupply
    price
  }
}

// Snapshot of balances
{
  DAI: cashflowPoints(
    orderBy: timestamp
    orderDirection: desc
    first: 1
    where: {
      timestamp_gte: 1601596916
      timestamp_lte: 1620774000
      userAddress: "0xF4Cd1Fc53c6146B81b4b50b3C92342E974b739eA"
      poolAddress: "0xf587EC50e2E6518F7f016d5A78561109Ab96FEa1"
    }
  ) {
    poolAddress
    timestamp
    userAptBalance
    total
  }

  USDC: cashflowPoints(
    orderBy: timestamp
    orderDirection: desc
    first: 1
    where: {
      timestamp_gte: 1601596916
      timestamp_lte: 1620774000
      userAddress: "0xF4Cd1Fc53c6146B81b4b50b3C92342E974b739eA"
      poolAddress: "0xA138299A1BB17e90F2edCc2d567358C4BEeCa092"
    }
  ) {
    poolAddress
    timestamp
    userAptBalance
    total
  }

  USDT: cashflowPoints(
    orderBy: timestamp
    orderDirection: desc
    first: 1
    where: {
      timestamp_gte: 1601596916
      timestamp_lte: 1620774000
      userAddress: "0xF4Cd1Fc53c6146B81b4b50b3C92342E974b739eA"
      poolAddress: "0x80073EeF3C57Ba05dC25E5cD5b78C5f9fb18e4D8"
    }
  ) {
    poolAddress
    timestamp
    userAptBalance
    total
  }
}

// Aave
{
  DAI: reserves (where:{ symbol:	"DAI"}) {
    symbol
    aToken {
      id
    }
    decimals
    aEmissionPerSecond
    totalATokenSupply
    underlyingAsset
    liquidityRate 
  },
	USDC: reserves (where:{ symbol:	"USDC"}) {
    symbol
    aToken {
      id
    }
    decimals
    aEmissionPerSecond
    totalATokenSupply
    underlyingAsset
    liquidityRate 
  },
  USDT: reserves (where:{ symbol:	"USDT"}) {
    symbol
    aToken {
      id
    }
    decimals
		aEmissionPerSecond
    totalATokenSupply
    underlyingAsset
    liquidityRate 
  }
}

*/
