import React from 'react';

export const tourSteps = {
  pool2: [
    {
      stepNum: 0,
      title: 'Welcome to APY.Finance!',
      content: (
        <>
          Our goal is to provide a simple, cost-effective way to access the
          highest and safest yields in the crypto markets.
          <br />
          <br />
          Each page will have its own dedicated tour. This tour gives you a
          quick overview of the platform, all of the possible actions you can
          take, and how each of those actions are performing.
        </>
      ),
    },
    {
      stepNum: 1,
      title: 'Start Yield Farming',
      content:
        "If you'd like to go straight to yield farming and/or see more info on our active strategies, just click here.",
      options: {
        modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
        placement: 'bottom-start',
      },
    },
    {
      stepNum: 2,
      title: 'Total Value Locked',
      content:
        'This is the value of all the stablecoins currently deposited. This includes the amount held in reserves, plus the total amount deployed across all active strategies',
      options: {
        modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
        placement: 'bottom-start',
      },
    },
    {
      stepNum: 3,
      title: 'Annual Yield',
      content: (
        <>
          This is the aggregate annual percentage yield across all active
          strategies.
          <br />
          <br />
          It's calculated by taking the weighted average of each strategy's
          total annual yield.
        </>
      ),
      options: {
        modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
        placement: 'bottom-start',
      },
    },
    {
      stepNum: 4,
      title: 'Active Strategies',
      content: (
        <>
          Strategies (a.k.a farms) get added throughout the week, but you can
          suggest new ones{' '}
          <a
            href="https://gv5qt5b8im5.typeform.com/to/Y8czQa3E"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#8e40dd' }}
          >
            here.
          </a>
          <br />
          <br />
          You can also vote on pending strategies, as well as other governance
          proposals, on our Snapshot{' '}
          <a
            href="https://snapshot.org/#/apy.eth"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#8e40dd' }}
          >
            page.
          </a>
        </>
      ),
      options: {
        modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
        placement: 'bottom-start',
      },
    },
    {
      stepNum: 5,
      title: 'Staking',
      content:
        'Aside from yield farming, liquidity providers have the option of earning more APY tokens through staking.',
      options: {
        modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
        placement: 'bottom-start',
      },
    },
    {
      stepNum: 6,
      title: 'Uniswap',
      content:
        'If you provide liquidity on our APY-ETH pool on Uniswap, you can stake those LP tokens here to earn additional APY tokens.',
      options: {
        modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
        placement: 'top-start',
      },
    },
    {
      stepNum: 7,
      title: 'Balancer',
      content:
        'If you provide liquidity on our APY-USDC pool on Balancer, you can stake those LP tokens here to earn additional APY tokens.',
      options: {
        modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
        placement: 'top-start',
      },
      isLastStep: true,
    },
  ],
  portfolio: [
    {
      stepNum: 0,
      title: 'Start Yield Farming',
      content: (
        <>
          This page is where you'll deposit your stablecoins to begin yield
          farming.
          <br />
          <br />
          With just one deposit, you'll automatically begin participating in the
          platform's active strategies.
        </>
      ),
    },
    {
      stepNum: 1,
      title: 'Account Value',
      content: (
        <>
          This is the $USD value of your share of the platform, plus any earned
          APY tokens that have not yet been withdrawn.
          <br />
          <br />
          When you make a deposit, you own a share of the platform's total value
          locked. This share includes the value of the stablecoins you deposited
          plus any yield generated.
        </>
      ),
      options: {
        modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
        placement: 'bottom-start',
      },
    },
    {
      stepNum: 2,
      title: 'Annual Yield',
      content: (
        <>
          This is the aggregate annual percentage yield across all active
          strategies.
          <br />
          <br />
          It's calculated by taking the weighted average of each strategy's
          total annual yield.
        </>
      ),
      options: {
        modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
        placement: 'bottom-start',
      },
    },
    [
      {
        stepNum: 3,
        title: 'Enabling',
        content: (
          <>
            Deposits can be made with DAI, USDC, or USDT. You can select your
            preferred coin by clicking on one of the three icons.
            <br />
            <br />
            Whichever you choose, you must first enable the stablecoin before
            depositing. This allows the platform to move your stablecoins during
            the farming process.
          </>
        ),
        options: {
          modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
          placement: 'right-end',
        },
      },
      {
        stepNum: 4,
        title: 'Depositing',
        content: (
          <>
            Your wallet balance will appear once that stablecoin is enabled.
            Then, simply input the amount you'd like to deposit for yield
            farming.
            <br />
            <br />
            Note: These glowing values marked with an asterisk are for
            demonstration purposes only, and they do not reflect your actual
            balance, metrics or state of the app.
          </>
        ),
        options: {
          modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
          placement: 'top',
        },
      },
      {
        stepNum: 5,
        title: 'Projectwed Growth',
        content: (
          <>
            This is the projected growth that your account value would
            experience if your share of total value locked, and the platform's
            annual yield, remain constant.
            <br />
            <br />
            When you type in an amount on the form, this metric will calculate
            the change in your projected growth based on that amount.
          </>
        ),
        options: {
          modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
          placement: 'top',
        },
      },
      {
        stepNum: 6,
        title: 'Rewards Per Day',
        content: (
          <>
            As part of the Liquidity Mining Rewards program, 30,000 APY tokens
            are set aside daily for users who have stablecoins deposited in the
            system.
            <br />
            <br />
            The amount of the 30,000 tokens that you earn daily is displayed
            below and it's based on your current share of the total value
            locked.
          </>
        ),
        options: {
          modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
          placement: 'top',
        },
      },
      {
        stepNum: 7,
        title: 'Withdrawing',
        content: (
          <>
            To withdraw, select the percentage of your deposit that you wish to
            remove. Similarly to the Deposit form, the projected effect of your
            withdrawal will also show up on the Projected Growth and Rewards
            metrics on the right.
            <br />
            <br />
            Note: A 5% fee will be charged on withdrawals made within 24 hours
            of your deposit.
          </>
        ),
        options: {
          modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
          placement: 'top',
        },
      },
    ],
    {
      stepNum: 8,
      title: 'Total Rewards Earned',
      content: (
        <>
          This is the total amount of APY tokens you've earned from our
          Liquidity Mining program.
          <br />
          <br />
          This includes tokens that you have already withdrawn.
        </>
      ),
      options: {
        modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
        placement: 'top',
      },
    },
    {
      stepNum: 9,
      title: 'Withdrawing APY',
      content:
        'This is the amount of your APY tokens that are vested and ready to be withdrawn.',
      options: {
        modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
        placement: 'top',
      },
    },
    {
      stepNum: 10,
      title: 'Strategies',
      content: (
        <>
          All of our strategies and their related metrics will be listed below.
          Once you make a deposit, you will automatically be participating in
          all of these strategies.
          <br />
          <br />
          Strategies (a.k.a farms) get added throughout the week, but you can
          suggest new ones{' '}
          <a
            href="https://gv5qt5b8im5.typeform.com/to/Y8czQa3E"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#8e40dd' }}
          >
            here.
          </a>
          <br />
          <br />
          You can also vote on pending strategies, as well as other governance
          proposals, on our Snapshot{' '}
          <a
            href="https://snapshot.org/#/apy.eth"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#8e40dd' }}
          >
            page.
          </a>
        </>
      ),
      options: {
        modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
        placement: 'bottom-start',
      },
    },
    [
      {
        stepNum: 11,
        title: 'Strategy Panel',
        content: (
          <>
            This is an example of a panel you'll see for each of our current
            strategies.
            <br />
            <br />
            Note: As mentioned before, the glowing text and asteriks are used
            here to indicate mock data; they do not reflect actual metrics.
          </>
        ),
        options: {
          modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
          placement: 'bottom-start',
        },
      },
      {
        stepNum: 12,
        title: 'Strategy Metrics',
        content: (
          <>
            Each strategy involves deploying a portion of funds to pools on
            other protocols in an effort to earn yield.
            <br />
            <br />
            You own portion of each strategy's deployed value, as well as any
            yield earned from those funds. The value of your ownership share is
            listed here.
            <br />
            <br />
            The Annual Yield listed on each panel is the sum of the pool's base
            yield and all other yield generated from the reward tokens
            distributed to participants of that pool.
          </>
        ),
        options: {
          modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
          placement: 'bottom-start',
        },
        isLastStep: true,
      },
    ],
    // [
    //   {
    //     stepNum: 13,
    //     title: 'Deployed Value',
    //     content: 'Some description on your share value.',
    //     options: {
    //       modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
    //       placement: 'bottom-start',
    //     },
    //   },
    //   {
    //     stepNum: 14,
    //     title: 'Yield Breakdown',
    //     content: 'Some description on strategy annual yield.',
    //     options: {
    //       modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
    //       placement: 'bottom-start',
    //     },
    //   },
    // ],
  ],
  stakingSteps: {
    uniIntro: {
      stepNum: 0,
      title: 'Earn APY with UNI-V2',
      content:
        'This tour will guide you to earn APY tokens by providing liquidity on Uniswap and staking your UNI-V2 pool tokens on our platform.',
    },
    bptIntro: {
      stepNum: 0,
      title: 'Earn APY with BPT',
      content:
        'This tour will guide you to earn APY tokens by providing liquidity on Uniswap and staking your BPT pool tokens on our platform.',
    },
    pageHeader: {
      stepNum: 1,
      title: 'Step 1: Add Liquidity',
      content:
        'Click Add Liquidity to provide APY and ETH to our liquidity pool on Uniswap and receive UNI-V2 tokens.',
      options: {
        modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
        placement: 'bottom-start',
      },
    },
    form: [
      {
        stepNum: 2,
        title: 'Stake and Earn More APY',
        content:
          'Add some description on staking form here. *** Add note on mock values',
        options: {
          modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
          placement: 'right-start',
        },
      },
      {
        stepNum: 3,
        title: 'Step 2: Enable UNI-V2',
        content:
          'Now that you have UNI-V2 Tokens in your wallet, you need to enable them before staking.',
        options: {
          modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
          placement: 'right-end',
        },
      },
      {
        stepNum: 4,
        title: 'Step 3: Stake UNI-V2',
        content:
          'Add the amount of UNI-V2 you want to stake. The metrics below will change depending on the amount you type in. Note: These values marked with an asterisk are for demonstration purposes only and do not reflect your actual amounts or state of the app.',
        options: {
          modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
          placement: 'top',
        },
      },
      {
        stepNum: 5,
        title: 'Total Value Staked',
        content: 'Add some description on staking form here...',
        options: {
          modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
          placement: 'top',
        },
      },
      {
        stepNum: 6,
        title: 'Your Share',
        content: 'Add some description on staking form here...',
        options: {
          modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
          placement: 'top',
        },
      },
      {
        stepNum: 7,
        title: 'Rewards Per Day',
        content: 'Add some description on staking form here...',
        options: {
          modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
          placement: 'top',
        },
      },
      {
        stepNum: 8,
        title: 'Unstaking',
        content: 'Add some description on staking form here...',
        options: {
          modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
          placement: 'top',
        },
      },
      {
        stepNum: 9,
        title: 'Claim and Exit',
        content: 'Add some description on staking form here...',
        options: {
          modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
          placement: 'right-end',
        },
      },
    ],
    claimIntro: {
      stepNum: 10,
      title: 'Claim Rewards',
      content: 'Add some description on claiming rewards here...',
      options: {
        modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
        placement: 'right-start',
      },
    },
    apy: {
      stepNum: 11,
      title: 'Annual Yield',
      content: 'Add some description on claiming rewards here...',
      options: {
        modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
        placement: 'top',
      },
    },
    claimable: {
      stepNum: 12,
      title: 'Claimable APY',
      content: 'Add some description on claiming rewards here...',
      options: {
        modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
        placement: 'top',
      },
      lastStep: {
        page: 'staking',
      },
    },
  },
};
