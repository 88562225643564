import React from 'react';
import styled from 'styled-components';

import { fromWei, displayAmount } from '../../../utils/helpers';
import { breakpoints } from '../../styles/breakpoints';

import { Stack } from '../../styles/primitives';

function NumberInput({ isDisabled, inputAmount, onChange, balance }) {
  const handleInputChange = evt => {
    const newInput = evt.target.value;
    onChange(newInput);
  };

  return (
    <Stack gap={1}>
      <BalanceDisplay disabled={isDisabled}>
        <span style={{ marginRight: '.5rem' }}>Wallet:</span>
        {`${displayAmount(fromWei(balance.data, 'APY'))} ${
          balance.data ? 'APY' : ''
        }`}
      </BalanceDisplay>

      <InputWrap disabled={isDisabled}>
        <InputContainer>
          <Input
            type="number"
            step="any"
            placeholder={'Enter an amount'}
            value={inputAmount ?? ''}
            onChange={handleInputChange}
          />
          <MaxButton
            type="button"
            onClick={() => onChange(fromWei(balance.data)?.toString())}
          >
            Max
          </MaxButton>
        </InputContainer>
      </InputWrap>
    </Stack>
  );
}

const BalanceDisplay = styled.div`
  text-align: right;
  font-size: ${({ theme }) => theme.typeScale[2]};

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.3;
  `}
`;

const InputWrap = styled.div`
  position: relative;
  border: 2px solid #444;
  border-radius: 7px;
  background-color: #333;
  box-shadow: rgba(0, 0, 0, 0.452) 0px 1px 2px 0px,
    rgba(0, 0, 0, 0.247) 0px 2px 6px 2px;
  height: 48px;

  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
    opacity: .3;
  `}
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 7px;
  height: 100%;
`;

const Input = styled.input`
  flex-grow: 2;
  border: none;
  border-radius: 7px;
  background-color: transparent;
  width: 100%;
  padding: 0 0.5rem;
  font-family: archialight;
  font-size: 15px;
  color: #d0d0d0;

  &:focus {
    outline: none;
  }

  &::placeholder {
    opacity: 1; /* Firefox */
    color: hsl(0, 0%, 60%);
  }

  @media ${breakpoints.tablet} {
    font-size: 1.15rem;
  }
`;

const MaxButton = styled.button`
  border: 1px solid #575757;
  border-radius: 7px;
  background-color: #202020;
  height: 33px;
  margin-right: 0.5rem;
  padding: 0 0.5rem;
  font-family: archialight;
  font-size: 0.8rem;
  color: #fff;
  text-align: center;
  letter-spacing: 1px;
  cursor: pointer;

  @media ${breakpoints.mobile} {
    .inputButton {
      padding: 0 0.75rem;
    }
  }
`;

export default NumberInput;
