import React from 'react';

import Brand from '../../../visuals/Brand';

function BrandLink() {
  return (
    <a href="https://apy.finance" rel="noopener noreferrer">
      <Brand />
    </a>
  );
}

export default BrandLink;
