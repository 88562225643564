import React, { useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { BigNumber } from 'bignumber.js';

import * as Contracts from '../../../../../../../constants/contracts';
import * as Tokens from '../../../../../../../constants/tokens';
import {
  APY_STAKING_REWARDS,
  EMPTY_VALUE_SYMBOL,
} from '../../../../../../../constants';
import { useCoinGeckoPrices } from '../../../../../../../hooks';
import {
  getContracts,
  fromWei,
  displayAmount,
  getRequiredDecimals,
} from '../../../../../../../utils/helpers';

import MetricColumn from '../../../../../info/MetricColumn';
import Panel from '../../../../../containers/Panel';
import Metric from '../../../../../info/Metric';
import {
  panelWrap,
  panelContainer,
  metric,
  positiveGreen,
  negativeRed,
  metricTitle,
} from './FormMetrics.module.scss';

BigNumber.config({ EXPONENTIAL_AT: 1e9 });

const FormMetrics = ({
  classes = {},
  balances,
  currentForm,
  selectedToken,
  underlyer,
  inputValue,
}) => {
  const { chainId, library } = useWeb3React();
  const { coinPrices } = useCoinGeckoPrices();

  const [totalValueStaked, setTotalValueStaked] = useState(null);
  const [yourShare, setYourShare] = useState(null);
  const [rewardsPerDay, setRewardsPerDay] = useState(null);

  // Projections
  const [projectedTotalValue, setProjectedTotalValue] = useState(null);
  const [projectedShare, setProjectedShare] = useState(null);
  const [projectedRewards, setProjectedRewards] = useState(null);

  useEffect(() => {
    const calculateCurrentMetrics = async stakedBalance => {
      const StakingContracts = await getContracts(
        [selectedToken, 'APY', underlyer],
        chainId,
        library
      );

      const PoolTokenContract = StakingContracts[selectedToken];
      const ApyContract = StakingContracts['APY'];
      const UnderlyerContract = StakingContracts[underlyer];

      let totalTokensStaked;
      let totalValueStaked;
      try {
        // Calulcate total value of liquidity pool reserves
        const apyReserves = fromWei(
          await ApyContract.balanceOf(PoolTokenContract.address),
          'APY'
        );

        const underlyerReserves = fromWei(
          await UnderlyerContract.balanceOf(PoolTokenContract.address),
          underlyer
        );

        const totalValuePoolReserves = apyReserves
          .times(coinPrices.data['apy-finance'])
          .plus(
            underlyerReserves.times(
              coinPrices.data[`${Tokens[underlyer].coinGeckoKey}`]
            )
          );

        // Calculat USD total value of staked tokens

        totalTokensStaked = fromWei(
          await PoolTokenContract.balanceOf(
            Contracts[`${selectedToken}_STAKING`][chainId].address
          ),
          selectedToken
        );

        const totalPoolTokenSupply = fromWei(
          await PoolTokenContract.totalSupply(),
          selectedToken
        );

        totalValueStaked = totalTokensStaked.times(
          totalValuePoolReserves.div(totalPoolTokenSupply)
        );

        setTotalValueStaked(totalValueStaked);
      } catch (err) {
        console.error(
          'Error: Issue fetching current total value staked -',
          err.message
        );
        setTotalValueStaked(totalValueStaked);
      }

      let yourShare;
      if (totalTokensStaked && totalValueStaked) {
        yourShare = stakedBalance.div(totalTokensStaked);
        setYourShare(yourShare);
      }

      if (yourShare) {
        const rewardsPerDay = yourShare.times(APY_STAKING_REWARDS);
        setRewardsPerDay(rewardsPerDay);
      }
    };

    if (
      chainId &&
      library &&
      selectedToken &&
      underlyer &&
      !coinPrices.isLoading &&
      balances.data?.[`${selectedToken}_STAKING`]
    ) {
      if (coinPrices.error) {
        const coinGeckoError = new Error(
          `COINGECKO_ERROR: Can't calculate staking metrics without price(s) from CoinGecko`
        );
        console.error(coinGeckoError.message);
        return;
      }

      const stakedBalance = balances.data[`${selectedToken}_STAKING`];
      calculateCurrentMetrics(stakedBalance);
    }
  }, [chainId, library, selectedToken, underlyer, coinPrices, balances]);

  useEffect(() => {
    const calculateProjectedMetrics = async stakedBalance => {
      const StakingContracts = await getContracts(
        [selectedToken, 'APY', underlyer],
        chainId,
        library
      );

      const PoolTokenContract = StakingContracts[selectedToken];
      const ApyContract = StakingContracts['APY'];
      const UnderlyerContract = StakingContracts[underlyer];

      let projectedTotalStaked;
      let projectedTotalValue;
      try {
        // 1) Calulcate total value of reserves
        const apyReserves = fromWei(
          await ApyContract.balanceOf(PoolTokenContract.address),
          'APY'
        );

        const underlyerReserves = fromWei(
          await UnderlyerContract.balanceOf(PoolTokenContract.address),
          underlyer
        );

        const totalValuePoolReserves = apyReserves
          .times(coinPrices.data['apy-finance'])
          .plus(
            underlyerReserves.times(
              coinPrices.data[`${Tokens[underlyer].coinGeckoKey}`]
            )
          );

        // 2) Calulate total tokens staked including input amount
        const currentTotalStaked = fromWei(
          await PoolTokenContract.balanceOf(
            Contracts[`${selectedToken}_STAKING`][chainId].address
          ),
          selectedToken
        );

        if (currentForm === 'STAKE') {
          projectedTotalStaked = new BigNumber(inputValue).plus(
            currentTotalStaked
          );
        } else if (currentForm === 'UNSTAKE') {
          projectedTotalStaked = new BigNumber(inputValue).minus(
            currentTotalStaked
          );
        }

        // 3) Calculate USD value of total stake (including increase/decrease w/ input amount)
        const totalPoolTokenSupply = fromWei(
          await PoolTokenContract.totalSupply(),
          selectedToken
        );

        projectedTotalValue = projectedTotalStaked.times(
          totalValuePoolReserves.div(totalPoolTokenSupply)
        );

        setProjectedTotalValue(projectedTotalValue);
      } catch (err) {
        console.error(
          'Error: Isusue calculating projected total value staked from form input -',
          err.message
        );
        setProjectedTotalValue(null);
        setProjectedShare(null);
        setProjectedRewards(null);
      }

      let projectedShare;
      if (projectedTotalStaked && projectedTotalValue) {
        if (currentForm === 'STAKE') {
          projectedShare = new BigNumber(inputValue)
            .plus(stakedBalance)
            .div(projectedTotalStaked);
        } else if (currentForm === 'UNSTAKE') {
          projectedShare = new BigNumber(inputValue)
            .minus(stakedBalance)
            .div(projectedTotalStaked);
        }

        setProjectedShare(projectedShare);
      }

      if (projectedShare) {
        let projectedRewards = projectedShare.times(APY_STAKING_REWARDS);
        // Can't earn more than current daily rewards distribution
        // if (projectedRewards.gt(APY_STAKING_REWARDS)) {
        //   projectedRewards = new BigNumber(APY_STAKING_REWARDS);
        // }

        setProjectedRewards(projectedRewards);
      }
    };

    const timeoutId = setTimeout(() => {
      if (
        chainId &&
        library &&
        currentForm &&
        selectedToken &&
        underlyer &&
        !coinPrices.isLoading &&
        balances.data?.[`${selectedToken}_STAKING`] &&
        inputValue > 0
      ) {
        if (coinPrices.error) {
          const coinGeckoError = new Error(
            `COINGECKO_ERROR: Can't calculate staking metrics without price(s) from CoinGecko`
          );
          console.error(coinGeckoError.message);
          return;
        }

        const stakedBalance = balances.data[`${selectedToken}_STAKING`];
        calculateProjectedMetrics(stakedBalance);
      } else {
        setProjectedTotalValue(null);
        setProjectedShare(null);
        setProjectedRewards(null);
      }
    }, 550);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [
    chainId,
    library,
    currentForm,
    selectedToken,
    underlyer,
    inputValue,
    coinPrices,
    balances,
  ]);

  const showTotalValueStaked = () => {
    let tvs = projectedTotalValue ?? totalValueStaked;

    const setColor = () => {
      if (projectedTotalValue) {
        if (projectedTotalValue.gt(totalValueStaked)) {
          return positiveGreen;
        } else if (projectedTotalValue.lt(totalValueStaked)) {
          return negativeRed;
        }
      }
    };

    return (
      <div
        className={`${metric} ${setColor()}`}
        style={{
          fontStyle: projectedTotalValue && 'italic',
          textAlign: 'left',
        }}
      >
        {displayAmount(tvs, { format: 'currency' })}
      </div>
    );
  };

  const showYourShare = () => {
    let share = projectedShare ?? yourShare;

    if (share) {
      const decimals = getRequiredDecimals(share, { format: 'percent' });
      share = displayAmount(share, { decimals: decimals, format: 'percent' });

      const setColor = () => {
        if (projectedShare) {
          if (projectedShare.gt(yourShare)) {
            return positiveGreen;
          } else if (projectedShare.lt(yourShare)) {
            return negativeRed;
          }
        }
      };

      return (
        <div
          className={`${metric} ${setColor()}`}
          style={{ fontStyle: projectedShare && 'italic', textAlign: 'left' }}
        >
          {share}
        </div>
      );
    }
  };

  const showRewardsPerDay = () => {
    let rewards = projectedRewards ?? rewardsPerDay;

    if (rewards) {
      const decimals = getRequiredDecimals(rewards);
      rewards = displayAmount(rewards, { decimals: decimals });

      const setColor = () => {
        if (projectedRewards) {
          if (projectedRewards.gt(rewardsPerDay)) {
            return positiveGreen;
          } else if (projectedRewards.lt(rewardsPerDay)) {
            return negativeRed;
          }
        }
      };

      return (
        <div
          className={`${metric} ${setColor()}`}
          style={{
            fontStyle: projectedRewards && 'italic',
            textAlign: 'left',
          }}
        >
          {rewards}
          {rewards !== EMPTY_VALUE_SYMBOL && (
            <span style={{ fontSize: '1rem' }}> APY</span>
          )}
        </div>
      );
    }
  };

  return (
    <MetricColumn classes={classes.metricColumn}>
      <Panel classes={{ panelWrap, panelContainer }}>
        <Metric
          classes={{ metricTitle }}
          title={'Total Value Staked'}
          isLoading={!totalValueStaked}
        >
          {showTotalValueStaked()}
        </Metric>
      </Panel>
      <Panel classes={{ panelWrap, panelContainer }}>
        <Metric
          classes={{ metricTitle }}
          title={'Your Share'}
          isLoading={!yourShare}
        >
          {showYourShare()}
        </Metric>
      </Panel>
      <Panel classes={{ panelWrap, panelContainer }}>
        <Metric
          classes={{ metricTitle }}
          title={'APY Per Day'}
          isLoading={!rewardsPerDay}
        >
          {showRewardsPerDay()}
        </Metric>
      </Panel>
    </MetricColumn>
  );
};

export default FormMetrics;
