import React from 'react';
import { AnimateSharedLayout, motion } from 'framer-motion';

import { gradient } from '../../shared/FramerVariants';
import {
  formToggleWrap,
  tab,
  activeOverlay,
  activeText,
} from './FormToggle.module.scss';

function Tab({ classes = {}, idx, toggleTab, selectedTab, children }) {
  const isTabActive = selectedTab => {
    return selectedTab === idx;
  };

  return (
    <div
      key={children}
      className={`${tab} ${classes.tab}`}
      onClick={() => toggleTab(idx, selectedTab)}
    >
      {isTabActive(selectedTab) && (
        <div className={`${activeOverlay} ${classes.activeOverlay}`} />
      )}
      <span className={isTabActive(selectedTab) ? activeText : undefined}>
        {children}
      </span>
    </div>
  );
}

function FormToggle({
  classes = {},
  tabLabels,
  toggleTab,
  selectedTab,
  isTourElementActive,
}) {
  return (
    <motion.div
      className={`${formToggleWrap} ${classes.formToggleWrap}`}
      variants={gradient}
      initial="initial"
      animate={isTourElementActive ? 'animate' : 'initial'}
      style={
        isTourElementActive && {
          zIndex: 9,
        }
      }
    >
      {tabLabels.map((label, idx) => {
        const tabProps = { idx, toggleTab, selectedTab };
        return (
          <Tab key={label} classes={classes.tabClasses} {...tabProps}>
            {label}
          </Tab>
        );
      })}
    </motion.div>
  );
}

export default FormToggle;
