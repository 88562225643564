import { useMemo } from 'react';
import BigNumber from 'bignumber.js';

import { usePortfolioValue, useBlApyCalulcator, useBlApyValue } from './index';

/**
 * Calculating Boost:
 * - blApyValue = blAPY Balance * TVL / Total blAPY Supply
 * - Lock Score = min(0.4 * APT Value + 0.6 * blApyValue, APT Value)
 * - Boost = Lock Score / (0.4 * APT Value)
 */

export function useBoostCalculator(account, amount, unlockEpoch) {
  const { generatedBlApy } = useBlApyCalulcator(amount, unlockEpoch);
  const { blApyValue } = useBlApyValue(generatedBlApy);

  const { totalAptValue } = usePortfolioValue(account);

  const lockScore = useMemo(() => {
    let data;

    if (totalAptValue.data && blApyValue.data) {
      data = BigNumber.min(
        totalAptValue.data.times(0.4).plus(blApyValue.data.times(0.6)),
        totalAptValue.data
      );
    }

    return {
      data: data,
      isLoading: !data && !totalAptValue.error && !blApyValue.error,
      error: totalAptValue.error ?? blApyValue.error,
    };
  }, [totalAptValue, blApyValue]);

  const calculatedBoost = useMemo(() => {
    let data;

    if (lockScore.data && totalAptValue.data) {
      const boost = lockScore.data.div(totalAptValue.data.times(0.4));
      data = boost.isNaN() ? BigNumber(1) : boost;
    }

    return {
      data: data,
      isLoading: !data && !totalAptValue.error && !lockScore.error,
      error: totalAptValue.error ?? lockScore.error,
    };
  }, [totalAptValue, lockScore]);

  return { calculatedBoost };
}
