import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';

import { useRewardApy } from '../../../../../hooks';
import { displayAmount, isChainSupported } from '../../../../../utils/helpers';
import { PageTourContext } from '../../../../../state/contexts';
import {
  APY_STAKING_REWARDS,
  EMPTY_VALUE_SYMBOL,
} from '../../../../../constants';

import PageTourBox from '../../../../apy/info/PageTourBox';
import SectionHeader from '../../../../apy/info/SectionHeader';
import MultiRowPanel from '../../../../apy/containers/MultiRowPanel';
import PanelRow from '../../../../apy/containers/MultiRowPanel/PanelRow';
import NewButton from '../../../../apy/buttons/NewButton';
import Metric from '../../../../apy/info/Metric';
import {
  header,
  panels,
  panelWrap,
  button,
  metricWrap,
  metricTitle,
  metricValue,
  panelTitle,
  statsRow,
  textLink,
} from './StakingOverview.module.scss';

function StakingOverview() {
  // Page Tour state
  const {
    tourSteps: { pool2: pool2Steps },
    isTourStepActive,
  } = useContext(PageTourContext);
  const [stakingHeaderElement, setStakingHeaderElement] = useState();
  const [uniswapPanelElement, setUniswapPanelElement] = useState();
  const [balancerPanelElement, setBalancerPanelElement] = useState();

  const { chainId } = useWeb3React();
  const univ2Yield = useRewardApy('UNIV2');
  const bptYield = useRewardApy('BPT');

  return (
    <section>
      <header className={header}>
        <SectionHeader
          title={'Earn More APY Tokens'}
          ref={setStakingHeaderElement}
          isTourElementActive={isTourStepActive(pool2Steps[5])}
          pageTourBox={
            <PageTourBox
              steps={pool2Steps[5]}
              isActive={isTourStepActive(pool2Steps[5])}
              refElement={stakingHeaderElement}
            />
          }
        >
          Advanced users can also provide liquidity to our incentivized
          liquidity pools (a.k.a. Pool2s) to earn APY tokens. Users acknowledge
          they understand the risks of Pool2s, including{' '}
          <a
            href={
              'https://www.gemini.com/cryptopedia/decentralized-finance-impermanent-loss-defi'
            }
            target="_blank"
            rel="noopener noreferrer"
            className={textLink}
          >
            impermanent loss.
          </a>
        </SectionHeader>
      </header>
      <div className={panels}>
        <MultiRowPanel
          classes={{ panelWrap }}
          ref={setUniswapPanelElement}
          isTourElementActive={isTourStepActive(pool2Steps[6])}
          pageTourBox={
            <PageTourBox
              steps={pool2Steps[6]}
              isActive={isTourStepActive(pool2Steps[6])}
              refElement={uniswapPanelElement}
            />
          }
        >
          <PanelRow>
            <h2 className={panelTitle}>Uniswap</h2>
            <Link to="/pool2/univ2">
              <NewButton color="gold" size="small" classes={{ button }}>
                Earn APY Tokens
              </NewButton>
            </Link>
          </PanelRow>
          <PanelRow classes={{ panelRow: statsRow }}>
            <Metric
              classes={{ metricWrap, metricTitle, metricValue }}
              title={'Annual Yield'}
              isLoading={univ2Yield.isLoading}
              spinnerSize={13}
            >
              {displayAmount(univ2Yield.data, { format: 'percent' })}
            </Metric>
            <Metric
              classes={{ metricWrap, metricTitle, metricValue }}
              title={'APY Per Day'}
            >
              {chainId && isChainSupported(chainId) ? (
                <>
                  {displayAmount(APY_STAKING_REWARDS, { decimals: 2 })}{' '}
                  <span style={{ fontSize: '15px' }}>APY</span>
                </>
              ) : (
                EMPTY_VALUE_SYMBOL
              )}
            </Metric>
          </PanelRow>
        </MultiRowPanel>
        <MultiRowPanel
          classes={{ panelWrap }}
          ref={setBalancerPanelElement}
          isTourElementActive={isTourStepActive(pool2Steps[7])}
          pageTourBox={
            <PageTourBox
              steps={pool2Steps[7]}
              isActive={isTourStepActive(pool2Steps[7])}
              refElement={balancerPanelElement}
            />
          }
        >
          <PanelRow>
            <h2 className={panelTitle}>Balancer</h2>
            <Link to="/pool2/bpt">
              <NewButton color="gold" size="small" classes={{ button }}>
                Earn APY Tokens
              </NewButton>
            </Link>
          </PanelRow>
          <PanelRow classes={{ panelRow: statsRow }}>
            <Metric
              classes={{ metricWrap, metricTitle, metricValue }}
              title={'Annual Yield'}
              isLoading={bptYield.isLoading}
              spinnerSize={13}
            >
              {displayAmount(bptYield.data, { format: 'percent' })}
            </Metric>
            <Metric
              classes={{ metricWrap, metricTitle, metricValue }}
              title={'APY Per Day'}
            >
              {chainId && isChainSupported(chainId) ? (
                <>
                  {displayAmount(APY_STAKING_REWARDS, { decimals: 2 })}{' '}
                  <span style={{ fontSize: '15px' }}>APY</span>
                </>
              ) : (
                EMPTY_VALUE_SYMBOL
              )}
            </Metric>
          </PanelRow>
        </MultiRowPanel>
      </div>
    </section>
  );
}

export default StakingOverview;
