import React from 'react';
import Tippy from '@tippyjs/react';

import { useClaimStore } from '../../../../state/stores';
import { messageBox, container } from './PendingBalance.module.scss';

function PendingBalance({ balance, message }) {
  // Zustand
  const pendingClaim = useClaimStore(state => state.pendingClaim);

  const Message = ({ message }) => {
    return (
      <div className={messageBox}>
        <p>{message}</p>
      </div>
    );
  };

  return pendingClaim.amount ? (
    <Tippy
      allowHTML="true"
      content={<Message message={message} />}
      placement="bottom"
    >
      <div className={container}>{balance}</div>
    </Tippy>
  ) : (
    <>{balance}</>
  );
}

export default PendingBalance;
