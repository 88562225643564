export const ChainId = {
  MAINNET: '1',
  ROPSTEN: '3',
  RINKEBY: '4',
  KOVAN: '42',
  GANACHE: '1337',
};

export const DEPLOYED_CHAINS = [ChainId.MAINNET, ChainId.KOVAN];

export const DEFAULT_TICKER_LIST = [
  'ethereum',
  'apy-finance',
  'dai',
  'usd-coin',
  'tether',
];

export const ACTIVE_STRATEGIES = [
  'convex_alusd',
  'convex_aave',
  'convex_frax',
  'convex_ousd',
  'convex_mim',
  'convex_susd',
  'convex_ust_wormhole',
];

export const STABLECOINS = {
  aptDAI: 'DAI',
  aptUSDC: 'USDC',
  aptUSDT: 'USDT',
};

export const APT = {
  DAI: 'aptDAI',
  USDC: 'aptUSDC',
  USDT: 'aptUSDT',
};

export const APY_SUBGRAPH_ID = {
  1: process.env.REACT_APP_ENV === 'staging' ? 'staging' : 1,
  42: 42,
};

export const POOL_CONTRACT_KEYS = {
  DAI:
    process.env.REACT_APP_USE_DEMO_POOLS === 'true'
      ? 'DAI_DEMO_POOL'
      : 'aptDAI',
  USDC:
    process.env.REACT_APP_USE_DEMO_POOLS === 'true'
      ? 'USDC_DEMO_POOL'
      : 'aptUSDC',
  USDT:
    process.env.REACT_APP_USE_DEMO_POOLS === 'true'
      ? 'USDT_DEMO_POOL'
      : 'aptUSDT',
};

export const LP_TOKENS = {
  UNIV2: 'UNI-V2',
  BPT: 'BPT',
};

export const STAKING_CONTRACTS = {
  UNIV2: 'UNIV2_STAKING',
  BPT: 'BPT_STAKING',
};

// Thu Oct 01 2020 ~ 8:00pm ET when we first opened liquidity pools!
export const APY_LAUNCH_TIME = 1601596916;

export const EMPTY_VALUE_SYMBOL = '\u2014 \u2014';

export const APY_MINING_REWARDS = 11671.4286;
export const APY_STAKING_REWARDS = 1945.21429;

export const WEI_PER_TOKEN = '1000000000000000000';

export const SECONDS_PER_DAY = 3600 * 24;
export const DAYS_PER_YEAR = 365;
export const SECONDS_PER_WEEK = 604800;
export const WEEKS_IN_A_MONTH = 4.345;
export const WEEKS_IN_A_YEAR = 52;

export const BOOST_DURATIONS = {
  1: '~1 week',
  2: '~2 weeks',
  5: '~1 month',
  13: '~3 months',
  26: '~6 months',
  52: '~1 year',
  104: '~2 years',
  208: '~4 years',
};

export const BOOST_LOCK_OPTIONS = [
  {
    weeks: 1,
    approx: '1 week',
  },
  {
    weeks: 2,
    approx: '2 weeks',
  },
  {
    weeks: WEEKS_IN_A_MONTH,
    approx: '1 month',
  },
  {
    weeks: 3 * WEEKS_IN_A_MONTH,
    approx: '3 months',
  },
  {
    weeks: 6 * WEEKS_IN_A_MONTH,
    approx: '6 months',
  },
  {
    weeks: WEEKS_IN_A_YEAR,
    approx: '1 year',
  },
  {
    weeks: 2 * WEEKS_IN_A_YEAR,
    approx: '2 years',
  },
  {
    weeks: 4 * WEEKS_IN_A_YEAR,
    approx: '4 years',
  },
];

export const GROWTH_RANGES = [
  {
    label: '7d',
    seconds: SECONDS_PER_DAY * 7,
  },
  {
    label: '3m',
    seconds: SECONDS_PER_DAY * 90,
  },
  {
    label: '6m',
    seconds: SECONDS_PER_DAY * 180,
  },
  {
    label: '12m',
    seconds: SECONDS_PER_DAY * 365,
  },
];

export const APY_BOOST_SERVICE =
  'https://apy-service-boost-xsehdlynwa-ue.a.run.app/v1/boostedApy';

export const PAGE_LINKS = [
  {
    label: 'yield',
    path: '/dashboard',
    exact: false,
  },
  {
    label: 'boost',
    path: '/boost-lock',
    exact: true,
  },
  {
    label: 'pool2',
    path: '/pool2',
    exact: false,
  },
  // {
  //   label: 'uniswap',
  //   path: '/stake-univ2',
  //   exact: true,
  // },
  // {
  //   label: 'balancer',
  //   path: '/stake-bpt',
  //   exact: true,
  // },
  // {
  //   label: 'faq',
  //   path: '/faq ',
  //   exact: true,
  // },
];

export const MOCK_PRODUCTION_TVL = '52000000';
