import React from 'react';
import { motion } from 'framer-motion';

import { tap, hover } from '../../shared/FramerVariants';
import Border from '../../svgComponents/Border';
import {
  hamburgerWrap,
  hamburgerContainer,
} from './HamburgerButton.module.scss';

const HamburgerButton = ({ isActive, toggle }) => {
  const borderPropColors = {
    border: '#474747',
    bg: '#171719',
  };

  const variant = isActive ? 'opened' : 'closed';

  const top = {
    closed: { rotate: 0, translateY: 0 },
    opened: { rotate: 45, translateY: 9 },
  };

  const mid = {
    closed: { opacity: 1, x1: 0, x2: 20 },
    opened: { opacity: 0, x1: 0, x2: 0 },
  };

  const bottom = {
    closed: { rotate: 0, translateY: 0, x2: '10', x1: '0', translateX: 0 },
    opened: { rotate: -45, translateY: -4, x2: '24', x1: '0', translateX: 2 },
  };

  const Line = props => (
    <motion.line
      initial={isActive ? 'closed' : 'opened'}
      animate={variant}
      fill="transparent"
      strokeWidth="1"
      stroke="#fff"
      strokeLinecap="round"
      vectorEffect="non-scaling-stroke"
      {...props}
    />
  );

  return (
    <motion.button
      className={hamburgerWrap}
      whileHover={hover}
      whileTap={tap}
      onClick={toggle}
    >
      <Border color={borderPropColors} />
      <div className={hamburgerContainer}>
        <motion.svg
          viewBox="0 0 24 24"
          overflow="visible"
          width="24"
          height="24"
        >
          <Line x1="0" x2="24" y1="2" y2="2" variants={top} />
          <Line x1="0" x2="24" y1="11" y2="11" variants={mid} />
          <Line y1="20" y2="20" variants={bottom} />
        </motion.svg>
      </div>
    </motion.button>
  );
};

export default HamburgerButton;
