import React from 'react';
import { motion } from 'framer-motion';

import { mid } from './FeedbackOrbit.module.scss';

const Mid = ({ color }) => {
  return (
    <motion.div
      animate={{ rotate: [0, 45, -45, 0] }}
      transition={{ repeat: Infinity, duration: 7, type: 'spring' }}
      className={mid}
    >
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 77.07086 56.5147"
        height="100%"
        width="100%"
      >
        <path
          style={{ fill: color, fillRule: 'evenodd' }}
          d="M1554.2606,358.03361a36.59438,36.59438,0,0,0,12.3971,19.9778l1.6237-2.12741a34.58024,34.58024,0,0,1-12.3925-28.76289c.5397-9.0942,5.2489-17.7022,11.1864-23.39311l-1.8624-2.05049a36.68975,36.68975,0,0,0-10.6917,17.8331l-1.8013-.4945a38.47733,38.47733,0,0,0-.2563,19.4877l1.797-.4702Z"
          transform="translate(-1551.33316 -321.67751)"
        />
        <path
          style={{ fill: color, fillRule: 'evenodd' }}
          d="M1625.4769,358.21451a36.59529,36.59529,0,0,1-12.3974,19.9777l-1.5428-2.1825a34.41252,34.41252,0,0,0,12.3115-28.7078c-.5397-9.0944-5.2489-17.7022-11.1864-23.3933l1.8628-2.0504a36.6924,36.6924,0,0,1,10.6915,17.833l1.8016-.4943a38.48631,38.48631,0,0,1,.2558,19.4877l-1.7966-.4701Z"
          transform="translate(-1551.33316 -321.67751)"
        />
      </svg>
    </motion.div>
  );
};

export default Mid;
