import React, { useState, useEffect } from 'react';
import { BeatLoader } from 'react-spinners';
import { CaretLeft, CaretRight } from 'phosphor-react';

import { displayAmount } from '../../../../utils/helpers';

import {
  slideshowWrapper,
  slideshowContent,
  slideshowControl,
  active,
  arrowControl,
  slideshowControlItem,
  positiveGreen,
  negativeRed,
  mockValue,
} from './Slideshow.module.scss';

function Slideshow({ format, slidesObj, isUsingMockValues, currentForm }) {
  // Starts at last slide
  const [currentSlideNum, setCurrentSlideNum] = useState(
    slidesObj.slides.length - 1
  );

  const showGrowth = (format, slidesObj, isUsingMockValues, currentForm) => {
    const selectedSlide = slidesObj.slides[currentSlideNum];
    const selectedValue = selectedSlide.data[format];

    const setValueColor = selectedValue => {
      if (
        !selectedValue?.isZero() &&
        selectedValue?.isPositive() &&
        selectedValue?.gte(0.001)
      ) {
        return positiveGreen;
      } else if (selectedValue?.isNegative()) {
        return negativeRed;
      }
    };

    const showSign = selectedValue => {
      if (
        slidesObj.type === 'current' ||
        selectedValue?.isZero() ||
        !selectedValue?.gte(0.001)
      )
        return;

      if (selectedValue?.isPositive()) {
        return '+';
      }
    };

    return (
      <div
        className={`${slideshowContent} ${
          isUsingMockValues ? mockValue : undefined
        } ${setValueColor(selectedValue)}`}
        style={{
          fontStyle:
            slidesObj.type === 'projections' &&
            !slidesObj.isLoading &&
            ((currentForm === 'DEPOSIT' && selectedValue?.gte(0.001)) ||
              (currentForm === 'WITHDRAW' &&
                format === 'dollar' &&
                selectedValue?.abs().gte(0.001)) ||
              (currentForm === 'WITHDRAW' &&
                format === 'percent' &&
                selectedValue?.abs().gte(0.0001))) &&
            'italic',
        }}
      >
        {slidesObj.isLoading ? (
          <BeatLoader size={16} margin={4} color={'gray'} />
        ) : (
          <>
            {showSign(selectedValue)}
            {displayAmount(selectedValue, {
              format: format === 'dollar' ? 'currency' : 'percent',
            })}
            {isUsingMockValues && '*'}
          </>
        )}
      </div>
    );
  };

  const goBack = () => {
    const slidesList = slidesObj.slides;
    let newPage =
      currentSlideNum === 0 ? slidesList.length - 1 : currentSlideNum - 1;

    setCurrentSlideNum(newPage);
  };

  const goForward = () => {
    const slidesList = slidesObj.slides;
    let newPage =
      currentSlideNum === slidesList.length - 1 ? 0 : currentSlideNum + 1;

    setCurrentSlideNum(newPage);
  };

  return (
    <div className={slideshowWrapper}>
      {/* Values */}
      {showGrowth(format, slidesObj, isUsingMockValues, currentForm)}

      {/* Controls */}
      <div className={slideshowControl}>
        <button className={arrowControl} onClick={goBack}>
          <CaretLeft size={18} color="#d0d0d0" weight="bold" />
        </button>
        {slidesObj.slides.map(({ label }, idx) => (
          <button
            key={label}
            className={`${slideshowControlItem} ${
              currentSlideNum === idx ? active : undefined
            }`}
            onClick={() => setCurrentSlideNum(idx)}
          >
            {label}
          </button>
        ))}
        <button className={arrowControl} onClick={goForward}>
          <CaretRight size={18} color="#d0d0d0" weight="bold" />
        </button>
      </div>
    </div>
  );
}

export default Slideshow;
