import React from 'react';
import { motion, AnimateSharedLayout } from 'framer-motion';

import { springBtn, active } from './TimeRange.module.scss';

function TimeRange({ times, isSelected, onClick }) {
  return (
    <AnimateSharedLayout>
      {times.map(time => (
        <motion.button
          key={time}
          type="button"
          whileTap={{ scale: 0.9 }}
          whileHover={{ scale: 0.9 }}
          className={`${springBtn} ${isSelected && active}`}
          value={time}
          onClick={() => onClick(time)}
        >
          {time}
        </motion.button>
      ))}
    </AnimateSharedLayout>
  );
}

export default TimeRange;
