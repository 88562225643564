import React, { useContext, useState } from 'react';

import { PageTourContext } from '../../../../state/contexts';

import PageTourBox from '../../info/PageTourBox';
import LpPageHeader from '../../info/LpPageHeader';
import StakeTokens from './sections/StakeTokens';
import ClaimStakingRewards from './sections/ClaimStakingRewards';
import { stakingPageWrap } from './StakingPage.module.scss';

function StakingPage(props) {
  const { protocol, poolToken, underlyer, pageTourToggle } = props;

  const {
    tourSteps: { stakingSteps },
    isTourStepActive,
  } = useContext(PageTourContext);
  const [headerElement, setHeaderElement] = useState();

  return (
    <div className={stakingPageWrap}>
      {pageTourToggle}
      <PageTourBox
        steps={
          poolToken === 'UNIV2'
            ? stakingSteps['uniIntro']
            : stakingSteps['bptIntro']
        }
        isActive={isTourStepActive(
          poolToken === 'UNIV2'
            ? stakingSteps['uniIntro']
            : stakingSteps['bptIntro']
        )}
      />

      <LpPageHeader
        ref={setHeaderElement}
        isTourElementActive={isTourStepActive(stakingSteps['pageHeader'])}
        pageTourBox={
          <PageTourBox
            steps={stakingSteps['pageHeader']}
            isActive={isTourStepActive(stakingSteps['pageHeader'])}
            refElement={headerElement}
          />
        }
        {...props}
      />
      <StakeTokens
        protocol={protocol}
        poolToken={poolToken}
        underlyer={underlyer}
      />
      <ClaimStakingRewards poolToken={poolToken} />
    </div>
  );
}

export default StakingPage;
