import { ethers } from 'ethers';
import axios from 'axios';

import { getAddressFromRegistry } from './helpers';
import * as Contracts from '../constants/contracts';

export const ethFetcher = library => async (...args) => {
  const [arg1, arg2, arg3, ...params] = args;
  // Calling function on contract
  if (Contracts[arg1]) {
    const contractKey = arg1;
    const chainId = arg2;
    const method = arg3;

    if (!Contracts[contractKey][chainId]) {
      throw new Error(
        `CHAIN_ID_ERROR: Can't interact with ${contractKey} contract from unsupported chain (Chain ID: ${chainId})`
      );
    }

    let address;
    if (!Contracts[contractKey][chainId].address) {
      address = await getAddressFromRegistry(contractKey, chainId, library);
    } else {
      address = Contracts[contractKey][chainId].address;
    }

    const contract = new ethers.Contract(
      address,
      Contracts[contractKey][chainId].abi,
      library.getSigner()
    );

    return contract[method](...params).catch(error => {
      throw error;
    });
  }

  // Calling function on provider
  const method = arg1;
  return library[method](arg2, ...params).catch(error => {
    throw error;
  });
};

export const axiosFetcher = url => axios.get(url).then(res => res.data);
