import React, { useContext } from 'react';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import BigNumber from 'bignumber.js';

import { TrxContext } from '../../../../../state/contexts';
import useTransaction from '../../../../../hooks/useTransaction';
import { useAccountBalance } from '../../../../../hooks';
import * as Contracts from '../../../../../constants/contracts';
import {
  getWei,
  displayAmount,
  displayDate,
  fromWei,
  displayDuration,
} from '../../../../../utils/helpers';

import NumberInput from '../../../../apy/reusableComponents/NumberInput';
import SelectLockEnd from './SelectLockEnd';
import Button from '../../../../apy/reusableComponents/Button';
import { PadBox, Stack } from '../../../../styles/primitives';

function LockForm({
  isDataLoading,
  updateApproval,
  updateBlApyData,
  updateLockData,
  apyStatus,
  isApproved,
  formState: { inputAmount, unlockEpoch, isTrxPending },
  formDispatch,
}) {
  const { library, chainId } = useWeb3React();
  const { showTrxModal } = useContext(TrxContext);

  const BL_APY_ADDRESS = Contracts['BL_APY'][chainId]?.address;

  const [sendTransaction] = useTransaction();
  const { balance: apyBalance, updateBalance } = useAccountBalance('APY');

  const onSubmit = async evt => {
    evt.preventDefault();

    // Input Validation
    if (isApproved.data && apyStatus !== 'lockExpired') {
      // Prohibit empty input and 0
      if (!inputAmount || BigNumber(inputAmount).isLessThanOrEqualTo(0)) {
        formDispatch({ type: 'INPUT_ERROR', errorMsg: 'Enter an amount' });
        return;

        // Prohibit input greater than balance
      } else if (
        apyBalance.data &&
        fromWei(apyBalance.data, 'APY').lt(inputAmount)
      ) {
        formDispatch({
          type: 'INPUT_ERROR',
          errorMsg: 'Input exceeds balance',
        });
        return;
      }
    }

    const signer = library.getSigner();
    let Contract;

    if (isApproved.data === false) {
      const apyAddress = Contracts['APY'][chainId]?.address;
      const apyAbi = Contracts['APY'][chainId]?.abi;

      Contract = new ethers.Contract(apyAddress, apyAbi, signer);
      const spender = BL_APY_ADDRESS;

      formDispatch({ type: 'SETUP_TRX' });

      sendTransaction('APPROVE', {
        contract: Contract,
        functionName: 'approve',
        args: [spender, getWei(Number.MAX_SAFE_INTEGER.toString(), 'APY')],
        options: { token: 'APY' },
      })
        .then(() => {
          updateApproval(undefined, true);

          formDispatch({ type: 'SUCCESSFUL_TRX' });
        })
        .catch(err => {
          console.error(err.message);
          formDispatch({ type: 'FAILED_TRX' });
        });
    } else if (apyStatus === 'lockExpired') {
      // Handle withdraw

      const blApyAddress = BL_APY_ADDRESS;
      const blApyAbi = Contracts['BL_APY'][chainId]?.abi;

      Contract = new ethers.Contract(blApyAddress, blApyAbi, signer);

      formDispatch({ type: 'SETUP_TRX' });

      sendTransaction('WITHDRAW_UNLOCKED_APY', {
        contract: Contract,
        functionName: 'withdraw',
      })
        .then(() => {
          updateBalance(undefined, true);
          updateLockData(undefined, true);

          formDispatch({ type: 'SUCCESSFUL_TRX' });
        })
        .catch(err => {
          console.error(err.message);
          formDispatch({ type: 'FAILED_TRX' });
        });
    } else {
      // Handle create_lock

      const blApyAddress = BL_APY_ADDRESS;
      const blApyAbi = Contracts['BL_APY'][chainId]?.abi;

      Contract = new ethers.Contract(blApyAddress, blApyAbi, signer);

      const apyAmount = getWei(inputAmount, 'APY');

      const approxDuration = displayDuration(unlockEpoch);

      const createLock = () => {
        formDispatch({ type: 'SETUP_TRX' });

        sendTransaction('CREATE_LOCK', {
          contract: Contract,
          functionName: 'create_lock',
          args: [apyAmount, unlockEpoch],
          options: {
            amount: displayAmount(inputAmount),
            unlockDate: displayDate(unlockEpoch),
          },
        })
          .then(() => {
            updateBalance(undefined, true);
            updateLockData(undefined, true);
            updateBlApyData(undefined, true);

            formDispatch({ type: 'SUCCESSFUL_TRX' });
          })
          .catch(err => {
            console.error(err.message);
            formDispatch({ type: 'FAILED_TRX' });
          });
      };

      // Show confirmation modal
      showTrxModal('CONFIRM_CREATE_LOCK', {
        message: 'Lock Details',
        lockAmount: displayAmount(inputAmount),
        unlockDate: displayDate(unlockEpoch),
        approx: approxDuration,
        onBtnClick: createLock,
      });
    }
  };

  const buttonProps = {
    notApproved: {
      label: 'Enable APY',
      color: 'blue',
    },
    readyToLock: {
      label: 'Create Lock',
      color: 'purple',
    },
    locked: {
      label: 'APY LOCKED',
      color: 'purple',
    },
    lockExpired: {
      label: 'Withdraw APY',
      color: 'gold',
    },
  };

  return (
    <form onSubmit={onSubmit}>
      <Stack as={PadBox} gap={6} padding={[6]}>
        {/* Inputs */}
        <Stack>
          <NumberInput
            isDisabled={
              isDataLoading ||
              isTrxPending ||
              apyStatus === 'locked' ||
              apyStatus === 'lockExpired'
            }
            balance={apyBalance}
            inputAmount={inputAmount}
            onChange={newInput =>
              formDispatch({ type: 'INPUT_CHANGE', newInput })
            }
          />

          <SelectLockEnd
            isDisabled={
              isDataLoading ||
              isTrxPending ||
              apyStatus === 'locked' ||
              apyStatus === 'lockExpired' ||
              !unlockEpoch
            }
            selectedEpoch={unlockEpoch}
            onChange={newUnlockEpoch =>
              formDispatch({
                type: 'UNLOCK_EPOCH_CHANGE',
                newUnlockEpoch,
              })
            }
          />
        </Stack>

        <Button
          isDisabled={
            isDataLoading ||
            isTrxPending ||
            apyStatus === 'locked' ||
            !unlockEpoch
          }
          isLoading={isTrxPending}
          label={buttonProps[apyStatus].label}
          type={'submit'}
          color={buttonProps[apyStatus].color}
        />
      </Stack>
    </form>
  );
}

export default LockForm;
