import { useEffect, useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useSessionStore } from '../state/stores';

export function useDisconnectListener() {
  const { active } = useWeb3React();
  const isSessionActive = useSessionStore(state => state.isSessionActive);
  const setSession = useSessionStore(
    useCallback(state => state.setSession, [])
  );

  useEffect(() => {
    if (window.ethereum) {
      const { ethereum } = window;

      function handleUserLockOrDisconnect(accounts) {
        if (accounts.length === 0) {
          // Set activeSession in localStorage to false
          setSession(false);

          // MetaMask is locked or the user has not connected any accounts
          console.log(
            'MetaMask is currently locked or disconnected. Please reconnect.'
          );
        } else if (accounts[0] && active && isSessionActive === false) {
          // Set to true on connect button click if wallet is already active
          setSession(true);
          // window.location.reload();
        }
      }

      ethereum.on('accountsChanged', handleUserLockOrDisconnect);
    }
  }, [active, isSessionActive, setSession]);
}
