import { useMemo } from 'react';
import useEtherSWR from 'ether-swr';

import * as Contracts from '../constants/contracts';
import { useAbisStore } from '../state/stores';
import { isListEmpty, bytes32 } from '../utils/helpers';
import { ChainId } from '../constants';
import { FetcherError } from '../utils/errors';

const REGISTRY_ABI = [
  [
    Contracts['REGISTRY'][ChainId.MAINNET].address,
    Contracts['REGISTRY'][ChainId.MAINNET].abi,
  ],
];

const REGISTRY_FUNCS = {
  TVL_MGR: 'tvlManagerAddress',
  LP_ACCOUNT: 'lpAccountAddress',
  aptDAI: 'daiPoolAddress',
  aptUSDC: 'usdcPoolAddress',
  aptUSDT: 'usdtPoolAddress',
  ORACLE_ADAPTER: 'oracleAdapterAddress',
};

const DEMO_POOL_IDS = {
  DAI_DEMO_POOL: 'daiDemoPool',
  USDC_DEMO_POOL: 'usdcDemoPool',
  USDT_DEMO_POOL: 'usdtDemoPool',
};

export function useAbis() {
  // Zustand
  const setAbiInfo = useAbisStore(state => state.setAbiInfo);

  const contractInfo = {};
  const needAddresses = [];
  Object.keys(Contracts).forEach(contractKey => {
    if (
      !Contracts[contractKey][ChainId.MAINNET].address &&
      REGISTRY_FUNCS[contractKey]
    ) {
      needAddresses.push([
        contractKey,
        [REGISTRY_ABI[0][0], REGISTRY_FUNCS[contractKey]],
      ]);
    } else if (
      !Contracts[contractKey][ChainId.MAINNET].address &&
      DEMO_POOL_IDS[contractKey]
    ) {
      needAddresses.push([
        contractKey,
        [REGISTRY_ABI[0][0], 'getAddress', bytes32(DEMO_POOL_IDS[contractKey])],
      ]);
    }

    contractInfo[contractKey] = [
      Contracts[contractKey][ChainId.MAINNET].address,
      Contracts[contractKey][ChainId.MAINNET].abi,
    ];
  });

  const registryCalls = !isListEmpty(needAddresses)
    ? needAddresses.map(entry => entry[1])
    : [];

  const { data: addresses, error } = useEtherSWR(registryCalls, {
    ABIs: new Map(REGISTRY_ABI),
  });

  const fetcherError = useMemo(() => {
    let fetcherError;

    // Note: Ethereum error messages are under error.data.message
    if (error) {
      fetcherError = new FetcherError(
        error.data?.message ?? error.message,
        '<registryFunction>',
        'useAbis'
      );
      console.error(fetcherError.message);
    }

    return fetcherError;
  }, [error]);

  const abiInfo = useMemo(() => {
    let abis;

    const newContractInfo = { ...contractInfo };
    if (addresses) {
      addresses.forEach((address, idx) => {
        newContractInfo[needAddresses[idx][0]][0] = address;
      });

      abis = [...Object.values(newContractInfo)];
    }

    return {
      abis,
      addresses: newContractInfo,
      error: fetcherError,
    };
  }, [addresses, contractInfo, needAddresses, fetcherError]);

  if (abiInfo.abis) {
    setAbiInfo(abiInfo);
  }

  return { abiInfo };
}
