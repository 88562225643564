import React, { useState, useReducer, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useMedia } from 'use-media';

import { POOL_CONTRACT_KEYS } from '../../../../constants';
import { formButtonReducer } from '../../../../state/reducers';
import {
  capitalizeLabel,
  displayAmount,
  getContracts,
  fromWei,
} from '../../../../utils/helpers';

import PercentageInput from '../../inputs/PercentageInput';
import PercentageSlider from '../../inputs/PercentageSlider';
import SelectPercentage from '../../inputs/SelectPercentage';
import NewButton from '../../buttons/NewButton';
import {
  percentageFormWrap,
  percentageFormContainer,
  percentageInputWrap,
  percentageSliderWrap,
  slider,
  button,
} from './PercentageForm.module.scss';

function PercentageForm({
  classes = {},
  formType,
  selectedToken,
  isFormDisabled,
  inputValue,
  onInputChange,
  onSubmit,
  isLoading,
}) {
  const { library, chainId, account } = useWeb3React();

  // Media Queries
  const isVwOver500 = useMedia({ minWidth: '501px' });
  const isVwOver768 = useMedia({ minWidth: '769px' });
  const isOver1000 = useMedia({ minWidth: '1001px' });

  // Can turn this into a hook
  const [{ btnColor, btnLabel }, dispatch] = useReducer(formButtonReducer, {
    btnColor: 'red',
    btnLabel: capitalizeLabel(formType),
  });

  useEffect(() => {
    if (!selectedToken) {
      dispatch({ type: 'NO_TOKEN_SELECTED', formType });
    } else {
      dispatch({ type: 'DEFAULT', formType, selectedToken });
    }
  }, [formType, selectedToken]);

  const [amountWithdrawing, setAmountWithdrawing] = useState(null);

  // Calc amount withdrawing
  useEffect(() => {
    const calcWithdrawalAmt = async () => {
      if (!chainId || !library || !account) {
        return;
      }

      const PoolContract = await getContracts(
        [POOL_CONTRACT_KEYS[selectedToken]],
        chainId,
        library
      );

      let withdrawalAmt;
      try {
        // Calc how much APT withdrawing based on percentage
        const aptBalance = await PoolContract.balanceOf(account);
        const aptWithdrawing = aptBalance.mul(inputValue).div(100);

        // Get withdrawal amount minus fee (includes fee if applicable)
        withdrawalAmt = fromWei(
          await PoolContract.getUnderlyerAmountWithFee(aptWithdrawing),
          selectedToken
        );
      } catch (err) {
        console.log(
          'Error: Issue calculating amount withdrawing -',
          err.message
        );
      }

      setAmountWithdrawing(withdrawalAmt);
    };

    if (selectedToken && inputValue) {
      calcWithdrawalAmt();
    } else {
      setAmountWithdrawing(null);
    }
  }, [chainId, library, account, selectedToken, inputValue]);

  return (
    <form
      className={`${percentageFormWrap} ${classes.percentageFormWrap}`}
      onSubmit={onSubmit}
    >
      <div className={percentageFormContainer}>
        <div className={percentageInputWrap}>
          <PercentageInput value={inputValue} onInputChange={onInputChange} />
          {(!isVwOver500 || (isVwOver768 && !isOver1000)) && (
            <SelectPercentage inputValue={inputValue} onClick={onInputChange} />
          )}
        </div>
        <div className={percentageSliderWrap}>
          <PercentageSlider
            classes={{ slider }}
            value={inputValue}
            onChange={onInputChange}
          />
          {((isVwOver500 && !isVwOver768) || isOver1000) && (
            <SelectPercentage inputValue={inputValue} onClick={onInputChange} />
          )}
        </div>
      </div>
      <NewButton
        type="submit"
        classes={{ button }}
        color={btnColor}
        disabled={isFormDisabled}
        isLoading={isLoading}
      >
        {amountWithdrawing?.gt(0.01) ? (
          <>
            <p style={{ marginBottom: '5px' }}>Withdrawing</p>
            <p>{`${displayAmount(amountWithdrawing)} ${selectedToken}`}</p>
          </>
        ) : (
          btnLabel
        )}
      </NewButton>
    </form>
  );
}

export default PercentageForm;
