import { useMemo } from 'react';
import useEtherSWR from 'ether-swr';
import BigNumber from 'bignumber.js';

import { useAbisStore } from '../state/stores';
import { useOracleStatus } from './index';
import { fromWei, isListEmpty } from '../utils/helpers';
import { FetcherError } from '../utils/errors';
import { POOL_CONTRACT_KEYS } from '../constants';

export function useTotalPoolValues() {
  // Zustand
  const { abis: ABIs, addresses } = useAbisStore(state => state.abiInfo);

  // Check Oracle Status
  const { isOracleLocked, blockBeforeLock } = useOracleStatus();

  // Set older blockTag is oracle is locked
  let blockTag = 'latest';
  if (isOracleLocked && blockBeforeLock) {
    blockTag = blockBeforeLock;
  }

  // const [blockTag, setBlockTag] = useState('latest');
  // useEffect(() => {
  //   if (isOracleLocked && blockBeforeLock) {
  //     setBlockTag(blockBeforeLock);
  //   }
  // }, [isOracleLocked, blockBeforeLock]);

  // Get each Pool's total value
  const { data: totals, error } = useEtherSWR(
    !isListEmpty(ABIs)
      ? [
          [
            addresses[POOL_CONTRACT_KEYS['DAI']][0],
            'getPoolTotalValue',
            { blockTag: blockTag },
          ],
          [
            addresses[POOL_CONTRACT_KEYS['USDC']][0],
            'getPoolTotalValue',
            { blockTag: blockTag },
          ],
          [
            addresses[POOL_CONTRACT_KEYS['USDT']][0],
            'getPoolTotalValue',
            { blockTag: blockTag },
          ],
        ]
      : [],
    { ABIs: new Map(ABIs) }
  );

  const fetcherError = useMemo(() => {
    let fetcherError;

    // Note: Ethereum error messages are under error.data.message
    if (error) {
      fetcherError = new FetcherError(
        error.data?.message ?? error.message,
        '"getPoolTotalValue"',
        'useTotalPoolValues'
      );
      console.error(fetcherError.message);
    }

    return fetcherError;
  }, [error]);

  const poolValues = useMemo(() => {
    const daiPoolValue = totals?.[0];
    const usdcPoolValue = totals?.[1];
    const usdtPoolValue = totals?.[2];

    return {
      aptDAI: {
        data: fromWei(daiPoolValue, 'USD'),
        isLoading: !daiPoolValue && !fetcherError,
        error: fetcherError,
      },
      aptUSDC: {
        data: fromWei(usdcPoolValue, 'USD'),
        isLoading: !usdcPoolValue && !fetcherError,
        error: fetcherError,
      },
      aptUSDT: {
        data: fromWei(usdtPoolValue, 'USD'),
        isLoading: !usdtPoolValue && !fetcherError,
        error: fetcherError,
      },
    };
  }, [totals, fetcherError]);

  const totalValueLocked = useMemo(() => {
    let tvl;

    const { aptDAI, aptUSDC, aptUSDT } = poolValues;
    if (
      ![aptDAI.isLoading, aptUSDC.isLoading, aptUSDT.isLoading].includes(
        true
      ) &&
      ![aptDAI.data, aptUSDC.data, aptUSDT.data].includes(undefined)
    ) {
      tvl = BigNumber.sum(aptDAI.data, aptUSDC.data, aptUSDT.data);
    }

    return {
      data: tvl,
      isLoading: !tvl && !fetcherError,
      error: fetcherError,
    };
  }, [poolValues, fetcherError]);

  return { poolValues, totalValueLocked };
}
