import React from 'react';
import Tippy from '@tippyjs/react';

import Icon from './Icon';
import {
  toolTipWrap,
  infoWrap,
  infoTitle,
  infoBody,
} from './NewTooltip.module.scss';

function Info({ title, body }) {
  return (
    <div className={infoWrap}>
      <h4 className={`${title && infoTitle}`}>{title}</h4>
      <p className={infoBody}>{body}</p>
    </div>
  );
}
function ToolTip({ classes = {}, placement, title, body, adjustIcon }) {
  return (
    <Tippy
      // placement={placement}
      allowHTML="true"
      content={<Info title={title} body={body} />}
      zIndex={0}
    >
      <div className={`${toolTipWrap} ${classes.toolTipWrap}`}>
        <Icon adjustIcon={adjustIcon} />
      </div>
    </Tippy>
  );
}

export default ToolTip;
