import moment from 'moment';
import BigNumber from 'bignumber.js';
import {
  SECONDS_PER_WEEK,
  EMPTY_VALUE_SYMBOL,
  WEEKS_IN_A_YEAR,
  BOOST_LOCK_OPTIONS,
} from '../../constants';
import { isValidNumber } from './index';

export const getInitialEpoch = startTime => {
  const currentTime = Math.floor(Date.now() / 1000);

  const weeksDuration = BOOST_LOCK_OPTIONS.find(option => {
    const epoch = getEpochWithTimestamp(
      currentTime + SECONDS_PER_WEEK * option.weeks
    );
    return epoch > startTime;
  });

  let initialEpoch;
  if (weeksDuration) {
    initialEpoch = getEpochWithTimestamp(
      currentTime + SECONDS_PER_WEEK * weeksDuration.weeks
    );
  }

  /**
   * Need to check whether this initial option does not go over the 4 year lock max
   * - Will only apply if a user has locked for 4 years, and re-visits page shortly
   *   after locking, and 1 week from that current time would still equal the lockEnd
   */
  const maxEpoch = getEpochWithTimestamp(
    SECONDS_PER_WEEK * WEEKS_IN_A_YEAR * 4 + currentTime
  );

  return initialEpoch <= maxEpoch ? initialEpoch : undefined;
};

export const getEpochWithTimestamp = timestamp => {
  const unlockEpoch =
    Math.floor(timestamp / SECONDS_PER_WEEK) * SECONDS_PER_WEEK;

  return unlockEpoch;
};

export const getWeeksWithTimestamp = unlockEpoch => {
  const currentTime = Math.floor(Date.now() / 1000);
  const weeks = BigNumber((unlockEpoch - currentTime) / SECONDS_PER_WEEK);

  return weeks;
};

export const displayDuration = epochTimestamp => {
  const weeks = Math.ceil(getWeeksWithTimestamp(epochTimestamp).toNumber());

  // const duration = BOOST_DURATIONS[weeks];
  const duration = `~${weeks} ${weeks === 1 ? 'week' : 'weeks'}`;
  return duration;
};

// Display date given timestamp
export const displayDate = timestamp => {
  if (!isValidNumber(timestamp) || timestamp === 0) {
    return EMPTY_VALUE_SYMBOL;
  }

  return moment.unix(timestamp).utc().format('MMM DD, YYYY');
};
