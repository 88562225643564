import { Strategy } from '../utils/entities/Strategy';
import * as icons from '../assets/defi';
import { ChainId } from '../constants';

const convex_frax = new Strategy('Convex FRAX', {
  protocol: 'convex',
  poolName: 'frax',
  protocolIcon: icons['convex'],
  outputAssets: {
    gov: 'CVX',
    addtlReward: 'FXS',
    crv: 'CRV',
  },
  allocationName: 'convex-frax',
  tokenPricesNeeded: ['curve-dao-token', 'frax', 'convex-finance'],
  options: {
    curveLiquidityGaugeAddress: {
      [ChainId.MAINNET]: '0x72e158d38dbd50a483501c24f792bdaaa3e7d55c',
    },
  },
});

export default convex_frax;
