import { Strategy } from '../utils/entities/Strategy';
import { Contract } from '../utils/entities/Contract';
import * as icons from '../assets/defi';
import { ChainId } from '../constants';

import { CURVE_POOL_ABI, CURVE_LIQUIDITY_GAUGE_ABI } from './abis';
import { CURVE_GAUGE_CONTROLLER } from './utils/curve';

const CURVE_ALUSD_POOL = {
  [ChainId.MAINNET]: new Contract(
    '0x43b4FdFD4Ff969587185cDB6f0BD875c5Fc83f8c',
    CURVE_POOL_ABI
  ),
};

const ALUSD_LIQUIDITY_GAUGE_ADDRESS =
  '0x9582c4adacb3bce56fea3e590f05c3ca2fb9c477';

const CURVE_ALUSD_LIQUIDITY_GAUGE = {
  [ChainId.MAINNET]: new Contract(
    ALUSD_LIQUIDITY_GAUGE_ADDRESS,
    CURVE_LIQUIDITY_GAUGE_ABI
  ),
};

const curve_alusd = new Strategy('Curve alUSD', {
  protocol: 'curve',
  poolName: 'alusd',
  protocolIcon: icons['curve'],
  outputAssets: ['CRV', 'ALCX'],
  contracts: {
    pool: CURVE_ALUSD_POOL,
    liquidityGauge: CURVE_ALUSD_LIQUIDITY_GAUGE,
    gaugeController: CURVE_GAUGE_CONTROLLER,
  },
  allocationName: 'curve-alusd',
  tokenPricesNeeded: ['curve-dao-token', 'alchemix-usd'],
  description: 'Add strategy description here...',
});

export default curve_alusd;
