import React from 'react';
import styled from 'styled-components';

import { faq } from '../../../../../constants/faq/boostLock';
import Accordion from '../../../../apy/reusableComponents/Accordion';

function Faq() {
  return (
    <div>
      <FaqHeader>Frequently Asked Questions</FaqHeader>
      <Accordion items={faq} />
    </div>
  );
}

const FaqHeader = styled.h2`
  letter-spacing: 1.25px;
  line-height: 1.3;
`;

export default Faq;
