import React, { useState, useEffect, useReducer } from 'react';
import { useWeb3React } from '@web3-react/core';
import styled from 'styled-components';

import * as Contracts from '../../../../../constants/contracts';
import { boostLockReducer } from '../../../../../state/reducers';
import {
  useBoostCalculator,
  useApproval,
  useAccountBalance,
  useLockData,
  useBlApyData,
} from '../../../../../hooks';
import {
  EMPTY_VALUE_SYMBOL,
  SECONDS_PER_WEEK,
  WEEKS_IN_A_YEAR,
} from '../../../../../constants';
import {
  displayAmount,
  getInitialEpoch,
  getEpochWithTimestamp,
} from '../../../../../utils/helpers';

import LockForm from './LockForm';
import IncreaseLockForm from './IncreaseLockForm';
import ExtendLockForm from './ExtendLockForm';
import { Panel } from '../../../../styles/Panel.styled';
import { Tabs, Tab } from '../../../../styles/Tabs.styled';

function LockApy() {
  const { account, chainId, library } = useWeb3React();

  const [apyStatus, setApyStatus] = useState('readyToLock');
  const [formState, formDispatch] = useReducer(boostLockReducer, {
    currentForm: 'lock',
    inputAmount: null,
    unlockEpoch: null,
    isTrxPending: false,
    inputErrorMsg: null,
  });

  const { lockData, updateLockData } = useLockData(account);

  // Balances
  const BL_APY_ADDRESS = Contracts['BL_APY'][chainId]?.address;
  const { blApyBalance, updateBlApyData } = useBlApyData(account);
  const { balance: apyBalance } = useAccountBalance('APY');
  const { isApproved, allowance, updateApproval } = useApproval(
    'APY',
    formState.inputAmount,
    BL_APY_ADDRESS
  );

  useEffect(() => {
    /**
     * If lockEnd is 0 then no lock exists and we use currentTime
     * as start time. Otherwise, we use lockEnd as startTime.
     * Note: But if lockEnd is not 0 AND currentTime is greater than lockeEnd,
     * that means the lock is expired and we should return undefined;
     */
    const currentTime = Math.floor(Date.now() / 1000);
    const lockEnd = lockData.data?.end;

    let initialEpoch;
    if (lockEnd > 0 && currentTime < lockEnd) {
      // Use lockEnd if there's an existing lock that's not expired
      initialEpoch = getInitialEpoch(lockEnd); // Adds week(s)
    } else {
      // Use currentTime if lockEnd is undefined, 0 or greater than or equal currentTime
      initialEpoch = getInitialEpoch(currentTime); // Adds week(s)
    }

    formDispatch({
      type: 'UNLOCK_EPOCH_CHANGE',
      newUnlockEpoch: initialEpoch,
    });
  }, [lockData, formState.currentForm]);

  // Set status of APY
  useEffect(() => {
    if (
      !library ||
      !blApyBalance.data ||
      !lockData.data ||
      isApproved.data === undefined
    )
      return;

    // *** Using block.timestamp instead of JS Date.now()
    library
      .getBlock()
      .then(block => {
        const now = block.timestamp;

        if (formState.currentForm === 'lock' && blApyBalance.data.gt(0)) {
          setApyStatus('locked');
        } else if (isApproved.data === false) {
          setApyStatus('notApproved');

          // lockData.data.end will be 0 if no lock exists
        } else if (lockData.data.end > 0 && now >= lockData.data.end) {
          setApyStatus('lockExpired');
        } else if (blApyBalance.data.gt(0)) {
          setApyStatus('locked');
        } else {
          setApyStatus('readyToLock');
        }
      })
      .catch(err =>
        console.error(
          'Error: Issue getting current block timestamp -',
          err.message
        )
      );
  }, [library, formState, isApproved, blApyBalance, lockData]);

  // getAmount and getDuration used for useBoostCalculator hook
  const getAmount = () => {
    if (apyStatus === 'locked') {
      // Add whatever input amount to existing lock in order to calc projected boost
      return lockData.data?.amount.plus(formState.inputAmount || 0);
    }

    return formState.inputAmount;
  };

  const getDuration = () => {
    const currentTime = Math.floor(Date.now() / 1000);
    const maxEpoch = getEpochWithTimestamp(
      SECONDS_PER_WEEK * WEEKS_IN_A_YEAR * 4 + currentTime
    );

    if (
      apyStatus === 'locked' &&
      !formState.unlockEpoch &&
      lockData.data.end === maxEpoch
    ) {
      return maxEpoch;
    }

    return formState.unlockEpoch;
  };

  const { calculatedBoost: projectedBoost } = useBoostCalculator(
    account,
    getAmount(),
    // formState.unlockEpoch
    getDuration()
  );

  const showForm = currentForm => {
    const Forms = {
      lock: (
        <LockForm
          isDataLoading={
            lockData.isLoading ||
            isApproved.isLoading ||
            blApyBalance.isLoading ||
            apyBalance.isLoading
          }
          updateLockData={updateLockData}
          updateApproval={updateApproval}
          apyStatus={apyStatus}
          allowance={allowance}
          isApproved={isApproved}
          formState={formState}
          formDispatch={formDispatch}
          updateBlApyData={updateBlApyData}
        />
      ),
      increase: (
        <IncreaseLockForm
          isDataLoading={
            lockData.isLoading ||
            isApproved.isLoading ||
            blApyBalance.isLoading ||
            apyBalance.isLoading
          }
          blApyBalance={blApyBalance}
          updateLockData={updateLockData}
          updateApproval={updateApproval}
          allowance={allowance}
          isApproved={isApproved}
          formState={formState}
          formDispatch={formDispatch}
          updateBlApyData={updateBlApyData}
        />
      ),
      extend: (
        <ExtendLockForm
          isDataLoading={
            lockData.isLoading ||
            isApproved.isLoading ||
            blApyBalance.isLoading ||
            apyBalance.isLoading
          }
          blApyBalance={blApyBalance}
          updateLockData={updateLockData}
          formState={formState}
          formDispatch={formDispatch}
          updateBlApyData={updateBlApyData}
        />
      ),
    };

    return Forms[currentForm];
  };

  const showMessage = (currentForm, apyStatus) => {
    const showProjectedBoost = () => {
      return (
        <>
          <span style={{ marginRight: '.5rem' }}>Projected Boost:</span>
          {projectedBoost.data?.gt(0)
            ? `${displayAmount(projectedBoost.data)}x `
            : EMPTY_VALUE_SYMBOL}
        </>
      );
    };

    const Messages = {
      lock: {
        notApproved: showProjectedBoost(),
        readyToLock: showProjectedBoost(),
        locked: '* You can only increase or extend an existing lock',
      },
      increase: {
        locked: formState.inputAmount > 0 && showProjectedBoost(),
      },
      extend: {
        locked: formState.unlockEpoch && showProjectedBoost(),
      },
    };

    return Messages[currentForm]?.[apyStatus];
  };

  return (
    <TabbedForm>
      <FormTabs>
        <Tab
          onClick={() => formDispatch({ type: 'FORM_CHANGE', newForm: 'lock' })}
          active={formState.currentForm === 'lock'}
        >
          Create Lock
        </Tab>
        <Tab
          onClick={() =>
            formDispatch({ type: 'FORM_CHANGE', newForm: 'increase' })
          }
          active={formState.currentForm === 'increase'}
        >
          Increase Lock Amount
        </Tab>
        <Tab
          onClick={() =>
            formDispatch({ type: 'FORM_CHANGE', newForm: 'extend' })
          }
          active={formState.currentForm === 'extend'}
        >
          Extend Lock Time
        </Tab>
      </FormTabs>

      <FormWrap padding={[0]}>
        <FormContainer>{showForm(formState.currentForm)}</FormContainer>
        <FormMessage>
          {formState.inputErrorMsg ? (
            <span style={{ color: '#c2005a' }}>
              Error: {formState.inputErrorMsg}
            </span>
          ) : (
            showMessage(formState.currentForm, apyStatus)
          )}
        </FormMessage>
      </FormWrap>
    </TabbedForm>
  );
}

const TabbedForm = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormTabs = styled(Tabs)`
  border-radius: 7px 7px 0 0;
`;

const FormWrap = styled(Panel)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-top: none;
  border-radius: 0 0 7px 7px;
`;

const FormContainer = styled.div`
  height: 100%;
`;

const FormMessage = styled.div`
  padding: 1rem 2rem;
  font-family: 'archiathin';
`;

export default LockApy;
