import React from 'react';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';

import { tap, hover, buttonBorders } from '../shared/FramerVariants';
// import {TourGuideContext} from '../../../state/contexts'

import {
  button,
  green,
  gold,
  red,
  orchid,
  violet,
  strategiesMobile,
  form,
  small,
  landing,
  magenta,
  extraSmall,
  blurple,
  extraExtraSmall,
  gradient,
  blue,
  primary,
} from './scss/Button.module.scss';

function Button({
  disabled,
  to,
  type,
  color,
  size,
  classes,
  onClick,
  children,
  step = null,
}) {
  /*
    To Add:
    - loading
  */

  let history = useHistory();

  const sizes = {
    extraExtraSmall,
    extraSmall,
    small,
    strategiesMobile,
    form,
    landing,
  };

  const colors = {
    disabled,
    green,
    gold,
    blurple,
    red,
    orchid,
    violet,
    magenta,
    gradient,
    blue,
    primary,
  };

  const handleClick = () => {
    if (onClick) {
      onClick();
    }

    if (to) {
      history.push(to);
    }
  };

  return (
    <motion.button
      // animate={step === currentTarget ? "animate" : "initial"}
      className={`${button} ${colors[color]} ${sizes[size]}`}
      disabled={disabled}
      initial="initial"
      onClick={handleClick}
      type={type}
      variants={buttonBorders[color]}
      whileHover={!disabled && hover}
      whileTap={!disabled && tap}
    >
      {children}
    </motion.button>
  );
}

export default Button;
