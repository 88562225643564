import React, { useContext, useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';

import { ToastContext, PageTourContext } from '../../../../state/contexts';
import {
  usePortfolioValue,
  // useTourControls,
  useOracleStatus,
  usePoolStatus,
  useApi,
} from '../../../../hooks';
import { displayAmount } from '../../../../utils/helpers';

import DashboardPage from '../../../apy/templates/DashboardPage';
import DepositStablecoins from './sections/DepositStablecoins';
// import Rewards from './sections/Rewards';
// import IndexInfo from './sections/IndexInfo';
import PageTourBox from '../../../apy/info/PageTourBox';
import {
  portfolioPageWrap,
  // pageTourToggle,
} from './Portfolio.module.scss';

function Portfolio() {
  const { res: platform } = useApi(
    'cortex',
    'https://cxd-data-api-xsehdlynwa-ue.a.run.app/platform'
  );

  // Page Tour
  // const { PageTourToggle } = useTourControls('portfolio');
  const {
    tourSteps: { portfolio: portfolioSteps },
    isTourStepActive,
  } = useContext(PageTourContext);
  const [accountValuePanelElement, setAccountValuePanelElement] = useState();
  // const [apyPanelElement, setApyPanelElement] = useState();

  const { chainId, library, account } = useWeb3React();
  // const { showToast, removeToastByTitle } = useContext(ToastContext);
  const { showToast } = useContext(ToastContext);

  // Custom hooks
  const { accountValue } = usePortfolioValue(account);
  const { isOracleLocked, lockEnd } = useOracleStatus();
  const { isPoolsLocked } = usePoolStatus();

  useEffect(() => {
    if (!chainId && !library) return;

    const checkPoolLocks = async () => {
      // Lock forms if Oracle is locked
      if (isOracleLocked && lockEnd) {
        const currentBlock = (await library.getBlock()).number;
        const blocksLeft = BigNumber(lockEnd).minus(currentBlock);

        const notification = {
          type: 'validating',
          title: 'Validating TVL',
          message: `Forms reactivate in ${blocksLeft} ${
            blocksLeft.eq(1) ? 'block' : 'blocks'
          }`,
          unique: true,
        };

        showToast(notification);
      } else if (isPoolsLocked.data === true) {
        const notification = {
          type: 'warning',
          title: 'Platform Under Maintenance',
          message: 'Forms will be reactivated shortly',
          unique: true,
        };

        showToast(notification);
      }
    };

    checkPoolLocks();
  }, [chainId, library, lockEnd, isOracleLocked, showToast, isPoolsLocked]);

  return (
    <div className={portfolioPageWrap}>
      <PageTourBox
        steps={portfolioSteps[0]}
        isActive={isTourStepActive(portfolioSteps[0])}
      />

      <DashboardPage
        mainMetrics={[
          {
            title: 'Account Value',
            tooltipInfo:
              'Account Value is combined deposits and earned APY rewards',
            value: displayAmount(accountValue.data, { format: 'currency' }),
            isLoading: accountValue.isLoading,
            ref: setAccountValuePanelElement,
            isTourElementActive: isTourStepActive(portfolioSteps[1]),
            pageTourBox: (
              <PageTourBox
                steps={portfolioSteps[1]}
                isActive={isTourStepActive(portfolioSteps[1])}
                refElement={accountValuePanelElement}
              />
            ),
          },
          {
            title: 'Total Annual Yield',
            value: displayAmount(platform.data?.apr, {
              format: 'percent',
            }),
            isLoading: false,
          },
        ]}
        // pageTourToggle={<PageTourToggle classes={[pageTourToggle]} />}
      >
        <DepositStablecoins
          poolsLocked={isPoolsLocked.data}
          oracleLocked={isOracleLocked}
        />

        {/* <Rewards /> */}

        {/* <IndexInfo /> */}
      </DashboardPage>
    </div>
  );
}

export default Portfolio;
