import React, { useState } from 'react';
import { BigNumber } from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';
import { BeatLoader } from 'react-spinners';
import styled from 'styled-components';

import {
  useApyTokenYield,
  useGrowthSlides,
  usePlatformApy,
  useRewardsPerDay,
} from '../../../../../../../hooks';
import {
  displayAmount,
  getRequiredDecimals,
} from '../../../../../../../utils/helpers';

import NewTooltip from '../../../../../../apy/controls/NewTooltip';
import MetricColumn from '../../../../../../apy/info/MetricColumn';
import Slideshow from '../../../../../../apy/controls/Slideshow';
import Panel from '../../../../../../apy/containers/Panel';
import { Panel as StyledPanel } from '../../../../../../styles/Panel.styled';
import { Stack, Inline } from '../../../../../../styles/primitives';
import Metric from '../../../../../../apy/info/Metric';
import { default as NewMetric } from '../../../../../../apy/reusableComponents/Metric';

import {
  panelWrap,
  panelContainer,
  metricTitle,
  rewardsDisplay,
  formatToggleWrap,
  formatBtn,
  activeFormatBtn,
} from './StablecoinFormMetrics.module.scss';
import { NavLink } from 'react-router-dom';

BigNumber.config({ EXPONENTIAL_AT: 1e9 });

function StablecoinFormMetrics({
  classes = {},
  currentForm,
  selectedToken,
  inputValue,
}) {
  const { account } = useWeb3React();

  const { platformApy, strategyYield, apyTokenYield } = usePlatformApy();

  const [growthFormat, setGrowthFormat] = useState('dollar');
  const { growthSlides } = useGrowthSlides({
    formInput: inputValue,
    formType: currentForm,
    selectedToken,
  });

  // const { rewardsPerDay } = useRewardsPerDay(account);

  // const showRewards = current => {
  //   let value = current;
  //   const decimals = value && getRequiredDecimals(value);

  //   return (
  //     <div className={rewardsDisplay}>
  //       {displayAmount(value, { decimals })}
  //       {value && <span style={{ fontSize: '1rem' }}> APY</span>}
  //     </div>
  //   );
  // };

  return (
    <MetricColumn classes={classes.metricColumn}>
      {/* Rewards Per Day */}
      {/* <Panel classes={{ panelWrap, panelContainer }}>
        <Metric
          classes={{ metricWrap, metricTitle, metricValue }}
          title={'APY Rewards Per Day'}
          // tooltip={
          //   <NewTooltip
          //     placement="top"
          //     body={'This rate includes your boost!'}
          //   />
          // }
          isLoading={rewardsPerDay.isLoading}
        >
          {showRewards(rewardsPerDay.data)}
        </Metric>
      </Panel> */}

      {/* Annual Yield Breakdown */}
      <StyledPanel as={Stack} gap={0}>
        <NewMetric
          title={'Total Annual Yield'}
          isLoading={platformApy.isLoading}
          tooltip={
            <NewTooltip
              placement="top"
              body={
                'Total Annual Yield is equal to your APY Reward Yield plus the Strategy Yield.'
              }
            />
          }
        >
          {displayAmount(platformApy.data, {
            format: 'percent',
          })}
        </NewMetric>

        <Inline justify={'center'} align={'start'} gap={0}>
          <BoostNavLink as={NavLink} to="/boost-lock">
            Boost Rewards up to 2.5x
          </BoostNavLink>
        </Inline>

        <Stack gap={0}>
          {/* <Inline
            justify={'end'}
            align={'end'}
            gap={0}
            style={{ border: '1px solid yellow' }}
          >
            <BoostNavLink as={NavLink} to="/boost-lock">
              Boost APY Reward Yield
            </BoostNavLink>
          </Inline> */}
          <InlineMetric justify={'spaceBetween'} align={'center'} gap={0}>
            <span>APY Reward Yield</span>
            <span>
              {apyTokenYield.isLoading ? (
                <BeatLoader size={12} margin={2} color={'gray'} />
              ) : (
                displayAmount(apyTokenYield.data, {
                  format: 'percent',
                })
              )}
            </span>
          </InlineMetric>

          <InlineMetric justify={'spaceBetween'} align={'center'} gap={0}>
            <span>Portfolio Yield</span>
            <span>
              {strategyYield.isLoading ? (
                <BeatLoader size={12} margin={2} color={'gray'} />
              ) : (
                displayAmount(strategyYield.data, {
                  format: 'percent',
                })
              )}
            </span>
          </InlineMetric>
        </Stack>
      </StyledPanel>

      {/* Projected Growth */}
      <Panel
        classes={{ panelWrap, panelContainer }}
        cornerComponent={
          <div className={formatToggleWrap}>
            <button
              className={`${formatBtn} ${
                growthFormat === 'dollar' && activeFormatBtn
              }`}
              onClick={() => setGrowthFormat('dollar')}
            >
              $
            </button>
            <button
              className={`${formatBtn} ${
                growthFormat === 'percent' && activeFormatBtn
              }`}
              onClick={() => setGrowthFormat('percent')}
            >
              %
            </button>
          </div>
        }
      >
        <Metric
          classes={{ metricTitle }}
          title={'Projected Growth'}
          // tooltip={
          //   <NewTooltip placement="top" body={'Insert tooltip info here...'} />
          // }
        >
          <Slideshow
            format={growthFormat}
            currentForm={currentForm}
            slidesObj={growthSlides}
          />
        </Metric>
      </Panel>
    </MetricColumn>
  );
}

const InlineMetric = styled(Inline)`
  font-family: archiathin;
  color: #b8b8b8;
`;

const BoostNavLink = styled.a`
  font-size: 14px;
  font-style: italic;
  color: #04b485;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export default StablecoinFormMetrics;
