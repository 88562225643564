import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

function Modal({ isModalOpen, closeModal, children }) {
  return ReactDOM.createPortal(
    isModalOpen && (
      <Overlay onClick={closeModal}>
        <div onClick={evt => evt.stopPropagation()}>{children}</div>
      </Overlay>
    ),
    document.querySelector('#modal2')
  );
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(7px);
  z-index: 100;
`;

export default Modal;
