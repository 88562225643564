import { useState, useMemo, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { useAbisStore } from '../state/stores';
import { PTV_QUERY } from '../apollo/queries';
import { getTimestamps, getDataPoints, fromWei } from '../utils/helpers';

import { APY_LAUNCH_TIME, POOL_CONTRACT_KEYS } from '../constants';

export function useHistoricalPoolValues(poolKey) {
  // Zustand
  const { addresses } = useAbisStore(state => state.abiInfo);
  const [shouldQuery, setShouldQuery] = useState(true);
  const [mergedData, setMergedData] = useState([]);
  const [startTime, setNextStartTime] = useState(APY_LAUNCH_TIME);

  const { data: queryData, error } = useQuery(PTV_QUERY, {
    skip: !addresses || !shouldQuery,
    variables: {
      poolAddress: addresses?.[POOL_CONTRACT_KEYS[poolKey]][0],
      firstTimestampOnPage: startTime,
    },
  });

  useEffect(() => {
    if (!queryData) return;

    const newPage = queryData.apts;

    if (newPage.length === 0) {
      setShouldQuery(false);
      return;
    }

    if (newPage.length < 1000) {
      setShouldQuery(false);
    } else {
      const nextStartTime = parseInt(newPage[newPage.length - 1].timestamp);
      setNextStartTime(nextStartTime);
    }

    setMergedData(oldPage => [...oldPage, ...newPage]);
  }, [queryData]);

  const pastPoolValues = useMemo(() => {
    let data;

    if (error) {
      console.error(error.message);
    }

    // Set only when pagination flag (shouldQuery) is off and there's no errors
    if (!shouldQuery && !error) {
      data = mergedData.map(entry => ({
        time: parseInt(entry.timestamp),
        value: fromWei(entry.totalValue, 'USD').toString(),
      }));
    }

    return {
      data: data,
      error: error,
    };
  }, [mergedData, shouldQuery, error]);

  const chartData = useMemo(() => {
    let data;

    if (pastPoolValues.data) {
      // Timestamp of most recent hour
      const mostRecentHour =
        Math.floor(Date.now() / 1000) - (Math.floor(Date.now() / 1000) % 3600);

      const newData = {};
      for (const timeRange of ['7d', '1m', '3m', 'all']) {
        const timeStamps = getTimestamps(mostRecentHour, timeRange);
        newData[timeRange] = getDataPoints(timeStamps, pastPoolValues.data);
      }

      data = newData;
    }

    return {
      data: data,
      error: pastPoolValues.error,
    };
  }, [pastPoolValues]);

  return { chartData: chartData };
}
