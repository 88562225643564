import React, { forwardRef } from 'react';
import { useMedia } from 'use-media';
import { BigNumber } from 'bignumber.js';

import InputButton from '../../buttons/InputButton';
import {
  numberInputWrap,
  numberInputContainer,
  numberInputField,
  inputButton,
  isDisabled,
} from './NumberInput.module.scss';

const NumberInput = forwardRef(
  (
    {
      classes = {},
      disabled,
      value,
      onChange,
      onMaxClick,
      isTourElementActive,
      pageTourBox,
    },
    ref
  ) => {
    // Media queries
    const isVwOver500 = useMedia({ minWidth: '501px' });

    const handleChange = input => {
      // Restrict input to under 1 trillion
      if (new BigNumber(input).gt(100000000000) && input.includes('.')) {
        input = input.substr(0, 12) + input.substr(input.indexOf('.'));
      } else if (new BigNumber(input).gt(100000000000)) {
        input = input.substr(0, 12);
      }

      if (new BigNumber(input).gte(1) && input.includes('.')) {
        // Allow up to 18 decimals if input >= 1
        input =
          input.substr(0, input.indexOf('.')) +
          input.substr(input.indexOf('.'), 19);
      } else if (input.includes('.')) {
        // Only allows for up to 6 decimals if input < 1 (i.e. min non-zero is .000001)
        input =
          input.substr(0, input.indexOf('.')) +
          input.substr(input.indexOf('.'), 7);
      }

      onChange(input);
    };

    return (
      <>
        <div
          className={`${numberInputWrap} ${classes.numberInputWrap} ${
            disabled && isDisabled
          }`}
          ref={ref}
          style={
            isTourElementActive
              ? {
                  overflow: 'initial',
                  scrollMarginTop: isVwOver500 ? '6.5rem' : '5rem',
                }
              : null
          }
        >
          <div className={numberInputContainer}>
            <input
              type="number"
              step="any"
              className={numberInputField}
              placeholder={'Enter an amount'}
              value={value || ''}
              disabled={disabled}
              onChange={evt => handleChange(evt.target.value)}
            />
            <InputButton
              classes={{ inputButton }}
              disabled={disabled}
              onClick={onMaxClick}
            >
              Max
            </InputButton>
          </div>
        </div>

        {pageTourBox}
      </>
    );
  }
);

export default NumberInput;
