import React, { forwardRef, useState, useEffect } from 'react';
import BigNumber from 'bignumber.js';

import { APY_STAKING_REWARDS } from '../../../../../../../constants';
import {
  displayAmount,
  getRequiredDecimals,
} from '../../../../../../../utils/helpers';

import MetricColumn from '../../../../../info/MetricColumn';
import Panel from '../../../../../containers/Panel';
import Metric from '../../../../../info/Metric';
import {
  panelWrap,
  panelContainer,
  metricValue,
  positiveGreen,
  negativeRed,
} from './TourStakingFormMetrics.module.scss';

const TourStakingFormMetrics = forwardRef(
  (
    { classes = {}, currentForm, inputValue, isTourElementActive, pageTourBox },
    ref
  ) => {
    // Current
    const totalValueStaked =
      currentForm === 'STAKE' ? BigNumber(2500000) : BigNumber(2508333.33);
    const yourShare =
      currentForm === 'STAKE' ? BigNumber(0) : BigNumber(8333 / 2508333);
    const rewardsPerDay =
      currentForm === 'STAKE' ? BigNumber(0) : BigNumber(16.61);

    // Projections
    const [projectedTotalValue, setProjectedTotalValue] = useState(null);
    const [projectedShare, setProjectedShare] = useState(null);
    const [projectedRewards, setProjectedRewards] = useState(null);

    // Calculate projections based on input
    useEffect(() => {
      // Mock values
      const userStakedBalance =
        currentForm === 'STAKE' ? BigNumber(0) : BigNumber(100);
      const currentTotalTokensStaked =
        currentForm === 'STAKE' ? BigNumber(30000) : BigNumber(30100);
      const valuePoolReserves = BigNumber(4000000);
      const poolTokenSuppply = BigNumber(48000);

      const timeoutId = setTimeout(() => {
        if (inputValue > 0) {
          const input = BigNumber(inputValue);
          let projectedTotalStaked;
          let projectedTotalValue;

          if (currentForm === 'STAKE') {
            projectedTotalStaked = input.plus(currentTotalTokensStaked);
          } else if (currentForm === 'UNSTAKE') {
            projectedTotalStaked = input.minus(currentTotalTokensStaked);
            if (projectedTotalStaked.isNegative()) {
              projectedTotalStaked = projectedTotalStaked.absoluteValue();
            }
          }

          projectedTotalValue = projectedTotalStaked.times(
            valuePoolReserves.div(poolTokenSuppply)
          );

          setProjectedTotalValue(projectedTotalValue);

          let projectedShare;
          if (currentForm === 'STAKE') {
            projectedShare = input
              .plus(userStakedBalance)
              .div(projectedTotalStaked);
          } else if (currentForm === 'UNSTAKE') {
            projectedShare = input
              .minus(userStakedBalance)
              .div(projectedTotalStaked);

            if (projectedShare.isNegative()) {
              projectedShare = projectedShare.absoluteValue();
            }
          }

          setProjectedShare(projectedShare);

          let projectedRewards = projectedShare.times(APY_STAKING_REWARDS);

          setProjectedRewards(projectedRewards);
        } else {
          setProjectedTotalValue(null);
          setProjectedShare(null);
          setProjectedRewards(null);
        }
      }, 550);

      return () => {
        clearTimeout(timeoutId);
      };
    }, [inputValue, currentForm]);

    // Helpers for metric value render logic
    const showValue = (
      current,
      projection,
      getDecimals,
      isRewards,
      displayOptions = {}
    ) => {
      let value = projection ?? current;

      if (getDecimals) {
        const decimals = getRequiredDecimals(
          value,
          displayOptions.format === 'percent' && { format: 'percent' }
        );
        displayOptions = { ...displayOptions, decimals };
      }

      const setColor = () => {
        if (projection) {
          if (projection.gt(current)) {
            return positiveGreen;
          } else if (projection.lt(current)) {
            return negativeRed;
          }
        }
      };

      return (
        <span
          className={setColor()}
          style={{ fontStyle: projection && 'italic' }}
        >
          {displayAmount(value, displayOptions)}
          {'*'}
          {isRewards && <span style={{ fontSize: '1rem' }}> APY</span>}
        </span>
      );
    };

    return (
      <>
        <MetricColumn
          classes={classes.metricColumn}
          ref={ref}
          isTourElementActive={isTourElementActive}
        >
          <Panel classes={{ panelWrap, panelContainer }}>
            <Metric classes={{ metricValue }} title={'Total Value Staked'}>
              {showValue(totalValueStaked, projectedTotalValue, false, false, {
                format: 'currency',
              })}
            </Metric>
          </Panel>
          <Panel classes={{ panelWrap, panelContainer }}>
            <Metric classes={{ metricValue }} title={'Your Share'}>
              {showValue(yourShare, projectedShare, true, false, {
                format: 'percent',
              })}
            </Metric>
          </Panel>
          <Panel classes={{ panelWrap, panelContainer }}>
            <Metric classes={{ metricValue }} title={'Rewards Per Day'}>
              {showValue(rewardsPerDay, projectedRewards, true, true)}
            </Metric>
          </Panel>
        </MetricColumn>

        {pageTourBox}
      </>
    );
  }
);

export default TourStakingFormMetrics;
