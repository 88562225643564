import React from 'react';

import dai from '../../../../../assets/currency-icons/dai-orbit.svg';
import usdc from '../../../../../assets/currency-icons/usdc-orbit.svg';
import usdt from '../../../../../assets/currency-icons/tether-orbit.svg';
import { stablecoinWrap, large } from './Stablecoin.module.scss';

function Stablecoin({ classes = {}, symbol, size }) {
  const stablecoins = {
    DAI: dai,
    USDC: usdc,
    USDT: usdt,
  };

  const sizes = {
    lg: large,
  };

  return (
    <div
      className={`${stablecoinWrap} ${sizes[size]} ${classes.stablecoinWrap}`}
    >
      <img src={stablecoins[symbol]} alt={symbol} />
    </div>
  );
}

export default Stablecoin;
