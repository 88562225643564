import { BigNumber } from 'bignumber.js';

import { APY_LAUNCH_TIME, APY_SUBGRAPH_ID } from '../../constants';
import { fromWei } from './index';
import { apolloClient } from '../../apollo/client';
import { PTV_QUERY } from '../../apollo/queries';

export const calcTotalDeployedValue = allocatedValues => {
  return Object.entries(allocatedValues).reduce((total, entry) => {
    const [, allocatedValue] = entry;
    return total.plus(allocatedValue);
  }, BigNumber(0));
};

export const getStablePoolValues = async (ethPrice, aptContracts) => {
  let stablePoolValues = {};
  for (const aptKey of Object.keys(aptContracts)) {
    let usdValue;
    try {
      const ethValue = fromWei(
        await aptContracts[aptKey].getPoolTotalEthValue()
      );
      usdValue = ethValue.times(ethPrice);
    } catch (err) {
      console.error(
        `Error: Issue fetching ETH value of ${aptKey} -`,
        err.message
      );
    }
    stablePoolValues[aptKey] = usdValue;
  }

  return stablePoolValues;
};

export const calcPlatformTvl = poolValues => {
  if (Object.values(poolValues).includes(undefined)) {
    return;
  }

  return Object.values(poolValues).reduce((total, value) => {
    return total.plus(value);
  }, BigNumber(0));
};

export const getTimestamps = (currentTime, timePeriod) => {
  // timePeriod options: 7d, 1m, 3m, all

  const HOUR = 3600;
  const DAY = HOUR * 24;

  let interval;
  let endTime;
  let startTime;
  let timestamps = [];
  switch (timePeriod) {
    case '7d':
      // Every hour => 169 timestamps (including starting point)
      interval = HOUR;
      // End time = current time rounded down to nearest interval (hour)
      // endTime = currentTime - (currentTime % interval);
      endTime = currentTime;
      startTime = endTime - DAY * 7;

      break;

    case '1m':
      // Every 12 hours => 61 timestamps (including starting point)
      interval = HOUR * 12;
      // End time = current time rounded down to nearest interval (12 hours)
      // endTime = currentTime - (currentTime % interval);
      endTime = currentTime;
      startTime = endTime - DAY * 30;

      break;

    case '3m':
      // Every 12 hours => 181 timestamps (including starting point)
      interval = HOUR * 12;
      // End time = current time rounded down to nearest interval (12 hours)
      // endTime = currentTime - (currentTime % interval);
      endTime = currentTime;
      startTime = endTime - DAY * 90;

      break;

    default:
      // Every day
      interval = DAY;
      // Thu Oct 01 2020 ~ 8:00pm ET when we first opened liquidity pools!
      const firstTimestampIndexed = APY_LAUNCH_TIME;

      // Start time for 'all' = first time rounded down to nearest interval (day)
      startTime = firstTimestampIndexed - (firstTimestampIndexed % interval);

      // End time = current time rounded down to nearest interval (day)
      // endTime = currentTime - (currentTime % interval);
      endTime = currentTime;

      break;
  }

  while (startTime <= endTime) {
    timestamps.push(startTime);
    startTime += interval;
  }

  return timestamps;
};

export const getDataPoints = (timestamps, poolTotalValues) => {
  return timestamps.map(timestamp => {
    // if timestamp is before launch start time (1601596916) value = 0
    if (timestamp < 1601596916) {
      return {
        time: timestamp,
        value: '0',
      };
    } else {
      let index = poolTotalValues.findIndex(
        element => element.time >= timestamp
      );

      // findIndex returns -1 of not found
      if (index === -1) {
        index = poolTotalValues.length - 1;
      }

      return {
        time: timestamp,
        value: poolTotalValues[index]?.value?.toString(),
      };
    }
  });
};

const queryPoolTotalValue = async (chainId, startTime, poolContracts) => {
  const ptvQueryResults = [];
  for (const poolKey in poolContracts) {
    // Pagination
    let allPages = [];
    let previousPage = [];

    while (previousPage.length === 0 || previousPage.length >= 1000) {
      const firstTimestampOnPage =
        previousPage.length === 0
          ? startTime
          : parseInt(previousPage[previousPage.length - 1].timestamp);

      const { data: apts } = await apolloClient(chainId, 'apyFinance').query({
        query: PTV_QUERY,
        variables: {
          firstTimestampOnPage,
          poolAddress: poolContracts[poolKey].address,
        },
      });

      const page = apts.apts;
      if (page.length === 0) {
        break;
      } else {
        previousPage = page;
        allPages.push(page);
      }
    }

    const results = allPages.flat();
    ptvQueryResults.push(results);
  }

  // Return an object with key for each pool and array of
  // entries for changes in each pool's total value
  const poolTotalValues = Object.fromEntries(
    // Create a single array with 3 nested arrays for each pool
    // Each nested array will have the pool key as the first item and value as second item
    // Object.entries will convert each of these nested arrays into a key-value pair
    ptvQueryResults.map((res, index) => {
      return [
        Object.keys(poolContracts)[index],
        res.map(entry => {
          return {
            time: parseInt(entry.timestamp),
            value: fromWei(entry.totalValue, 'USD'),
          };
        }),
      ];
    })
  );

  return poolTotalValues;
};

export const getTvlChartData = async (chainId, poolContracts, timePeriod) => {
  /*
   * - Set apolloClientChainId using chainId or staging
   * - Get current time, and round down to nearest hour
   * - Get all timestamps for a previous time period w/ getTimestamps()
   * - Query subgraph to get object with list of Pool Total Values from each pool
   * - Create list of data points w/ timestamps; calculate usd value of ETH
   */

  const currentTime = Math.floor(Date.now() / 1000);

  const timestamps = getTimestamps(currentTime, timePeriod);
  const startTime = timestamps[0];

  const poolTotalValues = await queryPoolTotalValue(
    APY_SUBGRAPH_ID[chainId],
    startTime,
    poolContracts
  );

  let chartData = {};
  for (const poolKey in poolTotalValues) {
    const dataPoints = getDataPoints(timestamps, poolTotalValues[poolKey]);

    chartData[poolKey] = dataPoints;
  }

  return chartData;
};
