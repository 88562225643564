import React, { useState } from 'react';
import styled from 'styled-components';
import { Plus, Minus } from 'phosphor-react';

import { breakpoints } from '../../styles/breakpoints';

function Accordion({ items }) {
  const [active, setActive] = useState(null);

  const toggleActive = index => {
    if (active === index) {
      // If question is already active, close when clicked
      return setActive(null);
    }

    setActive(index);
  };

  return (
    <StyledAccordion>
      {items.map((item, idx) => {
        const keys = Object.keys(item);

        return (
          <div key={item[keys[0]]}>
            <TogglePanel
              active={active === idx}
              onClick={() => toggleActive(idx)}
            >
              <h3>{item[keys[0]]}</h3>
              <span>{active === idx ? <Minus /> : <Plus />}</span>
            </TogglePanel>
            {active === idx ? (
              <ExpandedPanel>
                <p>{item[keys[1]]}</p>
              </ExpandedPanel>
            ) : null}
          </div>
        );
      })}
    </StyledAccordion>
  );
}

const StyledAccordion = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.grey[2]};
  border-radius: ${({ theme }) => theme.borderRadius[1]};
  margin: 1rem 0; //
`;

const TogglePanel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.colors.grey[1]};
  padding: 0rem 2rem;
  font-size: 1rem;
  cursor: pointer;

  & > h3 {
    /* border: 1px solid yellow; */
    padding: 1rem 2rem 1rem 0rem;
    font-size: 1em;
  }

  &:hover {
    background-color: #555;
    color: #f0f0f0;
  }

  ${({ active }) => active && `background-color: #555; color: #f0f0f0;`}

  @media ${breakpoints.tablet} {
    font-size: 1.25rem;
  }
`;

const ExpandedPanel = styled.div`
  display: flex;
  background: transparent;
  padding: 1.25rem;
  font-size: 14px;
  font-family: archiathin;

  @media ${breakpoints.tablet} {
    padding: 2rem;
    font-size: 18px;
  }
`;

export default Accordion;
