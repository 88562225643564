import { useMemo } from 'react';

import * as Strategies from '../strategies';
import { DEFAULT_TICKER_LIST, ACTIVE_STRATEGIES } from '../constants';
import { useCoinGeckoStore } from '../state/stores';
import { useApi } from '.';

export function useCoinGeckoPrices() {
  // Zustand
  const setCoinGeckoList = useCoinGeckoStore(state => state.setCoinGeckoList);
  const setCoinPrices = useCoinGeckoStore(state => state.setCoinPrices);

  // Fetch and store CoinGecko's entire coin list
  const { res: coinList } = useApi(
    'coingecko',
    'https://api.coingecko.com/api/v3/coins/list'
  );

  if (coinList.data) {
    setCoinGeckoList(coinList.data);
  }

  // Get CoinGecko tickers needed for getting allocation value and/or apys of each active strategies:
  let newTickerList = [...DEFAULT_TICKER_LIST];
  for (const stratKey of ACTIVE_STRATEGIES) {
    const { tokenPricesNeeded } = Strategies[stratKey];

    tokenPricesNeeded.forEach(tokenSymbol => {
      if (!newTickerList.includes(tokenSymbol)) {
        newTickerList.push(tokenSymbol);
      }
    });
  }

  let tickerList = encodeURIComponent(newTickerList.join());
  const { res: prices } = useApi(
    'coingecko',
    `https://api.coingecko.com/api/v3/simple/price?ids=${tickerList}&vs_currencies=usd`
  );

  const coinPrices = useMemo(() => {
    let coinPrices;

    if (prices.data) {
      coinPrices = {};
      const coins = Object.keys(prices.data);
      for (const coin of coins) {
        const coinPrice = prices.data[coin]?.usd;
        coinPrices[coin] = coinPrice;
      }

      coinPrices = coinPrices;
    }

    return {
      data: coinPrices,
      isLoading: !prices.error && !prices.data,
      error: prices.error,
    };
  }, [prices]);

  // Store prices in zustand store for easier access in helpers
  if (coinPrices.data) {
    setCoinPrices(coinPrices);
  }

  return { coinPrices };
}
