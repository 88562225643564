import React from 'react';
import styled from 'styled-components';
import { BeatLoader } from 'react-spinners';

import { breakpoints } from '../../styles/breakpoints';

function Metric({ title, tooltip, children, isLoading, spinnerSize = 16 }) {
  return (
    <StyledMetric>
      <h3>
        {title}
        {tooltip}
      </h3>
      <span>
        {isLoading ? (
          <BeatLoader size={spinnerSize} margin={4} color={'gray'} />
        ) : (
          children
        )}
      </span>
    </StyledMetric>
  );
}

const StyledMetric = styled.div`
  /* border: 1px solid green; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  & > h3 {
    display: flex;
    align-items: center;
    font-family: archiamedium;
    font-size: 1.1875rem; // 19px
  }

  & > span {
    font-family: archiathin;
    font-size: 1.6875rem; // 27px
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media ${breakpoints.tablet} and (max-width: 768px) {
    & > h3 {
      font-size: 1.2rem;
    }

    & > span {
      font-size: 2rem;
    }
  }

  @media (min-width: 1001px) {
    & > h3 {
      font-size: 1.2rem;
    }

    & > span {
      font-size: 2rem;
    }
  }
`;

export default Metric;
