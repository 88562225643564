import React, { useReducer, useState, useEffect, useContext } from 'react';
import { useMedia } from 'use-media';

import { useFormBalances } from '../../../../../../hooks';
import {
  tokenFormReducer,
  initialStablecoinFormState,
} from '../../../../../../state/reducers';
import { PageTourContext } from '../../../../../../state/contexts';
import { getDefaultToken } from '../../../../../../utils/helpers';

import StablecoinForm from './StablecoinForm';
import StablecoinFormMetrics from './StablecoinFormMetrics';
import PageTourBox from '../../../../../apy/info/PageTourBox';
import TourStablecoinForm from '../../../../../apy/templates/DashboardPage/TourStablecoinForm';
import TourStablecoinFormMetrics from '../../../../../apy/templates/DashboardPage/TourStablecoinFormMetrics';
import {
  depositStablecoinsWrap,
  metricList,
} from './DepositStablecoins.module.scss';

function DepositStablecoins({ poolsLocked, oracleLocked }) {
  // Page tour
  const {
    isTourActive,
    tourSteps: { portfolio: portfolioSteps },
    isTourStepActive,
    // currentTourStep,
  } = useContext(PageTourContext);
  const [formElement, setFormElement] = useState();
  const [metricColumnElement, setMetricColumnElement] = useState();

  // Media Queries
  const isVwOver500 = useMedia({ minWidth: '501px' });
  const isVwOver768 = useMedia({ minWidth: '769px' });

  // Form state
  const [
    { currentForm, selectedToken, inputValue, inputErrorMsg, isTrxPending },
    dispatch,
  ] = useReducer(tokenFormReducer, initialStablecoinFormState);

  // Custom hooks
  const { balances } = useFormBalances('DEPOSIT_WITHDRAW');

  // Auto-selects largest balance, if no token is selected
  useEffect(() => {
    if (selectedToken) {
      return;
    }

    const defaultToken = getDefaultToken(balances.data, currentForm);

    if (defaultToken) {
      dispatch({ type: 'CHANGE_TOKEN', token: defaultToken });
    }
  }, [selectedToken, balances, currentForm]);

  // Form event handlers
  const switchForm = (newTab, currentTab) => {
    if (newTab === currentTab) {
      return;
    }

    const formType = newTab === 0 ? 'DEPOSIT' : 'WITHDRAW';

    const defaultToken = getDefaultToken(balances.data, formType);

    dispatch({
      type: 'SWITCH_FORM',
      switchTo: formType,
      token: defaultToken,
    });
  };

  const changeToken = tokenOption => {
    if (tokenOption === selectedToken) {
      return;
    }

    dispatch({
      type: 'CHANGE_TOKEN',
      token: tokenOption,
      formType: currentForm,
    });
  };

  const changeInputValue = value => {
    dispatch({ type: 'CHANGE_INPUT', inputValue: value });
  };

  return (
    <section className={depositStablecoinsWrap}>
      {isTourActive ? (
        <>
          <TourStablecoinForm
            selectedToken={selectedToken}
            changeToken={changeToken}
            inputValue={inputValue}
            changeInputValue={changeInputValue}
            ref={setFormElement}
            isTourElementActive={isTourStepActive(portfolioSteps[3])}
            pageTourBox={
              <PageTourBox
                steps={[
                  portfolioSteps[3][0],
                  portfolioSteps[3][1],
                  portfolioSteps[3][4],
                ]}
                isActive={
                  isVwOver768
                    ? isTourStepActive(portfolioSteps[3][0])
                    : isVwOver500
                    ? isTourStepActive([
                        portfolioSteps[3][0],
                        portfolioSteps[3][1],
                        portfolioSteps[3][4],
                      ])
                    : false
                }
                refElement={formElement}
              />
            }
          />
          <TourStablecoinFormMetrics
            classes={{ metricColumn: { metricList } }}
            inputValue={inputValue}
            ref={setMetricColumnElement}
            isTourElementActive={
              isVwOver768 &&
              isTourStepActive([
                portfolioSteps[3][1],
                portfolioSteps[3][2],
                portfolioSteps[3][3],
                portfolioSteps[3][4],
              ])
            }
            pageTourBox={
              <PageTourBox
                steps={[
                  portfolioSteps[3][1],
                  portfolioSteps[3][2],
                  portfolioSteps[3][3],
                  portfolioSteps[3][4],
                ]}
                isActive={
                  isVwOver768 &&
                  isTourStepActive([
                    portfolioSteps[3][1],
                    portfolioSteps[3][2],
                    portfolioSteps[3][3],
                    portfolioSteps[3][4],
                  ])
                }
                refElement={metricColumnElement}
              />
            }
          />
        </>
      ) : (
        <>
          <StablecoinForm
            poolsLocked={poolsLocked}
            oracleLocked={oracleLocked}
            balances={balances}
            currentForm={currentForm}
            onTabToggle={switchForm}
            selectedToken={selectedToken}
            changeToken={changeToken}
            inputValue={inputValue}
            changeInputValue={changeInputValue}
            inputError={inputErrorMsg}
            dispatch={dispatch}
            isTrxPending={isTrxPending}
          />
          {/* <StablecoinFormMetrics
            classes={{ metricColumn: { metricList } }}
            currentForm={currentForm}
            selectedToken={selectedToken}
            inputValue={inputValue}
          /> */}
        </>
      )}
    </section>
  );
}

export default DepositStablecoins;
