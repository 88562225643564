import React, { forwardRef } from 'react';
import { motion } from 'framer-motion';

import { LP_TOKENS } from '../../../../constants';

import { gradientHeader } from '../../shared/FramerVariants';
import SectionHeader from '../SectionHeader';
import NewButton from '../../buttons/NewButton';
import {
  lpPageHeaderWrap,
  sectionHeaderWrap,
  sectionHeaderTitle,
  button,
} from './LpPageHeader.module.scss';

const LpPageHeader = forwardRef(
  ({ protocol, poolToken, poolUrl, isTourElementActive, pageTourBox }, ref) => {
    return (
      <>
        <motion.header
          className={lpPageHeaderWrap}
          ref={ref}
          variants={gradientHeader}
          initial="initial"
          animate={isTourElementActive ? 'animate' : 'initial'}
          style={isTourElementActive && { zIndex: 4 }}
        >
          <SectionHeader
            classes={{ sectionHeaderWrap, sectionHeaderTitle }}
            title={`Provide Liquidity on ${protocol}`}
          >
            {`Liquidity providers on ${protocol} can stake their ${LP_TOKENS[poolToken]} below to earn APY tokens.`}
          </SectionHeader>
          <a href={poolUrl} target="_blank" rel="noopener noreferrer">
            <NewButton classes={{ button }} color="green">
              Add Liquidity
            </NewButton>
          </a>
        </motion.header>

        {pageTourBox}
      </>
    );
  }
);

export default LpPageHeader;
