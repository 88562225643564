import React, { useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';

import * as Contracts from '../../../../../../../constants/contracts';
import useTransaction from '../../../../../../../hooks/useTransaction';
import { useAccountBalance } from '../../../../../../../hooks';
import { LP_TOKENS, STAKING_CONTRACTS } from '../../../../../../../constants';
import {
  getContracts,
  getWei,
  displayAmount,
} from '../../../../../../../utils/helpers';

import NumberForm from '../../../../../forms/NumberForm';
import { numberFormWrap } from './StakingForm.module.scss';

function StakeForm(props) {
  const {
    balance,
    selectedToken,
    isTokenApproved,
    inputValue,
    dispatch,
    updateApproval,
  } = props;

  const { library, chainId } = useWeb3React();
  const [isLoading, setIsLoading] = useState(false);

  // Custom hooks
  const { updateBalance: updateLptBalance } = useAccountBalance(selectedToken);
  const { updateBalance: updateStakedBalance } = useAccountBalance(
    STAKING_CONTRACTS[selectedToken]
  );
  const [sendTransaction] = useTransaction();

  const onSubmit = async evt => {
    evt.preventDefault();

    // Input Validation
    if (isTokenApproved.data === true) {
      // Prohibit empty input and 0
      if (!inputValue || BigNumber(inputValue).isLessThanOrEqualTo(0)) {
        dispatch({ type: 'INPUT_ERROR', errorMsg: 'Enter an amount' });
        return;

        // Prohibit input greater than balance
      } else if (balance?.lt(inputValue)) {
        dispatch({ type: 'INPUT_ERROR', errorMsg: 'Input exceeds balance' });
        return;

        // Prohibit input under .01
      } else if (BigNumber(inputValue).isLessThan(0.01)) {
        dispatch({
          type: 'INPUT_ERROR',
          errorMsg: 'Cannot deposit less than .01',
        });
        return;
      }
    }

    const signer = library.getSigner();
    dispatch({ type: 'SETUP_TRANSACTION' });
    setIsLoading(true);

    // Handle Approval
    if (isTokenApproved.data === false) {
      const connectedContract = (
        await getContracts([selectedToken], chainId, library)
      ).connect(signer);

      const stakingContractAddress =
        Contracts[`${selectedToken}_STAKING`][chainId].address;

      const res = await sendTransaction('APPROVE', {
        contract: connectedContract,
        functionName: 'approve',
        args: [
          stakingContractAddress,
          getWei(Number.MAX_SAFE_INTEGER.toString(), selectedToken),
        ],
        options: { token: LP_TOKENS[selectedToken] },
      }).catch(err => console.error(err.message));

      if (res === 'SUCCESS') {
        updateApproval(undefined, true);
        dispatch({ type: 'SUCCESSFUL_TRANSACTION_KEEP_INPUT' });
      } else {
        dispatch({ type: 'FAILED_TRANSACTION' });
      }

      // Handle Stake
    } else {
      const connectedContract = (
        await getContracts([`${selectedToken}_STAKING`], chainId, library)
      ).connect(signer);

      const res = await sendTransaction('STAKE', {
        contract: connectedContract,
        functionName: 'stake',
        args: [getWei(inputValue, selectedToken)],
        options: {
          token: LP_TOKENS[selectedToken],
          amount: displayAmount(inputValue),
        },
      }).catch(err => console.error(err.message));

      if (res === 'SUCCESS') {
        updateLptBalance(undefined, true);
        updateStakedBalance(undefined, true);

        dispatch({ type: 'SUCCESSFUL_TRANSACTION' });
      } else {
        dispatch({ type: 'FAILED_TRANSACTION' });
      }
    }

    setIsLoading(false);
  };

  return (
    <NumberForm
      classes={{ numberFormWrap }}
      {...props}
      onSubmit={onSubmit}
      isLoading={isLoading}
    />
  );
}

export default StakeForm;
