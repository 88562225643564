import React, { useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';

import {
  APT,
  EMPTY_VALUE_SYMBOL,
  POOL_CONTRACT_KEYS,
} from '../../../../../../../constants';
import * as Tokens from '../../../../../../../constants/tokens';
import { useApproval } from '../../../../../../../hooks';
import { displayAmount } from '../../../../../../../utils/helpers';

import TogglePanel from '../../../../../../apy/containers/TogglePanel';
import PanelHeader from '../../../../../../apy/containers/Panel/PanelHeader';
import SelectStablecoin from '../../../../../../apy/inputs/SelectStablecoin';
import FormBalance from '../../../../../../apy/forms/FormBalance';
import DepositForm from './DepositForm';
import WithdrawForm from './WithdrawForm';
import { panelHeader, togglePanelWrap } from './StablecoinForm.module.scss';

function StablecoinForm({
  poolsLocked,
  oracleLocked,
  balances,
  currentForm,
  onTabToggle,
  selectedToken,
  changeToken,
  inputValue,
  changeInputValue,
  inputError,
  dispatch,
  isTrxPending,
}) {
  const { chainId } = useWeb3React();

  const spenderKeys = {
    DAI: POOL_CONTRACT_KEYS['DAI'],
    USDC: POOL_CONTRACT_KEYS['USDC'],
    USDT: POOL_CONTRACT_KEYS['USDT'],
  };

  const { isApproved, updateApproval, allowance } = useApproval(
    selectedToken,
    inputValue,
    Tokens[spenderKeys[selectedToken]]?.[chainId]?.address
  );

  useEffect(() => {
    if (currentForm !== 'DEPOSIT' || !allowance.data || allowance.data.isZero())
      return;

    // Use '0' if input amount is 0, '', null, or undefined
    const inputAmount = !inputValue ? '0' : inputValue;

    if (allowance.data.lt(inputAmount)) {
      dispatch({
        type: 'INPUT_ERROR',
        errorMsg: `Enable ${selectedToken} for this amount`,
      });
    }
  }, [allowance, currentForm, inputValue, dispatch]);

  const showBalance = (currentForm, selectedToken, balances) => {
    if (!balances) {
      return EMPTY_VALUE_SYMBOL;
    }

    const displayBalances = {
      DEPOSIT: displayAmount(balances['DEPOSIT'][selectedToken]),
      WITHDRAW: displayAmount(balances['WITHDRAW'][APT[selectedToken]], {
        round: true,
      }),
    };

    return displayBalances[currentForm];
  };

  const showForm = currentForm => {
    const isFormDisabled = () => {
      if (oracleLocked) {
        return true;
      } else if (poolsLocked) {
        return true;
      } else if (balances.isLoading || balances.error) {
        return true;
      } else if (
        currentForm === 'DEPOSIT' &&
        (allowance.isLoading || allowance.error)
      ) {
        return true;
      } else if (
        currentForm === 'DEPOSIT' &&
        (isApproved.isLoading || isApproved.error)
      ) {
        return true;
      } else if (!selectedToken) {
        return true;
      } else if (
        // Disable WithdrawForm if actual balance is 0 or anything less than .01
        currentForm === 'WITHDRAW' &&
        balances.data?.['WITHDRAW'][APT[selectedToken]].toFixed(2, 1) === '0.00'
      ) {
        return true;
      } else if (isTrxPending) {
        return true;
      } else {
        return false;
      }
    };

    const Forms = {
      DEPOSIT: (
        <DepositForm
          poolsLocked={poolsLocked}
          oracleLocked={oracleLocked}
          allowance={allowance}
          updateApproval={updateApproval}
          balance={balances.data?.['DEPOSIT'][selectedToken]}
          isFormDisabled={isFormDisabled()}
          formType={currentForm}
          selectedToken={selectedToken}
          isTokenApproved={isApproved}
          inputValue={inputValue}
          onInputChange={changeInputValue}
          onMaxClick={() =>
            changeInputValue(
              balances.data?.['DEPOSIT'][selectedToken].toString()
            )
          }
          dispatch={dispatch}
        />
      ),
      WITHDRAW: (
        <WithdrawForm
          isFormDisabled={isFormDisabled()}
          balance={balances.data?.['WITHDRAW'][APT[selectedToken]]}
          formType={currentForm}
          selectedToken={selectedToken}
          inputValue={inputValue}
          onInputChange={changeInputValue}
          dispatch={dispatch}
        />
      ),
    };

    return Forms[currentForm];
  };

  return (
    <TogglePanel
      classes={{ togglePanelWrap }}
      tabLabels={['Deposit', 'Withdraw']}
      selectedTab={currentForm === 'DEPOSIT' ? 0 : 1}
      toggleTab={onTabToggle}
    >
      <PanelHeader classes={{ panelHeader }}>
        <FormBalance
          poolsLocked={poolsLocked}
          oracleLocked={oracleLocked}
          currentForm={currentForm}
          token={selectedToken}
          error={inputError}
          allowance={allowance}
          isApproved={isApproved}
          isLoading={
            currentForm === 'DEPOSIT'
              ? balances.isLoading ||
                allowance.isLoading ||
                isApproved.isLoading
              : balances.isLoading
          }
          showDisabledNotice={poolsLocked || oracleLocked}
        >
          {showBalance(currentForm, selectedToken, balances.data)}
        </FormBalance>
        <SelectStablecoin selected={selectedToken} onSelect={changeToken} />
      </PanelHeader>
      {showForm(currentForm)}
    </TogglePanel>
  );
}

export default StablecoinForm;
