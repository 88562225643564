import { useMemo } from 'react';
import { useAllowance } from './useAllowance';

export function useApproval(token, amount, spender) {
  const { data: allowance, mutate } = useAllowance(token, spender);

  const isApproved = useMemo(() => {
    let data;

    if (allowance.data) {
      // Use '0' if input amount is falsey (0, '', null, or undefined)
      const inputAmount = !amount ? '0' : amount;

      if (allowance.data.isZero() || allowance.data.lt(inputAmount)) {
        data = false;
      } else {
        data = true;
      }
    }

    return {
      data: data,
      isLoading: data === undefined && !allowance.error, // Because data is boolean value, can't just check if falsy for isLoading
      error: allowance.error,
    };
  }, [allowance, amount]);

  return { isApproved, updateApproval: mutate, allowance };
}
