import React, { forwardRef, useContext } from 'react';

import { PageTourContext } from '../../../../../../../state/contexts';
import { LP_TOKENS } from '../../../../../../../constants';

import PanelHeader from '../../../../../containers/Panel/PanelHeader';
import TogglePanel from '../../../../../containers/TogglePanel';
import NumberInput from '../../../../../inputs/NumberInput';
import NewButton from '../../../../../buttons/NewButton';
import {
  panelHeader,
  numberFormWrap,
  numberInputWrap,
  formBalanceWrap,
  formBalanceLabel,
  formBalance,
  notEnabled,
  button,
  claimAndExitBtn,
} from './TourStakingForm.module.scss';

const TourStakingForm = forwardRef(
  (
    {
      protocol,
      selectedToken,
      currentForm,
      inputValue,
      changeInputValue,
      isTourElementActive,
      pageTourBox,
    },
    ref
  ) => {
    const { currentTourStep, goForwardStep } = useContext(PageTourContext);

    const config = {
      2: {
        label: null,
        balance: (
          <span
            className={notEnabled}
          >{`Add liquidity on ${protocol} first`}</span>
        ),
        disableInput: true,
        disableButton: true,
      },
      3: {
        label: null,
        balance: (
          <span
            className={notEnabled}
          >{`Enable ${LP_TOKENS[selectedToken]} for staking`}</span>
        ),
        disableInput: true,
        disableButton: false,
      },
      4: {
        label: `${LP_TOKENS[selectedToken]} Balance`,
        balance: '100.00*',
        disableInput: false,
        disableButton: false,
      },
      5: {
        label: `${LP_TOKENS[selectedToken]} Balance`,
        balance: '100.00*',
        disableInput: false,
        disableButton: false,
      },
      6: {
        label: `${LP_TOKENS[selectedToken]} Balance`,
        balance: '100.00*',
        disableInput: false,
        disableButton: false,
      },
      7: {
        label: `${LP_TOKENS[selectedToken]} Balance`,
        balance: '100.00*',
        disableInput: false,
        disableButton: false,
      },
      8: {
        label: `${LP_TOKENS[selectedToken]} Staked`,
        balance: '100.00*',
        disableInput: false,
        disableButton: false,
      },
      9: {
        label: `${LP_TOKENS[selectedToken]} Staked`,
        balance: '100.00*',
        disableInput: false,
        disableButton: false,
      },
    };

    const { label, balance, disableInput, disableButton } = config[
      `${currentTourStep < 2 || currentTourStep > 9 ? 2 : currentTourStep}`
    ];

    const FormButtons = {
      STAKE: (
        <NewButton
          classes={{ button }}
          color={currentTourStep > 3 ? 'green' : 'blue'}
          onClick={goForwardStep}
          disabled={disableButton}
        >
          {`${currentTourStep > 3 ? `Stake` : `Enable`} ${
            LP_TOKENS[selectedToken]
          }`}
        </NewButton>
      ),
      UNSTAKE: (
        <>
          <NewButton
            classes={{ button }}
            color={'gold'}
            onClick={goForwardStep}
            disabled={disableButton}
          >
            {`Unstake ${LP_TOKENS[selectedToken]}`}
          </NewButton>
          <NewButton
            classes={{ button: claimAndExitBtn }}
            color={'red'}
            onClick={goForwardStep}
            disabled={currentTourStep < 9 ? true : false}
          >
            Claim and Exit
          </NewButton>
        </>
      ),
    };

    return (
      <>
        <TogglePanel
          tabLabels={['Stake', 'Unstake']}
          selectedTab={currentForm === 'STAKE' ? 0 : 1}
          toggleTab={() => undefined}
          ref={ref}
          isTourElementActive={isTourElementActive}
        >
          <PanelHeader classes={{ panelHeader }}>
            <div className={formBalanceWrap}>
              <h1 className={formBalanceLabel}>{label}</h1>
              <div className={formBalance}>{balance}</div>
            </div>
          </PanelHeader>
          <div className={numberFormWrap}>
            <NumberInput
              classes={{ numberInputWrap }}
              disabled={disableInput}
              value={inputValue}
              onChange={changeInputValue}
              onMaxClick={() => changeInputValue('100.0')}
            />
            {FormButtons[currentForm]}
          </div>
        </TogglePanel>

        {pageTourBox}
      </>
    );
  }
);

export default TourStakingForm;
