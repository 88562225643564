import React, { forwardRef } from 'react';
import { motion } from 'framer-motion';

// import { gradientHeader } from '../../shared/FramerVariants';
import {
  metricColumnWrap,
  title,
  metricList,
} from './MetricColumn.module.scss';

const MetricColumn = forwardRef(
  (
    { classes = {}, sectionTitle, tooltip, isTourElementActive, children },
    ref
  ) => {
    return (
      <motion.aside
        className={`${metricColumnWrap} ${classes.metricColumnWrap}`}
        ref={ref}
        // variants={gradientHeader}
        // initial="initial"
        // animate={isTourElementActive ? 'animate' : 'initial'}
        style={isTourElementActive && { zIndex: 8 }}
      >
        {sectionTitle && (
          <h2 className={title}>
            {sectionTitle} {tooltip}
          </h2>
        )}
        <div className={`${metricList} ${classes.metricList}`}>{children}</div>
      </motion.aside>
    );
  }
);

export default MetricColumn;
