import { Contract } from '../utils/entities/Contract';
import { Strategy } from '../utils/entities/Strategy';
import * as icons from '../assets/defi';
import { ChainId } from '../constants';

import { CURVE_POOL_ABI, CURVE_LIQUIDITY_GAUGE_ABI } from './abis';
import {
  CURVE_GAUGE_CONTROLLER,
  getCurveBaseApy,
  getCrvTokenApy,
  getCurveRewardTokenApy,
} from './utils/curve';

const CURVE_AAVE_POOL = {
  [ChainId.MAINNET]: new Contract(
    '0xDeBF20617708857ebe4F679508E7b7863a8A8EeE',
    CURVE_POOL_ABI
  ),
};

const AAVE_LIQUIDITY_GAUGE_ADDRESS =
  '0xd662908ADA2Ea1916B3318327A97eB18aD588b5d';

const CURVE_AAVE_LIQUIDITY_GAUGE = {
  [ChainId.MAINNET]: new Contract(
    AAVE_LIQUIDITY_GAUGE_ADDRESS,
    CURVE_LIQUIDITY_GAUGE_ABI
  ),
};

const curve_aave = new Strategy('Curve Aave', {
  protocol: 'curve',
  poolName: 'aave',
  protocolIcon: icons['curve'],
  outputAssets: ['CRV', 'STKAAVE'],
  contracts: {
    pool: CURVE_AAVE_POOL,
    liquidityGauge: CURVE_AAVE_LIQUIDITY_GAUGE,
    gaugeController: CURVE_GAUGE_CONTROLLER,
  },
  allocationName: 'curve-aave',
  tokenPricesNeeded: ['curve-dao-token'],
  getApys: (chainId, library) => ({
    baseApy: getCurveBaseApy('aave'),
    CRV: getCrvTokenApy('curve_aave', chainId, library),
    STKAAVE: getCurveRewardTokenApy(AAVE_LIQUIDITY_GAUGE_ADDRESS),
  }),
  description: 'Add strategy description here...',
});

export default curve_aave;
