import { useMemo } from 'react';
import BigNumber from 'bignumber.js';
import { getWeeksWithTimestamp } from '../utils/helpers';

export function useBlApyCalulcator(amount, unlockEpoch) {
  const generatedBlApy = useMemo(() => {
    let data;

    if (amount && unlockEpoch) {
      const actualWeeksLocked = getWeeksWithTimestamp(unlockEpoch);

      data = BigNumber(amount).times(actualWeeksLocked.div(208));
    }

    return {
      data,
    };
  }, [amount, unlockEpoch]);

  return { generatedBlApy };
}
