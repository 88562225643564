import React, { useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';

import { useApproval } from '../../../../../../../hooks';
import { STAKING_CONTRACTS, LP_TOKENS } from '../../../../../../../constants';
import * as Contracts from '../../../../../../../constants/contracts';
import { displayAmount } from '../../../../../../../utils/helpers';

import TogglePanel from '../../../../../containers/TogglePanel';
import PanelHeader from '../../../../../containers/Panel/PanelHeader';
import FormBalance from '../../../../../forms/FormBalance';
import StakeForm from './StakeForm';
import UnstakeForm from './UnstakeForm';
import { panelHeader } from './StakingForm.module.scss';

const StakingForm = ({
  protocol,
  balances,
  currentForm,
  onTabToggle,
  selectedToken,
  inputValue,
  changeInputValue,
  inputError,
  dispatch,
  isTrxPending,
}) => {
  const { chainId } = useWeb3React();

  const spenderKeys = {
    UNIV2: 'UNIV2_STAKING',
    BPT: 'BPT_STAKING',
  };

  const { isApproved, updateApproval, allowance } = useApproval(
    selectedToken,
    inputValue,
    Contracts[spenderKeys[selectedToken]]?.[chainId]?.address
  );

  useEffect(() => {
    if (currentForm !== 'STAKE' || !allowance.data || allowance.data.isZero())
      return;

    // Use '0' if input amount is 0, '', null, or undefined
    const inputAmount = !inputValue ? '0' : inputValue;

    if (allowance.data.lt(inputAmount)) {
      dispatch({
        type: 'INPUT_ERROR',
        errorMsg: `Enable ${LP_TOKENS[selectedToken]} for this amount`,
      });
    }
  }, [allowance, currentForm, inputValue, dispatch, selectedToken]);

  const showForm = currentForm => {
    const isFormDisabled = () => {
      if (balances.isLoading || balances.error) {
        return true;
      } else if (
        currentForm === 'STAKE' &&
        (allowance.isLoading || allowance.error)
      ) {
        return true;
      } else if (
        currentForm === 'STAKE' &&
        (isApproved.isLoading || isApproved.error)
      ) {
        return true;
      } else if (
        currentForm === 'STAKE' &&
        balances.data[selectedToken]?.isZero() &&
        allowance.data?.isZero()
      ) {
        return true;
      } else if (
        currentForm === 'UNSTAKE' &&
        balances.data[STAKING_CONTRACTS[selectedToken]]?.isZero()
      ) {
        return true;
      } else if (isTrxPending) {
        return true;
      } else {
        return false;
      }
    };

    const FormInputs = {
      STAKE: (
        <StakeForm
          balance={balances.data?.[selectedToken]}
          isFormDisabled={isFormDisabled()}
          formType={currentForm}
          selectedToken={selectedToken}
          allowance={allowance}
          isTokenApproved={isApproved}
          updateApproval={updateApproval}
          inputValue={inputValue}
          onInputChange={changeInputValue}
          onMaxClick={() =>
            changeInputValue(balances.data?.[selectedToken].toString())
          }
          dispatch={dispatch}
        />
      ),
      UNSTAKE: (
        <UnstakeForm
          balance={balances.data?.[STAKING_CONTRACTS[selectedToken]]}
          isFormDisabled={isFormDisabled()}
          formType={currentForm}
          selectedToken={selectedToken}
          inputValue={inputValue}
          onInputChange={changeInputValue}
          onMaxClick={() =>
            changeInputValue(
              balances.data?.[STAKING_CONTRACTS[selectedToken]].toString()
            )
          }
          dispatch={dispatch}
        />
      ),
    };

    return FormInputs[currentForm];
  };

  return (
    <TogglePanel
      tabLabels={['Stake', 'Unstake']}
      selectedTab={currentForm === 'STAKE' ? 0 : 1}
      toggleTab={onTabToggle}
    >
      <PanelHeader classes={{ panelHeader }}>
        <FormBalance
          protocol={protocol}
          currentForm={currentForm}
          token={LP_TOKENS[selectedToken]}
          displayBalance={
            currentForm === 'STAKE' && balances.data?.[selectedToken]
          }
          label={currentForm === 'STAKE' ? 'Balance' : 'Staked'}
          allowance={allowance}
          isApproved={isApproved}
          error={inputError}
          isLoading={
            currentForm === 'STAKE'
              ? balances.isLoading ||
                allowance.isLoading ||
                isApproved.isLoading
              : balances.isLoading
          }
        >
          {currentForm === 'STAKE'
            ? displayAmount(balances.data?.[selectedToken])
            : displayAmount(balances.data?.[STAKING_CONTRACTS[selectedToken]])}
        </FormBalance>
      </PanelHeader>
      {showForm(currentForm)}
    </TogglePanel>
  );
};

export default StakingForm;
