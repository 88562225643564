import axios from 'axios';
import BigNumber from 'bignumber.js';

import { ChainId } from '../../constants';
import { CURVE_GAUGE_CONTROLLER_ABI } from '../abis';
import { Contract } from '../../utils/entities/Contract';
import * as Strategies from '../index';
import { getCoinGeckoPrices, initContract } from '../../utils/helpers';

const WEI_PER_TOKEN = '1000000000000000000';
const MANTISSA = WEI_PER_TOKEN;
const SECONDS_PER_DAY = 86400;
const DAYS_PER_YEAR = 365;
const TOKENLESS_PRODUCTION = 0.4;

// Main endpoints
export const CURVE_BASE_APYS_URL = 'https://service.apy.finance/v1/apys/base';
export const CURVE_ADDTL_APYS_URL =
  'https://service.apy.finance/v1/apys/additional';

// Pulls data needed to calc CRV APY
export const CURVE_GAUGES_URL = 'https://api.curve.fi/api/getGauges';

// Factory pools
export const CURVE_FACTORY_BASE_APYS_URL =
  'https://api.curve.fi/api/getFactoryAPYs';
export const CURVE_FACTORY_ADDTL_APYS_URL =
  'https://api.curve.fi/api/getFactoryV2Pools';

// Curve Gauge Controller
export const CURVE_GAUGE_CONTROLLER = {
  [ChainId.MAINNET]: new Contract(
    '0x2F50D538606Fa9EDD2B11E2446BEb18C9D5846bB',
    CURVE_GAUGE_CONTROLLER_ABI
  ),
};

export const getCurveRewardTokenApy = async gaugeAddress => {
  const res = await axios
    .get('https://api.curve.fi/api/getMainPoolsGaugeRewards')
    .catch(err => {
      let error = new Error(
        `CURVE_API_ERROR: Issue fetching Curve reward token APY (Gauge: ${gaugeAddress}) - ${err.message}`
      );
      throw error;
    });

  const rewards = res.data.data.mainPoolsGaugeRewards;
  return rewards[gaugeAddress][0].apy / 100;
};

// May need to verify symbol on return
export const getCurveBaseApy = async curvePoolSymbol => {
  // Alt. solution for CORS issue
  // const PROXY = 'https://cors-anywhere.herokuapp.com/';
  // const URL = `https://pushservice.curve.fi/apys/${poolSymbol}`;
  // const res = await axios.get(PROXY + URL);
  // const baseApy = res.data.lendRates[0].apy;

  const res = await axios.get('https://stats.curve.fi/raw-stats/apys.json');
  const baseApy = res.data.apy.day[curvePoolSymbol];

  return baseApy;
};

export const calcCrvApy = ({
  crvPrice,
  gaugeRelativeWeight,
  workingSupply,
  inflationRate,
  virtualPrice,
}) => {
  gaugeRelativeWeight = BigNumber(gaugeRelativeWeight).div(MANTISSA);
  workingSupply = BigNumber(workingSupply).div(MANTISSA);
  virtualPrice = BigNumber(virtualPrice).div(MANTISSA);

  const tokensPerDay = BigNumber(inflationRate)
    .times(SECONDS_PER_DAY)
    .div(MANTISSA);

  const tokenValuePerYear = tokensPerDay
    .times(DAYS_PER_YEAR)
    .times(gaugeRelativeWeight)
    .times(crvPrice);

  const supplyValue = workingSupply.times(virtualPrice);

  return tokenValuePerYear
    .div(supplyValue)
    .times(TOKENLESS_PRODUCTION)
    .toNumber();
};

export const getCrvTokenApy = async (stratKey, chainId, provider) => {
  const curvePool = initContract(
    Strategies[stratKey].contracts.pool[chainId].address,
    Strategies[stratKey].contracts.pool[chainId].abi,
    provider
  );
  const liquidityGauge = initContract(
    Strategies[stratKey].contracts.liquidityGauge[chainId].address,
    Strategies[stratKey].contracts.liquidityGauge[chainId].abi,
    provider
  );
  const gaugeController = initContract(
    Strategies[stratKey].contracts.gaugeController[chainId].address,
    Strategies[stratKey].contracts.gaugeController[chainId].abi,
    provider
  );

  const { crv: crvPrice } = await getCoinGeckoPrices(['crv']);

  const poolVirtualPrice =
    (await curvePool.get_virtual_price()).toString() / MANTISSA;
  const gaugeRelativeWeight =
    (
      await gaugeController['gauge_relative_weight(address)'](
        liquidityGauge.address
      )
    ).toString() / MANTISSA;
  const workingSupply =
    (await liquidityGauge.working_supply()).toString() / MANTISSA;
  const inflationRate = await liquidityGauge.inflation_rate();
  const tokensPerDay = inflationRate
    .mul(SECONDS_PER_DAY)
    .div(WEI_PER_TOKEN)
    .toString();

  const tokenValuePerYear =
    DAYS_PER_YEAR * tokensPerDay * gaugeRelativeWeight * crvPrice;
  const supplyValue = workingSupply * poolVirtualPrice;

  return (tokenValuePerYear / supplyValue) * TOKENLESS_PRODUCTION;
};
