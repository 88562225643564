import React, { useContext } from 'react';
import { useMedia } from 'use-media';

// import { useTourControls } from '../../../hooks';
import { PageTourContext } from '../../../state/contexts';

import PageTourBox from '../../apy/info/PageTourBox';
import StakingOverview from './sections/StakingOverview';
import { pool2PageWrap } from './Pool2.module.scss';
// import { pool2PageWrap, pageTourToggle } from './Pool2.module.scss';

function Pool2() {
  // const { PageTourToggle } = useTourControls('pool2');

  // Media queries
  const isVwOver500 = useMedia({ minWidth: '501px' });

  const {
    tourSteps: { pool2: pool2Steps },
    isTourActive,
    isTourStepActive,
  } = useContext(PageTourContext);

  return (
    <div
      className={pool2PageWrap}
      style={
        isTourActive
          ? { marginBottom: !isVwOver500 ? '17rem' : undefined }
          : null
      }
    >
      <PageTourBox
        steps={pool2Steps[0]}
        isActive={isTourStepActive(pool2Steps[0])}
      />
      {/* <PageTourToggle classes={[pageTourToggle]} /> */}
      <StakingOverview />
    </div>
  );
}

export default Pool2;
