import React from 'react';
import { BeatLoader } from 'react-spinners';

import { metricWrap, metricTitle, metricValue } from './Metric.module.scss';

function Metric({
  classes = {},
  title,
  tooltip,
  children,
  isLoading,
  spinnerSize = 16,
}) {
  return (
    <div className={`${metricWrap} ${classes.metricWrap}`}>
      <h3 className={`${metricTitle} ${classes.metricTitle}`}>
        {title}
        {tooltip}
      </h3>
      <h2 className={`${metricValue} ${classes.metricValue}`}>
        {isLoading ? (
          <BeatLoader size={spinnerSize} margin={4} color={'gray'} />
        ) : (
          children
        )}
      </h2>
    </div>
  );
}

export default Metric;
