import { Strategy } from '../utils/entities/Strategy';
import * as icons from '../assets/defi';
import { ChainId } from '../constants';

const convex_saave = new Strategy('Convex sAAVE', {
  protocol: 'convex',
  poolName: 'saave',
  protocolIcon: icons['convex'],
  outputAssets: {
    gov: 'CVX',
    addtlReward: 'STKAAVE',
    crv: 'CRV',
  },
  allocationName: 'convex-saave',
  tokenPricesNeeded: ['curve-dao-token', 'convex-finance'],
  options: {
    curveLiquidityGaugeAddress: {
      [ChainId.MAINNET]: '0x462253b8F74B72304c145DB0e4Eebd326B22ca39',
    },
  },
});

export default convex_saave;
