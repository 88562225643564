import { ethers } from 'ethers';
import { BigNumber } from 'bignumber.js';
import axios from 'axios';

import { useCoinGeckoStore } from '../../state/stores';
import * as Tokens from '../../constants/tokens';
import * as Contracts from '../../constants/contracts';
import { ContractKeyError, ChainIdError, SmartContractError } from '../errors';
import {
  DEPLOYED_CHAINS,
  EMPTY_VALUE_SYMBOL,
  POOL_CONTRACT_KEYS,
} from '../../constants';
export * from './portfolio';
export * from './platform';
export * from './strategies';
export * from './rewards';
export * from './boostLock';

export const chunkList = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );

export const isValidNumber = number => {
  return (
    number !== null &&
    number !== undefined &&
    number !== '' &&
    !isNaN(number) &&
    number !== Infinity &&
    number !== -Infinity
  );
};

export const isChainSupported = chainId =>
  DEPLOYED_CHAINS.includes(chainId?.toString());

export const bytes32 = ethers.utils.formatBytes32String;

export const isListEmpty = list => (!list ? true : list.length === 0);

export function capitalizeLabel(formType) {
  return formType.charAt(0).toUpperCase() + formType.toLowerCase().slice(1);
}

const getCoinGeckoSymbolToId = (symbols, coinList) => {
  const blacklist = ['master-usd'];

  let coinIds = {};

  symbols.forEach(symbol => {
    const coin = coinList.find(coin => {
      return (
        coin.symbol.toLowerCase() === symbol.toLowerCase() &&
        !blacklist.includes(coin.id.toLowerCase())
      );
    });

    if (coin?.id) {
      coinIds[symbol] = coin.id;
    }
  });

  return coinIds;
};

export const getCoinGeckoPrices = async (
  symbols,
  { currency = 'usd' } = {}
) => {
  // Pull in CoinGecko coin list and get proper ids
  const {
    getState: getCoinGeckoState,
    setState: setCoinGeckoState,
  } = useCoinGeckoStore;
  const coinGeckoList = getCoinGeckoState().coinGeckoList;
  const symbolToId = getCoinGeckoSymbolToId(symbols, coinGeckoList);

  // Check to see if price(s) for symbol(s) already fetched
  let coinGeckoPrices = {};
  const { data: coinPrices } = getCoinGeckoState().coinPrices;

  symbols.forEach(symbol => {
    let id = symbolToId[symbol];
    if (coinPrices[id]) {
      coinGeckoPrices[symbol] = coinPrices[id];
    }
  });

  // If all prices found, return early (check array length)
  let idsToFetch = [];
  if (symbols.length === Object.keys(coinGeckoPrices).length) {
    return coinGeckoPrices;

    // Else grab id(s) of missing prices
  } else {
    for (const symbol in symbolToId) {
      if (!Object.keys(coinGeckoPrices).includes(symbol)) {
        idsToFetch.push(symbolToId[symbol]);
      }
    }
  }

  idsToFetch = encodeURIComponent(idsToFetch.join());

  // Fetch prices from CoinGecko
  let res;
  res = await axios
    .get(
      `https://api.coingecko.com/api/v3/simple/price?ids=${idsToFetch}&vs_currencies=${currency}`
    )
    .catch(err =>
      console.error(
        'COINGECKO_ERROR: Could not fetch coin prices -',
        err.message
      )
    );

  if (res) {
    const coins = Object.keys(res.data);
    for (const coin of coins) {
      const coinPrice = res.data[coin]?.usd;

      // Set price to symbol in coinGeckoPrices (return value)
      let symbol = symbols.find(symbol => {
        return symbolToId[symbol] === coin;
      });

      coinGeckoPrices[symbol] = coinPrice;

      // Should set newly fetched price to coinPrices in zustand store (no need to fetch later)
      // Set price to coinId (ticker) in global zustand coinPrices
      const coinPricesCopy = {
        data: {
          ...coinPrices,
          [coin]: coinPrice,
        },
        isLoading: false,
        error: null,
      };

      setCoinGeckoState({ coinPrices: coinPricesCopy });
      // Have to re-fetch state from zustand store if you want to use right after
      // Example:
      // const { data: newCoinPrices } = getCoinGeckoState().coinPrices;
      // console.log('newCoinPrices', newCoinPrices);
    }
  }

  // Throw error if symbol price missing (checks array length)
  const symbolsFound = Object.keys(coinGeckoPrices);
  if (!symbols.length === symbolsFound.length) {
    const missingSymbols = symbols.filter(
      symbol => !symbolsFound.includes(symbol)
    );

    throw new Error(
      `Could not get price of asset(s) [${missingSymbols.join(
        ', '
      )}] required to calculated allocation value`
    );
  }

  return coinGeckoPrices;
};

export const contractsInitialized = (poolContracts, underlyerContracts) => {
  if (
    poolContracts['DAI'] &&
    poolContracts['USDC'] &&
    poolContracts['USDT'] &&
    underlyerContracts['DAI'] &&
    underlyerContracts['USDC'] &&
    underlyerContracts['USDT']
  ) {
    return true;
  }
  return false;
};

export const getWei = (amount, currency) => {
  let decimals = 18;
  if (
    currency === 'USDC' ||
    currency === 'USDT' ||
    currency === POOL_CONTRACT_KEYS['USDC'] ||
    currency === POOL_CONTRACT_KEYS['USDT']
  ) {
    decimals = 6;
  }

  return ethers.utils.parseUnits(amount, decimals);
};

// const toWei = () => {

// }

export const fromWei = (bigNumWeiAmount, symbol) => {
  if (
    bigNumWeiAmount === null ||
    bigNumWeiAmount === undefined ||
    bigNumWeiAmount === '' ||
    isNaN(bigNumWeiAmount)
  ) {
    // throw new Error('fromWei() can only take valid numbers or numStrings');
    return;
  }

  let decimals;
  if (Tokens[symbol]) {
    decimals = Tokens[symbol].decimals;
  } else {
    decimals = 18;
  }

  // Convert all numbers, numStrings, and even Ethers BN to BN.js
  bigNumWeiAmount = BigNumber(bigNumWeiAmount.toString());

  return bigNumWeiAmount.div(`${10 ** decimals}`);
};

export const fromBaseBN = (amountInBaseUnits, symbol) => {
  let decimals;
  if (Tokens[symbol]) {
    decimals = Tokens[symbol].decimals;
  } else {
    decimals = 18;
  }

  return ethers.utils.formatUnits(amountInBaseUnits, decimals);
};

export const displayAmount = (
  balance,
  { format = 'decimal', decimals = 2, round = false } = {}
) => {
  balance = BigNumber(balance);

  if (
    balance.isNaN() ||
    balance.isEqualTo(Infinity) ||
    balance.isEqualTo(-Infinity)
  ) {
    return EMPTY_VALUE_SYMBOL;
  }

  let numString = balance.toString();

  // Multiply by 100 here (instead of through toLocaleString) in order to truncate properly
  if (format === 'percent') {
    numString = (numString * 100).toString();
  }

  if (round === false) {
    numString = truncNumString(numString, decimals);
  }

  let localeString = Number(numString).toLocaleString('en-US', {
    // Prevent multiplying by 100 twice by passing 'decimal' instead of 'percent
    style: format === 'percent' ? 'decimal' : format,
    currency: 'USD',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });

  if (format === 'percent') {
    localeString = `${localeString}%`;
  }

  return localeString;
};

export const truncNumString = (numString, decimals = 2) => {
  if (!numString.includes('.')) {
    numString = `${numString}.0`;
  }

  // Truncate after specified number of decimals
  const truncatedNumString = numString.slice(
    0,
    numString.indexOf('.') + (decimals + 1)
  );

  return truncatedNumString;
};

export const getRequiredDecimals = (bigNumber, options = {}) => {
  let numString = bigNumber.toString();

  if (!numString.includes('.')) {
    numString = `${numString}.0`;
  }

  // Shouldn't get more than two decimals if numString >= 1

  let decimals;
  // Check if first number after decimal isn't 0
  if (numString[numString.indexOf('.') + 1] !== '0') {
    decimals = 2;
  } else {
    // Get string of all digits after the decimal
    const decimalString = numString.slice(numString.indexOf('.'));
    decimals = decimalString.search(/([1-9])/);
  }

  if (options.format === '%') {
    decimals -= 2;
  }

  return decimals > 2 ? decimals : 2;
};

export const initContract = (address, abi, library) => {
  let signer;

  if (library?.provider === window.ethereum) {
    signer = library.getSigner();
  } else {
    const httpProvider = new ethers.providers.JsonRpcProvider();
    signer = httpProvider.getSigner();
  }

  const contract = new ethers.Contract(address, abi, signer);
  return contract;
};

export const getAddressFromRegistry = async (contractKey, chainId, library) => {
  if (!contractKey || !chainId || !library) return;

  let addressRegistry;
  addressRegistry = initContract(
    Contracts['REGISTRY'][chainId].address,
    Contracts['REGISTRY'][chainId].abi,
    library
  );

  const FUNC_NAMES = {
    TVL_MGR: 'tvlManagerAddress',
    LP_ACCOUNT: 'lpAccountAddress',
    aptDAI: 'daiPoolAddress',
    aptUSDC: 'usdcPoolAddress',
    aptUSDT: 'usdtPoolAddress',
    ORACLE_ADAPTER: 'oracleAdapterAddress',
  };

  const OTHER_IDS = {
    DAI_DEMO_POOL: 'daiDemoPool',
    USDC_DEMO_POOL: 'usdcDemoPool',
    USDT_DEMO_POOL: 'usdtDemoPool',
  };

  let address;
  if (FUNC_NAMES[contractKey]) {
    address = await addressRegistry.functions[
      `${FUNC_NAMES[contractKey]}`
    ]().catch(err => {
      throw new SmartContractError(
        FUNC_NAMES[contractKey],
        'AddressRegistry',
        err.message
      );
    });

    return address[0];
  } else {
    let id = OTHER_IDS[contractKey];
    if (!id) {
      throw new ContractKeyError(
        `Could not find contract in getAddressFromRegistry's ID list`,
        contractKey
      );
    }

    address = await addressRegistry.getAddress(bytes32(id)).catch(err => {
      throw new SmartContractError(
        OTHER_IDS[contractKey],
        'AddressRegistry',
        err.message
      );
    });

    return address;
  }
};

export const getContracts = async (contractKeys, chainId, library) => {
  if (!chainId || !library || Object.entries(library).length === 0) return;

  try {
    let initializedContracts = {};
    for (const contractKey of contractKeys) {
      if (!Contracts[contractKey]) {
        throw new ContractKeyError(
          `Could not find contract in app's contract list`,
          contractKey
        );
      }

      if (!Contracts[contractKey][chainId]) {
        throw new ChainIdError(
          `App does not support contract (${contractKey}) on this chain`,
          chainId
        );
      }

      let address;
      if (!Contracts[contractKey][chainId].address) {
        address = await getAddressFromRegistry(
          contractKey,
          chainId,
          library
        ).catch(err => {
          throw err;
        });
      } else {
        address = Contracts[contractKey][chainId].address;
      }

      let contract;
      contract = initContract(
        address,
        Contracts[contractKey][chainId].abi,
        library
      );

      initializedContracts[contractKey] = contract;
    }

    if (contractKeys.length === 1) {
      // Return single Contract
      return initializedContracts[contractKeys[0]];
    }

    // Return object with collection of Contracts
    return initializedContracts;
  } catch (err) {
    if (err instanceof ContractKeyError) {
      // Potential error on part of the dev
      console.error('Contract Not Supported -', err.message);
    } else if (err instanceof ChainIdError) {
      // Potential error on part of the dev/user
      console.error('Chain ID Not Supported -', err.message);
    } else if (err instanceof SmartContractError) {
      // Potential error from network/smart contracts issues
      console.error('Failed Function Call -', err.message);
    } else {
      throw err;
    }
  }
};
