import React from 'react';
import { motion } from 'framer-motion';

import { linkButton } from './InstallMetamaskButton.module.scss';

function InstallMetamaskButton() {
  return (
    <motion.a
      whileHover={{
        scale: 1.05,
      }}
      className={linkButton}
      href="https://metamask.io/download.html"
      rel="noopener noreferrer"
      target="_blank"
    >
      Install Metamask
    </motion.a>
  );
}

export default InstallMetamaskButton;
