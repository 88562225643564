import React from 'react';
import { ClipLoader } from 'react-spinners';

import {
  button,
  purple,
  blue,
  green,
  gold,
  orange,
  red,
  gradient,
  small,
  medium,
  large,
} from './NewButton.module.scss';

// Animations

function NewButton(props) {
  const {
    type = 'button',
    classes = {},
    color,
    size = 'medium',
    children,
    isLoading,
    ...otherProps // disabled, onClick, value, style etc.
  } = props;

  const setButtonClasses = (classes, color, size) => {
    const colors = {
      purple,
      blue,
      green,
      gold,
      orange,
      red,
      gradient,
    };

    const sizes = {
      small,
      medium,
      large,
    };

    return `${button} ${colors[color]} ${sizes[size]} ${classes.button}`;
  };

  return (
    <button
      type={type}
      className={setButtonClasses(classes, color, size)}
      {...otherProps}
    >
      {isLoading ? <ClipLoader size={20} color={'#d0d0d0'} /> : children}
    </button>
  );
}

export default NewButton;
