import React from 'react';

import InputButton from '../../buttons/InputButton';
import { selectPercentageWrap } from './SelectPercentage.module.scss';

function SelectPercentage({ classes = {}, inputValue, onClick }) {
  return (
    <div className={`${selectPercentageWrap} ${classes.selectPercentageWrap}`}>
      {['25', '50', '75', '100'].map(percent => {
        return (
          <InputButton
            key={percent}
            isSelected={percent === inputValue}
            value={percent}
            onClick={onClick}
          >
            {percent === '100' ? 'Max' : `${percent}%`}
          </InputButton>
        );
      })}
    </div>
  );
}

export default SelectPercentage;
