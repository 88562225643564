import React from 'react';
import { useMedia } from 'use-media';

import Logo from '../Logo';
import { brandWrap, brandText } from './Brand.module.scss';

function Brand() {
  // Media Queries
  const isVwOver768 = useMedia({ minWidth: '769px' });

  return (
    <div className={brandWrap}>
      <Logo />
      {isVwOver768 && <span className={brandText}>apy.finance</span>}
    </div>
  );
}

export default Brand;
