import { useMemo } from 'react';
import useSWR from 'swr';

import { APIError } from '../utils/errors';
import { axiosFetcher } from '../utils/swrFetchers';

export function useApi(
  orgName,
  endpoint,
  { conditions = [], swrOptions = {} } = {}
) {
  const { data, error } = useSWR(
    !conditions.includes(undefined) && !conditions.includes(false)
      ? endpoint
      : null,
    axiosFetcher,
    swrOptions
  );

  const res = useMemo(() => {
    let apiError;

    if (error) {
      apiError = new APIError(orgName, error.message, endpoint);
      console.error(apiError.message);
    }

    return {
      data: data,
      isLoading: !data && !apiError,
      error: apiError,
    };
  }, [data, error]);

  return { res };
}
