import { useMemo } from 'react';
import BigNumber from 'bignumber.js';

import { usePortfolioValue, useBlApyData } from '.';

export function useCurrentBoost(account) {
  const { totalAptValue } = usePortfolioValue(account);

  const { blApyValue } = useBlApyData(account);

  const lockScore = useMemo(() => {
    let data;

    if (totalAptValue.data && blApyValue.data) {
      data = BigNumber.min(
        totalAptValue.data.times(0.4).plus(blApyValue.data.times(0.6)),
        totalAptValue.data
      );
    }

    return {
      data: data,
      isLoading: !data && !totalAptValue.error && !blApyValue.error,
      error: totalAptValue.error ?? blApyValue.error,
    };
  }, [totalAptValue, blApyValue]);

  const currentBoost = useMemo(() => {
    let data;

    if (lockScore.data && totalAptValue.data) {
      const boost = lockScore.data.div(totalAptValue.data.times(0.4));
      data = boost.isNaN() ? BigNumber(1) : boost;
    }

    return {
      data: data,
      isLoading: !data && !totalAptValue.error && !lockScore.error,
      error: totalAptValue.error ?? lockScore.error,
    };
  }, [totalAptValue, lockScore]);

  return { currentBoost };
}
