import { Strategy } from '../utils/entities/Strategy';
import * as icons from '../assets/defi';
import { ChainId } from '../constants';

const convex_ousd = new Strategy('Convex OUSD', {
  protocol: 'convex',
  poolName: 'ousd',
  protocolIcon: icons['convex'],
  outputAssets: {
    gov: 'CVX',
    addtlReward: 'OGN',
    crv: 'CRV',
  },
  allocationName: 'convex-ousd',
  tokenPricesNeeded: ['curve-dao-token', 'origin-dollar', 'convex-finance'],
  options: {
    isCurveFactory: true,
    curveLiquidityGaugeAddress: {
      [ChainId.MAINNET]: '0x25f0cE4E2F8dbA112D9b115710AC297F816087CD',
    },
  },
});

export default convex_ousd;
