import React, { forwardRef, useContext, useState } from 'react';
import { useMedia } from 'use-media';

import { PageTourContext } from '../../../../../state/contexts';

import TogglePanel from '../../../containers/TogglePanel';
import PanelHeader from '../../../containers/Panel/PanelHeader';
import SelectStablecoin from '../../../inputs/SelectStablecoin';
import NumberInput from '../../../inputs/NumberInput';
import NewButton from '../../../buttons/NewButton';
import PercentageInput from '../../../inputs/PercentageInput';
import PercentageSlider from '../../../inputs/PercentageSlider';
import SelectPercentage from '../../../inputs/SelectPercentage';
import {
  panelHeader,
  formBalanceWrap,
  formBalanceLabel,
  formBalance,
  numberFormWrap,
  numberInputWrap,
  depositBtn,
  percentageFormWrap,
  percentageFormContainer,
  notEnabled,
  selectStablecoinWrap,
  earlyWithdrawalNotice,
  percentageInputWrap,
  percentageSliderWrap,
  slider,
  withdrawBtn,
} from './TourStablecoinForm.module.scss';
import PageTourBox from '../../../info/PageTourBox';

const TourStablecoinForm = forwardRef(
  (
    {
      selectedToken,
      changeToken,
      inputValue,
      changeInputValue,
      isTourElementActive,
      pageTourBox,
    },
    ref
  ) => {
    const {
      tourSteps: { portfolio: portfolioSteps },
      currentTourStep,
      goForwardStep,
      isTourStepActive,
    } = useContext(PageTourContext);
    const [inputElement, setInputElement] = useState();
    const [percentageInputElement, setPercentageInputElement] = useState();

    // Media Queries
    const isVwOver500 = useMedia({ minWidth: '501px' });
    const isVwOver768 = useMedia({ minWidth: '769px' });
    const isOver1000 = useMedia({ minWidth: '1001px' });

    const currentForm = currentTourStep === 7 ? 'WITHDRAW' : 'DEPOSIT';

    const getFormInfo = currentTourStep => {
      const label = currentTourStep > 3 && `${selectedToken} Balance`;
      const balance =
        currentTourStep > 3 ? (
          '100.00*'
        ) : (
          <span
            className={notEnabled}
          >{`Enable ${selectedToken} for depositing`}</span>
        );
      const disableInput = currentTourStep > 3 ? false : true;
      const disableButton = currentTourStep < 3 ? true : false;

      return { label, balance, disableInput, disableButton };
    };

    const { label, balance, disableInput, disableButton } = getFormInfo(
      currentTourStep
    );

    const Forms = {
      DEPOSIT: (
        <div className={numberFormWrap}>
          <NumberInput
            classes={{ numberInputWrap }}
            disabled={disableInput}
            value={inputValue}
            onChange={changeInputValue}
            onMaxClick={() => changeInputValue('100.0')}
            ref={setInputElement}
            isTourElementActive={
              !isVwOver500 &&
              isTourStepActive([portfolioSteps[3][0], portfolioSteps[3][1]])
            }
            pageTourBox={
              <PageTourBox
                steps={[portfolioSteps[3][0], portfolioSteps[3][1]]}
                isActive={
                  !isVwOver500 &&
                  isTourStepActive([portfolioSteps[3][0], portfolioSteps[3][1]])
                }
                refElement={inputElement}
              />
            }
          />
          <NewButton
            classes={{ button: depositBtn }}
            color={currentTourStep > 3 ? `green` : `blue`}
            onClick={goForwardStep}
            disabled={disableButton}
          >
            {`${currentTourStep > 3 ? `Deposit` : `Enable`} ${selectedToken}`}
          </NewButton>
          {currentTourStep > 3 && (
            <div className={earlyWithdrawalNotice}>
              * A 5% fee will be charged on withdrawals made within 24 hours of
              your deposit
            </div>
          )}
        </div>
      ),
      WITHDRAW: (
        <>
          <div
            className={percentageFormWrap}
            ref={setPercentageInputElement}
            style={
              !isVwOver500 && isTourStepActive(portfolioSteps[3][4])
                ? {
                    overflow: 'initial',
                    scrollMarginTop: isVwOver500 ? '6.5rem' : '5rem',
                  }
                : null
            }
          >
            <div className={percentageFormContainer}>
              <div className={percentageInputWrap}>
                <PercentageInput
                  value={inputValue ? inputValue : '0'}
                  onInputChange={changeInputValue}
                />
                {(!isVwOver500 || (isVwOver768 && !isOver1000)) && (
                  <SelectPercentage
                    inputValue={inputValue ? inputValue : '0'}
                    onClick={changeInputValue}
                  />
                )}
              </div>
              <div className={percentageSliderWrap}>
                <PercentageSlider
                  classes={{ slider }}
                  value={inputValue ? inputValue : '0'}
                  onChange={changeInputValue}
                />
                {((isVwOver500 && !isVwOver768) || isOver1000) && (
                  <SelectPercentage
                    inputValue={inputValue ? inputValue : '0'}
                    onClick={changeInputValue}
                  />
                )}
              </div>
            </div>
            <NewButton
              classes={{ button: withdrawBtn }}
              color={'red'}
              onClick={goForwardStep}
            >
              Withdraw
            </NewButton>
          </div>
          {
            <PageTourBox
              steps={portfolioSteps[3][4]}
              isActive={!isVwOver500 && isTourStepActive(portfolioSteps[3][4])}
              refElement={percentageInputElement}
            />
          }
        </>
      ),
    };

    return (
      <>
        <TogglePanel
          tabLabels={['Deposit', 'Withdraw']}
          selectedTab={currentForm === 'DEPOSIT' ? 0 : 1}
          toggleTab={() => undefined}
          ref={ref}
          isTourElementActive={isTourElementActive}
        >
          <PanelHeader classes={{ panelHeader }}>
            <div className={formBalanceWrap}>
              <h1 className={formBalanceLabel}>{label}</h1>
              <div className={formBalance}>{balance}</div>
            </div>
            <SelectStablecoin
              classes={currentTourStep === 3 && { selectStablecoinWrap }}
              selected={selectedToken}
              onSelect={changeToken}
            />
          </PanelHeader>
          {Forms[currentForm]}
        </TogglePanel>

        {pageTourBox}
      </>
    );
  }
);

export default TourStablecoinForm;
