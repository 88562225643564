import React from 'react';

import { useApi, useTotalPoolValues } from '../../../../hooks';
import { displayAmount } from '../../../../utils/helpers';

import DashboardPage from '../../../apy/templates/DashboardPage';
import TVLChart from './sections/TVLChart';
import { platformPageWrap, mainMetricsSection } from './Platform.module.scss';

function Platform() {
  const { res: platform } = useApi(
    'cortex',
    'https://cxd-data-api-xsehdlynwa-ue.a.run.app/platform'
  );
  const { totalValueLocked } = useTotalPoolValues();

  return (
    <div className={platformPageWrap}>
      <DashboardPage
        classes={{ mainMetricsSection }}
        mainMetrics={[
          {
            title: 'Total Value Locked',
            value: displayAmount(totalValueLocked.data, { format: 'currency' }),
            isLoading: totalValueLocked.isLoading,
          },
          {
            title: 'Total Annual Yield',
            value: displayAmount(platform.data?.apr, {
              format: 'percent',
            }),
            isLoading: false,
          },
        ]}
      >
        <TVLChart />
      </DashboardPage>
    </div>
  );
}

export default Platform;
