// import { fromWei, displayAmount } from '../utils/helpers';

export const trxConfig = {
  APPROVE: {
    messages: options => ({
      PROCESSING: `Enabling ${options.token}`,
      SUCCESS: `${options.token} Enabled`,
      FAILED: `There was an issue enabling ${options.token}`,
    }),
  },
  DEPOSIT: {
    messages: options => ({
      PROCESSING: `Depositing ${options.amount} ${options.token}`,
      DEPOSIT_SUCCESS: `Thank you for your deposit`,
      FAILED: `${options.token} deposit failed`,
    }),
  },
  WITHDRAW: {
    messages: options => ({
      PROCESSING: `Withdrawing ${options.amount} ${options.token}`,
      SUCCESS: `You withdrew ${options.amount} ${options.token}`,
      FAILED: `${options.token} withdrawal failed`,
    }),
  },
  CREATE_LOCK: {
    messages: options => ({
      PROCESSING: `Locking up ${options.amount} APY until ${options.unlockDate}`,
      SUCCESS: 'You successfully locked your APY',
      FAILED: 'Something went wrong creating lock',
    }),
  },
  WITHDRAW_UNLOCKED_APY: {
    messages: () => ({
      PROCESSING: 'Withdrawing unlocked APY',
      SUCCESS: 'You successfully withdrew your APY',
      FAILED: 'Something went wrong withdrawing your APY',
    }),
  },
  INCREASE_LOCK_AMOUNT: {
    messages: options => ({
      PROCESSING: `Adding ${options.amount} APY to lock`,
      SUCCESS: 'You successfully increased your locked amount',
      FAILED: 'Something went wrong increasing locked amount',
    }),
  },
  EXTEND_LOCK_TIME: {
    messages: options => ({
      PROCESSING: `Extending lock time to ${options.unlockDate}`,
      SUCCESS: 'You successfully extended lock time',
      FAILED: 'Something went wrong extending lock time',
    }),
  },
  LM_CLAIM: {
    verificationNeeded: true,
    confirmationsRequired: true,
    messages: options => ({
      PROCESSING: `Processing APY withdrawal`,
      SUCCESS_PENDING: `Successful APY withdrawal`,
      FAILED: `APY withdrawal failed`,
    }),
  },
  STAKE: {
    messages: options => ({
      PROCESSING: `Staking ${options.amount} ${options.token}`,
      SUCCESS: `You've staked ${options.amount} ${options.token}`,
      FAILED: `Something went wrong staking ${options.token}`,
    }),
  },
  UNSTAKE: {
    messages: options => ({
      PROCESSING: `Unstaking ${options.amount} ${options.token}`,
      SUCCESS: `You've unstaked ${options.amount} ${options.token}`,
      FAILED: `Something went wrong unstaking ${options.token}`,
    }),
  },
  STAKING_CLAIM: {
    messages: () => ({
      PROCESSING: 'Claiming APY tokens',
      SUCCESS: 'You successfully claimed APY`',
      FAILED: `Claim for APY failed`,
    }),
  },
  EXIT_STAKING: {
    messages: () => ({
      PROCESSING: 'Claiming APY and exiting your position',
      SUCCESS: 'You successfully claimed your APY and unstaked',
      FAILED: 'There was an issue with your claim',
    }),
  },
};
