import React, { forwardRef } from 'react';
import { useMedia } from 'use-media';
import { motion } from 'framer-motion';

import Loader from '../../Loader';
import { gradient } from '../../shared/FramerVariants';
import {
  panelWrap,
  panelContainer,
  cornerComponentWrap,
  animate,
} from './Panel.module.scss';

const Panel = forwardRef(
  (
    {
      classes = {},
      isLoading,
      cornerComponent,
      children,
      isTourElementActive,
      pageTourBox,
    },
    ref
  ) => {
    // Media queries
    const isVwOver500 = useMedia({ minWidth: '501px' });

    return (
      <>
        <motion.div
          variants={gradient}
          initial="initial"
          animate={isTourElementActive ? 'animate' : 'initial'}
          ref={ref}
          className={`${panelWrap} ${classes.panelWrap} ${
            isLoading && animate
          }`}
          style={
            isTourElementActive && {
              zIndex: 8,
              overflow: 'initial',
              scrollMarginTop: isVwOver500 ? '6.5rem' : '5rem',
            }
          }
        >
          <Loader isLoading={isLoading}>
            {cornerComponent && (
              <div className={cornerComponentWrap}>{cornerComponent}</div>
            )}
            <div className={`${panelContainer} ${classes.panelContainer}`}>
              {children}
            </div>
          </Loader>
        </motion.div>

        {pageTourBox}
      </>
    );
  }
);

export default Panel;
