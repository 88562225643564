import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { ethers } from 'ethers';
import { Web3ReactProvider } from '@web3-react/core';

import App from './App';
import './styles/scss/main.scss';

ReactGA.initialize(process.env.REACT_APP_GA_ID, { debug: true });

function getLibrary(provider) {
  let library = new ethers.providers.Web3Provider(provider);

  library.pollingInterval = 8000;

  return library;
}

ReactDOM.render(
  <React.Fragment>
    <Web3ReactProvider getLibrary={getLibrary}>
      <App />
    </Web3ReactProvider>
  </React.Fragment>,
  document.getElementById('root')
);
