import React from 'react';

import {
  textContainer,
  wrapper,
  paragraph,
  legalHeader,
  emphasize,
  link,
  legalList,
  augmented,
} from './scss/Legal.module.scss';
import SubHeader from '../../apy/headers/SubHeader';

const PrivacyPolicy = () => {
  return (
    <div className={wrapper}>
      <SubHeader heading="privacy policy" />
      <div
        data-augmented-ui="tl-round tr-round br-round bl-round border"
        className={`${textContainer} ${augmented}`}
      >
        <p className={paragraph}>
          At <span className={emphasize}>apy.finance</span>, accessible from{' '}
          <a className={link} href="https://apy.finance">
            https://apy.finance
          </a>
          , one of our main priorities is the privacy of our visitors. This
          Privacy Policy document contains types of information that is
          collected and recorded by{' '}
          <span className={emphasize}>apy.finance</span> and how we use it.
        </p>
        <p className={paragraph}>
          If you have additional questions or require more information about our
          Privacy Policy, do not hesitate to contact us. Our Privacy Policy was
          generated with the help of{' '}
          <span className={emphasize}>GDPR Privacy Policy Generator</span> from{' '}
          <a className={link} href="https://www.gdprprivacynotice.com/">
            GDPRPrivacyNotice.com
          </a>
        </p>

        <h2 className={legalHeader}>
          General Data Protection Regulation (GDPR)
        </h2>
        <p className={paragraph}>
          We are a Data Controller of your information.
        </p>

        <p className={paragraph}>
          DeFi Labs Inc. legal basis for collecting and using the personal
          information described in this Privacy Policy depends on the Personal
          Information we collect and the specific context in which we collect
          the information:
        </p>
        <ul className={legalList}>
          <li>DeFi Labs Inc. needs to perform a contract with you</li>
          <li>You have given DeFi Labs Inc. permission to do so</li>
          <li>
            Processing your personal information is in DeFi Labs Inc. legitimate
            interests
          </li>
          <li>DeFi Labs Inc. needs to comply with the law</li>
        </ul>

        <p className={paragraph}>
          DeFi Labs Inc. will retain your personal information only for as long
          as is necessary for the purposes set out in this Privacy Policy. We
          will retain and use your information to the extent necessary to comply
          with our legal obligations, resolve disputes, and enforce our
          policies.
        </p>

        <p className={paragraph}>
          If you are a resident of the European Economic Area (EEA), you have
          certain data protection rights. If you wish to be informed what
          Personal Information we hold about you and if you want it to be
          removed from our systems, please contact us.
        </p>
        <p className={paragraph}>
          In certain circumstances, you have the following data protection
          rights:
        </p>
        <ul className={legalList}>
          <li>
            The right to access, update or to delete the information we have on
            you.
          </li>
          <li>The right of rectification.</li>
          <li>The right to object.</li>
          <li>The right of restriction.</li>
          <li>The right to data portability</li>
          <li>The right to withdraw consent</li>
        </ul>

        <h2 className={legalHeader}>Log Files</h2>

        <p className={paragraph}>
          <span className={emphasize}>apy.finance</span> follows a standard
          procedure of using log files. These files log visitors when they visit
          websites. All hosting companies do this and a part of hosting
          services' analytics. The information collected by log files include
          internet protocol (IP) addresses, browser type, Internet Service
          Provider (ISP), date and time stamp, referring/exit pages, and
          possibly the number of clicks. These are not linked to any information
          that is personally identifiable. The purpose of the information is for
          analyzing trends, administering the site, tracking users' movement on
          the website, and gathering demographic information.
        </p>

        <h2 className={legalHeader}>Cookies and Web Beacons</h2>

        <p className={paragraph}>
          Like any other website, <span className={emphasize}>apy.finance</span>{' '}
          uses 'cookies'. These cookies are used to store information including
          visitors' preferences, and the pages on the website that the visitor
          accessed or visited. The information is used to optimize the users'
          experience by customizing our web page content based on visitors'
          browser type and/or other information.
        </p>

        <p className={paragraph}>
          For more general information on cookies, please read{' '}
          <a
            className={link}
            href="https://www.cookieconsent.com/what-are-cookies/"
          >
            "What Are Cookies"
          </a>
          .
        </p>

        <h2 className={legalHeader}>Privacy Policies</h2>

        <p className={paragraph}>
          You may consult this list to find the Privacy Policy for each of the
          advertising partners of <span className={emphasize}>apy.finance</span>
          .
        </p>

        <p className={paragraph}>
          Third-party ad servers or ad networks uses technologies like cookies,
          JavaScript, or Web Beacons that are used in their respective
          advertisements and links that appear on{' '}
          <span className={emphasize}>apy.finance</span>, which are sent
          directly to users' browser. They automatically receive your IP address
          when this occurs. These technologies are used to measure the
          effectiveness of their advertising campaigns and/or to personalize the
          advertising content that you see on websites that you visit.
        </p>

        <p className={paragraph}>
          Note that <span className={emphasize}>apy.finance</span> has no access
          to or control over these cookies that are used by third-party
          advertisers.
        </p>

        <h2 className={legalHeader}>Third Party Privacy Policies</h2>

        <p className={paragraph}>
          <span className={emphasize}>apy.finance's</span> Privacy Policy does
          not apply to other advertisers or websites. Thus, we are advising you
          to consult the respective Privacy Policies of these third-party ad
          servers for more detailed information. It may include their practices
          and instructions about how to opt-out of certain options.{' '}
        </p>

        <p className={paragraph}>
          You can choose to disable cookies through your individual browser
          options. To know more detailed information about cookie management
          with specific web browsers, it can be found at the browsers'
          respective websites.
        </p>

        <h2 className={legalHeader}>Children's Information</h2>

        <p className={paragraph}>
          Another part of our priority is adding protection for children while
          using the internet. We encourage parents and guardians to observe,
          participate in, and/or monitor and guide their online activity.
        </p>

        <p className={paragraph}>
          <span className={emphasize}>apy.finance</span> does not knowingly
          collect any Personal Identifiable Information from children under the
          age of 13. If you think that your child provided this kind of
          information on our website, we strongly encourage you to contact us
          immediately and we will do our best efforts to promptly remove such
          information from our records.
        </p>

        <h2 className={legalHeader}>Online Privacy Policy Only</h2>

        <p className={paragraph}>
          Our Privacy Policy applies only to our online activities and is valid
          for visitors to our website with regards to the information that they
          shared and/or collect in{' '}
          <span className={emphasize}>apy.finance</span>. This policy is not
          applicable to any information collected offline or via channels other
          than this website.
        </p>

        <h2 className={legalHeader}>Consent</h2>

        <p className={paragraph}>
          By using our website, you hereby consent to our Privacy Policy and
          agree to its terms.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
