import React, { useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import useSWR from 'swr';
import BigNumber from 'bignumber.js';

import useTransaction from '../../../../../../../hooks/useTransaction';
import { useAccountBalance } from '../../../../../../../hooks';
import { LP_TOKENS, STAKING_CONTRACTS } from '../../../../../../../constants';
import {
  getContracts,
  getWei,
  displayAmount,
} from '../../../../../../../utils/helpers';

import NewButton from '../../../../../buttons/NewButton';
import NumberForm from '../../../../../forms/NumberForm';
import { numberFormWrap, claimAndExitBtn } from './StakingForm.module.scss';

function UnstakeForm(props) {
  const {
    balance,
    selectedToken,
    inputValue,
    isFormDisabled,
    dispatch,
  } = props;

  const { library, chainId, account } = useWeb3React();
  const [isUnstaking, setIsUnStaking] = useState(false);
  const [isExiting, setIsExiting] = useState(false);

  // Custom hooks
  const { updateBalance: updateLptBalance } = useAccountBalance(selectedToken);
  const { updateBalance: updateStakedBalance } = useAccountBalance(
    STAKING_CONTRACTS[selectedToken]
  );
  const { updateBalance: updateApyBalance } = useAccountBalance('APY');

  const [sendTransaction] = useTransaction();

  const { data: claimable, mutate: updateClaimable } = useSWR(
    chainId &&
      account && [`${selectedToken}_STAKING`, chainId, 'earned', account]
  );

  const onSubmit = async evt => {
    evt.preventDefault();

    // Input Validation

    // Prohibit empty input and 0
    if (!inputValue || BigNumber(inputValue).isLessThanOrEqualTo(0)) {
      dispatch({ type: 'INPUT_ERROR', errorMsg: 'Enter an amount' });
      return;

      // Prohibit input greater than balance
    } else if (balance?.lt(inputValue)) {
      dispatch({ type: 'INPUT_ERROR', errorMsg: 'Input exceeds balance' });
      return;

      // Prohibit input under .01
    } else if (BigNumber(inputValue).isLessThan(0.01)) {
      dispatch({
        type: 'INPUT_ERROR',
        errorMsg: 'Cannot deposit less than .01',
      });
      return;
    }

    const signer = library.getSigner();
    dispatch({ type: 'SETUP_TRANSACTION' });
    setIsUnStaking(true);

    const connectedContract = (
      await getContracts([STAKING_CONTRACTS[selectedToken]], chainId, library)
    ).connect(signer);

    const res = await sendTransaction('UNSTAKE', {
      contract: connectedContract,
      functionName: 'withdraw',
      args: [getWei(inputValue, STAKING_CONTRACTS[selectedToken])],
      options: {
        token: LP_TOKENS[selectedToken],
        amount: displayAmount(inputValue),
      },
    }).catch(err => console.error(err.message));

    if (res === 'SUCCESS') {
      updateLptBalance(undefined, true);
      updateStakedBalance(undefined, true);
      dispatch({ type: 'SUCCESSFUL_TRANSACTION' });
    } else {
      dispatch({ type: 'FAILED_TRANSACTION' });
    }

    setIsUnStaking(false);
  };

  const onExit = async () => {
    dispatch({ type: 'SETUP_TRANSACTION' });
    setIsExiting(true);

    const signer = library.getSigner();

    const connectedContract = (
      await getContracts([STAKING_CONTRACTS[selectedToken]], chainId, library)
    ).connect(signer);

    const res = await sendTransaction('EXIT_STAKING', {
      contract: connectedContract,
      functionName: 'exit',
    }).catch(err => console.error(err.message));

    if (res === 'SUCCESS') {
      updateLptBalance(undefined, true);
      updateStakedBalance(undefined, true);
      updateApyBalance(undefined, true);
      updateClaimable(undefined, true);

      dispatch({ type: 'SUCCESSFUL_TRANSACTION' });
    }
    {
      dispatch({ type: 'FAILED_TRANSACTION' });
    }

    setIsExiting(false);
  };

  return (
    <div>
      <NumberForm
        classes={{ numberFormWrap }}
        {...props}
        onSubmit={onSubmit}
        isLoading={isUnstaking}
      >
        <NewButton
          classes={{ button: claimAndExitBtn }}
          color="red"
          onClick={onExit}
          disabled={isFormDisabled || claimable?.isZero()}
          isLoading={isExiting}
        >
          Claim and Exit
        </NewButton>
      </NumberForm>
    </div>
  );
}

export default UnstakeForm;
