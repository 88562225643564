import GovernanceTokenProxyAddresses from '@apy-finance/apy-core/deployed_addresses/GovernanceTokenProxy.json';
import DAI_PoolTokenProxyAddresses from '@apy-finance/apy-core/deployed_addresses/DAI_PoolTokenProxy.json';
import USDC_PoolTokenProxyAddresses from '@apy-finance/apy-core/deployed_addresses/USDC_PoolTokenProxy.json';
import USDT_PoolTokenProxyAddresses from '@apy-finance/apy-core/deployed_addresses/USDT_PoolTokenProxy.json';

import { ChainId } from '../index';
import { Token } from '../../utils/entities/Token';

export const USD = {
  decimals: 8,
};

export const APY = {
  decimals: 18,
  [ChainId.MAINNET]: new Token(
    GovernanceTokenProxyAddresses[ChainId.MAINNET],
    'APY'
  ),
  [ChainId.KOVAN]: new Token(
    GovernanceTokenProxyAddresses[ChainId.KOVAN],
    'APY'
  ),
};

export const aptDAI = {
  decimals: 18,
  [ChainId.MAINNET]: new Token(
    DAI_PoolTokenProxyAddresses[ChainId.MAINNET],
    'APT-DAI'
  ),
  [ChainId.KOVAN]: new Token(
    DAI_PoolTokenProxyAddresses[ChainId.KOVAN],
    'APT-DAI'
  ),
};

export const aptUSDC = {
  decimals: 18,
  [ChainId.MAINNET]: new Token(
    USDC_PoolTokenProxyAddresses[ChainId.MAINNET],
    'APT-USDC'
  ),
  [ChainId.KOVAN]: new Token(
    USDC_PoolTokenProxyAddresses[ChainId.KOVAN],
    'APT-USDC'
  ),
};

export const aptUSDT = {
  decimals: 18,
  [ChainId.MAINNET]: new Token(
    USDT_PoolTokenProxyAddresses[ChainId.MAINNET],
    'APT-USDT'
  ),
  [ChainId.KOVAN]: new Token(
    USDT_PoolTokenProxyAddresses[ChainId.KOVAN],
    'APT-USDT'
  ),
};

export const DAI_DEMO_POOL = {
  decimals: 18,
  [ChainId.MAINNET]: new Token(null, 'DEMO-APT-DAI'),
  [ChainId.KOVAN]: new Token(null, 'DEMO-APT-DAI'),
};

export const USDC_DEMO_POOL = {
  decimals: 18,
  [ChainId.MAINNET]: new Token(null, 'DEMO-APT-USDC'),
  [ChainId.KOVAN]: new Token(null, 'DEMO-APT-USDC'),
};

export const USDT_DEMO_POOL = {
  decimals: 18,
  [ChainId.MAINNET]: new Token(null, 'DEMO-APT-USDT'),
  [ChainId.KOVAN]: new Token(null, 'DEMO-APT-USDT'),
};

export const DAI = {
  coinGeckoKey: 'dai',
  decimals: 18,
  [ChainId.MAINNET]: new Token(
    '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    'DAI'
  ),
  [ChainId.KOVAN]: new Token(
    '0xff795577d9ac8bd7d90ee22b6c1703490b6512fd',
    'DAI'
  ),
};

export const USDC = {
  coinGeckoKey: 'usd-coin',
  decimals: 6,
  [ChainId.MAINNET]: new Token(
    '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    'USDC'
  ),
  [ChainId.KOVAN]: new Token(
    '0xe22da380ee6b445bb8273c81944adeb6e8450422',
    'USDC'
  ),
};

export const USDT = {
  coinGeckoKey: 'tether',
  decimals: 6,
  [ChainId.MAINNET]: new Token(
    '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    'USDT'
  ),
  [ChainId.KOVAN]: new Token(
    '0x13512979ade267ab5100878e2e0f485b568328a4',
    'USDT'
  ),
};

export const WETH = {
  coinGeckoKey: 'ethereum',
  decimals: 18,
  [ChainId.MAINNET]: new Token(
    '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    'WETH'
  ),
  [ChainId.KOVAN]: new Token(
    '0xd0a1e359811322d97991e03f863a0c30c2cf029c',
    'WETH'
  ),
};

// Uniswap Pool Token (APY-ETH UNI-V2)
export const UNIV2 = {
  decimals: 18,
  [ChainId.MAINNET]: new Token(
    '0xF043c39A106db6B58C76995F30Ba35fD211c3b76',
    'UNI-V2'
  ),
};

// Balancer Pool Token (APY-USDC BPT)
export const BPT = {
  decimals: 18,
  [ChainId.MAINNET]: new Token(
    '0xbC8B1f78ff5a0baF9945E145832ad79C494d4CF6',
    'BPT'
  ),
};
