import React from 'react';

import { inputButton, active } from './InputButton.module.scss';

function InputButton(props) {
  const {
    type = 'button',
    classes = {},
    isSelected,
    children,
    onClick,
    value,
    ...otherProps
  } = props;
  return (
    <button
      type={type}
      className={`${inputButton} ${classes.inputButton} ${
        isSelected ? active : undefined
      }`}
      onClick={() => onClick(value)}
      {...otherProps}
    >
      {children}
    </button>
  );
}

export default InputButton;
