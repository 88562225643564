import { Strategy } from '../utils/entities/Strategy';
import { Contract } from '../utils/entities/Contract';
import * as icons from '../assets/defi';
import { ChainId } from '../constants';

import { CURVE_POOL_ABI, CURVE_LIQUIDITY_GAUGE_ABI } from './abis';
import {
  CURVE_GAUGE_CONTROLLER,
  getCurveBaseApy,
  getCrvTokenApy,
  getCurveRewardTokenApy,
} from './utils/curve';

const CURVE_SAAVE_POOL = {
  [ChainId.MAINNET]: new Contract(
    '0xEB16Ae0052ed37f479f7fe63849198Df1765a733',
    CURVE_POOL_ABI
  ),
};

const SAAVE_LIQUIDITY_GAUGE_ADDRESS =
  '0x462253b8F74B72304c145DB0e4Eebd326B22ca39';

const CURVE_SAAVE_LIQUIDITY_GAUGE = {
  [ChainId.MAINNET]: new Contract(
    SAAVE_LIQUIDITY_GAUGE_ADDRESS,
    CURVE_LIQUIDITY_GAUGE_ABI
  ),
};

const curve_saave = new Strategy('Curve sAAVE', {
  protocol: 'curve',
  poolName: 'saave',
  protocolIcon: icons['curve'],
  outputAssets: ['CRV', 'STKAAVE'],
  contracts: {
    pool: CURVE_SAAVE_POOL,
    liquidityGauge: CURVE_SAAVE_LIQUIDITY_GAUGE,
    gaugeController: CURVE_GAUGE_CONTROLLER,
  },
  allocationName: 'curve-saave',
  tokenPricesNeeded: ['curve-dao-token'],
  getApys: (chainId, library) => ({
    baseApy: getCurveBaseApy('saave'),
    CRV: getCrvTokenApy('curve_saave', chainId, library),
    STKAAVE: getCurveRewardTokenApy(SAAVE_LIQUIDITY_GAUGE_ADDRESS),
  }),
  description: 'Add strategy description here...',
});

export default curve_saave;
