import React from 'react';
import ReactDOM from 'react-dom';

import { bannerWrap } from './TourBanner.module.scss';

const TourBanner = ({ children }) => {
  return ReactDOM.createPortal(
    <div className={bannerWrap}>{children}</div>,
    document.querySelector('#tourBanner')
  );
};

export default TourBanner;
