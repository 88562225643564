import { Strategy } from '../utils/entities/Strategy';
import * as icons from '../assets/defi';

const convex_ust_wormhole = new Strategy('Convex UST Wormhole', {
  protocol: 'convex',
  poolName: 'ust-wormhole',
  protocolIcon: icons['convex'],
  outputAssets: {
    gov: 'CVX',
    crv: 'CRV',
  },
  allocationName: 'convex-ust-wormhole',
  tokenPricesNeeded: ['curve-dao-token', 'convex-finance'],
  options: {},
});

export default convex_ust_wormhole;
