import RewardDistributorAddresses from '@apy-finance/apy-core/deployed_addresses/RewardDistributor.json';

import { ChainId } from './index';

/* -------------------------------------- */
/* ------ Liquidity Mining Rewards ------ */
/* -------------------------------------- */

const HOST = 'https://us-central1-continual-grin-289318.cloudfunctions.net';

let serviceEnv;
switch (process.env.REACT_APP_ENV) {
  case 'production':
    serviceEnv = '';
    break;
  case 'staging':
    serviceEnv = '_staging';
    break;
  default:
    serviceEnv = '';
}

const REWARDS_SERVICE = `${HOST}/rewards${serviceEnv}`;
const SIGNATURE_SERVICE = `${HOST}/signatures${serviceEnv}`;

export { REWARDS_SERVICE, SIGNATURE_SERVICE };

/* -------------------------------------- */
/* ---------- Staking Rewards ----------- */
/* -------------------------------------- */
export const LPT_STAKING_ADDRESSES = {
  UNIV2_STAKING: {
    [ChainId.MAINNET]: '0x0310DEE97b42063BbB46d02a674727C13eb79cFD',
    [ChainId.KOVAN]: '0x1d346f3b23ad568608bc301fe9707c5320ce4c64', // Mock Staking Contract
  },
  BPT_STAKING: {
    [ChainId.MAINNET]: '0xFe82ea0Ef14DfdAcd5dB1D49F563497A1a751bA1',
    [ChainId.KOVAN]: '0x1d346f3b23ad568608bc301fe9707c5320ce4c64', // Mock Staking Contract (Same as contract for UNI-V2 Staking on Kovan)
  },
};

export const REWARD_DISTRIBUTOR_ADDRESSES = {
  [ChainId.MAINNET]: RewardDistributorAddresses[ChainId.MAINNET],
  [ChainId.KOVAN]: RewardDistributorAddresses[ChainId.KOVAN],
};
