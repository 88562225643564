import React from 'react';
import { useWeb3React } from '@web3-react/core';
import { NavLink } from 'react-router-dom';
import { useMedia } from 'use-media';

import { useSessionStore, useSliderMenuStore } from '../../../../state/stores';
import { useAccountBalance } from '../../../../hooks';
import { PAGE_LINKS } from '../../../../constants';

import UserPill from '../../info/UserPill';
import IconButton from '../../buttons/IconButton';
import logoutIcon from '../../../../assets/icons/sign-out.svg';
import HamburgerButton from '../../buttons/HamburgerButton';
import {
  navWrap,
  leftNav,
  navLinkWrap,
  activeNavLink,
  rightNav,
} from './Nav.module.scss';

function Nav() {
  const { account, deactivate } = useWeb3React();
  const setSession = useSessionStore(state => state.setSession);
  const isSliderMenuOpen = useSliderMenuStore(state => state.isSliderMenuOpen);
  const setIsSliderMenuOpen = useSliderMenuStore(
    state => state.setIsSliderMenuOpen
  );
  const { balance: apyBalance } = useAccountBalance('APY');

  // Media queries
  const isVwOver1200 = useMedia({ minWidth: '1201px' });

  const disconnect = () => {
    deactivate();
    setSession(false);
  };

  return (
    <nav className={navWrap}>
      <ul className={leftNav}>
        {PAGE_LINKS.map(({ label, path, exact }) => {
          if (label === 'faq') return;
          return (
            <li className={navLinkWrap} key={label}>
              <NavLink to={path} exact={exact} activeClassName={activeNavLink}>
                {label}
              </NavLink>
            </li>
          );
        })}
      </ul>

      <ul className={rightNav}>
        {account && (
          <li>
            <UserPill account={account} balance={apyBalance} />
          </li>
        )}

        {isVwOver1200 && (
          <li>
            <IconButton
              name={'logout'}
              icon={logoutIcon}
              onClick={disconnect}
            />
          </li>
        )}
      </ul>

      {/* Hide past 1200 */}
      {!isVwOver1200 && (
        <HamburgerButton
          isActive={isSliderMenuOpen}
          toggle={() => setIsSliderMenuOpen(!isSliderMenuOpen)}
        />
      )}
    </nav>
  );
}

export default Nav;
