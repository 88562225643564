import React from 'react';
import styled from 'styled-components';
import { useWeb3React } from '@web3-react/core';
import { CaretDown } from 'phosphor-react';

import {
  BOOST_LOCK_OPTIONS,
  SECONDS_PER_WEEK,
  WEEKS_IN_A_YEAR,
} from '../../../../../constants';
import {
  getEpochWithTimestamp,
  displayDate,
  displayDuration,
} from '../../../../../utils/helpers';
import { useLockData } from '../../../../../hooks';
import { breakpoints } from '../../../../styles/breakpoints';

import Dropdown, {
  Menu,
  MenuItem,
} from '../../../../apy/reusableComponents/Dropdown';
import { Panel } from '../../../../styles/Panel.styled';
import { Inline } from '../../../../styles/primitives';

function SelectLockEnd({ isDisabled, selectedEpoch, onChange }) {
  const { account } = useWeb3React();

  const { lockData } = useLockData(account);

  const getEpochOptions = () => {
    // Should return an array of epochs
    const lockEnd = lockData.data?.end;
    const currentTime = Math.floor(Date.now() / 1000);

    let epochOptions = [];
    if (lockEnd > 0 && currentTime < lockEnd) {
      // Epoch can't be less than or equal to lockEnd OR greater than maxEpoch
      const maxEpoch = getEpochWithTimestamp(
        SECONDS_PER_WEEK * WEEKS_IN_A_YEAR * 4 + currentTime
      );

      BOOST_LOCK_OPTIONS.forEach(option => {
        const timestamp = currentTime + SECONDS_PER_WEEK * option.weeks;
        const epoch = getEpochWithTimestamp(timestamp);

        if (epoch <= lockEnd || epoch > maxEpoch) {
          return;
        }

        epochOptions.push(epoch);
      });
    } else {
      // Use currentTime if lockEnd is undefined, 0 or greater than or equal currentTime

      epochOptions = BOOST_LOCK_OPTIONS.map(option => {
        const timestamp = currentTime + SECONDS_PER_WEEK * option.weeks;
        const epoch = getEpochWithTimestamp(timestamp);

        return epoch;
      });
    }

    return epochOptions;
  };

  return (
    <Inline gap={2} justify={'end'} align={'center'}>
      <SelectLabel disabled={isDisabled} htmlFor="duration">
        Lock until:
      </SelectLabel>

      <Dropdown
        disabled={isDisabled}
        name="duration"
        toggle={props => (
          <SelectButton {...props}>
            <div
              style={{
                padding: '0.5rem 0rem .5rem .75rem',
              }}
            >
              {selectedEpoch
                ? `${displayDate(selectedEpoch)} | ${displayDuration(
                    selectedEpoch
                  )}`
                : 'Max Duration Locked'}
            </div>
            <div
              style={{
                backgroundColor: '#202020',
                borderRadius: '0 5px 5px 0',
                width: 30,
                alignSelf: 'stretch',
                display: 'grid',
                placeItems: 'center',
              }}
            >
              <CaretDown size={16} />
            </div>
          </SelectButton>
        )}
      >
        <Menu
          as={Panel}
          top={'2.75rem'}
          width={'200px'}
          style={{
            border: 'none',
            borderRadius: '3px',
            background: '#333',
            padding: '.5rem 0',
          }}
        >
          {getEpochOptions().map((epoch, i) => (
            <MenuItem key={i} onClick={() => onChange(epoch)}>
              {`${displayDate(epoch)} | ${displayDuration(epoch)}`}
            </MenuItem>
          ))}
        </Menu>
      </Dropdown>
    </Inline>
  );
}

const SelectLabel = styled.label`
  display: 'block';

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.3;
  `}
`;

const SelectButton = styled.button`
  display: flex;
  justify-content: space-between;
  border: 2px solid #444;
  border-radius: 7px;
  background-color: #333;
  box-shadow: rgba(0, 0, 0, 0.452) 0px 1px 2px 0px,
    rgba(0, 0, 0, 0.247) 0px 2px 6px 2px;
  width: 270px;
  font-size: 15px;
  font-family: archialight;
  color: hsl(0, 0%, 60%);
  opacity: 1; /* Firefox */
  cursor: pointer;

  @media ${breakpoints.tablet} {
    font-size: 1rem;
  }

  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
    opacity: .3;
  `}
`;

export default SelectLockEnd;
