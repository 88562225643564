import React, { useContext, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import useSWR from 'swr';

import { PageTourContext } from '../../../../../../state/contexts';
import { useRewardApy, useAccountBalance } from '../../../../../../hooks';
import useTransaction from '../../../../../../hooks/useTransaction';

import {
  displayAmount,
  fromWei,
  getContracts,
  getRequiredDecimals,
} from '../../../../../../utils/helpers';

import ClaimApy from '../../../../info/ClaimApy';
import NewTooltip from '../../../../controls/NewTooltip';
import { sectionHeaderTitle } from './ClaimStakingRewards.module.scss';
import PageTourBox from '../../../../info/PageTourBox';

function ClaimStakingRewards({ poolToken }) {
  // Page Tour
  const {
    tourSteps: { stakingSteps },
    isTourStepActive,
  } = useContext(PageTourContext);
  const [apyPanelElement, setApyPanelElement] = useState();
  const [claimablePanelElement, setClaimablePanelElement] = useState();

  const { account, chainId, library } = useWeb3React();
  const [isClaimPending, setIsClaimPending] = useState(false);

  const { updateBalance: updateApyBalance } = useAccountBalance('APY');

  const { data: annualYield } = useRewardApy(poolToken);
  const { data: claimable, mutate: updateClaimable } = useSWR(
    chainId && account && [`${poolToken}_STAKING`, chainId, 'earned', account]
  );

  const [sendTransaction] = useTransaction();

  const showClaimable = claimable => {
    if (claimable) {
      claimable = fromWei(claimable, 'APY');

      const decimals = getRequiredDecimals(claimable);
      return displayAmount(claimable, { decimals });
    }
  };

  const onClaim = async () => {
    const signer = library.getSigner(0);
    setIsClaimPending(true);

    const connectedContract = (
      await getContracts([`${poolToken}_STAKING`], chainId, library)
    ).connect(signer);

    const res = await sendTransaction('STAKING_CLAIM', {
      contract: connectedContract,
      functionName: 'getReward',
    }).catch(err => console.error(err.message));

    if (res === 'SUCCESS') {
      updateApyBalance(undefined, true);
      updateClaimable(undefined, true);
    }

    setIsClaimPending(false);
  };

  return (
    <ClaimApy
      claimType={'staking'}
      classes={{ sectionHeaderTitle }}
      title={'Claim APY Tokens'}
      // tooltip={
      //   <NewTooltip placement="right" body={'Insert tooltip info here...'} />
      // }
      metrics={[
        {
          title: 'Annual Yield',
          // tooltip: (
          //   <NewTooltip
          //     placement="right"
          //     body={'Insert tooltip info here...'}
          //   />
          // ),
          isLoading: !annualYield,
          value: displayAmount(annualYield, { format: 'percent' }),
          ref: setApyPanelElement,
          isTourElementActive: isTourStepActive(stakingSteps['apy']),
          pageTourBox: (
            <PageTourBox
              steps={stakingSteps['apy']}
              isActive={isTourStepActive(stakingSteps['apy'])}
              refElement={apyPanelElement}
            />
          ),
        },
        {
          title: 'Claimable APY',
          // tooltip: (
          //   <NewTooltip
          //     placement="right"
          //     body={'Insert tooltip info here...'}
          //   />
          // ),
          isLoading: !claimable,
          value: showClaimable(claimable),
          ref: setClaimablePanelElement,
          isTourElementActive: isTourStepActive(stakingSteps['claimable']),
          pageTourBox: (
            <PageTourBox
              steps={stakingSteps['claimable']}
              isActive={isTourStepActive(stakingSteps['claimable'])}
              refElement={claimablePanelElement}
            />
          ),
        },
      ]}
      claimable={claimable}
      onClaim={onClaim}
      isClaimPending={isClaimPending}
    />
  );
}

export default ClaimStakingRewards;
