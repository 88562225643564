import React from 'react';
import { useMedia } from 'use-media';

import { useAppConnection } from '../../../hooks';
import { motion } from 'framer-motion';

import Button from '../../apy/buttons/Button';
// import MetamaskNotify from './MetamaskNotify';
import InstallMetaMaskBtn from '../../apy/buttons/InstallMetaMaskBtn';
import Header from '../../apy/layout/Header';
import {
  mainContent,
  container,
  btnGroup,
  textBox,
  docsIcon,
  iconAlign,
  outer,
} from './Landing.module.scss';

const InjectedMetaMask = window?.ethereum?.isMetaMask;

function Landing() {
  const { protectedConnect } = useAppConnection();

  // Media queries
  const isVwOver500 = useMedia({ minWidth: '501px' });

  return (
    <>
      <Header />
      <main className={mainContent}>
        <div className={outer}>
          <motion.div
            className={container}
            data-augmented-ui="tl-round tr-round br-round bl-round border"
          >
            <div className={textBox}>
              <h1>Diversified Convex Portfolio</h1>
              <h2>
                Get exposure to the highest quality yield sources in the
                Curve/Convex ecosystem.
              </h2>
            </div>
            <div className={btnGroup}>
              {InjectedMetaMask ? (
                <Button
                  onClick={protectedConnect}
                  color="primary"
                  size={isVwOver500 ? 'landing' : 'small'}
                >
                  <p>Get Started</p>
                </Button>
              ) : (
                <InstallMetaMaskBtn />
              )}
              <Button color="primary" size={isVwOver500 ? 'landing' : 'small'}>
                <div className={iconAlign}>
                  <div className={docsIcon} />
                  <a
                    href="https://docs.apy.finance/whitepaper/litepaper"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Docs
                  </a>
                </div>
              </Button>
            </div>
          </motion.div>
        </div>
      </main>
    </>
  );
}

export default Landing;
