import { Strategy } from '../utils/entities/Strategy';
import { Contract } from '../utils/entities/Contract';
import * as icons from '../assets/defi';
import { ChainId } from '../constants';

import { CURVE_POOL_ABI, CURVE_LIQUIDITY_GAUGE_ABI } from './abis';
import {
  CURVE_GAUGE_CONTROLLER,
  getCurveBaseApy,
  getCrvTokenApy,
} from './utils/curve';

const CURVE_BUSDV2_POOL = {
  [ChainId.MAINNET]: new Contract(
    '0x4807862AA8b2bF68830e4C8dc86D0e9A998e085a',
    CURVE_POOL_ABI
  ),
};

const CURVE_BUSDV2_LIQUIDITY_GAUGE = {
  [ChainId.MAINNET]: new Contract(
    '0xd4B22fEdcA85E684919955061fDf353b9d38389b',
    CURVE_LIQUIDITY_GAUGE_ABI
  ),
};

const curve_busdv2 = new Strategy('Curve BUSDv2', {
  protocol: 'curve',
  poolName: 'BUSDv2',
  protocolIcon: icons['curve'],
  outputAssets: ['CRV'],
  contracts: {
    pool: CURVE_BUSDV2_POOL,
    liquidityGauge: CURVE_BUSDV2_LIQUIDITY_GAUGE,
    gaugeController: CURVE_GAUGE_CONTROLLER,
  },
  allocationName: '',
  getApys: (chainId, library) => ({
    baseApy: getCurveBaseApy('BUSDv2'),
    CRV: getCrvTokenApy('curve_busdv2', chainId, library),
  }),
  description: 'Add strategy description here...',
});

export default curve_busdv2;
