import { Contract } from '../utils/entities/Contract';
import { Strategy } from '../utils/entities/Strategy';
import * as icons from '../assets/defi';
import { ChainId } from '../constants';

import { CURVE_POOL_ABI, CURVE_LIQUIDITY_GAUGE_ABI } from './abis';
import {
  CURVE_GAUGE_CONTROLLER,
  getCurveBaseApy,
  getCrvTokenApy,
} from './utils/curve';

const CURVE_DUSD_POOL = {
  [ChainId.MAINNET]: new Contract(
    '0x8038C01A0390a8c547446a0b2c18fc9aEFEcc10c',
    CURVE_POOL_ABI
  ),
};

const CURVE_DUSD_LIQUIDITY_GAUGE = {
  [ChainId.MAINNET]: new Contract(
    '0xAEA6c312f4b3E04D752946d329693F7293bC2e6D',
    CURVE_LIQUIDITY_GAUGE_ABI
  ),
};

const curve_dusd = new Strategy('Curve DUSD', {
  protocol: 'curve',
  poolName: 'DUSD',
  protocolIcon: icons['curve'],
  outputAssets: ['CRV'],
  contracts: {
    pool: CURVE_DUSD_POOL,
    liquidityGauge: CURVE_DUSD_LIQUIDITY_GAUGE,
    gaugeController: CURVE_GAUGE_CONTROLLER,
  },
  allocationName: '',
  getApys: (chainId, library) => ({
    baseApy: getCurveBaseApy('dusd'),
    CRV: getCrvTokenApy('curve_dusd', chainId, library),
  }),
  description: 'Add strategy description here...',
});

export default curve_dusd;
