import { useContext } from 'react';
import axios from 'axios';

import { TrxContext } from '../state/contexts';
import { SIGNATURE_SERVICE } from '../constants/claims';
import { trxConfig } from '../constants/transactions';
import { APIError, TransactionError } from '../utils/errors';

function useTransaction() {
  const { showTrxModal } = useContext(TrxContext);

  async function sendTransaction(
    trxType,
    { contract, functionName, args = [], options = {} }
  ) {
    const verify = async () => {
      showTrxModal('VERIFYING', {
        message: 'Verifying APY amount',
      });

      const endpoint = `${SIGNATURE_SERVICE}/generate/${options.chainId}/${options.account}`;
      const res = await axios.get(endpoint).catch(err => {
        throw new APIError('apy.finance', err.message, endpoint);
      });

      const data = res.data.data;
      const signature = res.data.signature;
      const rewards = parseFloat(data[2]);

      if (rewards === 0) {
        throw new TransactionError('No APY rewards', functionName);
      } else {
        return [data, signature.v, signature.r, signature.s];
      }
    };

    const executeTransaction = async trxArgs => {
      const trx = await contract.functions[functionName](...trxArgs);
      return trx;
    };

    const send = async () => {
      // Use signature service if needed
      let trxArgs = trxConfig[trxType].verificationNeeded
        ? await verify().catch(err => {
            showTrxModal('FAILED', {
              message: `Verification Failed`,
            });

            throw err;
          })
        : args;

      // Execute transaction
      try {
        showTrxModal('PROCESSING', { trxType, trxArgs, ...options });

        const trx = await executeTransaction(trxArgs);
        await trx.wait();

        trxConfig[trxType].confirmationsRequired
          ? showTrxModal('SUCCESS_PENDING', {
              trxType,
              trxArgs,
              hash: trx.hash,
              ...options,
            })
          : trxType === 'DEPOSIT'
          ? showTrxModal('DEPOSIT_SUCCESS', {
              trxType,
              trxArgs,
              hash: trx.hash,
              ...options,
            })
          : showTrxModal('SUCCESS', {
              trxType,
              trxArgs,
              hash: trx.hash,
              ...options,
            });
        return 'SUCCESS';
      } catch (err) {
        if (err.code === 4001) {
          showTrxModal('FAILED', {
            message: 'You rejected the request',
          });

          throw new TransactionError(
            'User rejected the transaction request via wallet',
            functionName
          );
        } else {
          showTrxModal('FAILED', { trxType, ...options });

          throw new TransactionError(err.message, functionName);
        }
      }
    };

    return send();
  }

  return [sendTransaction];
}

export default useTransaction;
