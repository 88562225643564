import { useMemo } from 'react';
import { APY_BOOST_SERVICE } from '../constants';
import { fromWei } from '../utils/helpers';
import { useApi } from './index';
import { usePortfolioValue } from './usePortfolioValue';

export function useRewardsPerDay(account) {
  // Fetch APY per day per dollar
  const { res } = useApi('apy.finance', `${APY_BOOST_SERVICE}/${account}`);
  const { totalAptValue } = usePortfolioValue(account);

  const rewardsPerDay = useMemo(() => {
    let data;

    if (res.data && totalAptValue.data) {
      const rewardPerDollar = fromWei(res.data.dailyRewardPerDollar);
      data = rewardPerDollar.times(totalAptValue.data);
    }

    return {
      data: data,
      isLoading: !data && !res.error,
      error: res.error,
    };
  }, [res, totalAptValue]);

  return { rewardsPerDay };
}
