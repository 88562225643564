import React from 'react';

// import { useTourControls } from '../../../hooks';

import StakingPage from '../../apy/templates/StakingPage';
// import { pageTourToggle } from './Bpt.module.scss';

function Bpt() {
  // const { PageTourToggle } = useTourControls('staking');

  return (
    <StakingPage
      protocol={'Balancer'}
      poolToken={'BPT'}
      underlyer={'USDC'}
      poolUrl={
        'https://pools.balancer.exchange/#/pool/0xbc8b1f78ff5a0baf9945e145832ad79c494d4cf6/'
      }
      // pageTourToggle={<PageTourToggle classes={[pageTourToggle]} />}
    />
  );
}

export default Bpt;
