import React from 'react';

export const faq = [
  {
    question: 'What is boost-locking?',
    answer:
      'Boost-locking allows $APY token holders to lock owned $APY for set durations to earn boosted yield.',
  },
  {
    question: 'How is my boosted yield determined?',
    answer: (
      <>
        Your amount of boosted yield earned is determined by your lock-score
        percentage relative to the sum of all users’ lock-scores.
        <br />
        <br />
        Use a copy of this{' '}
        <a
          href={
            'https://docs.google.com/spreadsheets/d/1rXxojH-Su4_4jAAHa4egNuxsl8lwbbz38rITnJTrxY4'
          }
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: '#8e40dd' }}
        >
          calculator
        </a>{' '}
        to dynamically calculate boosted yield (created by community member{' '}
        <span style={{ color: '#8e40dd' }}>@awinter</span>).
      </>
    ),
  },
  {
    question: 'Can I earn rewards for boost-locking if I do not yield farm?',
    answer:
      'No. At the moment, boost-locking only impacts liquidity mining rewards. In the future, there may be additional incentives provided to those who boost-lock.',
  },
  {
    question: 'Does boosted yield apply to stablecoin and $APY yield earned?',
    answer:
      'No, boosted yield only applies to $APY rewards earned from yield farming.',
  },
  {
    question: 'How long can I boost-lock for?',
    answer:
      'Users can boost-lock any amount of $APY for up to 4 years in weekly increments (208 weeks).',
  },
  {
    question: 'Can I withdraw my locked $APY before it unlocks?',
    answer: 'No. You can not withdraw $APY before it unlocks.',
  },
  {
    question: 'When do boost-locked tokens unlock?',
    answer:
      '$APY tokens unlock on the date selected by the user upon locking. Unlocks have a weekly epoch of Thursday. I.E. tokens locked will always be unlocked on the Thursday nearest to the unlock date (rounded down).',
  },
  {
    question: 'Will my lock-score change over time?',
    answer:
      'Yes, your lock-score decays as the unlock time approaches. Users will be able to increase locked amount and extend unlock time before the lock expires.',
  },
  {
    question:
      'Can I boost-lock $APY tokens that are earned but not yet claimable (i.e. earned, but not yet vested $APY?)',
    answer:
      'No. Currently, you can only lock owned $APY that is already in your wallet.',
  },
  {
    question:
      'If I already have stablecoins deposited in the platform, do I need to deposit more in order to begin earning boosted yield?',
    answer:
      'No. Boost will apply to already existing deposits but you must lock $APY.',
  },
  {
    question: "Why hasn't my unlocked $APY returned to my wallet?",
    answer:
      'Once your $APY unlocks, you need to manually withdraw unlocked $APY from the boost-lock page.',
  },
  {
    question: ' My lock duration expired. Why can I no longer extend my lock?',
    answer:
      'Users can only extend the unlock time of locked $APY before it unlocks. Once it unlocks, users must withdraw before creating a new boost-lock.',
  },
  {
    question: 'What is boost?',
    answer:
      'Boost shows a user’s position in a possible range of lock-scores given your account value and blAPY balance. The minimum boost is 1 and the maximum is 2.5',
  },
  {
    question: 'What is blAPY?',
    answer: (
      <>
        A tokenized measure of user commitment. You get more blAPY for a longer
        time-lock or more $APY locked. blAPY is non-transferable but is used in
        reward calculations to issue rewards every week.
        <br />
        <br />
        blAPY balance = locked $APY amount * (time to lock expiry / max lock
        duration)
        <br />
        <br />
        Examples:
        <br />
        100 $APY deposited for 1 year → 100 * (1 year / 4 years) = 25 blAPY
        <br />
        <br />
        200 $APY deposited for 6 months → 200 * (0.5 year / 4 years) = 25 blAPY
        <br />
        <br />
        100 $APY deposited for 4 years → 100 * (4 years / 4 years) = 100 blAPY
        <br />
        <br />
        Note: A smaller locked amount can be compensated for by a larger lock
        duration and a smaller lock duration can be compensated for by a larger
        locked amount.
      </>
    ),
  },
  {
    question: 'What is lock-score?',
    answer: (
      <>
        A user’s lock-score is a weighted average of a user’s deposit value and
        portion of TVL as determined by blAPY balance and total supply. The
        score is capped to benefit smaller holders.
        <br />
        <br />
        Uncapped score = 0.4 * deposit value + 0.6 * TVL * (blAPY balance /
        blAPY total supply)
        <br />
        <br />
        Lock-score = min(uncapped score, deposit value)
        <br />
        <br />
        Note: The minimal possible score is 40% of deposit value. Capping the
        score at deposit value means the score can only be at most 2.5x the
        minimum score.
      </>
    ),
  },
  {
    question: 'Where can I learn more about boost-locking?',
    answer: (
      <>
        You can learn more about boost-locking, including insight into the
        escrow contract and specific formulas used to calculate boost-locking in
        our{' '}
        <a
          href="https://docs.apy.finance/token-utility/boost-locking"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: '#8e40dd' }}
        >
          boost-locking spec here.
        </a>
      </>
    ),
  },
];
