import React from 'react';

import Box from '../../containers/Box';
import { inputField, inputLabel } from './PercentageInput.module.scss';

function PercentageInput({ classes = {}, value, onInputChange }) {
  const onChange = value => {
    if (value < 0) {
      value = '0';
    } else if (value > 100) {
      value = '100';
    } else {
      value = value.toString();
    }

    onInputChange(value);
  };

  const onBlur = evt => {
    if (evt.target.value === '') {
      onChange('0');
    }
  };

  const blockDecimals = evt => {
    let charCode = evt.which ?? evt.keyCode;
    if (
      charCode === 101 ||
      charCode === 43 ||
      charCode === 45 ||
      charCode === 46
    ) {
      evt.preventDefault();
    }
  };

  return (
    <>
      <Box classes={classes.boxStyles}>
        <input
          id={'percentInput'}
          className={`${inputField} ${classes.inputField}`}
          type="number"
          pattern="/[^0-9]/"
          min="0"
          max="100"
          step="1"
          placeholder={'—'}
          onKeyPress={blockDecimals}
          onBlur={onBlur}
          value={value || ''}
          onChange={evt => onChange(evt.target.value)}
        />
        <label
          htmlFor={'percentInput'}
          className={`${inputLabel} ${classes.inputLabel}`}
        >
          percent
        </label>
      </Box>
    </>
  );
}

export default PercentageInput;
