import React, { forwardRef } from 'react';

import Panel from '../Panel';
import { panelContainer } from './MultiRowPanel.module.scss';

const MultiRowPanel = forwardRef(
  ({ classes = {}, children, isTourElementActive, pageTourBox }, ref) => {
    const panelClasses = {
      panelContainer,
      ...classes,
    };

    return (
      <Panel
        classes={panelClasses}
        ref={ref}
        isTourElementActive={isTourElementActive}
        pageTourBox={pageTourBox}
      >
        {children}
      </Panel>
    );
  }
);

export default MultiRowPanel;
