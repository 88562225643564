import { useState, useEffect, useMemo } from 'react';
import useEtherSWR from 'ether-swr';
import { useWeb3React } from '@web3-react/core';

import { ChainId } from '../constants';
import * as Contracts from '../constants/contracts';
import { bytes32 } from '../utils/helpers';
import { ContractKeyError, ChainIdError } from '../utils/errors';

const REGISTRY_ABIS = [
  [
    Contracts['REGISTRY'][ChainId.MAINNET].address,
    Contracts['REGISTRY'][ChainId.MAINNET].abi,
  ],
  [
    Contracts['REGISTRY'][ChainId.KOVAN].address,
    Contracts['REGISTRY'][ChainId.KOVAN].abi,
  ],
];

export function useAddressAndABI(contractKey) {
  const { chainId } = useWeb3React();
  const [contractAddress, setContractAddress] = useState(null);

  const [useRegistry, setUseRegistry] = useState(false);

  useEffect(() => {
    if (!chainId || !contractKey) return;

    try {
      if (!Contracts[contractKey]) {
        throw new ContractKeyError(
          `Could not find contract in app's contract list`,
          contractKey
        );
      }

      if (!Contracts[contractKey][chainId]) {
        throw new ChainIdError(
          `App does not support contract (${contractKey}) on this chain`,
          chainId
        );
      }

      if (Contracts[contractKey][chainId].address) {
        setContractAddress(Contracts[contractKey][chainId].address);
      } else {
        setUseRegistry(true);
      }
    } catch (err) {
      if (err instanceof ContractKeyError) {
        console.error('Contract Not Supported -', err.message);
      } else if (err instanceof ChainIdError) {
        console.error('Chain ID Not Supported -', err.message);
      } else {
        throw err;
      }
    }
  }, [chainId, contractKey]);

  const FUNC_NAMES = {
    TVL_MGR: 'tvlManagerAddress',
    LP_ACCOUNT: 'lpAccountAddress',
    aptDAI: 'daiPoolAddress',
    aptUSDC: 'usdcPoolAddress',
    aptUSDT: 'usdtPoolAddress',
    ORACLE_ADAPTER: 'oracleAdapterAddress',
  };

  const OTHER_IDS = {
    DAI_DEMO_POOL: 'daiDemoPool',
    USDC_DEMO_POOL: 'usdcDemoPool',
    USDT_DEMO_POOL: 'usdtDemoPool',
  };

  const { data: addressFromRegistry } = useEtherSWR(
    chainId && useRegistry && FUNC_NAMES[contractKey]
      ? [Contracts['REGISTRY'][chainId].address, FUNC_NAMES[contractKey]]
      : chainId && useRegistry && OTHER_IDS[contractKey]
      ? [
          Contracts['REGISTRY'][chainId].address,
          'getAddress',
          bytes32(OTHER_IDS[contractKey]),
        ]
      : [],
    { ABIs: new Map(REGISTRY_ABIS) }
  );

  const addressAndABI = useMemo(() => {
    const abi = Contracts[contractKey][chainId].abi;

    return { address: addressFromRegistry ?? contractAddress, abi: abi };
  }, [chainId, contractAddress, addressFromRegistry]);

  return addressAndABI;
}
