import { useState, useEffect, useMemo } from 'react';
import { useWeb3React } from '@web3-react/core';

import { usePlatformStore } from '../state/stores';
import { useCoinGeckoPrices, useActiveWalletCheck } from './index';

import {
  getContracts,
  calcApyFinanceApy,
  calcStakingApy,
} from '../utils/helpers';

export function useRewardApy(token) {
  const { active, chainId, library } = useWeb3React();
  const platformTvl = usePlatformStore(state => state.tvl);
  const { isWalletActive } = useActiveWalletCheck(active);
  const [rewardApy, setRewardApy] = useState(null);
  const [error, setError] = useState(null);

  const { coinPrices } = useCoinGeckoPrices();

  useEffect(() => {
    if (coinPrices.isLoading) return;

    if (coinPrices.error) {
      const coinGeckoError = new Error(
        `COINGECKO_ERROR: Can't calculate reward APY without price(s) from CoinGecko`
      );
      console.error(coinGeckoError.message);
      setError(coinGeckoError);
      return;
    }

    const apyPrice = coinPrices.data['apy-finance'];
    const ethPrice = coinPrices.data['ethereum'];
    const usdcPrice = coinPrices.data['usd-coin'];

    if (!isWalletActive) {
      const providerError = new Error(
        `PROVIDER_ERROR: Can't fetch reward apy for ${token} without a connected provider. Make sure your wallet is not disconnected.`
      );
      setError(providerError);
      return;
    }

    const getRewardApy = async () => {
      let Contracts;
      let apy;
      switch (token) {
        case 'APT':
          try {
            apy = calcApyFinanceApy(apyPrice, platformTvl);
            setRewardApy(apy);
            break;
          } catch (err) {
            console.error(err.message);
            setError(err);
          }

        case 'UNIV2':
          try {
            Contracts = await getContracts(
              ['APY', 'UNIV2', 'UNIV2_STAKING', 'WETH'],
              chainId,
              library
            );

            if (Contracts) {
              apy = calcStakingApy(
                'UNIV2',
                Contracts['APY'],
                apyPrice,
                Contracts['UNIV2'],
                Contracts['UNIV2_STAKING'],
                { contract: Contracts['WETH'], symbol: 'ETH', price: ethPrice }
              ).then(res => setRewardApy(res));
            }

            break;
          } catch (err) {
            console.error(err.message);
            setError(err);
          }

        case 'BPT':
          try {
            Contracts = await getContracts(
              ['APY', 'BPT', 'BPT_STAKING', 'USDC'],
              chainId,
              library
            );

            if (Contracts) {
              apy = calcStakingApy(
                'BPT',
                Contracts['APY'],
                apyPrice,
                Contracts['BPT'],
                Contracts['BPT_STAKING'],
                {
                  contract: Contracts['USDC'],
                  symbol: 'USDC',
                  price: usdcPrice,
                }
              ).then(res => setRewardApy(res));
            }

            break;
          } catch (err) {
            console.error(err.message);
            setError(err);
          }

        default:
          break;
      }
    };

    getRewardApy();
  }, [isWalletActive, chainId, library, token, platformTvl, coinPrices]);

  const apy = useMemo(() => {
    return {
      data: rewardApy,
      isLoading: !error && !rewardApy,
      error,
    };
  }, [rewardApy, error]);

  return apy;
}
