import React, { createContext, useReducer, useCallback, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'ADD_NOTIFICATION':
        return [...state, action.payload];
      case 'REMOVE_BY_ID':
        return state.filter(notification => notification.id !== action.payload);
      case 'REMOVE_BY_TITLE':
        return state.filter(
          notification => notification.title !== action.payload
        );
      case 'UPDATE_BY_TITLE':
        let newStateCopy = [...state];
        const { payload } = action;
        const idx = newStateCopy.findIndex(
          notification => notification.title === payload.title
        );

        if (newStateCopy[idx]) {
          newStateCopy[idx].message = payload.message;
        } else {
          const newNotification = {
            id: uuidv4(),
            type: payload.type,
            title: payload.title,
            message: payload.message,
          };
          newStateCopy = [...state, newNotification];
        }

        return newStateCopy;

      case 'CLEAR_LIST':
        return [];
      default:
        return state;
    }
  }, []);

  const uniqueToasts = useRef([]);
  const showToast = useCallback(notification => {
    if (notification.unique) {
      if (!uniqueToasts.current.includes(notification.title)) {
        const newUniqueToasts = [...uniqueToasts.current, notification.title];
        uniqueToasts.current = newUniqueToasts;

        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: {
            id: uuidv4(),
            type: notification.type,
            title: notification.title,
            message: notification.message,
          },
        });
      }
    } else {
      dispatch({
        type: 'ADD_NOTIFICATION',
        payload: {
          id: uuidv4(),
          type: notification.type,
          title: notification.title,
          message: notification.message,
        },
      });
    }
  }, []);

  const removeToastById = id => {
    dispatch({
      type: 'REMOVE_BY_ID',
      payload: id,
    });
  };

  const removeToastByTitle = useCallback(title => {
    if (uniqueToasts.current.includes(title)) {
      const newUniqueToasts = uniqueToasts.current.filter(
        title => title !== title
      );
      uniqueToasts.current = newUniqueToasts;
    }

    dispatch({
      type: 'REMOVE_BY_TITLE',
      payload: title,
    });
  }, []);

  const updateToastByTitle = useCallback(({ type, title, message }) => {
    dispatch({
      type: 'UPDATE_BY_TITLE',
      payload: {
        type,
        title,
        message,
      },
    });
  }, []);

  const clearList = useCallback(() => {
    dispatch({
      type: 'CLEAR_LIST',
    });
  }, []);

  return (
    <ToastContext.Provider
      value={{
        state,
        showToast,
        removeToastById,
        removeToastByTitle,
        updateToastByTitle,
        clearList,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};
