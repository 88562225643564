import { useState, useEffect, useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';

import { useSessionStore } from '../state/stores';

export function useEagerConnect(injectedConnector) {
  const { activate, active } = useWeb3React();
  const isSessionActive = useSessionStore(state => state.isSessionActive);
  const setSession = useSessionStore(
    useCallback(state => state.setSession, [])
  );

  const [tried, setTried] = useState(false);

  useEffect(() => {
    if (!injectedConnector) {
      return;
    }

    injectedConnector.isAuthorized().then(isAuthorized => {
      if (isAuthorized && isSessionActive) {
        activate(injectedConnector, undefined, true).catch(() => {
          setTried(true);
        });
      } else {
        // setSession(false);
        setTried(true);
      }
    });
  }, [activate, setSession, injectedConnector, isSessionActive]); // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && active) {
      setTried(true);
    }
  }, [tried, active]);

  return tried;
}
