import React from 'react';
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import BigNumber from 'bignumber.js';

import * as Contracts from '../../../../../constants/contracts';
import { displayAmount, getWei, fromWei } from '../../../../../utils/helpers';
import { useAccountBalance } from '../../../../../hooks';
import useTransaction from '../../../../../hooks/useTransaction';

import NumberInput from '../../../../apy/reusableComponents/NumberInput';
import Button from '../../../../apy/reusableComponents/Button';
import { PadBox, Stack } from '../../../../styles/primitives';

function IncreaseLockForm({
  updateBlApyData,
  blApyBalance,
  updateApproval,
  isDataLoading,
  allowance,
  isApproved,
  formState: { inputAmount, isTrxPending },
  formDispatch,
  updateLockData,
}) {
  const { library, chainId } = useWeb3React();

  const { balance: apyBalance, updateBalance } = useAccountBalance('APY');

  const [sendTransaction] = useTransaction();

  const onSubmit = async evt => {
    evt.preventDefault();

    // Input Validation
    if (isApproved.data) {
      // Prohibit empty input and 0
      if (!inputAmount || BigNumber(inputAmount).isLessThanOrEqualTo(0)) {
        formDispatch({ type: 'INPUT_ERROR', errorMsg: 'Enter an amount' });
        return;

        // Prohibit input greater than balance
      } else if (
        apyBalance.data &&
        fromWei(apyBalance.data, 'APY').lt(inputAmount)
      ) {
        formDispatch({
          type: 'INPUT_ERROR',
          errorMsg: 'Input exceeds balance',
        });
        return;
      }
    }

    const signer = library.getSigner();
    let Contract;

    if (isApproved.data === false) {
      const apyAddress = Contracts['APY'][chainId]?.address;
      const apyAbi = Contracts['APY'][chainId]?.abi;

      Contract = new ethers.Contract(apyAddress, apyAbi, signer);

      const spender = Contracts['BL_APY'][chainId]?.address;

      formDispatch({ type: 'SETUP_TRX' });

      sendTransaction('APPROVE', {
        contract: Contract,
        functionName: 'approve',
        args: [spender, getWei(Number.MAX_SAFE_INTEGER.toString(), 'APY')],
        options: { token: 'APY' },
      })
        .then(() => {
          updateApproval(undefined, true);

          formDispatch({ type: 'SUCCESSFUL_TRX' });
        })
        .catch(err => {
          console.error(err.message);
          formDispatch({ type: 'FAILED_TRX' });
        });
    } else {
      const blApyAddress = Contracts['BL_APY'][chainId]?.address;
      const blApyAbi = Contracts['BL_APY'][chainId]?.abi;
      Contract = new ethers.Contract(blApyAddress, blApyAbi, signer);

      const addtionalApy = getWei(inputAmount, 'APY');

      formDispatch({ type: 'SETUP_TRX' });

      sendTransaction('INCREASE_LOCK_AMOUNT', {
        contract: Contract,
        functionName: 'increase_amount',
        args: [addtionalApy],
        options: { amount: displayAmount(inputAmount) },
      })
        .then(() => {
          updateBalance(undefined, true);
          updateLockData(undefined, true);
          updateBlApyData(undefined, true);

          formDispatch({ type: 'SUCCESSFUL_TRX' });
        })
        .catch(err => {
          console.error(err.message);
          formDispatch({ type: 'FAILED_TRX' });
        });
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <Stack as={PadBox} gap={6} padding={[6]}>
        {/* Input */}
        <NumberInput
          isDisabled={
            isDataLoading ||
            isTrxPending ||
            blApyBalance.data?.lte(0) ||
            allowance.data?.isZero()
          }
          balance={apyBalance}
          inputAmount={inputAmount}
          onChange={newInput =>
            formDispatch({ type: 'INPUT_CHANGE', newInput })
          }
        />

        <Button
          isDisabled={
            isDataLoading || isTrxPending || blApyBalance.data?.lte(0)
          }
          isLoading={isTrxPending}
          label={
            blApyBalance.data?.gt(0) && isApproved.data === false
              ? 'Enable APY'
              : 'Increase Lock Amount'
          }
          type={'submit'}
          color={
            blApyBalance.data?.gt(0) && isApproved.data === false
              ? 'blue'
              : 'green'
          }
        />
      </Stack>
    </form>
  );
}

export default IncreaseLockForm;
