import { Strategy } from '../utils/entities/Strategy';
import { Contract } from '../utils/entities/Contract';
import * as icons from '../assets/defi';
import { ChainId } from '../constants';

import { CURVE_POOL_ABI, CURVE_LIQUIDITY_GAUGE_ABI } from './abis';
import {
  CURVE_GAUGE_CONTROLLER,
  getCurveBaseApy,
  getCrvTokenApy,
} from './utils/curve';

const CURVE_LUSD_POOL = {
  [ChainId.MAINNET]: new Contract(
    '0xEd279fDD11cA84bEef15AF5D39BB4d4bEE23F0cA',
    CURVE_POOL_ABI
  ),
};

const CURVE_LUSD_LIQUIDITY_GAUGE_ADDRESS =
  '0x9b8519a9a00100720ccdc8a120fbed319ca47a14';

const CURVE_LUSD_LIQUIDITY_GAUGE = {
  [ChainId.MAINNET]: new Contract(
    CURVE_LUSD_LIQUIDITY_GAUGE_ADDRESS,
    CURVE_LIQUIDITY_GAUGE_ABI
  ),
};

const curve_lusd = new Strategy('Curve LUSD', {
  protocol: 'curve',
  poolName: 'lusd',
  protocolIcon: icons['curve'],
  outputAssets: ['CRV'],
  contracts: {
    pool: CURVE_LUSD_POOL,
    liquidityGauge: CURVE_LUSD_LIQUIDITY_GAUGE,
    gaugeController: CURVE_GAUGE_CONTROLLER,
  },
  allocationName: 'curve-lusd',
  tokenPricesNeeded: ['curve-dao-token', 'liquity-usd'],
  getApys: (chainId, library) => ({
    baseApy: getCurveBaseApy('lusd'),
    CRV: getCrvTokenApy('curve_lusd', chainId, library),
  }),
  description: 'Add strategy description here...',
});

export default curve_lusd;
