// import { useState, useEffect } from 'react';
// import { useWeb3React } from '@web3-react/core';

// import { getContracts } from '../utils/helpers';
import { useApi } from './useApi';

export function useOracleStatus() {
  // const { library, chainId } = useWeb3React();

  // const [lockEnd, setLockEnd] = useState();
  // const [blockBeforeLock, setBlockBeforeLock] = useState();

  const { res: oracleStatus } = useApi(
    'apy.finance',
    'https://service.apy.finance/v1/oracle'
  );

  // Uncomment useEffect below if needed to find lastUnlocked via contracts
  // useEffect(() => {
  //   if (!chainId || !library || !oracleStatus.data?.locked) return;

  //   const getLockEnd = async (chainId, library) => {
  //     const OracleAdapter = await getContracts(
  //       ['ORACLE_ADAPTER'],
  //       chainId,
  //       library
  //     );

  //     const lockEnd = await OracleAdapter.lockEnd();

  //     const defaultLockPeriod = await OracleAdapter.defaultLockPeriod();
  //     const startBlock = lockEnd.sub(defaultLockPeriod).sub(1).toNumber();

  //     let blockTag;
  //     for (let i = 0; i < 5; i++) {
  //       blockTag =
  //         i === 0 ? startBlock : startBlock - (i * defaultLockPeriod) / 2;

  //       const isLocked = await OracleAdapter.isLocked.call({
  //         blockTag: blockTag,
  //       });

  //       if (!isLocked) {
  //         break;
  //       }
  //     }

  //     return [lockEnd, blockTag];
  //   };

  //   getLockEnd(chainId, library)
  //     .then(data => {
  //       const [lockEnd, blockTag] = data;

  //       setLockEnd(lockEnd.toString());
  //       setBlockBeforeLock(blockTag);
  //     })
  //     .catch(err => console.error('Could not get Lock End', err.message));
  // }, [library, chainId, oracleStatus]);

  return {
    isOracleLocked: oracleStatus.data?.locked,
    lockEnd: oracleStatus.data?.lockEnd.toString(),
    blockBeforeLock: oracleStatus.data?.lastUnlocked,
  };
}
