export const theme = {
  colors: {
    white: '#f0f0f0',
    grey: ['#202020', '#272727', '#333', '#474747', '#555'],
    font: '#d0d0d0',
  },
  borderRadius: ['0px', '7px'],
  space: [
    '0',
    '0.25rem',
    '0.5rem',
    '0.75rem',
    '1rem',
    '1.5rem',
    '2rem',
    '3rem',
    '4rem',
    '6rem',
    '8rem',
    '12rem',
    '16rem',
    '24rem',
    '32rem',
    '40rem',
    '48rem',
  ],
  typeScale: ['12px', '14px', '16px', '18px', '20px', '24px', '32px', '48px'],
};
