import React, { useContext } from 'react';
import { WarningCircle, Check, Warning, Info } from 'phosphor-react';
import { PuffLoader, ClockLoader } from 'react-spinners';

import { ToastContext } from '../../../../state/contexts';

import CloseBtn from '../../buttons/Close';
import {
  toastListWrap,
  toastWrap,
  toastIcon,
  toastContent,
  toastTitle,
  toastMessage,
  topRight,
  bottomRight,
  topLeft,
  bottomLeft,
} from './ToastList.module.scss';

function ToastList({ position, autoClose }) {
  const { state, removeToastById } = useContext(ToastContext);

  const positions = {
    topLeft: topLeft,
    topRight: topRight,
    bottomRight: bottomRight,
    bottomLeft: bottomLeft,
  };

  const colors = {
    error: '#c2001a',
    success: '#04b485',
    warning: '#d3a21d',
    info: '#3382d7',
    validating: '#3382d7',
    pending: '#d3a21d',
  };

  // Refactor icon see BannerNotification component
  const Icons = {
    error: <WarningCircle size={36} color="#c2001a" weight="bold" />,
    success: <Check size={36} color="#04b485" weight="bold" />,
    warning: <Warning size={36} color="#d3a21d" weight="bold" />,
    info: <Info size={36} color="#3382d7" weight="bold" />,
    validating: <PuffLoader size={36} color={'#3382d7'} />,
    pending: <ClockLoader size={34} color={'#d3a21d'} />,
  };

  return (
    <div className={`${toastListWrap} ${positions[position]}`}>
      {state.map(notification => {
        if (autoClose) {
          setInterval(() => removeToastById(notification.id), autoClose);
        }

        return (
          <div
            key={notification.id}
            className={`${toastWrap} ${positions[position]}`}
            style={{ borderLeftColor: colors[notification.type] }}
          >
            <CloseBtn
              size={'small'}
              onClick={() => removeToastById(notification.id)}
            />
            <div className={toastIcon}>{Icons[`${notification.type}`]}</div>
            <div className={toastContent}>
              <div className={toastTitle}>{notification.title}</div>
              <div className={toastMessage}>{notification.message}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ToastList;
