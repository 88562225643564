export class ChainIdError extends Error {
  constructor(message, chainId) {
    super(`${message} (Chain ID: ${chainId})`);
    this.name = 'ChainID Error';
  }
}

export class ContractKeyError extends Error {
  constructor(message, contractKey) {
    super(`${message} (Contract Key: "${contractKey}")`);
    this.name = 'Contract Initialization Error';
  }
}

export class SmartContractError extends Error {
  constructor(funcName, contractName, errorDetails) {
    super(
      `Issue calling ${funcName}() on smart contract (Contract: ${contractName}) - ${errorDetails}`
    );
    this.name = 'Contract Error';
  }
}

export class FetcherError extends Error {
  constructor(message, funcName, location) {
    super(
      `Fetch Failed: ${message} (Function: ${funcName}, Location: ${location})`
    );
    this.name = 'Fetcher Error';
  }
}

export class APIError extends Error {
  constructor(apiOrg, message, endpoint) {
    super(
      `API Error: ${message} (${apiOrg.toUpperCase()} Endpoint: ${endpoint})`
    );
    this.name = 'API Error';
  }
}

export class TransactionError extends Error {
  constructor(message, funcName) {
    super(`Transaction Failed: ${message} (Transaction Type: ${funcName})`);
    this.name = 'Transaction Error';
  }
}
