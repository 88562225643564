import React, { useContext } from 'react';
import { useWeb3React } from '@web3-react/core';
import { motion } from 'framer-motion';

import { TrxContext } from '../../state/contexts';
// import ToolTip from '../apy/ToolTip';
import Close from '../apy/buttons/Close';
import FeedbackOrbit from '../apy/orbits/FeedbackOrbit';
import MiniModal from './MiniModal';
import Button from '../apy/buttons/Button';
import {
  trxModalOverlay,
  trxModal,
  container,
  content,
  loadingSpinnerContainer,
  trxMsg,
  addtlSuccessInfo,
  btnGroup,
  modalBtn,
  addtlInfo,
  trxReceiptLink,
  btnWrap,
  status,
  pending,
  success,
  failed,
  pendingInner,
  successInner,
  failedInner,
  successMsgWrap,
  trxModalLink,
} from './scss/TrxModal.module.scss';

const Palette = {
  EARLY_REDEEM: pending,
  WITHDRAWAL_FEE: pending,
  LARGE_DEPOSIT: pending,
  CONFIRM_CREATE_LOCK: pending,
  PENDING: pending,
  VERIFYING: pending,
  FAILED: failed,
  SUCCESS: success,
  INSUFFICIENT_RESERVE: failed,
  SUCCESS_PENDING: success,
  DEPOSIT_SUCCESS: success,
};

const InnerPalette = {
  EARLY_REDEEM: pendingInner,
  WITHDRAWAL_FEE: pendingInner,
  LARGE_DEPOSIT: pendingInner,
  CONFIRM_CREATE_LOCK: pendingInner,
  PENDING: pendingInner,
  VERIFYING: pendingInner,
  FAILED: failedInner,
  SUCCESS: successInner,
  INSUFFICIENT_RESERVE: failedInner,
  SUCCESS_PENDING: successInner,
  DEPOSIT_SUCCESS: successInner,
};

function Spinner({ trxStatus }) {
  const renderSpinner = () => {
    if (
      trxStatus === 'PENDING' ||
      trxStatus === 'VERIFYING' ||
      trxStatus === 'EARLY_REDEEM' ||
      trxStatus === 'WITHDRAWAL_FEE' ||
      trxStatus === 'LARGE_DEPOSIT' ||
      trxStatus === 'CONFIRM_CREATE_LOCK'
    ) {
      return <FeedbackOrbit status="pending" />;
    } else if (
      trxStatus === 'SUCCESS' ||
      trxStatus === 'DEPOSIT_SUCCESS' ||
      trxStatus === 'SUCCESS_PENDING'
    ) {
      return <FeedbackOrbit status="success" />;
    } else if (trxStatus === 'FAILED' || trxStatus === 'INSUFFICIENT_RESERVE') {
      return <FeedbackOrbit status="failed" />;
    }
  };
  return <div className={loadingSpinnerContainer}>{renderSpinner()}</div>;
}

function TrxModal() {
  const { chainId } = useWeb3React();
  const { state, closeTrxModal } = useContext(TrxContext);

  function MainInfo({ trxStatus, trxMessage }) {
    const renderMessage = () => {
      switch (trxStatus) {
        case 'PENDING':
          return (
            <>
              {state.trxMessage}
              {/* {state.trxMessage.includes('Enabling') ? (
                <ToolTip
                  info={{
                    title: 'Enabling',
                    body: `Grant APY.Finance permission to access your ${state.trxMessage.slice(
                      9,
                      state.trxMessage.length
                    )}.`,
                  }}
                />
              ) : null} */}
            </>
          );

        case 'FAILED':
          return 'Transaction failed';

        case 'SUCCESS_PENDING':
          return (
            <div className={successMsgWrap}>
              <span style={{ fontSize: 20 }}>{state.trxMessage}</span>
              <div className={addtlSuccessInfo}>
                Your withdrawal will require 50 block confirmations before it is
                reflected in your account.
                <br />
                <br />
                Increase your APY Reward yield up to 2.5x by{' '}
                <a href={'/boost-lock'} className={trxModalLink}>
                  boost-locking!
                </a>
              </div>
            </div>
          );

        case 'DEPOSIT_SUCCESS':
          return (
            <div className={successMsgWrap}>
              <span style={{ fontSize: 20 }}>{state.trxMessage}</span>
              <div className={addtlSuccessInfo}>
                Your stablecoins earn yield from APY rewards and platform
                positions on Convex.
                <br />
                <br />
                Increase your APY Reward yield up to 2.5x by{' '}
                <a href={'/boost-lock'} className={trxModalLink}>
                  boost-locking!
                </a>
              </div>
            </div>
          );

        default:
          return trxMessage;
      }
    };

    return (
      <div className={status}>
        <div className={trxMsg}>{renderMessage()}</div>
      </div>
    );
  }

  function BottomInfo({ trxStatus, trxMessage, trxHash }) {
    const etherscanTrxUrl =
      chainId === 1 ? 'etherscan.io/tx' : 'kovan.etherscan.io/tx';

    const renderMessage = () => {
      switch (trxStatus) {
        case 'CONFIRM_CREATE_LOCK':
          return (
            <div style={{ textAlign: 'left', fontSize: 15 }}>
              {`Amount: ${state.lockAmount} APY`}
              <br />
              {`Unlocks on: ${state.unlockDate}`}
              <br />
              {`Duration: ${state.approx}`}
              <br />
              <br />
              Once locked, you won't be able to reduce the amount, or time of
              this lock.
              <br />
              <br />
              Would you like to continue?
            </div>
          );
        case 'EARLY_REDEEM':
          return (
            <div style={{ textAlign: 'left', fontSize: 15 }}>
              This withdrawal is within 24 hours of your deposit. You'll be
              charged a 5% fee in addition to the 0.1% fee charged on all
              withdrawals.
              <br />
              <br />
              {`Receiving: ${state.withdrawalAmount} ${state.selectedToken}`}
              <br />
              <br />
              Would you like to continue?
            </div>
          );
        case 'WITHDRAWAL_FEE':
          return (
            <div style={{ textAlign: 'left', fontSize: 15 }}>
              A 0.1% fee is charged on all withdrawals.
              <br />
              <br />
              {`Receiving: ${state.withdrawalAmount} ${state.selectedToken}`}
              <br />
              <br />
              Would you like to continue?
            </div>
          );
        case 'LARGE_DEPOSIT':
          return (
            <div style={{ textAlign: 'left', fontSize: 15 }}>
              A deposit of this size will likely require multiple withdrawals
              over a period of time to avoid triggering safeguards.
              <br />
              <br />
              You can contact us at{' '}
              <address>
                <a href="mailto:reservepool@apy.finance">
                  reservepool@apy.finance
                </a>
              </address>
              to perform larger single withdrawals.
              <br />
              <br />
              Would you like to continue with this deposit?
            </div>
          );
        case 'INSUFFICIENT_RESERVE':
          return (
            <div style={{ textAlign: 'left' }}>
              Unable to withdraw due to insufficient reserves.
              <br />
              <br />
              {`Withdrawing: ${state.withdrawalAmount}`}
              <br />
              {`${state.selectedToken} Reserves: ${state.reserveAmount}`}
              <br />
              <br />
              For withdraws exceeding the amount of reserves currently
              available, please contact us using the following{' '}
              <a
                className={trxModalLink}
                href="https://docs.google.com/forms/d/1H-Vw2wiOEKR5yS2Tvhz3kxA6tYcT-S4DxfJlZF8Z14w"
                target="_blank"
                rel="noopener noreferrer"
              >
                form
              </a>
              <br />
              <br />
              For all other inquiries please contact our support team:{' '}
              <address>
                <a href="mailto:support@apy.finance">support@apy.finance</a>
              </address>
            </div>
          );
        case 'VERIFYING':
          return '';
        case 'PENDING':
          return 'Confirm transaction through your wallet';
        case 'FAILED':
          return trxMessage;
        default:
          return (
            <>
              View transaction details on{' '}
              <a
                className={trxReceiptLink}
                href={`https://${etherscanTrxUrl}/${trxHash}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Etherscan
              </a>
            </>
          );
      }
    };

    return <div className={addtlInfo}>{renderMessage()}</div>;
  }

  function ButtonGroup({ trxStatus, onBtnClick }) {
    const renderButton = () => {
      switch (trxStatus) {
        case 'CONFIRM_CREATE_LOCK':
          return (
            <div className={btnGroup}>
              <Button
                classes={[modalBtn]}
                size="extraExtraSmall"
                color="red"
                onClick={closeTrxModal}
              >
                Cancel
              </Button>
              <Button
                classes={[modalBtn]}
                color="green"
                size="extraExtraSmall"
                onClick={onBtnClick}
              >
                Continue
              </Button>
            </div>
          );
        case 'EARLY_REDEEM':
          return (
            <div className={btnGroup}>
              <Button
                classes={[modalBtn]}
                size="extraExtraSmall"
                color="red"
                onClick={closeTrxModal}
              >
                Cancel
              </Button>
              <Button
                classes={[modalBtn]}
                color="green"
                size="extraExtraSmall"
                onClick={onBtnClick}
              >
                Continue
              </Button>
            </div>
          );

        case 'WITHDRAWAL_FEE':
          return (
            <div className={btnGroup}>
              <Button
                classes={[modalBtn]}
                size="extraExtraSmall"
                color="red"
                onClick={closeTrxModal}
              >
                Cancel
              </Button>
              <Button
                classes={[modalBtn]}
                color="green"
                size="extraExtraSmall"
                onClick={onBtnClick}
              >
                Continue
              </Button>
            </div>
          );
        case 'LARGE_DEPOSIT':
          return (
            <div className={btnGroup}>
              <Button
                classes={[modalBtn]}
                size="extraExtraSmall"
                color="red"
                onClick={closeTrxModal}
              >
                Cancel
              </Button>
              <Button
                classes={[modalBtn]}
                color="green"
                size="extraExtraSmall"
                onClick={onBtnClick}
              >
                Continue
              </Button>
            </div>
          );
        case 'INSUFFICIENT_RESERVE':
          return (
            <div>
              <Button
                color="red"
                size="extraExtraSmall"
                onClick={closeTrxModal}
              >
                Got it
              </Button>
            </div>
          );
        default:
          return null;
      }
    };
    return <div className={btnWrap}>{renderButton()}</div>;
  }

  return (
    <MiniModal
      variants={{
        open: { opacity: 0, scale: 1.25 },
        close: { opacity: 1, scale: 1 },
      }}
      classes={{ overlay: [trxModalOverlay], modal: [trxModal] }}
      isOpen={state.showTrxModal}
      onClose={closeTrxModal}
    >
      <motion.div
        data-augmented-ui="tl-round tr-round br-round bl-round border"
        className={`${container} ${Palette[state.trxStatus]}`}
      >
        <Close onClick={closeTrxModal} />
        <div data-augmented-ui-reset>
          <Spinner trxStatus={state.trxStatus} />
          <div
            data-augmented-ui="t-clip-x border"
            className={`${content} ${InnerPalette[state.trxStatus]}`}
          >
            <MainInfo
              trxStatus={state.trxStatus}
              trxMessage={state.trxMessage}
            />
            <BottomInfo
              trxStatus={state.trxStatus}
              trxMessage={state.trxMessage}
              trxHash={state.trxHash}
              onBtnClick={state.onBtnClick}
            />
            <ButtonGroup
              trxStatus={state.trxStatus}
              onBtnClick={state.onBtnClick}
            />
          </div>
        </div>
      </motion.div>
    </MiniModal>
  );
}

export default TrxModal;
