import React, { useState, useContext } from 'react';
import { useWeb3React } from '@web3-react/core';
import { BigNumber } from 'bignumber.js';

import { TrxContext } from '../../../../../../../state/contexts';
import useTransaction from '../../../../../../../hooks/useTransaction';
import { useAccountBalance } from '../../../../../../../hooks';
import { POOL_CONTRACT_KEYS } from '../../../../../../../constants';
import {
  getContracts,
  getWei,
  displayAmount,
  getAddressFromRegistry,
} from '../../../../../../../utils/helpers';

import NumberForm from '../../../../../../apy/forms/NumberForm';
import {
  numberFormWrap,
  earlyWithdrawalNotice,
} from './StablecoinForm.module.scss';

function DepositForm(props) {
  const {
    formType,
    balance,
    selectedToken,
    isTokenApproved,
    allowance,
    inputValue,
    dispatch,
    poolsLocked,
    oracleLocked,
    updateApproval,
  } = props;

  const { library, chainId } = useWeb3React();
  const { showTrxModal } = useContext(TrxContext);
  const [isLoading, setIsLoading] = useState(false);

  // Custom hooks
  const { updateBalance: updateStablecoinBalance } = useAccountBalance(
    selectedToken
  );
  const { updateBalance: updatePoolBalance } = useAccountBalance(
    POOL_CONTRACT_KEYS[selectedToken]
  );
  const [sendTransaction] = useTransaction();

  // Submit
  const onSubmit = async evt => {
    evt.preventDefault();

    // Input Validation
    if (isTokenApproved.data === true && formType === 'DEPOSIT') {
      // Prohibit empty input and 0
      if (!inputValue || BigNumber(inputValue).isLessThanOrEqualTo(0)) {
        dispatch({ type: 'INPUT_ERROR', errorMsg: 'Enter an amount' });
        return;

        // Prohibit input greater than balance
      } else if (balance?.lt(inputValue)) {
        dispatch({ type: 'INPUT_ERROR', errorMsg: 'Input exceeds balance' });
        return;

        // Prohibit input under .01
      } else if (BigNumber(inputValue).isLessThan(0.01)) {
        dispatch({
          type: 'INPUT_ERROR',
          errorMsg: 'Cannot deposit less than .01',
        });
        return;
      }
    }

    const signer = library.getSigner();

    // Handle Approval
    if (isTokenApproved.data === false) {
      dispatch({ type: 'SETUP_TRANSACTION' });
      setIsLoading(true);

      const connectedContract = (
        await getContracts([selectedToken], chainId, library)
      ).connect(signer);

      const apyPoolAddress = await getAddressFromRegistry(
        POOL_CONTRACT_KEYS[selectedToken],
        chainId,
        library
      );

      const res = await sendTransaction('APPROVE', {
        contract: connectedContract,
        functionName: 'approve',
        args: [
          apyPoolAddress,
          getWei(Number.MAX_SAFE_INTEGER.toString(), selectedToken),
        ],
        options: { token: selectedToken },
      }).catch(err => console.error(err.message));

      if (res === 'SUCCESS') {
        updateApproval(undefined, true);
        dispatch({ type: 'SUCCESSFUL_TRANSACTION_KEEP_INPUT' });
      } else {
        dispatch({ type: 'FAILED_TRANSACTION' });
      }

      setIsLoading(false);

      // Handle Deposit
    } else if (formType === 'DEPOSIT') {
      const proceedWithDeposit = async (
        PoolContract,
        inputValue,
        selectedToken,
        depositAmount
      ) => {
        dispatch({ type: 'SETUP_TRANSACTION' });
        setIsLoading(true);

        const res = await sendTransaction('DEPOSIT', {
          contract: PoolContract,
          functionName: 'addLiquidity',
          args: [depositAmount],
          options: { token: selectedToken, amount: displayAmount(inputValue) },
        }).catch(err => console.error(err.message));

        if (res === 'SUCCESS') {
          updateStablecoinBalance(undefined, true);
          updatePoolBalance(undefined, true);

          dispatch({ type: 'SUCCESSFUL_TRANSACTION' });
        } else {
          dispatch({ type: 'FAILED_TRANSACTION' });
        }

        setIsLoading(false);
      };

      const PoolContract = (
        await getContracts(
          [POOL_CONTRACT_KEYS[selectedToken]],
          chainId,
          library
        )
      ).connect(signer);

      const depositAmount = getWei(inputValue, selectedToken);
      const depositValue = await PoolContract.getValueFromUnderlyerAmount(
        depositAmount
      );
      const poolTotalValue = await PoolContract.getPoolTotalValue();
      const reservePercent = await PoolContract.reservePercentage();

      const reservePoolValue = poolTotalValue
        .add(depositValue)
        .mul(reservePercent)
        .div(reservePercent.add(100));

      let isDepositLarge;
      if (depositValue.gt(reservePoolValue)) {
        isDepositLarge = true;
      }

      if (isDepositLarge) {
        showTrxModal('LARGE_DEPOSIT', {
          message: 'Pool Safeguard Notice',
          onBtnClick: () =>
            proceedWithDeposit(
              PoolContract,
              inputValue,
              selectedToken,
              depositAmount
            ),
        });
      } else {
        proceedWithDeposit(
          PoolContract,
          inputValue,
          selectedToken,
          depositAmount
        );
      }
    }
  };

  return (
    <NumberForm
      {...props}
      classes={{ numberFormWrap: numberFormWrap }}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >
      {formType === 'DEPOSIT' && !poolsLocked && !oracleLocked && (
        <div className={earlyWithdrawalNotice}>
          <ul style={{ listStyle: 'initial' }}>
            <li>Withdrawal fee is 0.1%</li>
            <li>
              For the first 24 hours after a deposit, there is an additional 5%
              withdrawal fee
            </li>
          </ul>
        </div>
      )}
    </NumberForm>
  );
}

export default DepositForm;
