import React from 'react';
import { motion } from 'framer-motion';

import { iconButton } from './IconButton.module.scss';

function IconButton(props) {
  const { type = 'button', animate = true, name, icon, ...otherProps } = props;
  return (
    <motion.button
      whileHover={{
        scale: animate && 1.2,
      }}
      type={type}
      className={iconButton}
      {...otherProps}
    >
      <img src={icon} alt={name} />
    </motion.button>
  );
}

export default IconButton;
