import React from 'react';

import { boxWrap, boxContainer } from './Box.module.scss';

function BoxSvgBorder({ colors }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.4987 40.49869">
      <path
        fill={colors.border}
        d="M2489.65254,1479.366h14.2143v1.0854h-14.2143a8.53533,8.53533,0,0,0-8.5088,8.5087v14.9251h-1.0854v-14.9251a9.62087,9.62087,0,0,1,9.5942-9.5941Zm17.8324,0h3.4779a9.621,9.621,0,0,1,9.5942,9.5941v3.4781h-1.0854v-3.4781a8.53533,8.53533,0,0,0-8.5088-8.5087h-3.4779v-1.0854Zm13.0721,16.6902v14.2144a9.62085,9.62085,0,0,1-9.5942,9.59409h-14.2069v-1.08549h14.2069a8.53516,8.53516,0,0,0,8.5088-8.5086v-14.2144Zm-27.4192,23.80849h-3.4853a9.62068,9.62068,0,0,1-9.5942-9.59409v-2.7673h1.0854v2.7673a8.53516,8.53516,0,0,0,8.5088,8.5086h3.4853v1.08549Z"
        transform="translate(-2480.05834 -1479.36604)"
      />
      <path
        fill={colors.bg}
        d="M2511.03545,1480.22874h-21.58376a8.37739,8.37739,0,0,0-5.95749,2.49857,8.51154,8.51154,0,0,0-2.4786,6.00475V1510.488a8.51209,8.51209,0,0,0,2.4786,6.00486,8.37739,8.37739,0,0,0,5.95749,2.49857h21.58376a8.37722,8.37722,0,0,0,5.95749-2.49857,8.51134,8.51134,0,0,0,2.4787-6.00486v-21.75589a8.51145,8.51145,0,0,0-2.4787-6.00475,8.377,8.377,0,0,0-5.95749-2.49857Z"
        transform="translate(-2480.05834 -1479.36604)"
      />
    </svg>
  );
}

function Box({ classes = { border: '#575757', bg: '#202020' }, children }) {
  return (
    <div className={`${boxWrap} ${classes.boxWrap}`}>
      <BoxSvgBorder colors={{ border: classes.border, bg: classes.bg }} />
      <div className={`${boxContainer} ${classes.boxContainer}`}>
        {children}
      </div>
    </div>
  );
}

export default Box;
