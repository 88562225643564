import React from 'react';

import Logo from '../../Logo';
import Inner from './Inner';
import Mid from './Mid';
import Outer from './Outer';
import { container, icon } from './FeedbackOrbit.module.scss';

const FeedbackOrbit = ({ status }) => {
  const PALETTE = {
    pending: {
      primary: '#cc9804',
      secondary: 'hsl(44, 97%, 12%)',
    },
    success: {
      primary: '#04b384',
      secondary: 'hsl(165, 100%, 7%)',
    },
    failed: {
      primary: '#c0005b',
      secondary: 'hsl(332, 100%, 11%)',
    },
  };
  return (
    <div className={container}>
      <Outer color={PALETTE[status]} />
      <Mid color={PALETTE[status].primary} />
      <Inner color={PALETTE[status].primary} />
      <div className={icon}>
        <Logo color={PALETTE[status].primary} />
      </div>
    </div>
  );
};

export default FeedbackOrbit;
