// For augmented container
export const gradient = {
  initial: {
    borderColor: '#303036',
    scale: 1,
  },
  animate: {
    scale: [1, 0.98, 1],
    borderColor: [
      '#d556f5',
      '#c450ef',
      '#b24ae9',
      '#a045e3',
      '#8e40dd',
      '#823ed9',
      '#763dd5',
      '#693bd1',
      '#613ccd',
      '#593cca',
      '#503dc6',
      '#483dc2',
    ],
    transition: {
      duration: 3,
      type: 'spring',
      stiffness: 100,
      repeat: Infinity,
      repeatType: 'reverse',
    },
  },
};

export const gradientHeader = {
  initial: {
    borderColor: '#1a1a1a', // Must be same as page background; hack/solution to prevent border color when inactive header
    scale: 1,
  },
  animate: {
    scale: [1, 0.98, 1],
    borderColor: [
      '#d556f5',
      '#c450ef',
      '#b24ae9',
      '#a045e3',
      '#8e40dd',
      '#823ed9',
      '#763dd5',
      '#693bd1',
      '#613ccd',
      '#593cca',
      '#503dc6',
      '#483dc2',
    ],
    transition: {
      duration: 3,
      type: 'spring',
      stiffness: 100,
      repeat: Infinity,
      repeatType: 'reverse',
    },
  },
};

// For balance box
export const pillGradient = {
  initial: { borderColor: '#474747', scale: 1 },
  animate: {
    scale: [1, 0.98, 1],
    borderColor: [
      '#d556f5',
      '#c450ef',
      '#b24ae9',
      '#a045e3',
      '#8e40dd',
      '#823ed9',
      '#763dd5',
      '#693bd1',
      '#613ccd',
      '#593cca',
      '#503dc6',
      '#483dc2',
    ],
    transition: {
      duration: 3,
      type: 'spring',
      stiffness: 100,
      repeat: Infinity,
      repeatType: 'reverse',
    },
  },
};

// For buttons when they're selected as a tour step
export const buttonBorders = {
  blue: {
    initial: {
      borderColor: '#3382D7',
      scale: 1,
    },
    animate: {
      scale: [1, 0.99, 1],
      borderColor: ['#071422', '#102d4a', '#184876', '#2464a5', '#3382d7'],
      transition: {
        duration: 2,
        type: 'spring',
        stiffness: 100,
        repeat: Infinity,
        repeatType: 'reverse',
      },
    },
  },
  red: {
    initial: {
      borderColor: '#c2005a',
      scale: 1,
    },
    animate: {
      scale: [1, 0.99, 1],
      borderColor: ['#c2005a', '#980449', '#700538', '#4a0526', '#290013'],
      transition: {
        duration: 2,
        type: 'spring',
        stiffness: 100,
        repeat: Infinity,
        repeatType: 'reverse',
      },
    },
  },

  green: {
    initial: {
      borderColor: '#04b485',
      scale: 1,
    },
    animate: {
      scale: [1, 0.99, 1],
      borderColor: ['#04b485', '#068867', '#0b5e49', '#0c372c', '#01140f'],
      transition: {
        duration: 2,
        type: 'spring',
        stiffness: 100,
        repeat: Infinity,
        repeatType: 'reverse',
      },
    },
  },

  gold: {
    initial: {
      borderColor: '#cd9704',
      scale: 1,
    },
    animate: {
      scale: [1, 0.99, 1],
      borderColor: ['#cd9704', '#9f770a', '#73570b', '#4b3a0a', '#281e01'],
      transition: {
        duration: 2,
        type: 'spring',
        stiffness: 100,
        repeat: Infinity,
        repeatType: 'reverse',
      },
    },
  },
};

// Button Animations
export const hover = {
  scale: 1.02,
  boxShadow:
    'rgba(0, 0, 0, 0.5) 0px 1px 2px 0px, rgba(0, 0, 0, 0.45) 0px 2px 6px 2px',
  transition: {
    type: 'spring',
  },
};

export const tap = {
  scale: 0.98,
  boxShadow:
    'rgba(0, 0, 0, 0.5) 0px 1px 2px 0px, rgba(0, 0, 0, 0.45) 0px 2px 6px 2px',
  transition: {
    type: 'spring',
  },
};
