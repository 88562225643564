import React from 'react';
import { useWeb3React } from '@web3-react/core';
import { BeatLoader } from 'react-spinners';
import styled from 'styled-components';
import { useMedia } from 'react-use';

import { breakpoints } from '../../../../styles/breakpoints';
import { useLockData } from '../../../../../hooks';

import { Panel } from '../../../../styles/Panel.styled';
import { Split } from '../../../../styles/primitives';
import { displayAmount, displayDate } from '../../../../../utils/helpers';

function LockData() {
  const { account } = useWeb3React();
  const { lockData } = useLockData(account);

  const isVwUnder501 = useMedia('(max-width: 500px)');

  return (
    // Padding 5
    <Panel
      as={Split}
      padding={[5, 3]}
      gap={5}
      minmax={`${isVwUnder501 ? '200px' : '275px'}, 1fr`}
    >
      <DataWrap>
        <h3 style={{ fontFamily: 'archiathin', color: '#959595' }}>
          Your Locked APY
        </h3>
        <span>
          {lockData.isLoading ? (
            <BeatLoader size={14} margin={4} color={'gray'} />
          ) : (
            <>
              {displayAmount(lockData.data?.amount)}{' '}
              {lockData.data && !isVwUnder501 && 'APY'}
            </>
          )}
        </span>
      </DataWrap>

      <DataWrap>
        <h3 style={{ fontFamily: 'archiathin', color: '#959595' }}>
          Unlocks On
        </h3>
        <span>
          {lockData.isLoading ? (
            <BeatLoader size={14} margin={4} color={'gray'} />
          ) : (
            displayDate(lockData.data?.end)
          )}
        </span>
      </DataWrap>
    </Panel>
  );
}

const DataWrap = styled.div`
  text-align: center;

  & > span {
    font-size: 1.8rem;
  }

  @media ${breakpoints.tablet} {
    & > span {
      font-size: 2rem;
    }
  }
`;

export default LockData;
