import * as Tokens from '../tokens';
import { ChainId } from '../index';
import { Contract } from '../../utils/entities/Contract';
import REGISTRY_ADDRESSES from '@apy-finance/apy-core/deployed_addresses/AddressRegistryProxy.json';
import { REWARD_DISTRIBUTOR_ADDRESSES, LPT_STAKING_ADDRESSES } from '../claims';
import {
  REWARDS_DISTRIBUTOR_ABI,
  ERC20_ABI,
  APT_ABI,
  STAKING_ABI,
  TVL_MGR_ABI,
  REGISTRY_ABI,
  ORACLE_ABI,
  LP_ABI,
  BOOST_LOCK_ABI,
} from './abis';

/* ---------------------------------- */
/* ---------- APY Finance ----------- */
/* ---------------------------------- */

// Address Registry
export const REGISTRY = {
  [ChainId.MAINNET]: new Contract(
    REGISTRY_ADDRESSES[ChainId.MAINNET],
    REGISTRY_ABI
  ),
  [ChainId.KOVAN]: new Contract(
    REGISTRY_ADDRESSES[ChainId.KOVAN],
    REGISTRY_ABI
  ),
};

// APY Governance Token
export const APY = {
  [ChainId.MAINNET]: new Contract(
    Tokens['APY'][ChainId.MAINNET].address,
    ERC20_ABI
  ),
  [ChainId.KOVAN]: new Contract(
    Tokens['APY'][ChainId.KOVAN].address,
    ERC20_ABI
  ),
};

// TVL Manager
export const TVL_MGR = {
  [ChainId.MAINNET]: new Contract(null, TVL_MGR_ABI),
  [ChainId.KOVAN]: new Contract(null, TVL_MGR_ABI),
};

// LP Account
export const LP_ACCOUNT = {
  [ChainId.MAINNET]: new Contract(null, LP_ABI),
  [ChainId.KOVAN]: new Contract(null, LP_ABI),
};

// APY Rewards Distributor
export const REWARDS_DISTRIBUTOR = {
  [ChainId.MAINNET]: new Contract(
    REWARD_DISTRIBUTOR_ADDRESSES[ChainId.MAINNET],
    REWARDS_DISTRIBUTOR_ABI
  ),
  [ChainId.KOVAN]: new Contract(
    REWARD_DISTRIBUTOR_ADDRESSES[ChainId.KOVAN],
    REWARDS_DISTRIBUTOR_ABI
  ),
};

// APY UNI-V2 Staking
export const UNIV2_STAKING = {
  [ChainId.MAINNET]: new Contract(
    LPT_STAKING_ADDRESSES['UNIV2_STAKING'][ChainId.MAINNET],
    STAKING_ABI
  ),
  [ChainId.KOVAN]: new Contract(
    LPT_STAKING_ADDRESSES['UNIV2_STAKING'][ChainId.KOVAN],
    STAKING_ABI
  ),
};

// APY BPT Staking
export const BPT_STAKING = {
  [ChainId.MAINNET]: new Contract(
    LPT_STAKING_ADDRESSES['BPT_STAKING'][ChainId.MAINNET],
    STAKING_ABI
  ),
  [ChainId.KOVAN]: new Contract(
    LPT_STAKING_ADDRESSES['BPT_STAKING'][ChainId.KOVAN],
    STAKING_ABI
  ),
};

// Oracle Adapter
export const ORACLE_ADAPTER = {
  [ChainId.MAINNET]: new Contract(null, ORACLE_ABI),
  [ChainId.KOVAN]: new Contract(null, ORACLE_ABI),
};

// APY DAI Pool Token
export const aptDAI = {
  [ChainId.MAINNET]: new Contract(null, APT_ABI),
  [ChainId.KOVAN]: new Contract(null, APT_ABI),
};

// APY USDC Pool Token
export const aptUSDC = {
  [ChainId.MAINNET]: new Contract(null, APT_ABI),
  [ChainId.KOVAN]: new Contract(null, APT_ABI),
};

// APY USDT Pool Token
export const aptUSDT = {
  [ChainId.MAINNET]: new Contract(null, APT_ABI),
  [ChainId.KOVAN]: new Contract(null, APT_ABI),
};

// APY Demo DAI Pool Token
export const DAI_DEMO_POOL = {
  [ChainId.MAINNET]: new Contract(null, APT_ABI),
  [ChainId.KOVAN]: new Contract(null, APT_ABI),
};

// APY Demo USDC Pool Token
export const USDC_DEMO_POOL = {
  [ChainId.MAINNET]: new Contract(null, APT_ABI),
  [ChainId.KOVAN]: new Contract(null, APT_ABI),
};

// APY Demo USDT Pool Token
export const USDT_DEMO_POOL = {
  [ChainId.MAINNET]: new Contract(null, APT_ABI),
  [ChainId.KOVAN]: new Contract(null, APT_ABI),
};

// Boost-Locked APY (blAPY)
export const BL_APY = {
  [ChainId.MAINNET]: new Contract(
    '0xdc9eff7bb202fd60de3f049c7ec1efb08006261f',
    BOOST_LOCK_ABI
  ),
};

/* ---------------------------------- */
/* ------------ Uniswap ------------- */
/* ---------------------------------- */

// UNI-V2 LP Token (APY-ETH Pool)
export const UNIV2 = {
  [ChainId.MAINNET]: new Contract(
    Tokens['UNIV2'][ChainId.MAINNET].address,
    ERC20_ABI
  ),
  // Mock LP Token
  [ChainId.KOVAN]: new Contract(
    '0xB19b922c6115BfCB621945eE8B445daC96e2291d',
    ERC20_ABI
  ),
};

/* ---------------------------------- */
/* ------------ Balancer ------------ */
/* ---------------------------------- */

// BPT LP Token (APY-USDC Pool)
export const BPT = {
  [ChainId.MAINNET]: new Contract(
    Tokens['BPT'][ChainId.MAINNET].address,
    ERC20_ABI
  ),
  // Mock LP Token (same as token for UNI-V2 on Kovan)
  [ChainId.KOVAN]: new Contract(
    '0xB19b922c6115BfCB621945eE8B445daC96e2291d',
    ERC20_ABI
  ),
};

/* ---------------------------------- */
/* ---------- Stablecoins ----------- */
/* ---------------------------------- */

// DAI
export const DAI = {
  [ChainId.MAINNET]: new Contract(
    Tokens['DAI'][ChainId.MAINNET].address,
    ERC20_ABI
  ),
  [ChainId.KOVAN]: new Contract(
    Tokens['DAI'][ChainId.KOVAN].address,
    ERC20_ABI
  ),
};

// USDC
export const USDC = {
  [ChainId.MAINNET]: new Contract(
    Tokens['USDC'][ChainId.MAINNET].address,
    ERC20_ABI,
    'USDC Contract'
  ),
  [ChainId.KOVAN]: new Contract(
    Tokens['USDC'][ChainId.KOVAN].address,
    ERC20_ABI
  ),
};

// USDT
export const USDT = {
  [ChainId.MAINNET]: new Contract(
    Tokens['USDT'][ChainId.MAINNET].address,
    ERC20_ABI
  ),
  [ChainId.KOVAN]: new Contract(
    Tokens['USDT'][ChainId.KOVAN].address,
    ERC20_ABI
  ),
};

/* ---------------------------------- */
/* ---------- Misc. Tokens ---------- */
/* ---------------------------------- */

// WETH
export const WETH = {
  [ChainId.MAINNET]: new Contract(
    Tokens['WETH'][ChainId.MAINNET].address,
    ERC20_ABI
  ),
  [ChainId.KOVAN]: new Contract(
    Tokens['WETH'][ChainId.KOVAN].address,
    ERC20_ABI
  ),
};
