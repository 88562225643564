import { useMemo } from 'react';
import { useWeb3React } from '@web3-react/core';
import useEtherSWR from 'ether-swr';

import * as Contracts from '../constants/contracts';
import { ChainId } from '../constants';
import { fromWei } from '../utils/helpers';
import { FetcherError } from '../utils/errors';

const ABIs = {
  [ChainId.MAINNET]: [
    [Contracts['BL_APY'][1].address, Contracts['BL_APY'][1].abi],
  ],
};

export function useLockData(account) {
  const { chainId } = useWeb3React();

  const BL_APY_ADDRESS = Contracts['BL_APY'][chainId]?.address;
  const { data: lockedData, error: lockedDataError, mutate } = useEtherSWR(
    [BL_APY_ADDRESS, 'locked', account],
    {
      ABIs: new Map(ABIs[chainId]),
    }
  );

  const lockData = useMemo(() => {
    let data;
    let error;

    if (lockedDataError) {
      error = new FetcherError(
        lockedDataError.data?.message ?? lockedDataError.message,
        '"locked"',
        'useLockData'
      );
      console.error(error.message);
    }

    if (lockedData) {
      const amount = fromWei(lockedData.amount, 'APY');

      // Note: lockData.end will be 0 (BigNumber) if no lock exists
      const end = lockedData.end.toNumber();

      data = {
        amount,
        end,
      };
    }

    return {
      data,
      isLoading: !data && !error,
      error: error,
    };
  }, [lockedData, lockedDataError]);

  return { lockData, updateLockData: mutate };
}
