import { useModalStore, useCortexModalStore } from '../state/stores';

export function useModal(modalKey) {
  // Zustand
  const isModalOpen = useModalStore(state => state.isModalOpen);
  const setIsModalOpen = useModalStore(state => state.setIsModalOpen);

  const isCortexModalOpen = useCortexModalStore(
    state => state.isCortexModalOpen
  );
  const setIsCortexModalOpen = useCortexModalStore(
    state => state.setIsCortexModalOpen
  );

  return modalKey === 'cortex'
    ? [isCortexModalOpen, setIsCortexModalOpen]
    : [isModalOpen, setIsModalOpen];
}
