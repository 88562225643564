import React, { useContext, useState } from 'react';
import { useWeb3React } from '@web3-react/core';

import { useClaimStore } from '../../../../state/stores';
import { PageTourContext } from '../../../../state/contexts';
import { fromWei } from '../../../../utils/helpers';

import PageTourBox from '../PageTourBox';
import SectionHeader from '../SectionHeader';
import NewButton from '../../buttons/NewButton';
import Panel from '../../containers/Panel';
import Metric from '../Metric';
import {
  claimApyWrap,
  claimApyHeader,
  sectionHeaderTitle,
  button,
  claimApyPanels,
  panelContainer,
  metricWrap,
  metricTitle,
  metricValue,
} from './ClaimApy.module.scss';

function ClaimApy({
  claimType,
  classes = {},
  title = 'Claim APY',
  tagline,
  tooltip,
  metrics,
  claimable,
  onClaim,
  isTrxPending,
}) {
  // const { tourSteps, isTourStepActive } = useContext(PageTourContext);
  // const [claimHeaderElement, setClaimHeaderElement] = useState();

  // const headerSteps = {
  //   liquidityMining: tourSteps['portfolioSteps']['rewards'],
  //   staking: tourSteps['stakingSteps']['claimIntro'],
  // };

  const pendingClaim = useClaimStore(state => state.pendingClaim);
  const { chainId, library } = useWeb3React();

  return (
    <section className={claimApyWrap}>
      <div className={claimApyHeader}>
        <SectionHeader
          title={title}
          classes={{
            sectionHeaderTitle:
              classes.sectionHeaderTitle ?? sectionHeaderTitle,
          }}
          tooltip={tooltip}
          // ref={setClaimHeaderElement}
          // isTourElementActive={isTourStepActive(headerSteps[claimType])}
          // pageTourBox={
          //   <PageTourBox
          //     steps={headerSteps[claimType]}
          //     isActive={isTourStepActive(headerSteps[claimType])}
          //     refElement={claimHeaderElement}
          //   />
          // }
        >
          {tagline}
        </SectionHeader>
        <NewButton
          color="purple"
          classes={{ button }}
          onClick={onClaim}
          disabled={
            (claimType === 'liquidityMining' && pendingClaim.amount) ||
            !claimable ||
            claimable.isZero() ||
            isTrxPending ||
            !chainId ||
            !library
          }
          isLoading={isTrxPending}
        >
          {claimType === 'liquidityMining' ? 'Withdraw APY' : 'Claim APY'}
        </NewButton>
      </div>
      <div className={claimApyPanels}>
        {metrics.map(
          ({
            title,
            tooltip,
            value,
            isLoading,
            ref,
            isTourElementActive,
            pageTourBox,
          }) => {
            return (
              <Panel
                key={title}
                classes={{ panelContainer }}
                ref={ref}
                isTourElementActive={isTourElementActive}
                pageTourBox={pageTourBox}
              >
                <Metric
                  classes={{ metricWrap, metricTitle, metricValue }}
                  title={title}
                  tooltip={tooltip}
                  isLoading={isLoading}
                >
                  {value}
                </Metric>
              </Panel>
            );
          }
        )}
      </div>
    </section>
  );
}

export default ClaimApy;
