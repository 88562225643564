export function boostLockReducer(
  state,
  { type, newForm, newInput, newUnlockEpoch, errorMsg }
) {
  switch (type) {
    case 'FORM_CHANGE':
      return {
        ...state,
        currentForm: newForm,
        inputAmount: null,
        inputErrorMsg: null,
      };

    case 'INPUT_CHANGE':
      return {
        ...state,
        inputAmount: newInput,
        inputErrorMsg: null,
      };

    case 'INPUT_ERROR':
      return {
        ...state,
        inputErrorMsg: errorMsg,
      };

    case 'UNLOCK_EPOCH_CHANGE':
      return {
        ...state,
        unlockEpoch: newUnlockEpoch,
        inputErrorMsg: null,
      };

    case 'SETUP_TRX':
      return {
        ...state,
        isTrxPending: true,
      };

    case 'SUCCESSFUL_TRX':
      return {
        ...state,
        inputAmount: null,
        isTrxPending: false,
        inputErrorMsg: null,
      };

    case 'FAILED_TRX':
      return {
        ...state,
        isTrxPending: false,
      };

    default:
      return state;
  }
}
