import { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import useEtherSWR from 'ether-swr';

import { useCoinGeckoStore, useAbisStore } from '../state/stores';
import { ToastContext } from '../state/contexts';
import * as Strategies from '../strategies';
import { useStrategyAllocations, useCoinGeckoPrices } from './index';
import {
  getActiveStrategies,
  getAssetAllocationValue,
  fromWei,
  isListEmpty,
} from '../utils/helpers';
import { FetcherError } from '../utils/errors';

const activeStrategies = getActiveStrategies(Strategies);

export function useAllocatedValues() {
  // Zustand
  const { abis: ABIs, addresses } = useAbisStore(state => state.abiInfo);
  const coinGeckoList = useCoinGeckoStore(
    useCallback(state => state.coinGeckoList, [])
  );
  const { coinPrices } = useCoinGeckoPrices();

  // Toast notifications
  const { showToast } = useContext(ToastContext);

  const [allocatedValues, setAllocatedValues] = useState(null);

  /**
   * Create list of "getAssetAllocation" calls
   * Uses activeStrategies to construct list of calls to grab allocations (a.k.a deployed funds)
   */
  let getAssetAllocationCalls;
  if (addresses) {
    getAssetAllocationCalls = Object.values(activeStrategies).map(Strategy => {
      const { allocationName } = Strategy;

      return [addresses['TVL_MGR'][0], 'getAssetAllocation', allocationName];
    });
  }

  // Fetch addresses allocation (if any)
  const {
    data: allocationAddresses,
    error: allocationAddressError,
  } = useEtherSWR(getAssetAllocationCalls ?? [], {
    ABIs: new Map(ABIs),
  });

  const addressFetcherError = useMemo(() => {
    let addressFetcherError;

    if (allocationAddressError) {
      addressFetcherError = new FetcherError(
        allocationAddressError.data?.message ?? allocationAddressError.message,
        '"getAssetAllocation"',
        'useAllocatedValues'
      );
      console.error(addressFetcherError.message);
    }

    return addressFetcherError;
  }, [allocationAddressError]);

  const { allocations } = useStrategyAllocations(
    allocationAddresses,
    addresses?.['LP_ACCOUNT'][0]
  );

  useEffect(() => {
    const getAllocationValues = async (activeStrategies, allocations) => {
      let deployedValues = Object.fromEntries(
        await Promise.all(
          Object.keys(activeStrategies).map(async (stratKey, idx) => {
            const value = fromWei(
              await getAssetAllocationValue(allocations[idx]),
              'USD'
            );

            return [stratKey, value];
          })
        )
      );

      setAllocatedValues(deployedValues);
    };

    if (
      coinPrices.data &&
      coinGeckoList &&
      !allocations.isLoading &&
      !isListEmpty(allocations.data) &&
      allocations.data
    ) {
      getAllocationValues(activeStrategies, allocations.data).catch(err => {
        console.error(
          'Error: Unable to calculate allocation values:',
          err.message
        );
        setAllocatedValues({});
      });
    }
  }, [coinPrices, coinGeckoList, allocations]);

  const memoAllocatedValues = useMemo(() => {
    return {
      data: allocatedValues,
      isLoading: !allocatedValues && !addressFetcherError,
      error: addressFetcherError,
    };
  }, [allocatedValues, addressFetcherError]);

  // Show notification if no active strategies
  // Note: B/c this is for users, should be based on actual allocated values, and
  // NOT the internal ACTIVE_STRATEGIES constant
  useEffect(() => {
    if (
      !memoAllocatedValues.isLoading &&
      Object.entries(memoAllocatedValues.data).length === 0
    ) {
      const notification = {
        type: 'warning',
        title: 'No Active Strategies',
        message: 'Currently no active strategies',
        unique: true,
      };

      showToast(notification);
    }
  }, [showToast, memoAllocatedValues]);

  return { allocatedValues: memoAllocatedValues };
}
