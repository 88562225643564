import styled from 'styled-components';

export const TransparentButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;
  font-size: inherit;
  color: inherit;
  text-align: center;
  cursor: pointer;
`;
