import React from 'react';
import { useMedia } from 'use-media';
import { useHistory } from 'react-router-dom';

import { useHistoricalPoolValues } from '../../../../hooks';

import PageToggle from '../../controls/PageToggle';
import Panel from '../../containers/Panel';
import Metric from '../../info/Metric';
import NewTooltip from '../../controls/NewTooltip';
import {
  mainMetricsSection,
  panelContainer,
  metricWrap,
  metricTitle,
  metricValue,
  tourToggleContainer,
} from './DashboardPage.module.scss';
// import StatusPanel from '../../../pages/Yield/Portfolio/StatusPanel';

function DashboardPage({
  classes = {},
  banner,
  mainMetrics,
  pageTourToggle,
  children,
}) {
  let history = useHistory();

  // Media queries
  const isVwOver768 = useMedia({ minWidth: '769px' });

  const cssGridProp = !isVwOver768 ? 'gridTemplateRows' : 'gridTemplateColumns';

  // Fetch TVL chart data even if on Portfolio page
  useHistoricalPoolValues('DAI');
  useHistoricalPoolValues('USDC');
  useHistoricalPoolValues('USDT');

  return (
    <>
      <PageToggle />
      <div className={tourToggleContainer}>{pageTourToggle}</div>
      {banner}
      <section
        className={`${mainMetricsSection} ${classes.mainMetricsSection}`}
        style={{ [cssGridProp]: `repeat(${2}, 1fr)` }}
      >
        {mainMetrics.map(
          ({
            title,
            tooltipInfo,
            value,
            isLoading,
            ref,
            isTourElementActive,
            pageTourBox,
          }) => {
            return (
              <>
                {/* {history.location.pathname === '/dashboard/portfolio' && (
                  <StatusPanel />
                )} */}
                <Panel
                  key={title}
                  classes={{ panelContainer }}
                  ref={ref}
                  isTourElementActive={isTourElementActive}
                  pageTourBox={pageTourBox}
                >
                  <Metric
                    title={title}
                    classes={{ metricWrap, metricTitle, metricValue }}
                    tooltip={
                      tooltipInfo && (
                        <NewTooltip placement="right" body={tooltipInfo} />
                      )
                    }
                    isLoading={isLoading}
                  >
                    {value}
                  </Metric>
                </Panel>
              </>
            );
          }
        )}
      </section>
      <main>{children}</main>
    </>
  );
}

export default DashboardPage;
