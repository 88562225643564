import BigNumber from 'bignumber.js';
import { ChainId } from '../../constants';
import { fromWei } from '../../utils/helpers';

// Constants needed for CVX token APY calc
const CLIFF_SIZE = BigNumber(100000 * 1000000000000000000);
const CLIFF_COUNT = BigNumber(1000);
const MAX_SUPPLY = BigNumber(100000000 * 1000000000000000000);

export const CVX_ADDRESS = {
  [ChainId.MAINNET]: '0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b',
};

export const BOOSTER_ADDRESS = {
  [ChainId.MAINNET]: '0xf403c135812408bfbe8713b5a23a04b3d48aae31',
};

export const CONVEX_CURVE_APYS_URL =
  'https://service.apy.finance/v1/apys/convex-crv';

export const getCvxMintAmount = (crvEarned, totalSupply) => {
  crvEarned = crvEarned * 1000000000000000000;
  totalSupply = BigNumber(totalSupply.toString());

  const currentCliff = totalSupply.div(CLIFF_SIZE);

  if (currentCliff.lt(CLIFF_COUNT)) {
    const remaining = CLIFF_COUNT.minus(currentCliff);

    let cvxEarned = BigNumber(crvEarned).times(remaining).div(CLIFF_COUNT);
    const amountTillMax = MAX_SUPPLY.minus(totalSupply);

    cvxEarned = BigNumber.minimum(cvxEarned, amountTillMax);

    return cvxEarned;
  }

  return 0;
};
