export function trxModalReducer(state, action) {
  /*
    TrxModal Statuses:
    - EARLY_REDEEM
    - WITHDRAWAL_FEE
    - INSUFFICIENT_RESERVE
    - PENDING
    - SUCCESS
    - FAILED
    - VERIFYING
    - SUCCESS_PENDING
    - DEPOSIT_SUCCESS
  */

  switch (action.type) {
    case 'VERIFYING':
      return {
        ...state,
        showTrxModal: true,
        trxStatus: 'VERIFYING',
        trxMessage: action.message,
      };

    case 'CONFIRM_CREATE_LOCK':
      return {
        ...state,
        showTrxModal: true,
        trxStatus: 'CONFIRM_CREATE_LOCK',
        trxMessage: action.message,
        lockAmount: action.lockAmount,
        unlockDate: action.unlockDate,
        approx: action.approx,
        onBtnClick: action.onBtnClick,
      };

    case 'EARLY_REDEEM':
      return {
        ...state,
        showTrxModal: true,
        trxStatus: 'EARLY_REDEEM',
        trxMessage: action.message,
        onBtnClick: action.onBtnClick,
        selectedToken: action.token,
        withdrawalAmount: action.amount,
      };

    case 'WITHDRAWAL_FEE':
      return {
        ...state,
        showTrxModal: true,
        trxStatus: 'WITHDRAWAL_FEE',
        trxMessage: action.message,
        onBtnClick: action.onBtnClick,
        selectedToken: action.token,
        withdrawalAmount: action.amount,
      };

    case 'LARGE_DEPOSIT':
      return {
        ...state,
        showTrxModal: true,
        trxStatus: 'LARGE_DEPOSIT',
        trxMessage: action.message,
        onBtnClick: action.onBtnClick,
      };

    case 'INSUFFICIENT_RESERVE':
      return {
        ...state,
        showTrxModal: true,
        trxStatus: 'INSUFFICIENT_RESERVE',
        trxMessage: action.message,
        selectedToken: action.selectedToken,
        withdrawalAmount: action.withdrawalAmount,
        reserveAmount: action.reserveAmount,
      };

    case 'PROCESSING':
      return {
        ...state,
        showTrxModal: true,
        trxStatus: 'PENDING',
        trxMessage: action.message,
      };

    case 'SUCCESS':
      return {
        ...state,
        showTrxModal: true,
        trxStatus: 'SUCCESS',
        trxMessage: action.message,
        trxHash: action.hash,
      };

    case 'SUCCESS_PENDING':
      return {
        ...state,
        showTrxModal: true,
        trxStatus: 'SUCCESS_PENDING',
        trxMessage: action.message,
        trxHash: action.hash,
      };

    case 'DEPOSIT_SUCCESS':
      return {
        ...state,
        showTrxModal: true,
        trxStatus: 'DEPOSIT_SUCCESS',
        trxMessage: action.message,
        trxHash: action.hash,
      };

    case 'FAILED':
      return {
        ...state,
        showTrxModal: true,
        trxStatus: 'FAILED',
        trxMessage: action.message,
      };

    case 'CLOSE':
      return {
        ...state,
        showTrxModal: false,
        trxStatus: '',
        trxMessage: '',
        trxHash: '',
      };

    default:
      return state;
  }
}
