import React from 'react';
import { motion } from 'framer-motion';

import FeedbackOrbit from '../apy/orbits/FeedbackOrbit';
import MiniModal from './MiniModal';
import Close from '../apy/buttons/Close';
import {
  container,
  primaryText,
  secondaryText,
  closeBtn,
} from './scss/NetworkNotify.module.scss';

const NetworkNotify = ({ isOpen, chainId, onClose }) => {
  const chains = {
    1: 'Mainnet',
    3: 'Ropsten',
    4: 'Rinkeby',
    5: 'Goerli',
    42: 'Kovan',
  };

  return (
    <MiniModal
      variants={{
        open: { opacity: 0 },
        close: { opacity: 1 },
      }}
      isOpen={isOpen}
      onClose={null}
    >
      <motion.div
        variants={{
          open: { scale: 0, opacity: 0 },
          close: { scale: 1, opacity: 1 },
        }}
        data-augmented-ui="tl-round tr-round br-round bl-round border"
        className={container}
        key="network"
      >
        <FeedbackOrbit status="failed" />
        <h2 className={primaryText}>Switch to Ethereum Mainnet</h2>
        <p className={secondaryText}>
          APY.Finance only works on Ethereum Mainnet or Kovan. You're currently
          connected to{' '}
          <span style={{ fontFamily: 'archiabold' }}>{chains[chainId]}</span>.
          You can switch networks directly from your wallet.
        </p>
        <Close onClick={onClose} />
      </motion.div>
    </MiniModal>
  );
};

export default NetworkNotify;
