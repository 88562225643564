import { Strategy } from '../utils/entities/Strategy';
import { Contract } from '../utils/entities/Contract';
import * as icons from '../assets/defi';
import { ChainId } from '../constants';

import { CURVE_POOL_ABI, CURVE_LIQUIDITY_GAUGE_ABI } from './abis';
import {
  CURVE_GAUGE_CONTROLLER,
  getCurveBaseApy,
  getCrvTokenApy,
} from './utils/curve';

const CURVE_UST_POOL = {
  [ChainId.MAINNET]: new Contract(
    '0x890f4e345B1dAED0367A877a1612f86A1f86985f',
    CURVE_POOL_ABI
  ),
};

const CURVE_UST_LIQUIDITY_GAUGE = {
  [ChainId.MAINNET]: new Contract(
    '0x3B7020743Bc2A4ca9EaF9D0722d42E20d6935855',
    CURVE_LIQUIDITY_GAUGE_ABI
  ),
};

const curve_ust = new Strategy('Curve UST', {
  protocol: 'curve',
  poolName: 'UST',
  protocolIcon: icons['curve'],
  outputAssets: ['CRV'],
  contracts: {
    pool: CURVE_UST_POOL,
    liquidityGauge: CURVE_UST_LIQUIDITY_GAUGE,
    gaugeController: CURVE_GAUGE_CONTROLLER,
  },
  allocationName: '',
  getApys: (chainId, library) => ({
    baseApy: getCurveBaseApy('ust'),
    CRV: getCrvTokenApy('curve_ust', chainId, library),
  }),
  description: 'Add strategy description here...',
});

export default curve_ust;
