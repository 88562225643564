import React, { useState, useEffect } from 'react';

import { loader, animate } from './LoadingSkeleton.module.scss';

function LoadingSkeleton({ children, isLoading }) {
  const [showLoading, setLoading] = useState(isLoading);

  useEffect(() => {
    if (isLoading === false) {
      setLoading(false);
    } else if (isLoading === true) {
      setLoading(true);
    }
  }, [isLoading]);

  return (
    <div
      className={`${showLoading ? loader : null} ${
        showLoading ? animate : null
      }`}
    >
      <span>{children}</span>
    </div>
  );
}

export default LoadingSkeleton;
