import React, { useReducer, useEffect } from 'react';

import { formButtonReducer } from '../../../../state/reducers';
import { capitalizeLabel } from '../../../../utils/helpers';

import NewButton from '../../buttons/NewButton';
import NumberInput from '../../inputs/NumberInput';
import {
  numberFormWrap,
  numberInputWrap,
  button,
} from './NumberForm.module.scss';

function NumberForm({
  classes = {},
  formType,
  poolsLocked,
  oracleLocked,
  isFormDisabled,
  selectedToken,
  allowance,
  isTokenApproved,
  inputValue,
  onInputChange,
  onMaxClick,
  onSubmit,
  setBtnLabel,
  children,
  isLoading,
}) {
  // Can turn this into a hook
  const [{ btnColor, btnLabel }, dispatch] = useReducer(formButtonReducer, {
    btnColor: 'green',
    btnLabel: setBtnLabel
      ? capitalizeLabel(setBtnLabel)
      : capitalizeLabel(formType),
  });

  useEffect(() => {
    if (!selectedToken) {
      dispatch({ type: 'NO_TOKEN_SELECTED', formType });
    } else if (poolsLocked || oracleLocked) {
      dispatch({ type: 'POOLS_LOCKED', formType });
    } else if (isTokenApproved?.data === false) {
      dispatch({ type: 'NEEDS_APPROVAL', selectedToken });
    } else {
      dispatch({ type: 'DEFAULT', formType, setBtnLabel, selectedToken });
    }
  }, [formType, selectedToken, isTokenApproved, poolsLocked, oracleLocked]);

  return (
    <form
      className={`${numberFormWrap} ${classes.numberFormWrap}`}
      onSubmit={onSubmit}
    >
      <NumberInput
        classes={{ numberInputWrap }}
        disabled={isFormDisabled || allowance?.data?.isZero()}
        value={inputValue}
        onChange={onInputChange}
        onMaxClick={onMaxClick}
      />
      <NewButton
        type="submit"
        classes={{ button }}
        color={btnColor}
        disabled={isFormDisabled}
        isLoading={isLoading}
      >
        {btnLabel}
      </NewButton>
      {children}
    </form>
  );
}

export default NumberForm;
