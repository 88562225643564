import React from 'react';
import { motion } from 'framer-motion';
import ReactDOM from 'react-dom';

import { overlay, remove } from './scss/MiniModal.module.scss';

function MiniModal({ variants = null, isOpen, onClose, classes, children }) {
  return ReactDOM.createPortal(
    <motion.div
      variants={variants}
      initial="open"
      animate="close"
      exit="open"
      className={`${overlay} ${
        classes.overlay ? classes.overlay.join(' ') : null
      } ${!isOpen ? remove : null}`}
      onClick={onClose}
    >
      <motion.div
        className={`${classes.modal ? classes.modal.join(' ') : null}`}
        onClick={evt => evt.stopPropagation()}
      >
        {children}
      </motion.div>
    </motion.div>,
    document.querySelector('#modal')
  );
}

MiniModal.defaultProps = {
  classes: {
    overlay: [],
    modal: [],
  },
};

export default MiniModal;
