import { Strategy } from '../utils/entities/Strategy';
import * as icons from '../assets/defi';
import { ChainId } from '../constants';

const convex_musd = new Strategy('Convex mUSD', {
  protocol: 'convex',
  poolName: 'musd',
  protocolIcon: icons['convex'],
  outputAssets: {
    gov: 'CVX',
    addtlReward: 'MTA',
    crv: 'CRV',
  },
  allocationName: 'convex-musd',
  tokenPricesNeeded: ['curve-dao-token', 'musd', 'convex-finance'],
  options: {
    curveLiquidityGaugeAddress: {
      [ChainId.MAINNET]: '0x5f626c30ec1215f4edcc9982265e8b1f411d1352',
    },
  },
});

export default convex_musd;
