import React from 'react';
import { motion } from 'framer-motion';

import { linkBtn } from './LinkButton.module.scss';

function LinkButton({ classes = {}, whileHover, children, onClick, style }) {
  return (
    <motion.button
      whileHover={whileHover}
      className={`${linkBtn} ${classes.linkBtn}`}
      onClick={onClick}
      style={style}
    >
      {children}
    </motion.button>
  );
}

export default LinkButton;
