import React from 'react';

function Logo({ color = '#d0d0d0', size = 35, addMargin = '0 0 0 0' }) {
  return (
    <svg
      style={{ margin: addMargin }}
      id="svg47"
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 294.8114 270.1236"
    >
      <g id="logo-logo">
        <g id="g44">
          <g id="g14">
            <path
              id="path12"
              style={{ fill: `${color}`, fillRule: 'evenodd' }}
              d="M128.43679,188.53361l16.2107,14.20287v67.38713l-2.07886-1.501.01379-.01578L90.765,230.92916Z"
              transform="translate(-0.00001 -0.00001)"
            />
          </g>
          <g id="g18">
            <path
              id="path16"
              style={{ fill: `${color}`, fillRule: 'evenodd' }}
              d="M101.28034,185.23654h18.899L84.042,225.90234l-55.92583-40.6658Z"
              transform="translate(-0.00001 -0.00001)"
            />
          </g>
          <g id="g22">
            <path
              id="path20"
              style={{ fill: `${color}`, fillRule: 'evenodd' }}
              d="M77.01809,230.77114H0q12.00863-19.26882,24.037-38.52383Z"
              transform="translate(-0.00001 -0.00001)"
            />
          </g>
          <g id="g26">
            <path
              id="path24"
              style={{ fill: `${color}`, fillRule: 'evenodd' }}
              d="M144.60063,115.36835q-18.97,31.12064-38.11162,62.13871H33.39391Q88.89569,88.68945,144.60063,0Z"
              transform="translate(-0.00001 -0.00001)"
            />
          </g>
          <g id="g30">
            <path
              id="path28"
              style={{ fill: `${color}`, fillRule: 'evenodd' }}
              d="M167.3626,188.53361l-16.21069,14.20287v67.38713l2.07886-1.501-.01381-.01578,51.81941-37.6777Z"
              transform="translate(-0.00001 -0.00001)"
            />
          </g>
          <g id="g34">
            <path
              id="path32"
              style={{ fill: `${color}`, fillRule: 'evenodd' }}
              d="M193.53009,185.23654h-18.899l36.13532,40.6658,55.92782-40.6658Z"
              transform="translate(-0.00001 -0.00001)"
            />
          </g>
          <g id="g38">
            <path
              id="path36"
              style={{ fill: `${color}`, fillRule: 'evenodd' }}
              d="M217.79136,230.77114h77.02005q-12.01455-19.26882-24.03894-38.52383Z"
              transform="translate(-0.00001 -0.00001)"
            />
          </g>
          <g id="g42">
            <path
              id="path40"
              style={{ fill: `${color}`, fillRule: 'evenodd' }}
              d="M152.18975,115.36835q18.97,31.12064,38.10964,62.13871h73.097Q207.89175,88.68945,152.18975,0Z"
              transform="translate(-0.00001 -0.00001)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Logo;
