import { Strategy } from '../utils/entities/Strategy';
import { Contract } from '../utils/entities/Contract';
import * as icons from '../assets/defi';
import { ChainId } from '../constants';

import { CURVE_POOL_ABI, CURVE_LIQUIDITY_GAUGE_ABI } from './abis';
import {
  CURVE_GAUGE_CONTROLLER,
  getCurveBaseApy,
  getCrvTokenApy,
} from './utils/curve';

const CURVE_USDN_POOL = {
  [ChainId.MAINNET]: new Contract(
    '0x0f9cb53Ebe405d49A0bbdBD291A65Ff571bC83e1',
    CURVE_POOL_ABI
  ),
};

const CURVE_USDN_LIQUIDITY_GAUGE = {
  [ChainId.MAINNET]: new Contract(
    '0xF98450B5602fa59CC66e1379DFfB6FDDc724CfC4',
    CURVE_LIQUIDITY_GAUGE_ABI
  ),
};

const curve_usdn = new Strategy('Curve USDN', {
  protocol: 'curve',
  poolName: 'USDN',
  protocolIcon: icons['curve'],
  outputAssets: ['CRV'],
  contracts: {
    pool: CURVE_USDN_POOL,
    liquidityGauge: CURVE_USDN_LIQUIDITY_GAUGE,
    gaugeController: CURVE_GAUGE_CONTROLLER,
  },
  allocationName: '',
  getApys: (chainId, library) => ({
    baseApy: getCurveBaseApy('usdn'),
    CRV: getCrvTokenApy('curve_usdn', chainId, library),
  }),
  description: 'Add strategy description here...',
});

export default curve_usdn;
