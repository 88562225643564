import React from 'react';
import styled from 'styled-components';
import { ClipLoader } from 'react-spinners';

function Button({ type = 'button', isDisabled, isLoading, label, ...props }) {
  return (
    <StyledButton disabled={isDisabled} type={type} {...props}>
      {isLoading ? <ClipLoader size={16} color={'#d0d0d0'} /> : label}
    </StyledButton>
  );
}

const buttonColors = {
  purple: {
    border: '#663da4',
    bg: '#231638',
  },
  blue: {
    border: '#3382d7',
    bg: '#0a1e33',
  },
  green: {
    border: '#04b485',
    bg: '#022219',
  },
  gold: {
    border: '#cd9704',
    bg: '#3c2c01',
  },
  orange: {
    border: '#ff8902',
    bg: '#4e2a01',
  },
  red: {
    border: '#c2005a',
    bg: '#4b0626',
  },
};

const StyledButton = styled.button`
  border: 1px solid transparent;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius[1]};
  padding: ${({ theme, padding }) =>
    padding
      ? []
          .concat(padding)
          .map(padKey => theme.space[padKey])
          .join(' ')
      : theme.space[3]};
  color: inherit;
  letter-spacing: 1.25px;
  text-align: center;
  cursor: pointer;

  ${({ color }) =>
    color &&
    `
    border-color: ${buttonColors[color].border};
    background-color: ${buttonColors[color].bg};
  `};

  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
    opacity: .4;
  `}
`;

export default Button;
