import { useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';

export function useNetworkListener() {
  const { active, activate } = useWeb3React();

  useEffect(() => {
    const { ethereum } = window;
    if (ethereum && ethereum.on && active) {
      const handleChainChanged = chainId => {
        console.log("Handling 'chainChanged' w/ page refresh on", chainId);
        window.location.reload();
      };

      const handleNetworkChanged = networkId => {
        console.log("Handling 'networkChanged' w/ page refresh on", networkId);
        window.location.reload();
      };

      // Refresh page when accounts change
      ethereum.on('accountsChanged', () => window.location.reload());
      ethereum.on('chainChanged', handleChainChanged);
      ethereum.on('networkChanged', handleNetworkChanged);
    }
  }, [active, activate]);
}
