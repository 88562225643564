import styled from 'styled-components';

/**
 * Fractional units (fr) are only available w/ display: grid
 * - An "fr" gives a fraction of the remaining space available
 * - i.e. - Takes remaining space and divides among all fractions
 * - Not to be confused w/ % which refers to the size of the parent
 */

const gridFractions = {
  '1/4': '1fr 3fr',
  '1/3': '1fr 2fr',
  '1/2': '1fr 1fr',
  '2/3': '2fr 1fr',
  '3/4': '3fr 1fr',
  'auto-start': 'auto 1fr',
  'auto-end': '1fr auto',
};

const justifyAlignMap = {
  start: 'flex-start',
  center: 'center',
  end: 'flex-end',
  spaceBetween: 'space-between',
};

// ----------------------------- //
// ---------- SPACERS ---------- //
// ----------------------------- //
export const Stack = styled.div`
  display: grid;
  gap: ${({ theme, gap }) => (gap >= 0 ? theme.space[gap] : theme.space[4])};
`;

export const Split = styled.div`
  display: grid;
  gap: ${({ theme, gap }) => theme.space[gap] ?? theme.space[3]};
  grid-template-columns: ${({ minmax, fraction }) =>
    minmax
      ? `repeat(auto-fit, minmax(${minmax}))`
      : gridFractions[fraction] ?? gridFractions['1/2']};
`;

export const Inline = styled.div`
  --gap: ${({ theme, gap }) => theme.space[gap] ?? theme.space[4]};

  display: flex;
  gap: var(--gap);
  flex-wrap: wrap;
  justify-content: ${({ justify }) =>
    justifyAlignMap[justify] ?? justifyAlignMap.start};
  align-items: ${({ align }) =>
    justifyAlignMap[align] ?? justifyAlignMap.start};
`;

export const Columns = styled.div`
  --columns: ${({ columns = 1 }) => columns};

  display: grid;
  gap: ${({ theme, gap }) => theme.space[gap] ?? theme.space[4]};
  grid-template-columns: repeat(var(--columns), 1fr);
`;

export const Column = styled.div`
  grid-column: span min(${({ spread = 1 }) => spread}, var(--columns));
`;

export const Grid = styled.div`
  display: grid;
  gap: ${({ theme, gap }) => theme.space[gap] ?? theme.space[4]};
  grid-template-columns: repeat(
    auto-fit,
    minmax(min(${({ minItemWidth }) => minItemWidth ?? '320px'}, 100%), 1fr)
  );
`;

// ----------------------------- //
// --------- WRAPPERS ---------- //
// ----------------------------- //
export const PadBox = styled.div`
  padding: ${({ theme, padding }) =>
    padding
      ? []
          .concat(padding)
          .map(padKey => theme.space[padKey])
          .join(' ')
      : theme.space[4]};
`;

export const Center = styled(PadBox)`
  box-sizing: content-box;
  max-inline-size: ${props => props.maxWidth};
  margin-inline-start: auto;
  margin-inline-end: auto;
  ${props =>
    props.centerText &&
    `
    text-align: center;
  `}

  ${props =>
    props.centerChildren &&
    `
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`;
