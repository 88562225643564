import React, { useContext, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { BigNumber } from 'bignumber.js';

import { TrxContext } from '../../../../../../../state/contexts';
import useTransaction from '../../../../../../../hooks/useTransaction';
import { useAccountBalance } from '../../../../../../../hooks';
import { POOL_CONTRACT_KEYS } from '../../../../../../../constants';
import {
  getContracts,
  fromWei,
  displayAmount,
} from '../../../../../../../utils/helpers';

import PercentageForm from '../../../../../../apy/forms/PercentageForm';

function WithdrawForm(props) {
  const { selectedToken, inputValue, dispatch, formType } = props;

  const { library, chainId, account } = useWeb3React();
  const { showTrxModal } = useContext(TrxContext);
  const [isLoading, setIsLoading] = useState(false);

  // Custom hooks
  const { updateBalance: updateStablecoinBalance } = useAccountBalance(
    selectedToken
  );
  const { updateBalance: updatePoolBalance } = useAccountBalance(
    POOL_CONTRACT_KEYS[selectedToken]
  );
  const [sendTransaction] = useTransaction();

  // Handle Withdrawal
  const onSubmit = async evt => {
    evt.preventDefault();

    const signer = library.getSigner();

    const connectedContract = (
      await getContracts([POOL_CONTRACT_KEYS[selectedToken]], chainId, library)
    ).connect(signer);

    // Calc how much APT withdrawing based on percentage
    const aptBalance = await connectedContract.balanceOf(account);
    const aptWithdrawing = aptBalance.mul(inputValue).div(100);

    // Get withdrawal amount minus fee (if applicable)
    const withdrawalAmt = await connectedContract.getUnderlyerAmountWithFee(
      aptWithdrawing
    );

    // Input Validation
    // Prohibit empty input and 0%
    if (!inputValue || BigNumber(inputValue).isLessThanOrEqualTo(0)) {
      dispatch({ type: 'INPUT_ERROR', errorMsg: 'Enter a percentage' });
      return;

      // Prohibit withdrawal amount under .01 (takes into account withdrawal fee if applicable)
    } else if (fromWei(withdrawalAmt, selectedToken).isLessThan(0.01)) {
      dispatch({
        type: 'INPUT_ERROR',
        errorMsg: 'Cannot withdraw less than .01',
      });
      return;
    }

    const proceedWithWithdrawal = async () => {
      dispatch({ type: 'SETUP_TRANSACTION' });
      setIsLoading(true);

      const stableCoinContract = await getContracts(
        [selectedToken],
        chainId,
        library
      );

      const stableCoinPoolReserve = await stableCoinContract.balanceOf(
        connectedContract.address
      );
      const isReserveSufficient = withdrawalAmt.lte(stableCoinPoolReserve);

      if (isReserveSufficient) {
        const res = await sendTransaction('WITHDRAW', {
          contract: connectedContract,
          functionName: 'redeem',
          args: [aptWithdrawing],
          options: {
            token: selectedToken,
            amount: displayAmount(fromWei(withdrawalAmt, selectedToken)),
          },
        }).catch(err => console.error(err.message));

        if (res === 'SUCCESS') {
          updateStablecoinBalance(undefined, true);
          updatePoolBalance(undefined, true);

          dispatch({ type: 'SUCCESSFUL_TRANSACTION', formType });
        } else {
          dispatch({ type: 'FAILED_TRANSACTION' });
        }
      } else {
        showTrxModal('INSUFFICIENT_RESERVE', {
          message: 'Insufficient Reserve',
          selectedToken: selectedToken,
          withdrawalAmount: displayAmount(
            fromWei(withdrawalAmt, selectedToken)
          ),
          reserveAmount: displayAmount(
            fromWei(stableCoinPoolReserve, selectedToken)
          ),
        });

        dispatch({ type: 'FAILED_TRANSACTION' });
      }

      setIsLoading(false);
    };

    const isEarlyRedeem = await connectedContract
      .isEarlyRedeem()
      .catch(err => console.log(err.message));

    if (isEarlyRedeem) {
      showTrxModal('EARLY_REDEEM', {
        message: 'Early Withdrawal Fee',
        onBtnClick: proceedWithWithdrawal,
        token: selectedToken,
        amount: displayAmount(fromWei(withdrawalAmt, selectedToken)),
      });
    } else {
      showTrxModal('WITHDRAWAL_FEE', {
        message: 'Withdrawal Fee',
        onBtnClick: proceedWithWithdrawal,
        token: selectedToken,
        amount: displayAmount(fromWei(withdrawalAmt, selectedToken)),
      });
    }
  };

  return (
    <PercentageForm {...props} onSubmit={onSubmit} isLoading={isLoading} />
  );
}

export default WithdrawForm;
