import { Strategy } from '../utils/entities/Strategy';
import { Contract } from '../utils/entities/Contract';
import * as icons from '../assets/defi';
import { ChainId } from '../constants';

import { CURVE_POOL_ABI, CURVE_LIQUIDITY_GAUGE_ABI } from './abis';
import { CURVE_GAUGE_CONTROLLER } from './utils/curve';

const OUSD_POOL = {
  [ChainId.MAINNET]: new Contract(
    '0x87650D7bbfC3A9F10587d7778206671719d9910D',
    CURVE_POOL_ABI
  ),
};

const OUSD_LIQUIDITY_GAUGE_ADDRESS =
  '0x25f0cE4E2F8dbA112D9b115710AC297F816087CD';

const OUSD_LIQUIDITY_GAUGE = {
  [ChainId.MAINNET]: new Contract(
    OUSD_LIQUIDITY_GAUGE_ADDRESS,
    CURVE_LIQUIDITY_GAUGE_ABI
  ),
};

const curve_ousd = new Strategy('Curve Origin Dollar', {
  protocol: 'curve',
  protocolIcon: icons['curve'],
  poolName: 'ousd',
  outputAssets: ['CRV', 'OGN'],
  allocationName: 'curve-ousd',
  tokenPricesNeeded: ['curve-dao-token', 'origin-dollar'],
  contracts: {
    pool: OUSD_POOL,
    liquidityGauge: OUSD_LIQUIDITY_GAUGE,
    gaugeController: CURVE_GAUGE_CONTROLLER,
  },
  options: {
    isCurveFactory: true,
    calcCrvApy: true,
  },
});

export default curve_ousd;
