import React from 'react';
import { BeatLoader } from 'react-spinners';
import { useMedia } from 'use-media';

import { useClaimStore } from '../../../../state/stores';
import { displayAmount, fromWei } from '../../../../utils/helpers';

import PendingBalance from '../PendingBalance';
import Panel from '../../containers/Panel';
import Logo from '../../visuals/Logo';
import {
  panelWrap,
  panelContainer,
  addressBox,
  greenDot,
  accountAddress,
  balanceBox,
} from './UserPill.module.scss';

function UserPill({ account, balance }) {
  const pendingClaim = useClaimStore(state => state.pendingClaim);

  // Media query
  const isVwOver550 = useMedia({ minWidth: '551px' });

  const showAddress = account => {
    return `${account.substring(0, 6)}...${account.substring(
      account.length - 3
    )}`;
  };

  return (
    <Panel classes={{ panelWrap, panelContainer }}>
      <div className={addressBox}>
        <span className={greenDot} />
        <span className={accountAddress}>{showAddress(account)}</span>
      </div>
      {isVwOver550 && (
        <div className={balanceBox}>
          {balance.isLoading ? (
            <BeatLoader size={10} margin={1} color={'gray'} />
          ) : (
            <PendingBalance
              balance={displayAmount(fromWei(balance.data, 'APY'))}
              message={`Awaiting ${pendingClaim.blocksLeft} block ${
                pendingClaim.blocksLeft === 1 ? 'confirmation' : 'confirmations'
              }`}
            />
          )}
          <Logo color="#8e40dd" size={20} addMargin={'0 0 .1rem .5rem'} />
        </div>
      )}
    </Panel>
  );
}

export default UserPill;
