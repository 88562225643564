import React, { useState, useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { useMedia } from 'use-media';

import { PageTourContext } from '../../../../state/contexts';

import TourBanner from '../TourBanner';
import LinkButton from '../../buttons/LinkButton';
import {
  backdrop,
  pageTourBoxWrap,
  mobileTourBox,
  centerTourBox,
  gradientBorder,
  pageTourBoxContainer,
  boxTitle,
  boxContent,
  boxControls,
} from './PageTourBox.module.scss';

function PageTourBox({ steps, isActive, refElement }) {
  const {
    currentTourStep,
    goForwardStep,
    goBackStep,
    endTour,
    saveTourComplete,
    currentTourPage,
  } = useContext(PageTourContext);

  // Media queries
  const isVwOver768 = useMedia({ minWidth: '769px' });

  // Handle multiple tour steps
  let step = steps;
  if (Array.isArray(steps) && isActive) {
    step = steps.find(tourStep => tourStep.stepNum === currentTourStep);
  }

  const [popperRef, setPopperRef] = useState();
  const { styles, attributes } = usePopper(
    refElement,
    popperRef,
    step?.options
  );

  // Scrolls to the element that tour box is describing
  useEffect(() => {
    if (isActive && refElement) {
      const scrollPosition =
        currentTourPage === 'portfolio' &&
        [4, 5, 6, 7].includes(currentTourStep)
          ? 'end'
          : 'center';

      refElement.scrollIntoView({
        behavior: 'smooth',
        block: isVwOver768 ? scrollPosition : 'start',
      });
    }
  }, [refElement, isActive, currentTourStep, currentTourPage, isVwOver768]);

  return ReactDOM.createPortal(
    isActive && (
      <div className={`${backdrop}`}>
        <TourBanner>Tour mode activated</TourBanner>
        <div
          className={`${pageTourBoxWrap} ${
            !isVwOver768 ? mobileTourBox : undefined
          } ${!refElement ? centerTourBox : undefined}`}
        >
          <div
            className={`${pageTourBoxContainer} ${gradientBorder}`}
            ref={setPopperRef}
            style={refElement && isVwOver768 ? styles.popper : null}
            {...attributes.popper}
          >
            <div className={boxTitle}>{step.title}</div>
            <div className={boxContent}>{step.content}</div>
            <div className={boxControls}>
              {step.isLastStep ? (
                <LinkButton
                  whileHover={{ scale: 1.2 }}
                  onClick={goBackStep}
                  style={{
                    border: 'none',
                    fontSize: 24,
                    color: '#7f39c5',
                  }}
                >
                  &#10094;
                </LinkButton>
              ) : (
                <LinkButton whileHover={{ scale: 1.05 }} onClick={endTour}>
                  End Tour
                </LinkButton>
              )}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {step.stepNum > 0 && !step.isLastStep && (
                  <LinkButton
                    whileHover={{ scale: 1.2 }}
                    onClick={goBackStep}
                    style={{
                      border: 'none',
                      marginRight: '1.5rem',
                      fontSize: 24,
                      color: '#7f39c5',
                    }}
                  >
                    &#10094;
                  </LinkButton>
                )}
                {step.isLastStep ? (
                  <LinkButton
                    whileHover={{ scale: 1.05 }}
                    onClick={() => saveTourComplete(currentTourPage)}
                  >
                    Mark Tour as Complete
                  </LinkButton>
                ) : (
                  <LinkButton
                    whileHover={{ scale: 1.2 }}
                    style={{
                      fontSize: 24,
                      border: 'none',
                      color: '#7f39c5',
                    }}
                    onClick={goForwardStep}
                  >
                    &#10095;
                  </LinkButton>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    ),
    document.querySelector('#pageTour')
  );
}

export default PageTourBox;
