import { Strategy } from '../utils/entities/Strategy';
import { Contract } from '../utils/entities/Contract';
import * as icons from '../assets/defi';
import { ChainId } from '../constants';

import { CURVE_POOL_ABI, CURVE_LIQUIDITY_GAUGE_ABI } from './abis';
import {
  CURVE_GAUGE_CONTROLLER,
  getCurveBaseApy,
  getCrvTokenApy,
  getCurveRewardTokenApy,
} from './utils/curve';

const CURVE_FRAX_POOL = {
  [ChainId.MAINNET]: new Contract(
    '0xd632f22692FaC7611d2AA1C0D552930D43CAEd3B',
    CURVE_POOL_ABI
  ),
};

const FRAX_LIQUIDITY_GAUGE_ADDRESS =
  '0x72e158d38dbd50a483501c24f792bdaaa3e7d55c';

const CURVE_FRAX_LIQUIDITY_GAUGE = {
  [ChainId.MAINNET]: new Contract(
    FRAX_LIQUIDITY_GAUGE_ADDRESS,
    CURVE_LIQUIDITY_GAUGE_ABI
  ),
};

const curve_frax = new Strategy('Curve FRAX', {
  protocol: 'curve',
  poolName: 'frax',
  protocolIcon: icons['curve'],
  outputAssets: ['CRV', 'FXS'],
  contracts: {
    pool: CURVE_FRAX_POOL,
    liquidityGauge: CURVE_FRAX_LIQUIDITY_GAUGE,
    gaugeController: CURVE_GAUGE_CONTROLLER,
  },
  allocationName: 'curve-frax',
  tokenPricesNeeded: ['curve-dao-token', 'frax'],
  getApys: (chainId, library) => ({
    baseApy: getCurveBaseApy('frax'),
    CRV: getCrvTokenApy('curve_frax', chainId, library),
    FXS: getCurveRewardTokenApy(FRAX_LIQUIDITY_GAUGE_ADDRESS),
  }),
  description: 'Add strategy description here...',
});

export default curve_frax;
