import React, { forwardRef } from 'react';
import { motion } from 'framer-motion';
import { useMedia } from 'use-media';

import { gradientHeader } from '../../shared/FramerVariants';
import {
  sectionHeaderWrap,
  sectionHeaderTitle,
  sectionHeaderDescription,
} from './SectionHeader.module.scss';

const SectionHeader = forwardRef(
  (
    {
      classes = {},
      title,
      tooltip,
      children,
      isTourElementActive,
      pageTourBox,
    },
    ref
  ) => {
    // Media queries
    const isVwOver500 = useMedia({ minWidth: '501px' });

    return (
      <>
        <motion.div
          variants={gradientHeader}
          initial="initial"
          animate={isTourElementActive ? 'animate' : 'initial'}
          ref={ref}
          className={`${sectionHeaderWrap} ${classes.sectionHeaderWrap}`}
          style={
            isTourElementActive && {
              zIndex: 8,
              position: 'relative',
              scrollMarginTop: isVwOver500 ? '6.5rem' : '5rem',
            }
          }
        >
          <h1 className={`${sectionHeaderTitle} ${classes.sectionHeaderTitle}`}>
            {title}
            {tooltip}
          </h1>
          <h3
            className={`${sectionHeaderDescription} ${classes.sectionHeaderDescription}`}
          >
            {children}
          </h3>
        </motion.div>

        {pageTourBox}
      </>
    );
  }
);

export default SectionHeader;
