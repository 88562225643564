import React from 'react';

// import { useTourControls } from '../../../hooks';

import StakingPage from '../../apy/templates/StakingPage';
// import { pageTourToggle } from './Uni.module.scss';

function Uni() {
  // const { PageTourToggle } = useTourControls('staking');

  return (
    <StakingPage
      protocol={'Uniswap'}
      poolToken={'UNIV2'}
      underlyer={'WETH'}
      poolUrl={
        'https://app.uniswap.org/#/add/v2/0x95a4492f028aa1fd432ea71146b433e7b4446611/ETH'
      }
      // pageTourToggle={<PageTourToggle classes={[pageTourToggle]} />}
    />
  );
}

export default Uni;
