import React from 'react';
import { motion } from 'framer-motion';

import { closeButtonWrap, small, medium } from './Close.module.scss';

const Close = ({ onClick, size = 'medium' }) => {
  const sizes = {
    small: small,
    medium: medium,
  };

  return (
    <motion.button
      whileHover={{ scale: 1.15 }}
      whileTap={{ scale: 0.85 }}
      className={`${closeButtonWrap} ${sizes[size]}`}
      onClick={onClick}
    />
  );
};

export default Close;
