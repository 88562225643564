import { useEffect, useMemo } from 'react';
import { useWeb3React } from '@web3-react/core';
import useEtherSWR from 'ether-swr';

import * as Tokens from '../constants/tokens';
import { useAbisStore } from '../state/stores';
import { FetcherError } from '../utils/errors';
import {
  fromWei,
  getContracts,
  isChainSupported,
  isListEmpty,
} from '../utils/helpers';

export function useAllowance(tokenKey, spender) {
  const { chainId, library, account } = useWeb3React();

  // Zustand
  const { abis: ABIs } = useAbisStore(state => state.abiInfo);

  const {
    data: allowance,
    error: allowanceError,
    mutate,
  } = useEtherSWR(
    !isListEmpty(ABIs) && tokenKey && chainId
      ? [Tokens[tokenKey][chainId].address, 'allowance', account, spender]
      : [],
    { ABIs: new Map(ABIs) }
  );

  useEffect(() => {
    if (!chainId || !isChainSupported(chainId)) {
      return;
    }

    const listenForApprovals = async () => {
      const contract = await getContracts([tokenKey], chainId, library);

      const approved = contract.filters.Approval(account, null);
      library.on(approved, () => {
        mutate(undefined, true);
      });
    };

    if (library && account && tokenKey) {
      listenForApprovals();
    }

    return () => {
      if (library) {
        library.removeAllListeners();
      }
    };
  }, [chainId, tokenKey, library, account, mutate]);

  const data = useMemo(() => {
    let error;

    if (allowanceError) {
      error = new FetcherError(
        allowanceError.data?.message ?? allowanceError.message,
        '"allowance"',
        'useAllowance'
      );
      console.error(error.message);
    }

    return {
      data: fromWei(allowance, tokenKey),
      isLoading: !error && !allowance,
      error: error,
    };
  }, [allowance, allowanceError]);

  return { data, mutate };
}
