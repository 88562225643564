import React from 'react';

import { slider } from './PercentageSlider.module.scss';

function PercentageSlider({ classes = {}, value, onChange }) {
  const handleChange = evt => {
    onChange(evt.target.value);
  };

  return (
    <input
      type="range"
      id="slider"
      className={`${slider} ${classes.slider}`}
      min="0"
      max="100"
      value={value || '0'}
      step="1"
      onChange={handleChange}
      style={{
        background: `linear-gradient(90deg, #373737 ${value}%, #202020 ${value}%)`,
      }}
    />
  );
}

export default PercentageSlider;
