import React from 'react';
import { motion } from 'framer-motion';

import { inner } from './FeedbackOrbit.module.scss';

const Inner = ({ color }) => {
  return (
    <motion.div
      animate={{ rotate: [270, 0, -270, 0, 270] }}
      transition={{ repeat: Infinity, duration: 15 }}
      className={inner}
    >
      <svg
        id="svg47"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 55.5172 56.5967"
        height="100%"
        width="100%"
      >
        <path
          style={{ fill: color }}
          d="M2500.05426,1478.73106a20.92674,20.92674,0,1,1-14.7976,6.1293,20.86213,20.86213,0,0,1,14.7976-6.1293Zm14.53,6.3967a20.54858,20.54858,0,1,0,6.0186,14.5302,20.48408,20.48408,0,0,0-6.0186-14.5302Z"
          transform="translate(-2472.04436 -1471.38586)"
        />
        <polygon
          style={{ fill: color, fillRule: 'evenodd' }}
          points="20.855 4.96 21.366 4.805 22.475 8.45 21.964 8.606 20.855 4.96 20.855 4.96"
        />
        <polygon
          style={{ fill: color, fillRule: 'evenodd' }}
          points="47.739 22.473 47.583 21.963 49.801 21.288 49.957 21.799 47.739 22.473 47.739 22.473"
        />
        <polygon
          style={{ fill: color, fillRule: 'evenodd' }}
          points="6.136 35.12 5.981 34.609 8.198 33.934 8.353 34.445 6.136 35.12 6.136 35.12"
        />
        <path
          style={{ fill: color, fillRule: 'evenodd' }}
          d="M2527.31266,1499.72736l-.813-.04821a24.64717,24.64717,0,0,1-1.0759,7.54021l2.1378.9513a29.17943,29.17943,0,0,1-14.1919,17.191l-1.4893-2.514a26.36243,26.36243,0,0,1-11.4365,3.0069l-.0467.9487a22.60626,22.60626,0,0,1-6.9554-.8674c-.0316-.012-.6332,2.0467-.6332,2.0467a29.24307,29.24307,0,0,1-18.5536-13.9398l1.7091-.9788c-2.5079-3.4499-4.2242-8.818-3.8731-15.085l.9055.0303a20.675,20.675,0,0,1,1.2041-6.9605l-2.1562-1.0882a29.50522,29.50522,0,0,1,14.3859-16.4486l1.0294,1.622c3.2573-1.47081,7.748-3.091,11.3828-2.8499l.1345-.8982c15.3393.5282,28.6516,11.1059,28.3357,28.3415Zm-27.5919,25.2037a25.40305,25.40305,0,1,1,25.4029-25.4031,25.40309,25.40309,0,0,1-25.4029,25.4031Z"
          transform="translate(-2472.04436 -1471.38586)"
        />
        <path
          style={{ fill: color }}
          d="M2499.80306,1474.05556a25.57807,25.57807,0,1,1-18.0499,7.5065,25.39566,25.39566,0,0,1,18.0499-7.5065Zm17.7237,7.8341a25.23672,25.23672,0,1,0,7.3413,17.79449,24.93619,24.93619,0,0,0-7.3413-17.79449Z"
          transform="translate(-2472.04436 -1471.38586)"
        />
      </svg>
    </motion.div>
  );
};

export default Inner;
