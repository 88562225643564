import { Strategy } from '../utils/entities/Strategy';
import * as icons from '../assets/defi';
import { ChainId } from '../constants';

const convex_aave = new Strategy('Convex Aave', {
  protocol: 'convex',
  poolName: 'aave',
  protocolIcon: icons['convex'],
  outputAssets: {
    gov: 'CVX',
    addtlReward: 'STKAAVE',
    crv: 'CRV',
  },
  allocationName: 'convex-aave',
  tokenPricesNeeded: ['curve-dao-token', 'convex-finance'],
  options: {
    curveLiquidityGaugeAddress: {
      [ChainId.MAINNET]: '0xd662908ADA2Ea1916B3318327A97eB18aD588b5d',
    },
  },
});

export default convex_aave;
