import styled from 'styled-components';

import { Columns, PadBox } from './primitives';
import { TransparentButton } from './TransparentButton.styled';

export const Tabs = styled(PadBox).attrs(({ padding }) => ({
  as: Columns,
  columns: 3,
  gap: 2,
  padding: padding ?? [2],
}))`
  border: 2px solid ${({ theme }) => theme.colors.grey[2]};
  border-radius: ${({ theme }) => theme.borderRadius[1]};
  background-color: ${({ theme }) => theme.colors.grey[0]};
`;

export const Tab = styled(TransparentButton)`
  --activeBorder: 1px solid ${({ theme }) => theme.colors.grey[2]};
  --activeBorderRadius: 3px;
  --activeBgColor: ${({ theme }) => theme.colors.grey[3]};
  --activeFontColor: #f1f1f6;

  padding: 1rem;
  font-size: inherit;
  letter-spacing: 1.25px;
  color: ${({ theme }) => theme.colors.grey[4]};

  ${({ active }) =>
    active &&
    `
  border: var(--activeBorder);
  border-radius: var(--activeBorderRadius);
  background-color: var(--activeBgColor);
  color: var(--activeFontColor);
`};
`;
